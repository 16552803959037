import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFI from "./assets/locales/fi.json";
import translationEN from "./assets/locales/en.json";
import translationSE from "./assets/locales/se.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fi: {
        translation: translationFI,
      },
      se: {
        translation: translationSE,
      },
    },
    lng: "fi",
    fallbackLng: ["en", "fi", "se"],

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { observable, action, makeObservable, runInAction } from "mobx";
import { Tty } from "@mui/icons-material";
import { deleteLocation,
  getLocation,
  getLocationCount,
  getLocations,
  getLocationsPaged,
  saveNewLocation,
  uploadMultipleLocations,
  editLocation, uploadMultipleLocationsToCustomerOrganization, getLocationsForSelect } from "../api/LocationsApi";
import Location from "../models/Location";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import CreateLocation from "../models/CreateLocation";

export default class LocationStore extends BaseStore {
    locations?: Location[];

    allLocations: Location[] = [];

    location?: Location;

    totalLocations = 0;

    totalLocationsForCard = 0;

    fetchingLocations = true;

    fetchingLocationsCount = true;

    newlyCreatedLocations: Location[] = [];

    creatingLocationsInProgress = false;

    // FOR DYNAMICALLY POPULATED SELECTS
    meterEditSelectLocations: Location[] = [];

    meterEditSelectLocationsAvailable = 0;

    fetchingMeterEditLocations = false;

    newUserSelectLocations: Location[] = [];

    newUserSelectLocationsAvailable = 0;

    fetchingNewUserLocations = false;

    editUserSelectLocations: Location[] = [];

    editUserSelectLocationsAvailable = 0;

    fetchingEditUserLocations = false;

    // FOR DYNAMICALLY POPULATED SELECTS
    loadMeterEditLocations = async (userToken: string, search: string, abortSignal?: AbortSignal): Promise<void> => {
      try {
        this.fetchingMeterEditLocations = true;
        const locationsResp = await getLocationsForSelect(userToken, 0, 15, abortSignal, search);
        runInAction(() => {
          if (abortSignal && !abortSignal.aborted && locationsResp) {
            this.meterEditSelectLocations = locationsResp.content;

            if (locationsResp.totalElements > 15) { this.meterEditSelectLocationsAvailable = locationsResp.totalElements - 15; } else { this.meterEditSelectLocationsAvailable = 0; }
          } else {
            this.meterEditSelectLocations = [];
            this.meterEditSelectLocationsAvailable = 0;
          }
          this.fetchingMeterEditLocations = false;
        });
      } catch (e) {
        this.fetchingMeterEditLocations = false;
        this.setError(i18n.t("locationstore.locationsFetchError"));
        console.error(e.stack);
      }
    }

    // FOR DYNAMICALLY POPULATED SELECTS
    loadNewUserLocations = async (userToken: string, search: string, abortSignal?: AbortSignal): Promise<void> => {
      try {
        this.fetchingNewUserLocations = true;
        const locationsResp = await getLocationsForSelect(userToken, 0, 15, abortSignal, search);
        runInAction(() => {
          if (abortSignal && !abortSignal.aborted && locationsResp) {
            this.newUserSelectLocations = locationsResp.content;

            if (locationsResp.totalElements > 15) { this.newUserSelectLocationsAvailable = locationsResp.totalElements - 15; } else { this.newUserSelectLocationsAvailable = 0; }
          } else {
            this.newUserSelectLocations = [];
            this.newUserSelectLocationsAvailable = 0;
          }
          this.fetchingNewUserLocations = false;
        });
      } catch (e) {
        this.fetchingNewUserLocations = false;
        this.setError(i18n.t("locationstore.locationsFetchError"));
        console.error(e.stack);
      }
    }

    // FOR DYNAMICALLY POPULATED SELECTS
    loadEditUserLocations = async (userToken: string, search: string, abortSignal?: AbortSignal): Promise<void> => {
      try {
        this.fetchingEditUserLocations = true;
        const locationsResp = await getLocationsForSelect(userToken, 0, 15, abortSignal, search);
        runInAction(() => {
          if (abortSignal && !abortSignal.aborted && locationsResp) {
            this.editUserSelectLocations = locationsResp.content;

            if (locationsResp.totalElements > 15) { this.editUserSelectLocationsAvailable = locationsResp.totalElements - 15; } else { this.editUserSelectLocationsAvailable = 0; }
          } else {
            this.editUserSelectLocations = [];
            this.editUserSelectLocationsAvailable = 0;
          }
          this.fetchingEditUserLocations = false;
        });
      } catch (e) {
        this.fetchingEditUserLocations = false;
        this.setError(i18n.t("locationstore.locationsFetchError"));
        console.error(e.stack);
      }
    }

    loadLocation = async (userToken: string, locationid: string): Promise<void> => {
      try {
        this.fetchingLocations = true;

        const conf = await getLocation(userToken, locationid);
        runInAction(() => {
          this.location = conf;
          this.fetchingLocations = false;
        });
      } catch (e: any) {
        this.fetchingLocations = false;
        this.setError("Virhe yksittäisen käyttöpaikan haussa");
        console.error(e.stack);
      }
    }

    loadLocations = async (userToken: string): Promise<void> => {
      try {
        this.fetchingLocations = true;
        const locations = await getLocations(userToken);
        runInAction(() => {
          this.allLocations = locations;
          this.fetchingLocations = false;
        });
        // todo: sort?
      } catch (e: any) {
        this.fetchingLocations = false;
        this.setError("Virhe käytöpaikkojen haussa");
        console.error(e.stack);
      }
    }

  loadLocationsPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<void> => {
    try {
      this.fetchingLocations = true;
      const locationsResp = await getLocationsPaged(userToken, page, pageSize, sortBy, sortDirection, archived, search);
      runInAction(() => {
        this.locations = locationsResp.content;
        this.totalLocations = locationsResp.totalElements;
        this.fetchingLocations = false;
      });
    } catch (e: any) {
      this.fetchingLocations = false;
      this.setError(i18n.t("locationstore.locationsFetchError"));
      this.locations = [];
      console.error(e.stack);
    }
  }

  uploadMultipleLocationsWithFile = async (userToken: string, file: File, organizationId?: string): Promise<void> => {
    try {
      this.creatingLocationsInProgress = true;
      if (organizationId) {
        this.newlyCreatedLocations = await uploadMultipleLocationsToCustomerOrganization(userToken, file, organizationId);
      } else {
        this.newlyCreatedLocations = await uploadMultipleLocations(userToken, file);
      }
      this.locations = this.locations?.concat(this.newlyCreatedLocations);
      this.totalLocations += this.newlyCreatedLocations.length;
      this.creatingLocationsInProgress = false;
    } catch (e: any) {
      this.setError(i18n.t("locationStore.locationsFetchError"));
      console.error(e.stack);
      this.creatingLocationsInProgress = false;
    }
  }

  getTotalLocationCount = async (userToken: string) :Promise<void> => {
    try {
      this.fetchingLocationsCount = true;
      const locationResp = await getLocationCount(userToken);
      runInAction(() => {
        this.totalLocationsForCard = locationResp;
        this.fetchingLocationsCount = false;
      });
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.metersOfLocationFetchError"));
      this.totalLocationsForCard = 0;
    }
  }

  removeLocation = async (userToken: string, location: Location): Promise<void> => {
    try {
      await deleteLocation(userToken, location);

      runInAction(() => {
        if (this.locations) {
          const lastCount = this.locations.length;
          this.locations = this.locations.filter((l) => l.id !== location.id);
          const newCount = this.locations.length;

          if (lastCount !== newCount) {
            this.totalLocations -= 1;
            this.totalLocationsForCard -= 1;
          }
        }
        if (this.allLocations) {
          this.allLocations = this.allLocations.filter((l) => l.id !== location.id);
        }
      });
    } catch (e: any) {
      this.setError("Virhe käyttöpaikan poistossa (käyttöpaikkaan saattaa olla liitetty käyttäjiä)");
      console.error(e.stack);
    }
  }

    saveNewLocation = async (userToken: string, location: CreateLocation): Promise<void> => {
      try {
        const newLocation = await saveNewLocation(userToken, location);
        this.locations = this.locations?.concat(newLocation);
        this.totalLocations += 1;
      } catch (e: any) {
        this.setError("Virhe käyttöpaikan tallennuksessa");
        console.error(e.stack);
      }
    }

  editLocation = async (userToken: string, location: Location): Promise<void> => {
    try {
      const updated = await editLocation(location, location.id, userToken);
      const locationInAllLocations = this.allLocations?.find((e) => e.id === updated.id);
      const locationInLocations = this.locations?.find((e) => e.id === updated.id);
      if (locationInAllLocations) {
        this.allLocations?.splice(this.allLocations?.indexOf(locationInAllLocations), 1, updated);
      }
      if (locationInLocations) {
        this.locations?.splice(this.locations?.indexOf(locationInLocations), 1, updated);
      }
    } catch (e: any) {
      this.setError("Virhe käyttöpaikan tallennuksessa");
      console.error(e.stack);
    }
  }

    clearState = () : void => {
      runInAction(() => {
        this.locations = [];
        this.allLocations = [];
        this.totalLocations = 0;
        this.location = undefined;
        this.error = undefined;
        this.fetchingLocations = false;
        this.fetchingLocationsCount = false;
        this.newlyCreatedLocations = [];
        this.creatingLocationsInProgress = false;
        this.totalLocationsForCard = 0;

        this.fetchingMeterEditLocations = false;
        this.meterEditSelectLocations = [];
        this.meterEditSelectLocationsAvailable = 0;

        this.newUserSelectLocations = [];
        this.newUserSelectLocationsAvailable = 0;
        this.fetchingNewUserLocations = false;

        this.editUserSelectLocations = [];
        this.editUserSelectLocationsAvailable = 0;
        this.fetchingEditUserLocations = false;
      });
    }

    clearMeterEditLocations = () : void => {
      runInAction(() => {
        this.meterEditSelectLocations = [];
        this.meterEditSelectLocationsAvailable = 0;
        this.fetchingMeterEditLocations = false;
      });
    }

    clearNewUserLocations = () : void => {
      runInAction(() => {
        this.newUserSelectLocations = [];
        this.newUserSelectLocationsAvailable = 0;
        this.fetchingNewUserLocations = false;
      });
    }

    clearEditUserLocations = () : void => {
      runInAction(() => {
        this.editUserSelectLocations = [];
        this.editUserSelectLocationsAvailable = 0;
        this.fetchingEditUserLocations = false;
      });
    }

    constructor() {
      super();
      makeObservable(this, {
        locations: observable,
        location: observable,
        allLocations: observable,
        fetchingLocations: observable,
        fetchingLocationsCount: observable,
        creatingLocationsInProgress: observable,
        totalLocations: observable,
        totalLocationsForCard: observable,
        newlyCreatedLocations: observable,
        meterEditSelectLocations: observable,
        meterEditSelectLocationsAvailable: observable,
        fetchingMeterEditLocations: observable,
        newUserSelectLocations: observable,
        newUserSelectLocationsAvailable: observable,
        fetchingNewUserLocations: observable,
        editUserSelectLocations: observable,
        editUserSelectLocationsAvailable: observable,
        fetchingEditUserLocations: observable,
        loadMeterEditLocations: action,
        uploadMultipleLocationsWithFile: action,
        editLocation: action,
        loadLocation: action,
        loadLocations: action,
        loadLocationsPaged: action,
        saveNewLocation: action,
        removeLocation: action,
        error: observable,
        clearState: action,
        getTotalLocationCount: action,
        loadNewUserLocations: action,
        loadEditUserLocations: action,
      });
    }
}

import GoogleMapReact from "google-map-react";
import { GeoJSONLocation, LatLngPoint, Viewport } from "../models/Map";

// All options for at least ALERTS MAP should be declared here
// For METER MAP, there should probably be getMapOptionsForMeterMap method
// https://developers.google.com/maps/documentation/javascript/controls
// https://www.npmjs.com/package/google-maps-react#additional-map-props
export const getMapOptions = () : GoogleMapReact.MapOptions => ({
  zoomControl: true,
  fullscreenControl: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: 1,
  },
  disableDoubleClickZoom: true,
  styles: [
    {
      featureType: "administrative",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.place_of_worship",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.arterial",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
  ],
});

export const distanceToMouse = ({ x, y } : GoogleMapReact.Point, { x: mouseX, y: mouseY } : GoogleMapReact.Point) : number => Math.sqrt((x - mouseX) * (x - mouseX) + (y - mouseY) * (y - mouseY));

export const boundsToViewport = (bounds: google.maps.LatLngBounds) : Viewport => [
  {
    lat: bounds?.getNorthEast().lat(), lng: bounds?.getSouthWest().lng(),
  },
  {
    lat: bounds?.getSouthWest().lat(), lng: bounds?.getSouthWest().lng(),
  },
  {
    lat: bounds?.getSouthWest().lat(), lng: bounds?.getNorthEast().lng(),
  },
  {
    lat: bounds?.getNorthEast().lat(), lng: bounds?.getNorthEast().lng(),
  },
];

export const viewportToAreaRequest = (viewport : Viewport) : string => `?area=${viewport.map(((v) => `${v.lng},${v.lat}`)).join("&area=")}`;

export const isValidLocation = (location: GeoJSONLocation | null) : boolean => {
  if (location === null) return false;

  if (location) {
    if (location.coordinates && location.coordinates[0] !== null && location.coordinates[1] !== null) {
      return true;
    }
    return false;
  }
  return false;
};

import { delWithToken, getForFileWithAccessToken, getWithAccessToken, postWithAccessToken } from "./HttpUtils";
import { ReportRowResponseWrapper } from "../models/Report";
import { CreateEmailReportSetting, CreateSftpReportSetting, ReportSetting, ReportSettingResponseWrapper, ReportSettingRow } from "../models/ReportSetting";
import { CreateFullMeterReport, CreateSingleMeterReport } from "../models/CreateReport";

const api = process.env.REACT_APP_API_URL || "";

export const getMeterExcel = async (userToken: string): Promise<Blob> => await getForFileWithAccessToken(`${api}/service/report/getMeterExcel`, userToken) as Blob;

export const getExcelWithContentId = async (userToken: string, contentId: string): Promise<Blob> => await getForFileWithAccessToken(`${api}/service/report/${contentId}/download`, userToken) as Blob;

export const createFullMeterReport = (userToken: string, createReport: CreateFullMeterReport): Promise<void> => postWithAccessToken(`${api}/service/report`, createReport, userToken) as Promise<void>;
export const createSingleMeterReport = (userToken: string, createReport: CreateSingleMeterReport, meterId: string): Promise<void> => postWithAccessToken(`${api}/service/report/${meterId}`, createReport, userToken) as Promise<void>;

export const getMeterReport = async (userToken: string, startDay: Date, endDay: Date): Promise<Blob> => await getForFileWithAccessToken(`${api}/service/report/getReport/${startDay}/${endDay}`, userToken) as Blob;

export const updateAutomaticReportSettings = async (userToken: string, reportSetting: ReportSetting):Promise<ReportSetting> => await postWithAccessToken(`${api}/service/reportSetting`, reportSetting, userToken) as ReportSetting;

export const createAutomaticReportSettingEmail = async (userToken: string, reportSetting: CreateEmailReportSetting):Promise<ReportSettingRow> => await postWithAccessToken(`${api}/service/report-setting`, reportSetting, userToken) as Promise<ReportSettingRow>;
export const createAutomaticReportSettingSftp = async (userToken: string, reportSetting: CreateSftpReportSetting):Promise<ReportSettingRow> => await postWithAccessToken(`${api}/service/report-setting`, reportSetting, userToken) as Promise<ReportSettingRow>;

export const deleteSetting = async (currentUserToken: string, settingId: string): Promise<void> => delWithToken(`${api}/service/report-setting/${settingId}`, currentUserToken);

export const getAutomaticReportSettings = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<ReportSettingRow[]> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  return await getWithAccessToken(`${api}/service/report-setting`, userToken) as ReportSettingRow[];
};

export const getReportsPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<ReportRowResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  return await getWithAccessToken(`${api}/service/report/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}`, userToken) as ReportRowResponseWrapper;
};

export const getReportCount = async (currentUserToken: string): Promise<number> => await getWithAccessToken(`${api}/service/reportcount`, currentUserToken) as number;

export const deleteReport = async (currentUserToken: string, reportId: string): Promise<void> => delWithToken(`${api}/service/report/${reportId}`, currentUserToken);

import { del, get, getWithAccessToken, post, postWithAccessToken, put, putWithAccessToken } from "./HttpUtils";
import { Organization, OrganizationResponseWrapper } from "../models/Organization";
import Location from "../models/Location";
import { MapLocationWithZoom } from "../models/Map";

const api = process.env.REACT_APP_API_URL || "";

export const getSubOrganizationsAsList = async (userToken: string): Promise<Organization[]> => await getWithAccessToken(`${api}/service/organization`, userToken) as Organization[];

export const getOrganization = async (userToken: string, organizationId: string): Promise<Organization> => await getWithAccessToken(`${api}/service/organization/${organizationId}`, userToken) as Organization;

export const getOrganizationsPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<OrganizationResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";

  return await getWithAccessToken(`${api}/service/organizationspaged/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useSearch}`, userToken) as OrganizationResponseWrapper;
};
export const editOrganization = async (userToken: string, org: Organization, id: string): Promise<Organization> => await putWithAccessToken(`${api}/service/organization/${id}`, userToken, org) as Organization;

export const saveNewOrganization = async (userToken: string, org: Organization): Promise<any> => postWithAccessToken(`${api}/service/organization`, org, userToken);

export const deleteOrganization = async (userToken: string, orgId: string): Promise<void> => del(`${api}/service/organization/${orgId}`);

export const setDefaultMapLocation = async (userToken: string, orgId: string, location: MapLocationWithZoom): Promise<void> => putWithAccessToken(`${api}/service/organization/${orgId}/defaultMapView/`, userToken, location) as Promise<void>;

import { Theme } from "@mui/material/styles";

export const CSS_GLOBALS = {
    // topContainerPadding: "20px 10px 10px 10px",
    // mainContainerPadding: "0px 10px 20px 10px",
    // mainContainerMargin: "10px 0px 0px 0px",
    topContainerPadding: null,
    mainContainerPadding: null,
    mainContainerMargin: null,

    tabBar: (theme: Theme) => ({
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        boxShadow: "none",
        display: "grid",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
        "grid-template-columns": "1fr auto 1fr",
        marginTop: 0,
        "& .MuiTab-wrapper": {
        display: "inline",
        },
        "& .MuiSvgIcon-root": {
        top: 0,
        position: "relative",
        paddingRight: 2,
        fontSize: "1.2rem",
        },
        "& .MuiTabs-flexContainer": {
            "& .MuiButtonBase-root": {
                color: "white",
            },
            "& .MuiButtonBase-root.Mui-selected": {},
        },
        "& .MuiTabs-scroller": {
            overflow: "visible !important",
            overflowX: "visible !important",
        },
    }),
};

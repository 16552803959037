import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import UsersTable, { Column } from "../../../../components/table/TableWithPagination";
import { UserForLocationAccessEditing, UserForResidentCreation, UserInfo } from "../../../../models/UserInfo";
import Location from "../../../../models/Location";
import useStyles from "../LocationManagementComponentsStyles";
import PendingCircle from "../../../../components/PendingCircle/PendingCircle";
import CreateResidentModal from "../createResidentModal/CreateResidentModal";
import GrantAccessModal from "../grantAccessToLocationModal/GrantAccessModal";
import { UserRole } from "../../../../models/UserRole";
import ConfirmationModal from "../../../../components/confirmationModal/ConfirmationModal";
import useResponsivity from "../../../../hooks/useResponsivity";

interface ILocationManagementViewProps {
  location: Location;
  usersOfLocation?: UserInfo[];
  totalUsers: number;
  fetchingLocationUsers: boolean;
  saveUser: (s: UserForResidentCreation) => void;
  grantAccessToUser: (s: UserForLocationAccessEditing) => void;
  removeUserFromLocation: (u: UserForLocationAccessEditing) => void;
  residentUsers?: UserInfo[];
}

export default function ({ location, residentUsers, usersOfLocation, totalUsers, fetchingLocationUsers, saveUser, grantAccessToUser, removeUserFromLocation }: ILocationManagementViewProps): JSX.Element {
  const { isMobile } = useResponsivity();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const [userCreationModalOpen, setUserCreationModalOpen] = useState(false);
  const [grantAccessModalOpen, setGrantAccessModalOpen] = useState(false);
  const [deleteUserFromLocationOpen, setDeleteUserFromLocationOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInfo|undefined>();

  const closeUserCreationModal = (): void => {
    setUserCreationModalOpen(false);
  };

  const takeAccessAway = (): void => {
    if (selectedUser !== undefined) {
      const residentUser: UserForLocationAccessEditing = {
        identityUserId: selectedUser.identityUserId,
        locationId: location.id,
        roleInLocation: UserRole.OV_RESIDENT,
        removeAccessFromUser: true,
      };
      removeUserFromLocation(residentUser);
      setSelectedUser(undefined);
    }
  };

  const closeGrantAccessModal = (): void => {
    setGrantAccessModalOpen(false);
  };

  const deleteRow = (user: UserInfo): void => {
    setSelectedUser(user);
    setDeleteUserFromLocationOpen(true);
  };

  const userColumns: Column[] = [
    {
      id: "firstName",
      label: t("user.firstName"),
      minWidth: 170,
    },
    {
      id: "lastName",
      label: t("user.lastName"),
      minWidth: 170,
    },
    {
      id: "email",
      label: t("user.email"),
      minWidth: 170,
    },
    {
      id: "organizationName",
      label: "Organisaatio",
      minWidth: 170,
    },
    {
      id: "userRole",
      label: "Rooli",
      minWidth: 170,
    },
  ];

  const sort = (field: string): void => {
    setSortBy(field);
  };

  if (fetchingLocationUsers) {
    return (
        <PendingCircle />
    );
  }

  if (!usersOfLocation || usersOfLocation.length === 0) {
    return (
        <>
            {/* <CreateResidentModal handleClose={closeUserCreationModal} submit={saveUser} open={userCreationModalOpen} locationId={location.id} /> */}
            <GrantAccessModal handleClose={closeGrantAccessModal} submit={grantAccessToUser} open={grantAccessModalOpen} users={residentUsers} locationId={location.id} />
            {!isMobile && (
                <div className={classes.locationTableButtonContainer}>
                    <Button
                      onClick={() => setGrantAccessModalOpen(true)}
                      color="primary"
                      variant="contained"
                      id="SelectResidentButton"
                      className={`${classes.selectResidentButton}`}
                    >
                        {t("locationView.giveAccessToExistingUser")}
                    </Button>
                </div>
)}
            <div className={classes.noData}><Typography className={classes.headerContainer} variant="h5" component="h5">{t("locationView.noUsers")}</Typography></div>
        </>
    );
  }

  return (
      <>
          {/* <CreateResidentModal handleClose={closeUserCreationModal} submit={saveUser} open={userCreationModalOpen} locationId={location.id} /> */}
          <GrantAccessModal handleClose={closeGrantAccessModal} submit={grantAccessToUser} open={grantAccessModalOpen} users={residentUsers} locationId={location.id} />
          <ConfirmationModal
            open={deleteUserFromLocationOpen}
            text={t("locationView.confirmDeleteUserFromLocation")}
            handleClose={() => setDeleteUserFromLocationOpen(false)}
            submit={takeAccessAway}
          />
          {!isMobile && (
              <div className={classes.locationTableButtonContainer}>
                  {/* <Button
                    onClick={() => setUserCreationModalOpen(true)}
                    color="primary"
                    variant="contained"
                    id="AddNewResidentButton"
                    className={classes.buttonShadow}
                  >
                      <PersonAddAltIcon />
                      {t("locationView.createNewResident")}
                  </Button> */}

                  <Button
                    onClick={() => setGrantAccessModalOpen(true)}
                    color="primary"
                    variant="contained"
                    id="SelectResidentButton"
                    className={`${classes.selectResidentButton}`}
                  >
                      {t("locationView.giveAccessToExistingUser")}
                  </Button>
              </div>
)}
          <UsersTable<UserInfo>
            columns={userColumns}
            rows={usersOfLocation || []}
            handlePageChange={setPage}
            handlePageSizeChange={setPageSize}
            count={totalUsers}
            currentPage={page}
            currentRowsPerPage={25}
            sort={sort}
            deleteRow={deleteRow}
            setSortDirection={setSortDirection}
          />
      </>

  );
};

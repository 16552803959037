import { action, makeObservable, observable, runInAction } from "mobx";
import { getMapAlerts, getMapMeters } from "../api/MapApi";
import { AlertMarker, MeterMarker, Viewport } from "../models/Map";
import { WaterMeter } from "../models/WaterMeter";
import BaseStore from "./BaseStore";

export default class MapStore extends BaseStore {
    fetchingMapAlerts = true;

    fetchingMapMeters = false;

    mapAlerts : AlertMarker[] = [];

    mapMeters: MeterMarker[] = [];

loadMapAlerts = async (userToken: string, viewport: Viewport, abortSignal: AbortSignal): Promise<void> => {
  try {
    this.fetchingMapAlerts = true;

    const resp = await getMapAlerts(userToken, viewport, abortSignal);

    runInAction(() => {
      if (!abortSignal.aborted) { this.mapAlerts = resp; }

      this.fetchingMapAlerts = false;
    });
  } catch (e) {
    this.setError("Virhe karttahälytysten haussa");
    this.fetchingMapAlerts = false;
    console.error(e.stack);
  }
}

loadMapMeters = async (userToken: string, viewport: Viewport, abortSignal: AbortSignal): Promise<void> => {
  try {
    this.fetchingMapMeters = true;

    const resp = await getMapMeters(userToken, viewport, abortSignal);

    runInAction(() => {
      if (!abortSignal.aborted) { this.mapMeters = resp; }

      this.fetchingMapMeters = false;
    });
  } catch (e) {
    this.setError("Virhe kartan mittareiden haussa");
    this.fetchingMapMeters = false;
    console.error(e.stack);
  }
}

setMapMeter = async (meter: WaterMeter): Promise<void> => {
  try {
    const marker = {
        address: meter.address,
        locationId: meter.locationId,
        coordinates: meter.location?.coordinates,
        meters: [{
          name: meter.meterNumber,
          type: meter.type,
          meterId: meter.id,
      }],
    } as MeterMarker;

    runInAction(() => {
      this.mapMeters = [marker];
      this.fetchingMapMeters = false;
    });
  } catch (e) {
    this.setError("Virhe kartan mittareiden haussa");
    this.fetchingMapMeters = false;
    console.error(e.stack);
  }
}

  clearState = (): void => {
    runInAction(() => {
      this.fetchingMapAlerts = false;
      this.mapAlerts = [];

      this.fetchingMapMeters = false;
      this.mapMeters = [];
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      fetchingMapAlerts: observable,
      mapAlerts: observable,
      fetchingMapMeters: observable,
      mapMeters: observable,
      loadMapAlerts: action,
      loadMapMeters: action,
      setMapMeter: action,
    });
  }
}

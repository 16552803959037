import moment from "moment";
import i18n from "i18next";
import { CalendarPickerView } from "@mui/x-date-pickers";
import { GraphTimeModel } from "../models/GraphTimeModel";
import { previousInterval } from "./DataUtils";

export const getActiveDateIntervalCurrent = (scale: string, graphTime: GraphTimeModel) : string => {
  if (scale === "hourly") return `${moment(graphTime.day_start).format("DD.MM HH:mm")} - ${moment(graphTime.day_end).format("DD.MM HH:mm")}`;
  if (scale === "daily") return `${moment(graphTime.week_start).format("DD.MM.Y")} - ${moment(graphTime.week_end).format("DD.MM.Y")}`;
  if (scale === "monthly") {
    return `${moment(graphTime.month_start).format("DD.MM.Y")} - ${moment(graphTime.month_end).format("DD.MM.Y")}`;
  }
  if (scale === "yearly") {
    return `${moment(graphTime.year_start).format("DD.MM.Y")} - ${moment(graphTime.year_end).format("DD.MM.Y")}`;
  }

  return "";
};

export const getActiveDateIntervalPrevious = (scale: string, graphTime: GraphTimeModel) : string => {
  const pDay = previousInterval(graphTime.day_start, graphTime.day_end, "hourly", true);
  const pWeek = previousInterval(graphTime.week_start, graphTime.week_end, "weekly");
  const pMonth = previousInterval(graphTime.month_start, graphTime.month_end, "daily");
  const pYear = previousInterval(graphTime.year_start, graphTime.year_start, "yearly");

  if (scale === "hourly") return `${moment(pDay.startDate).format("DD.MM HH:mm")} - ${moment(pDay.endDate).format("DD.MM HH:mm")}`;
  if (scale === "daily") return `${moment(pWeek.startDate).format("DD.MM.Y")} - ${moment(pWeek.endDate).format("DD.MM.Y")}`;
  if (scale === "monthly") {
    return `${moment(pMonth.startDate).format("DD.MM.Y")} - ${moment(pMonth.endDate).format("DD.MM.Y")}`;
  }
  if (scale === "yearly") {
    return `${moment(pYear.startDate).format("DD.MM.Y")} - ${moment(pYear.endDate).format("DD.MM.Y")}`;
  }

  return "";
};

export const getScaleDescription = (scale: string, current: boolean) : string => {
  if (scale === "hourly") { return current ? i18n.t("singleMeter.currentDay") : i18n.t("singleMeter.lastDay"); }

  if (scale === "daily") { return current ? i18n.t("singleMeter.currentWeek") : i18n.t("singleMeter.lastWeek"); }

  if (scale === "monthly") { return current ? i18n.t("singleMeter.currentMonth") : i18n.t("singleMeter.lastMonth"); }

  if (scale === "yearly") { return current ? i18n.t("singleMeter.currentYear") : i18n.t("singleMeter.lastYear"); }

  return "";
};

export const getScaleForMoment = (scale: string) : moment.unitOfTime.DurationConstructor | undefined => {
  if (scale === "hourly") { return "days"; }

  if (scale === "daily") { return "weeks"; }

  if (scale === "monthly") { return "months"; }

  if (scale === "yearly") { return "years"; }

  return undefined;
};

export const getDatePickerViews = (scale: string) : CalendarPickerView[] => {
  if (scale === "monthly") { return ["month"]; }
  if (scale === "yearly") { return ["year"]; }
  return ["month"];
};

export const getDatePickerValue = (scale: string, graphTime: GraphTimeModel) : string => {
  if (scale === "hourly") { return graphTime.timeBase.format("DD.MM"); }
  if (scale === "daily") { return graphTime.timeBase.format("[Viikko] W"); }
  if (scale === "monthly") { return graphTime.timeBase.format("MMMM"); }
  if (scale === "yearly") { return graphTime.timeBase.format("YYYY"); }
  return "-";
};

import React, { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography, SelectChangeEvent, Grid } from "@mui/material";
  import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import useStyles from "./ImportUsersModalStyles";
import ModalHeader from "../../../components/modalComponents/modalHeader";
import { Organization } from "../../../models/Organization";
import ModalPortalTarget from "../../../stores/ModalPortalTarget";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  submit: (file: File, organizationId: string) => void;
  updatingUsersInProgress: boolean;
  organizations?: Organization[];
}

export default function ({ open, handleClose, submit, updatingUsersInProgress, organizations }: IModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [organizationId, setOrganizationId] = useState("");

  const clear = (): void => {
    setSelectedFile(null);
    handleClose();
  };

  const onfileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setOrganizationId(event.target.value as string);
    }
  };

  const submitFile = (): void => {
    if (selectedFile) {
      if (organizationId) {
        submit(selectedFile, organizationId);
      }
    }
  };

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <ModalHeader headerText={t("uploadExcelModal.uploadUsers")} clear={clear} id="uploadUsersTitle" />

              <div className={classes.section}>

                  <div className={classes.subsection}>
                      <Typography variant="subtitle1" className={classes.excelFormText} component="h2">
                          {t("uploadExcelModal.excelMustContainFollowing")}
                      </Typography>
                      <Typography variant="subtitle1" className={classes.excelFormText} component="h2">
                          {t("uploadExcelModal.expectedFormUserCreation")}
                      </Typography>

                      <div className={classes.helpTextSection}>
                          {/* <Typography variant="caption">
                              {locationCreationForm ? t("uploadExcelModal.extraInfoLocationCreation") : t("uploadExcelModal.extraInfoMeterInfoCreation")}
                          </Typography> */}
                          <Typography variant="caption">{t("uploadExcelModal.supportedFileTypeIsCSV")}</Typography>
                      </div>
                  </div>

                  {organizations
                              && (
                                  <div className={classes.subsection}>
                                      <FormControl className={classes.formControl}>
                                          <InputLabel id="organizationSelectLabel">{t("uploadExcelModal.organizationForLocations")}</InputLabel>
                                          <Select
                                            labelId="orgLabel"
                                            id="orgLabelIdUserCreation"
                                            value={organizationId}
                                            onChange={handleOrganizationDropdownChange}
                                          >
                                              {organizations.map((org) => (
                                                  <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                                              ))}
                                          </Select>
                                          <FormHelperText>{t("uploadExcelModal.selectOrganizationHelperText")}</FormHelperText>
                                      </FormControl>
                                  </div>
                              )}

                  <div className={classes.subsection}>
                      <form encType="multipart/form-data">
                          <Button
                            color="secondary"
                            variant="contained"
                            component="label"
                            id="UploadCreateUsersExcelButton"
                          >
                              {t("uploadExcelModal.chooseFile")}
                              <input
                                type="file"
                                onChange={onfileChange}
                                hidden
                                accept=".csv"
                              />
                          </Button>
                      </form>
                      <Typography variant="overline" className={classes.selectedFile} component="h2" id="SelectedExcelFileText">
                          {`${t("uploadExcelModal.selectedFile")} `}
                          <span>{selectedFile ? selectedFile.name : "Ei tiedostoa"}</span>
                      </Typography>
                  </div>

                  <div className={classes.subsection}>

                      {updatingUsersInProgress && (
                          <Typography component="h2">
                              {t("uploadExcelModal.creatingUsers")}
                          </Typography>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { submitFile(); }}
                        id="FinishFileUploadingButtonUserCreation"
                        className={classes.okButton}
                        disabled={updatingUsersInProgress}
                      >
                          {t("locationCreation.sendFile")}
                      </Button>
                  </div>

              </div>
              <div className={classes.navigation}>

                  <Grid container className={classes.modalButtons}>
                      <Button
                        component={Link}
                        to="/import_templates/omavesi_kayttajatiedot_pohja.csv"
                        variant="contained"
                        color="primary"
                        target="_blank"
                        download
                        style={{
                          marginRight: "auto",
                        }}
                      >
                          {t("common.loadTemplate")}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={clear}
                        id="CancelFileUploadingButton"
                      >
                          {t("common.close")}
                      </Button>
                  </Grid>
              </div>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
};

import { WaterMeter, WaterMeterJustId, WaterMeterResponseWrapper } from "../models/WaterMeter";
import { ConsumptionSummary } from "../models/Consumption";
import { getWithAccessToken,
  postFileWithAccessToken,
  putWithAccessToken } from "./HttpUtils";

const api = process.env.REACT_APP_API_URL || "";

export const getMetersOfLocationPaged = async (locationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<WaterMeterResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  if (sortBy === "connectionStatusCombined") {
    return await getWithAccessToken(`${api}/service/watermeter/${locationId}/?page=${page}&size=${pageSize}&sort=connectionStatus${useSortDirection}&sort=connectionStatusDays${useSortDirection}${useArchived}${useSearch}`, userToken) as WaterMeterResponseWrapper;
  }
  return await getWithAccessToken(`${api}/service/watermeter/${locationId}/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}`, userToken) as WaterMeterResponseWrapper;
};

export const getMetersOfOrganizationPaged = async (organizationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<WaterMeterResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  if (sortBy === "connectionStatusCombined") {
    return await getWithAccessToken(`${api}/service/watermetersOfOrganization/${organizationId}/?page=${page}&size=${pageSize}&sort=connectionStatus${useSortDirection}&sort=connectionStatusDays${useSortDirection}${useArchived}${useSearch}`, userToken) as WaterMeterResponseWrapper;
  }
  return await getWithAccessToken(`${api}/service/watermetersOfOrganization/${organizationId}/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}`, userToken) as WaterMeterResponseWrapper;
};

export const getAllMetersPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<WaterMeterResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  if (sortBy === "connectionStatusCombined") {
    return await getWithAccessToken(`${api}/service/watermeter/?page=${page}&size=${pageSize}&sort=connectionStatus${useSortDirection}&sort=connectionStatusDays${useSortDirection}${useArchived}${useSearch}`, userToken) as WaterMeterResponseWrapper;
  }
  return await getWithAccessToken(`${api}/service/watermeter/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}`, userToken) as WaterMeterResponseWrapper;
};

export const getMetersWithoutCustomer = async (userToken: string): Promise<WaterMeter[]> => await getWithAccessToken(`${api}/service/watermetersWithoutOrganization`, userToken) as WaterMeter[];

export const updateMultipleMeters = async (userToken: string, fileToUpload: File, organizationId: string): Promise<WaterMeter[]> => await postFileWithAccessToken(`${api}/service/watermeter/updateMeters/${organizationId}`, fileToUpload, userToken) as WaterMeter[];

export const getMeter = async (currentUserToken: string, meterId: string): Promise<WaterMeter> => await getWithAccessToken(`${api}/service/singlewatermeter/${meterId}`, currentUserToken) as WaterMeter;
export const updateMeter = async (currentUserToken: string, meterid: string, waterMeter: WaterMeter): Promise<WaterMeter> => await putWithAccessToken(`${api}/service/watermeter/${meterid}`, currentUserToken, waterMeter) as WaterMeter;

export const giveMeterToOrganization = async (currentUserToken: string, meterId: string, waterMeter: WaterMeterJustId, organizationId: string): Promise<WaterMeter> => await putWithAccessToken(`${api}/service/watermeter/${meterId}/${organizationId}`, currentUserToken, waterMeter) as WaterMeter;
export const removeMeterFromOrganization = async (currentUserToken: string, meterId: string, waterMeter: WaterMeter): Promise<WaterMeter> => await putWithAccessToken(`${api}/service/watermeterFromCustomer/${meterId}/`, currentUserToken, waterMeter) as WaterMeter;

export const getMeterCount = async (currentUserToken: string): Promise<number> => await getWithAccessToken(`${api}/service/watermetercount`, currentUserToken) as number;

export const getWeeklyConsumption = async (userToken: string) : Promise<ConsumptionSummary> => await getWithAccessToken(`${api}/service/watermeter/consumption`, userToken) as ConsumptionSummary;

export const getMetersForSelect = async (userToken: string, page: number, pageSize: number, abortSignal?: AbortSignal, search = ""): Promise<WaterMeterResponseWrapper | undefined> => {
  const useSearch = search !== "" ? `&search=${search}` : "";
  try {
    const response = await getWithAccessToken(`${api}/service/watermeter/?page=${page}&size=${pageSize}${useSearch}`, userToken, undefined, abortSignal) as WaterMeterResponseWrapper;
    if (response) return response as WaterMeterResponseWrapper;
    return undefined;
  } catch (err) {
    return undefined;
  }
};

import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { WaterMeter } from "../../../models/WaterMeter";
import { useRootStore } from "../../../RootStateContext";
import MetersView from "../MetersView";
import { loginRequest } from "../../../authConfig";
import acquireToken from "../../../api/MsalUtil";
import { UserRole } from "../../../models/UserRole";
import { CreateFullMeterReport } from "../../../models/CreateReport";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const metersStore = useRootStore().meterStore;
  const locationsStore = useRootStore().locationStore;
  const reportsStore = useRootStore().reportStore;
  const organizationsStore = useRootStore().organizationStore;
  const { allOmaVesiOrganizations } = organizationsStore;
  const { allActiveMeters,
    totalMetersInTable,
    totalMetersOfUser,
    updatingMetersInProgress,
    downloadingMeterExcel,
    weeklyConsumption } = metersStore;
  const { creatingMeterReport } = reportsStore;
  const { allLocations } = locationsStore;
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { instance, accounts } = useMsal();
  const [searchJustActivated, setSearchJustActivated] = useState(false);
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;

  const editMetersLocationsWithFile = async (excelFile: File, organizationId: string): Promise<void> => {
    if (currentUserToken) {
      await metersStore.updateMultipleMetersWithFile(currentUserToken, excelFile, organizationId);
      if (metersStore.error === undefined) {
        toast.info(t("meterView.metersMassUpdated", {
          updatedCount: metersStore.updatedMeters.length,
        }), {
          toastId: "MetersUpdatedToast",
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);

      if (response) {
        await metersStore.updateMultipleMetersWithFile(response.idToken, excelFile, organizationId);
        if (metersStore.error === undefined) {
          toast.info(t("meterView.metersMassUpdated", {
            updatedCount: metersStore.updatedMeters.length,
          }), {
            toastId: "MetersUpdatedToast",
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    }
  };

  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("");
    setSortDirection("desc");
    setSearchValue("");
    setPage(0);
  };

  const archive = (b: boolean): void => {
    setArchived(b);
  };
  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const archiveMeter = async (meter: WaterMeter): Promise<void> => {
    // await metersStore.updateMeter(meter);
    // await metersStore.loadMetersPaged(currentUserAccessToken, page, pageSize, sortBy, sortDirection, archived);

    if (metersStore.error === undefined) {
      toast.info(t("meterView.meterDeleted"), {
        toastId: "MeterDeletedToast",
        autoClose: 5000,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const downloadFileFromBlob = (blob: Blob, filename: string): void => {
    if (metersStore.error === undefined) {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("error", {
        toastId: "MeterDeletedToast",
        autoClose: 5000,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  // use this to just create new report to report page (no download)
  const createMeterReport = (createReport: CreateFullMeterReport): void => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        reportsStore.createFullMeterReport(response.idToken, createReport);
      }).catch((e) => {
        //
      });
    } else {
      reportsStore.createFullMeterReport(currentUserToken, createReport);
    }
  };

  /* Unused so far, no need to download report right away */
  /* const getMeterReport = async (createReport: CreateFullMeterReport): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        metersStore.loadMeterReport(response.idToken, createReport).then((responseBlob) => {
          downloadFileFromBlob(responseBlob, "meterReport.xlsx");
        });
      }).catch((e) => {
        //
      });
    } else {
      await metersStore.loadMeterReport(currentUserToken, createReport).then((responseBlob) => {
        downloadFileFromBlob(responseBlob, "meterReport.xlsx");
      });
    }
  }; */

  const getMetersExcel = async (): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        metersStore.downloadMeterExcel(response.idToken).then((responseBlob) => {
          downloadFileFromBlob(responseBlob, "meters.xlsx");
        });
      }).catch((e) => {
        //
      });
    } else {
      await metersStore.downloadMeterExcel(currentUserToken).then((responseBlob) => {
        downloadFileFromBlob(responseBlob, "meters.xlsx");
      });
    }
  };

  const editMeter = async (meter: WaterMeter): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);

      if (response) {
        await metersStore.updateMeter(response.idToken, meter);
        if (metersStore.error === undefined) {
          toast.info(t("meterView.meterUpdated"), {
            toastId: "MeterCreatedToast",
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await metersStore.updateMeter(currentUserToken, meter);
      if (metersStore.error === undefined) {
        toast.info(t("meterView.meterUpdated"), {
          toastId: "MeterCreatedToast",
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const loadMetersAndLocations = useCallback(async (userToken:string, mounted:boolean): Promise<void> => {
    if (searchValue && searchJustActivated) {
      setPage(0);
      setSearchJustActivated(false);
    }
    await metersStore.loadMeters(userToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, searchValue)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
    await locationsStore.loadLocations(userToken)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
  }, [archived, locationsStore, metersStore, page, pageSize, searchJustActivated, searchValue, sortBy, sortDirection]);

  const loadWeeklyConsumption = useCallback(async (userToken:string, mounted:boolean): Promise<void> => {
    await metersStore.loadWeeklyConsumption(userToken)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
  }, [metersStore]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);

          const metersAndLocationsPromise = loadMetersAndLocations(response.idToken, mounted);
          const weeklyConsumptionPromise = loadWeeklyConsumption(response.idToken, mounted);
          const totalMeterCountPromise = metersStore.getTotalMeterCount(response.idToken);
          const userDetailsPromise = !currentUser ? authStore.fetchUserDetails(response.idToken) : Promise.resolve();
          if (currentUser?.userRole === UserRole.OV_ADMIN) {
            const subOrganizationsPromise = organizationsStore.loadAllSubOrganizations(response.idToken);
            await Promise.allSettled([metersAndLocationsPromise, weeklyConsumptionPromise, totalMeterCountPromise, userDetailsPromise, subOrganizationsPromise]);
          } else {
            await Promise.allSettled([metersAndLocationsPromise, weeklyConsumptionPromise, totalMeterCountPromise, userDetailsPromise]);
          }
        }
      } else {
        const metersAndLocationsPromise = loadMetersAndLocations(currentUserToken, mounted);
        const weeklyConsumptionPromise = loadWeeklyConsumption(currentUserToken, mounted);
        const totalMeterCountPromise = metersStore.getTotalMeterCount(currentUserToken);
        const userDetailsPromise = !currentUser ? authStore.fetchUserDetails(currentUserToken) : Promise.resolve();
        if (currentUser?.userRole === UserRole.OV_ADMIN) {
          const subOrganizationsPromise = organizationsStore.loadAllSubOrganizations(currentUserToken);
          await Promise.allSettled([metersAndLocationsPromise, weeklyConsumptionPromise, totalMeterCountPromise, userDetailsPromise, subOrganizationsPromise]);
        } else {
          await Promise.allSettled([metersAndLocationsPromise, weeklyConsumptionPromise, totalMeterCountPromise, userDetailsPromise]);
        }
      }
    })();
    return function cleanup() {
      mounted = false;
      metersStore.clearState();
    };
  }, [page, pageSize, sortBy, sortDirection, archived, searchValue, metersStore, accounts, instance, locationsStore, loadWeeklyConsumption, loadMetersAndLocations]); // DO NOT ADD currentUserToken

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  /* if (fetchingMeters) {
    return (
        <PendingCircle />
    );
  } */

  return (
      <MetersView
        sort={sort}
        setSortDirection={setSortDirection}
        getReport={createMeterReport}
        getMeterExcel={getMetersExcel}
        creatingReport={creatingMeterReport}
        downloadingMeterExcel={downloadingMeterExcel}
        page={page}
        rowsPerPage={pageSize}
        setPage={handlePageChange}
        totalMeters={totalMetersInTable}
        totalMetersForCard={totalMetersOfUser}
        locations={allLocations}
        setPageSize={handlePageSizeChange}
        editMeter={editMeter}
        editMetersLocationsWithFile={editMetersLocationsWithFile}
        meters={allActiveMeters}
        setArchived={archive}
        search={setSearchValue}
        setSearchJustActivated={setSearchJustActivated}
        resetAllOptions={resetAllOptions}
        updatingMetersInProgress={updatingMetersInProgress}
        activeSearchValue={searchValue}
        weeklyConsumption={weeklyConsumption}
        currentUser={currentUser}
        customerOrganizations={allOmaVesiOrganizations}
      />
  );
});

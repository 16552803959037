import { observable, action, makeObservable, runInAction } from "mobx";
import { createAutomaticReportSettingEmail, createAutomaticReportSettingSftp, createFullMeterReport, createSingleMeterReport, deleteReport,
  deleteSetting,
  getAutomaticReportSettings,
  getExcelWithContentId, getReportCount, getReportsPaged,
  updateAutomaticReportSettings } from "../api/ReportApi";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import ReportRow from "../models/Report";
import { CreateEmailReportSetting, CreateSftpReportSetting, ReportSetting, ReportSettingRow, ReportType } from "../models/ReportSetting";
import { Organization } from "../models/Organization";
import { CreateFullMeterReport, CreateSingleMeterReport } from "../models/CreateReport";

const unwrapSftpSettings = (settings: ReportSettingRow) : ReportSettingRow => {
    const sftp = settings.sftpSettings;

    const unwrapped = {
      ...settings,
    };

    if (sftp !== null && sftp !== undefined) {
      unwrapped.sftpServer = sftp.sftpServer;
      unwrapped.sftpUserName = sftp.sftpUserName;
      unwrapped.sftpPassword = sftp.sftpPassword;
      unwrapped.sftpFolder = sftp.sftpFolder;
      delete unwrapped.sftpSettings;
    }

    return unwrapped;
};
export default class ReportStore extends BaseStore {
  meterReports?: ReportRow[] = [];

  meterReport?: ReportRow;

  totalMeterReports = 0;

  totalReportsForCard = 0;

  automaticReportSettings?: ReportSettingRow[] = [];

  selectedReportSetting?: ReportSettingRow;

  fetchingReportSettings = false;

  fetchingMeterReports = false;

  creatingMeterReport = false;

  selectedReport?: ReportRow;

  reportTypes: ReportType[] = [
    "METER_REPORT_XLSX",
    "METER_REPORT_TAMPUURI_CSV",
    "METER_REPORT_VESIKANTA_CSV",
    "METER_REPORT_SOLTEQ_INWORKS_CSV",
  ];

  loadMeterReportsPaged = async (
userToken: string,
showSpinner: boolean,
page: number,
pageSize: number,
sortBy?: string,
sortDirection?: string,
    archived?: boolean,
search?: string,
): Promise<void> => {
    try {
      if (showSpinner) {
        this.fetchingMeterReports = true;
      }
      const meterReportsResp = await getReportsPaged(userToken, page, pageSize, sortBy, sortDirection, archived, search);
      runInAction(() => {
        this.meterReports = meterReportsResp.content;
        this.totalMeterReports = meterReportsResp.totalElements;
        this.fetchingMeterReports = false;
      });
    } catch (e: any) {
      this.fetchingMeterReports = false;
      this.setError(i18n.t("reportStore.meterReportFetchFailed"));
      this.meterReports = [];
    }
  }

  setSelectedReport = (o: ReportRow | undefined): void => {
    this.selectedReport = o;
  };

  deleteMeterReport = async (userToken: string): Promise<void> => {
    try {
      if (this.selectedReport) {
        await deleteReport(userToken, this.selectedReport.id);
        runInAction(() => {
          this.meterReports = this.meterReports?.filter((e) => e.id !== this.selectedReport?.id);
          this.totalReportsForCard -= 1;
          this.totalMeterReports -= 1;
          this.selectedReport = undefined;
        });
        return Promise.resolve();
      }
      this.setError("reportStore.meterReportDeleteFailed");
      return Promise.resolve();
    } catch (e: any) {
      this.setError("reportStore.meterReportDeleteFailed");
      return Promise.resolve();
    }
  }

  /* updateReportSetting = async (userToken: string, reportSetting: ReportSetting): Promise<void> => {
     try {
      const settingResp = await updateAutomaticReportSettings(userToken, reportSetting);
      runInAction(() => {
        this.automaticReportSetting = settingResp;
      });
      return settingResp;
    } catch (e) {
      this.setError("Virhe automatiikka-asetuksen luomisessa");
      return Promise.reject(e.stack);
    }
  } */

  createEmailReportSetting = async (userToken: string, reportSetting: CreateEmailReportSetting): Promise<void> => {
    try {
      const newSetting = await createAutomaticReportSettingEmail(userToken, reportSetting);

      runInAction(() => {
        const setting = unwrapSftpSettings(newSetting);
        this.automaticReportSettings = this.automaticReportSettings?.concat(setting);
      });

      return Promise.resolve();
    } catch (e) {
      this.setError(i18n.t("reportSettingsView.settingCreationFailed"));
      return Promise.reject(e.stack);
    }
  }

  createSftpReportSetting = async (userToken: string, reportSetting: CreateSftpReportSetting): Promise<void> => {
    try {
      const newSetting = await createAutomaticReportSettingSftp(userToken, reportSetting);

      runInAction(() => {
        const setting = unwrapSftpSettings(newSetting);
        this.automaticReportSettings = this.automaticReportSettings?.concat(setting);
      });

      return Promise.resolve();
    } catch (e) {
      this.setError(i18n.t("reportSettingsView.settingCreationFailed"));
      return Promise.reject(e.stack);
    }
  }

  loadReportSettings = async (
userToken: string,
    page: number,
    pageSize: number,
    sortBy?: string,
    sortDirection?: string,
    search?: string,
) : Promise<void> => {
    try {
      this.fetchingReportSettings = true;
      const settingsResp = await getAutomaticReportSettings(userToken, page, pageSize, sortBy, sortDirection, search);

      // Have to unwrap sftpSettings-object from the response
      const settings = settingsResp.map((s) => unwrapSftpSettings(s));

      runInAction(() => {
        this.automaticReportSettings = settings;
        this.fetchingReportSettings = false;
      });
    } catch (e) {
      this.setError(i18n.t("reportSettingsView.settingFetchFailed"));
      this.fetchingReportSettings = false;
    }
  }

  createFullMeterReport = (userToken: string, createReport: CreateFullMeterReport): void => {
    try {
      this.creatingMeterReport = true;
      createFullMeterReport(userToken, createReport);
      setTimeout(this.clearCreatingReport, 50000);
    } catch (e) {
      this.setError("Virhe raportin luomisessa");
      this.creatingMeterReport = false;
    }
  }

  createSingleMeterReport = (userToken: string, createReport: CreateSingleMeterReport): void => {
    try {
      this.creatingMeterReport = true;
      createSingleMeterReport(userToken, createReport, createReport.meterId);
      setTimeout(this.clearCreatingReport, 50000);
    } catch (e) {
      this.setError("Virhe raportin luomisessa");
      this.creatingMeterReport = false;
    }
  }

  clearCreatingReport = (): void => {
    this.creatingMeterReport = false;
  }

  downloadExcelReport = async (userToken: string, reportContentId: string): Promise<Blob> => {
    try {
      const response = await getExcelWithContentId(userToken, reportContentId);
      return response;
    } catch (e) {
      this.setError("Virhe excelin luomisessa");
      return Promise.reject(e.stack);
    }
  }

  getTotalReportCount = async (userToken: string) :Promise<void> => {
    try {
      const locationResp = await getReportCount(userToken);
      runInAction(() => {
        this.totalReportsForCard = locationResp;
      });
    } catch (e) {
      this.setError(i18n.t("reportView.reportCountError"));
      this.totalReportsForCard = 0;
    }
  }

  deleteReportSetting = async (userToken: string, setting: ReportSettingRow) :Promise<void> => {
    try {
      await deleteSetting(userToken, setting.id);
      runInAction(() => {
        if (this.automaticReportSettings) { this.automaticReportSettings = this.automaticReportSettings.filter((s) => s.id !== setting.id); }
      });
    } catch (e) {
      this.setError(i18n.t("reportSettingsView.settingDeletionFailed"));
    }
  }

  clearState = (): void => {
    runInAction(() => {
      this.meterReports = [];
      this.meterReport = undefined;
      this.totalMeterReports = 0;
      this.selectedReport = undefined;
      this.totalReportsForCard = 0;
      this.fetchingMeterReports = false;
      this.creatingMeterReport = false;
      this.error = undefined;
      this.automaticReportSettings = [];
      this.selectedReportSetting = undefined;
      this.fetchingReportSettings = false;
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      meterReports: observable,
      meterReport: observable,
      selectedReport: observable,
      totalMeterReports: observable,
      fetchingMeterReports: observable,
      creatingMeterReport: observable,
      totalReportsForCard: observable,
      loadMeterReportsPaged: action,
      downloadExcelReport: action,
      createFullMeterReport: action,
      createSingleMeterReport: action,
      deleteMeterReport: action,
      // updateReportSetting: action,
      loadReportSettings: action,
      clearState: action,
      setSelectedReport: action,
      deleteReportSetting: action,
      createEmailReportSetting: action,
      createSftpReportSetting: action,
      automaticReportSettings: observable,
      selectedReportSetting: observable,
      getTotalReportCount: observable,
      reportTypes: observable,
      fetchingReportSettings: observable,
    });
  }
}

import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { useRootStore } from "../../../RootStateContext";
import { loginRequest } from "../../../authConfig";
import ReportsView from "../ReportsView";
import acquireToken from "../../../api/MsalUtil";
import ReportRow from "../../../models/Report";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchJustActivated, setSearchJustActivated] = useState(false);
  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const { reportStore } = useRootStore();
  const { meterReports, totalMeterReports, totalReportsForCard } = reportStore;

  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("createdAt");
    setSortDirection("desc");
    setSearchValue("");
  };

  const archive = (b: boolean): void => {
    setArchived(b);
  };
  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const loadMeterReports = useCallback(async (userToken:string, mounted:boolean, showSpinner:boolean): Promise<void> => {
    if (searchValue && searchJustActivated) {
      setPage(0);
      setSearchJustActivated(false);
    }
    await reportStore.loadMeterReportsPaged(userToken, showSpinner, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, searchValue)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
  }, [archived, reportStore, page, pageSize, searchJustActivated, searchValue, sortBy, sortDirection]);

  const downloadFileFromBlob = (blob: Blob, filename: string): void => {
    if (reportStore.error === undefined) {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("error", {
        toastId: "MeterDeletedToast",
        autoClose: 5000,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  // use this to download report with reportRow
  const downloadMeterReport = async (report: ReportRow): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        reportStore.downloadExcelReport(response.idToken, report.id).then((responseBlob) => {
          downloadFileFromBlob(responseBlob, `${report.filename}.${report.fileType}`);
        });
      }).catch((e) => {
        //
      });
    } else {
      reportStore.downloadExcelReport(currentUserToken, report.id).then((responseBlob) => {
        downloadFileFromBlob(responseBlob, `${report.filename}.${report.fileType}`);
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          const meterReportsPromise = loadMeterReports(response.idToken, mounted, true);
          const totalReportCountPromise = reportStore.getTotalReportCount(response.idToken);

          await Promise.allSettled([meterReportsPromise, totalReportCountPromise]);
        }
      } else {
        const meterReportsPromise = loadMeterReports(currentUserToken, mounted, true);
        const totalReportCountPromise = reportStore.getTotalReportCount(currentUserToken);

        await Promise.allSettled([meterReportsPromise, totalReportCountPromise]);
      }
    })();
    return function cleanup() {
      mounted = false;
      reportStore.clearState();
    };
  }, [accounts, instance, loadMeterReports, reportStore]);

  const deleteReport = async (): Promise<void> => {
    if (currentUserToken) {
      await reportStore.deleteMeterReport(currentUserToken);
      if (reportStore.error === undefined) {
        toast.info(t("reportView.reportDeleted"), {
          toastId: "reportRemovedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);
      if (response) {
        await reportStore.deleteMeterReport(response.idToken);
      }
      if (reportStore.error === undefined) {
        toast.info(t("reportView.reportDeleted"), {
          toastId: "reportRemovedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const getReportsForCreationStatus = async ():Promise<void> => {
    if (currentUserToken) {
      loadMeterReports(currentUserToken, true, false);
    } else {
      const request = {
        ...loginRequest,
        account: instance.getAllAccounts()[0],
      };
      const response = await acquireToken(instance, request);
      if (response) {
        setCurrentUserToken(response.idToken);
        loadMeterReports(response.idToken, true, false);
      }
    }
  };

  /* fetces reports once in every 10sec */
  useEffect(() => {
    const timer = setInterval(getReportsForCreationStatus, 10000);
    return () => clearInterval(timer);
  }, []);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  return (
      <ReportsView
        meterReports={meterReports}
        downloadReport={downloadMeterReport}
        deleteReport={deleteReport}
        setPage={handlePageChange}
        setPageSize={handlePageSizeChange}
        totalMeterReports={totalMeterReports}
        totalReportCountForCard={totalReportsForCard}
        page={page}
        rowsPerPage={pageSize}
        sort={sort}
        setSortDirection={setSortDirection}
        search={setSearchValue}
        resetAllOptions={resetAllOptions}
        activeSearchValue={searchValue}
        setSearchJustActivated={setSearchJustActivated}
      />
  );
});

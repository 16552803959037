import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  marker:
  {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    // color: "#EE145B", PINK
    color: "#828282",
    filter: "drop-shadow(1px 1px 1px #000)",
  },
}));

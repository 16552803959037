import { blue } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { WhitelabelConfiguration } from "./assets/clientconfiguration/whitelabelConfig";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    tertiary: Palette["primary"];
    important: Palette["primary"];
    hotColdGraph: Palette["primary"];
    consumptionGraph: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    tertiary?: PaletteOptions["primary"];
    important?: PaletteOptions["primary"];
    hotColdGraph?: PaletteOptions["primary"];
    consumptionGraph?: PaletteOptions["primary"];
  }

}

type ThemeContainer = {
  [key: string]: Theme
}

const themes: ThemeContainer = {};

const defaultTheme = createTheme({
  palette: {
    warning: {
      main: "rgb(255, 1, 50)",
      dark: "rgb(197 0 38)",
    },
    primary: {
      main: "#00a0b4",
      dark: "#00707D",
    },
    secondary: {
      main: "#025965",
      dark: "#00A0B4",
    },
    tertiary: {
      main: "#FF5800",
      dark: "rgb(178, 61, 0)",
    },
    important: {
      main: "rgb(238, 20, 91)",
    },
    hotColdGraph: {
      main: "#009fb4", // Cold color
      dark: "#fb9265", // Hot color
    },
    consumptionGraph: {
      main: "#009fb4", // Main color
      dark: "#00707d", // Secondary color
    },
    error: {
      main: "#FF5800",
    },
    info: {
      main: "#0099CB",
    },
    neutral: {
      main: "#64748B",
      light: "#bad2f6",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    h4: {
      color: blue[900],
    },
    h6: {
      color: blue[900],
    },
    fontFamily: ["sans-serif"].join(","),
  },
});

const external1Theme = createTheme({
  palette: {
    warning: {
      main: "rgb(255, 1, 50)",
      dark: "rgb(197 0 38)",
    },
    primary: {
      main: "#003057",
      dark: "#003057",
    },
    secondary: {
      main: "#4298b5",
      dark: "#4298b5",
    },
    tertiary: {
      main: "#ff8d6d",
      dark: "#e88164",
    },
    important: {
      main: "#ff8d6d",
    },
    hotColdGraph: {
      main: "#4298b5", // Cold color
      dark: "#ff8d6d", // Hot color
    },
    consumptionGraph: {
      main: "#4298b5", // Main color
      dark: "#ff8d6d", // Secondary color
    },
    error: {
      main: "#FF5800",
      dark: "#df4d00",
    },
    info: {
      main: "#0099CB",
    },
    neutral: {
      main: "#64748B",
      light: "#bad2f6",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    h4: {
      color: blue[900],
    },
    h6: {
      color: blue[900],
    },
    fontFamily: ["sans-serif"].join(","),
  },
});

const external2Theme = createTheme({
  palette: {
    warning: {
      main: "rgb(255, 1, 50)",
      dark: "rgb(197 0 38)",
    },
    primary: {
      main: "#003057",
      dark: "#003057",
    },
    secondary: {
      main: "#4298b5",
      dark: "#4298b5",
    },
    tertiary: {
      main: "#ff8d6d",
      dark: "#e88164",
    },
    important: {
      main: "#ff8d6d",
    },
    hotColdGraph: {
      main: "#4298b5", // Cold color
      dark: "#ff8d6d", // Hot color
    },
    consumptionGraph: {
      main: "#4298b5", // Main color
      dark: "#ff8d6d", // Secondary color
    },
    error: {
      main: "#FF5800",
      dark: "#df4d00",
    },
    info: {
      main: "#0099CB",
    },
    neutral: {
      main: "#64748B",
      light: "#bad2f6",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    h4: {
      color: blue[900],
    },
    h6: {
      color: blue[900],
    },
    fontFamily: ["sans-serif"].join(","),
  },
});

themes.defaultTheme = defaultTheme;
themes.external1Theme = external1Theme;

function getTheme() : Theme {
  const themeName = WhitelabelConfiguration.getThemeName();

  if (themeName in themes) {
    return themes[themeName];
  }

  throw new Error(`MaterialUITheme: Could not find theme ${themeName}`);
}

export function useWhitelabelTheme() : Theme {
  const [theme, setTheme] = useState<Theme>(themes.defaultTheme);

  useEffect(() => {
    const onWlcUpdated = () : void => {
      const t = getTheme();
      setTheme(t);
    };

    window.addEventListener("wlcupdated", onWlcUpdated);
    onWlcUpdated();

    return () => window.removeEventListener("wlcupdated", onWlcUpdated);
  }, []);

  if (theme) return theme;
  throw new Error(`MaterialUITheme: Could not find theme`);
}

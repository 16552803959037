import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react-lite";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../RootStateContext";
import OrganizationsAndUsersView from "../OrganizationsAndUsersView";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { loginRequest } from "../../../authConfig";
import acquireToken, { tokenAction } from "../../../api/MsalUtil";
import { Organization } from "../../../models/Organization";
import { OrganizationType, UserInfo, UserInfoForCreation, UserInfoForUpdating } from "../../../models/UserInfo";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";

export default observer((): JSX.Element => {
  const { organizationStore } = useRootStore();
  const { userStore } = useRootStore();
  const { allOmaVesiOrganizations, allOrganizations } = organizationStore;
  const { allUsers, fetchingLocationUsers, fetchingUsersPaged, updatingUsersInProgress } = userStore;
  const { locationStore } = useRootStore();
  const { t } = useTranslation();

  const [userPageSize, setUserPageSize] = useState(25);
  const [userPage, setUserPage] = useState(0);
  const [userSortBy, setUserSortBy] = useState("");
  const [userSortDirection, setUserSortDirection] = useState("desc");
  const [userSearchValue, setUserSearchValue] = useState("");
  const [usersLoadingState, setUsersLoadingState] = useState(false);

  const [orgPageSize, setOrgPageSize] = useState(25);
  const [orgPage, setOrgPage] = useState(0);
  const [orgSortBy, setOrgSortBy] = useState("");
  const [orgSortDirection, setOrgSortDirection] = useState("desc");
  const [orgSearchValue, setOrgSearchValue] = useState("");
  const [orgsLoadingState, setOrgsLoadingState] = useState(false);
  const { instance, accounts } = useMsal();

  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;

  const [forbidden, setForbidden] = useState(false);
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const [showOrganizations, setShowOrganizations] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<number>(0);

  const resetAllOptions = () : void => {
    setUserPageSize(25);
    setUserPage(0);
    setUserSortBy("");
    setUserSortDirection("desc");
    setUserSearchValue("");

    setOrgPageSize(25);
    setOrgPage(0);
    setOrgSortBy("");
    setOrgSortDirection("desc");
    setOrgSearchValue("");
  };

  const userSort = (field: string): void => {
    setUserSortBy(field);
  };

  const handleUserPageChange = (p: number): void => {
    setUserPage(p);
  };

  const handleUserPageSizeChange = (p: number): void => {
    setUserPage(0);
    setUserPageSize(p);
  };

  const orgSort = (field: string): void => {
    setOrgSortBy(field);
  };

  const handleOrgPageChange = (p: number): void => {
    setOrgPage(p);
  };

  const handleOrgPageSizeChange = (p: number): void => {
    setOrgPage(0);
    setOrgPageSize(p);
  };

  const saveOrganization = async (o: any): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await organizationStore.saveNewOrganization(response.idToken, o);
          if (userStore.error === undefined) {
            toast.info(t("userManagementView.organizationCreated"), {
              toastId: "OrgCreatedToast",
              autoClose: 9000,
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("organization creation failed");
        }
      }
    } else {
      try {
        await organizationStore.saveNewOrganization(currentUserToken, o);
        if (userStore.error === undefined) {
          toast.info(t("userManagementView.organizationCreated"), {
            toastId: "OrgCreatedToast",
            autoClose: 9000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("organization creation failed");
      }
    }
  };

  const saveUser = async (u: UserInfoForCreation): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await userStore.saveNewUser(response.idToken, u);
          if (userStore.error === undefined) {
            toast.info(t("userManagementView.creationSuccess"), {
              toastId: "UserCreatedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await userStore.saveNewUser(currentUserToken, u);
        if (userStore.error === undefined) {
          toast.info(t("userManagementView.creationSuccess"), {
            toastId: "UserCreatedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  const importUsers = async (file: File, organizationId: string): Promise<void> => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);

        await userStore.importMultipleUserWithFile(idToken, file, organizationId);
        // if (locationsStore.error === undefined) {
      }, currentUserToken, accounts, instance);
  };

  const deleteOrganization = async (): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        await organizationStore.deleteOrganization(response.idToken, organizationStore?.selectedOrganization?.id || "");
        if (organizationStore.error === undefined) {
          toast.info(t("userManagementView.deleteSuccess"), {
            toastId: "OrgDeletedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await organizationStore.deleteOrganization(currentUserToken, organizationStore?.selectedOrganization?.id || "");
      if (organizationStore.error === undefined) {
        toast.info(t("userManagementView.deleteSuccess"), {
          toastId: "OrgDeletedToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const updateUser = async (u: UserInfoForUpdating, id: string): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        await userStore.updateUser(response.idToken, u, id);
        if (userStore.error === undefined) {
          toast.info(t("userManagementView.updateSuccess"), {
            toastId: "userUpdatedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await userStore.updateUser(currentUserToken, u, id);
      if (userStore.error === undefined) {
        toast.info(t("userManagementView.updateSuccess"), {
          toastId: "userUpdatedToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const updateOrg = async (o: Organization, id: string): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        await organizationStore.updateOrganization(response.idToken, o, id);
        if (organizationStore.error === undefined) {
          toast.info(t("userManagementView.orgUpdateSuccess"), {
            toastId: "orgUpdatedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await organizationStore.updateOrganization(currentUserToken, o, id);
      if (organizationStore.error === undefined) {
        toast.info(t("userManagementView.orgUpdateSuccess"), {
          toastId: "orgUpdatedToast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const getOrgRelatedData = async (user: UserInfo, userToken: string): Promise<void> => {
    if (user.usersOrganizationType === OrganizationType.SUPERADMIN || user.usersOrganizationType === OrganizationType.WATER_COMPANY || user.usersOrganizationType === OrganizationType.RETAILER) {
      setShowOrganizations(true);
      await organizationStore.loadAllSubOrganizations(userToken);
      await organizationStore.loadOrganizationsPaged(userToken, orgPage, orgPageSize, orgSortBy, orgSortDirection, orgSearchValue);
    }
  };

  const removeUser = async (user: UserInfo): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await userStore.removeUser(response.idToken, user);
          if (userStore.error === undefined) {
            toast.info(t("userView.userRemoved"), {
              toastId: "userRemovedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("User deletion failed");
        }
      }
    } else {
      try {
        await userStore.removeUser(currentUserToken, user);
        if (userStore.error === undefined) {
          toast.info(t("userView.userRemoved"), {
            toastId: "userRemovedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("User deletion failed");
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    setOrgsLoadingState(true);
    setUsersLoadingState(true);
    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          if (!currentUser) {
            await authStore.fetchUserDetails(response.idToken).then((user) => {
              getOrgRelatedData(user, response.idToken);
            });
          } else {
            getOrgRelatedData(currentUser, response.idToken);
          }

          const locationsPromise = locationStore.loadLocations(response.idToken);
          const usersPagedPromise = userStore.loadUsersPaged(response.idToken, userPage, userPageSize, userSortBy, userSortDirection, userSearchValue);
          const allUsersPromise = userStore.loadAllUsers(response.idToken);

          await Promise.allSettled([locationsPromise, usersPagedPromise, allUsersPromise]);
        }
      } else {
        if (!currentUser) {
          await authStore.fetchUserDetails(currentUserToken).then((user) => {
            getOrgRelatedData(user, currentUserToken);
          });
        } else {
          getOrgRelatedData(currentUser, currentUserToken);
        }

        const locationsPromise = locationStore.loadLocations(currentUserToken);
        const usersPagedPromise = userStore.loadUsersPaged(currentUserToken, userPage, userPageSize, userSortBy, userSortDirection, userSearchValue);
        const allUsersPromise = userStore.loadAllUsers(currentUserToken);

        await Promise.allSettled([locationsPromise, usersPagedPromise, allUsersPromise]);
      }
      setUsersLoadingState(false);
      setOrgsLoadingState(false);
    })();
    return function cleanup() {
      mounted = false;
      organizationStore.clearState();
    };
  }, [accounts, instance, organizationStore, orgPage, orgPageSize, orgSortBy, orgSortDirection, orgSearchValue, userStore, userSortBy, userPage, userPageSize, userSortDirection, userSearchValue]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  return (
      <OrganizationsAndUsersView
        organizations={allOrganizations || []}
        saveNewOrganization={saveOrganization}
        updateOrganization={updateOrg}
        deleteOrganization={deleteOrganization}
        saveUser={saveUser}
        updateUser={updateUser}
        totalOrgs={organizationStore.totalOrganizations}
        users={allUsers}
        totalUsers={userStore.totalUsers}
        fetchingUsers={fetchingUsersPaged}
        userSort={userSort}
        setUserPage={handleUserPageChange}
        setUserPageSize={handleUserPageSizeChange}
        setUserSortDirection={setUserSortDirection}
        userPage={userPage}
        userSearch={setUserSearchValue}
        userRowsPerPage={userPageSize}
        usersLoading={usersLoadingState}
        orgSort={orgSort}
        setOrgPage={handleOrgPageChange}
        setOrgPageSize={handleOrgPageSizeChange}
        setOrgSortDirection={setOrgSortDirection}
        orgPage={orgPage}
        orgSearch={setOrgSearchValue}
        orgRowsPerPage={orgPageSize}
        orgsLoading={orgsLoadingState}
        resetAll={resetAllOptions}
        fetchingOrganizations={organizationStore.fetchingOrganizations}
        totalUsersForCard={userStore.totalUsers}
        totalOrganizationsForCard={organizationStore.totalOrganizationsForCard}
        activeUsersSearchValue={userSearchValue}
        activeOrganizationsSearchValue={orgSearchValue}
        currentUser={currentUser}
        locations={locationStore.allLocations}
        showOrganizations={showOrganizations}
        editableOrganizations={allOmaVesiOrganizations || []}
        removeUser={removeUser}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        importUsers={importUsers}
        updatingUsersInProgress={updatingUsersInProgress}
      />
  );
});

import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade, Typography, Grid, TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import useStyles from "./SingleAlertModalStyles";
import Alert from "../../models/Alert";
import useResponsivity from "../../hooks/useResponsivity";
import ModalHeader from "../modalComponents/modalHeader";
import { useRootStore } from "../../RootStateContext";
import { UserRole } from "../../models/UserRole";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
    alert?: Alert;
    open: boolean;
    handleClose: () => void;
    submit: (alert: Alert, comment: string) => void;
}

export default function ({ alert, open, handleClose, submit }: IModalProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isMobile } = useResponsivity();
  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;

  const alertCommentRef = useRef<HTMLTextAreaElement>(null);

  const clear = (): void => {
    handleClose();
  };

  const markAlert = (): void => {
    if (alert) {
        const comment = alertCommentRef?.current?.value ?? "";
        submit(alert, comment);
        clear();
    }
  };

  return ReactDOM.createPortal(<Modal
    className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
              <ModalHeader headerText={`${t("alertView.alert")} ${t(`alertType.${alert?.alertType}`)}`} clear={clear} id="singleAlertModalTitle" />
              {alert && (
                  <Grid container className={classes.container}>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("waterMeter.meterNumber")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {alert.meterNumber}
                      </Grid>

                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("waterMeter.meterModel")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {alert.meterModel}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.type")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {t(`alertType.${alert.alertType}`)}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.address")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {alert.address}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.meterType")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {t(`meterType.${alert.meterType}`)}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.startedAt")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {format(parseISO(alert.alertStartedAt as unknown as string), "dd.MM.yyyy' 'HH:mm:ss")}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.checkedAt")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {alert.alertCheckedAt && (
                              <span>{format(parseISO(alert.alertCheckedAt as unknown as string), "dd.MM.yyyy' 'HH:mm:ss")}</span>
                                  )}
                          {!alert.alertCheckedAt && (
                              <span>-</span>
                                  )}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.checkedByName")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {alert.checkedByName}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.endedAt")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          {alert.alertEndedAt && (
                              <span>{format(parseISO(alert.alertEndedAt as unknown as string), "dd.MM.yyyy' 'HH:mm:ss")}</span>
                                  )}
                          {!alert.alertEndedAt && (
                              <span>-</span>
                                  )}
                      </Grid>
                      <Grid item xs={4} md={4} className={classes.titleColumn}>
                          {t("alert.comments")}
                      </Grid>
                      <Grid item xs={8} md={8} className={classes.valueColumn}>
                          <TextareaAutosize
                            minRows={5}
                            maxRows={10}
                            style={{
                                        width: "100%",
                            }}
                            ref={alertCommentRef}
                          />
                      </Grid>
                  </Grid>
                      )}
              <div className={classes.navigation}>

                  <div className={classes.modalButtons}>
                      {alert?.alertCheckedAt === null && currentUser?.userRole !== UserRole.OV_RESIDENT && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={markAlert}
                            className={classes.okButton}
                            id="mark-alarm-as-checked"
                          >
                              {t("alertView.markAsChecked")}
                          </Button>
                              )}
                      <Button
                        onClick={() => clear()}
                        variant="outlined"
                        color="secondary"
                        id="CancelInfoChangesButton"
                      >
                          {alert?.alertCheckedAt === null && (<span>{t("common.cancel")}</span>)}
                          {alert?.alertCheckedAt !== null && (<span>{t("common.close")}</span>)}
                      </Button>
                  </div>

              </div>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
};

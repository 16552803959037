import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import MetersTable, { Column } from "../../../../components/table/TableWithPagination";
import { useRootStore } from "../../../../RootStateContext";
import useStyles from "./MetersOfOrganizationStyles";
import PendingCircle from "../../../../components/PendingCircle/PendingCircle";
import { Organization } from "../../../../models/Organization";
import { WaterMeter, WaterMeterJustId } from "../../../../models/WaterMeter";
import AddMeterToOrganization from "./AddMeterToOrganization";
import { OrganizationType } from "../../../../models/UserInfo";
import { loginRequest } from "../../../../authConfig";
import acquireToken, { tokenAction } from "../../../../api/MsalUtil";
import useResponsivity from "../../../../hooks/useResponsivity";

interface IOrganizationMeterManagementViewProps {
  organization: Organization;
  addMeter: (m: WaterMeterJustId, orgId: string) => void;
  removeMeter: (m: WaterMeter) => void;
}

export default observer(({ organization, addMeter, removeMeter }: IOrganizationMeterManagementViewProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const metersStore = useRootStore().meterStore;
  const { fetchingMeters, totalMetersInTable, metersOfOrganization } = metersStore;

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const { isMobile } = useResponsivity();

  const addMeterToOrganization = async (meter: WaterMeterJustId, selectedOrganizationId: string): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await metersStore.addMeterToOrganization(response.idToken, meter, selectedOrganizationId);
          if (metersStore.error === undefined) {
            toast.info(t("organizationView.meterAdded"), {
              toastId: "MeterAddedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await metersStore.addMeterToOrganization(currentUserToken, meter, selectedOrganizationId);
        if (metersStore.error === undefined) {
          toast.info(t("organizationView.meterAdded"), {
            toastId: "MeterAddedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  const giveMeterToSelectedOrganization = async (meter: WaterMeterJustId): Promise<void> => {
    if (organization && organization.id) {
      await addMeterToOrganization(meter, organization.id);
    }
  };

  useEffect(() => {
    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        if (organization && organization.id !== undefined) {
          await metersStore.loadMetersOfOrganizationPaged(organization.id, idToken, page, pageSize);
        }
      }, currentUserToken, accounts, instance);
    })();

    return function cleanup() {
      metersStore.clearState();
    };
  }, [accounts, instance, metersStore, archived, page, pageSize, sortBy, sortDirection]);

  const meterColumns: Column[] = [
    {
      id: "meterNumber", label: t("waterMeter.meterNumber"), minWidth: 170,
    },
    {
      id: "address",
      label: t("waterMeter.address"),
      minWidth: 170,
    },
    {
      id: "devEUI",
      label: t("waterMeter.devEUI"),
      minWidth: 170,
    },
    {
      id: "type",
      label: t("waterMeter.type"),
      minWidth: 170,
    },
  ];

  const sort = (field: string): void => {
    setSortBy(field);
  };

  if (fetchingMeters) {
    return (
        <PendingCircle />
    );
  }

  return (
      <>

          {((organization.organizationType === OrganizationType.OTHER || organization.organizationType === OrganizationType.OMAVESI_HOUSING) && !isMobile)
            && (
                <>
                    <AddMeterToOrganization open={modalOpen} handleClose={() => setModalOpen(false)} submit={giveMeterToSelectedOrganization} />
                    <Button
                      className={classes.addButton}
                      color="primary"
                      variant="contained"
                      id="AddMeterToOrganizationButton"
                      onClick={() => setModalOpen(true)}
                    >
                        {t("organizationView.addMeterToOrganization")}
                    </Button>
                </>
            )}
          {!metersOfOrganization || metersOfOrganization.length === 0
            ? (
                <div className={classes.noData}>
                    <Typography className={classes.headerContainer} variant="h5" component="h5">{t("organizationView.noMetersInOrganization")}</Typography>
                </div>
            ) : (
                <>
                    {(organization.organizationType === OrganizationType.OTHER || organization.organizationType === OrganizationType.OMAVESI_HOUSING)
                      ? (
                          <MetersTable<WaterMeter>
                            columns={meterColumns}
                            rows={metersOfOrganization || []}
                            handlePageChange={setPage}
                            handlePageSizeChange={setPageSize}
                            count={totalMetersInTable}
                            currentPage={page}
                            currentRowsPerPage={25}
                            deleteRow={removeMeter}
                            sort={sort}
                            setSortDirection={setSortDirection}
                          />
                      ) : (
                          <MetersTable<WaterMeter>
                            columns={meterColumns}
                            rows={metersOfOrganization || []}
                            handlePageChange={setPage}
                            handlePageSizeChange={setPageSize}
                            count={totalMetersInTable}
                            currentPage={page}
                            currentRowsPerPage={25}
                            sort={sort}
                            setSortDirection={setSortDirection}
                          />
                      )}
                </>

            )}
      </>
  );
});

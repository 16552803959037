import { observer } from "mobx-react-lite";
import React, { useState, useRef } from "react";
import { AppBar, Box, Button, Container, Tab, Tabs, Typography, Grid, TextField, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "@fontsource/roboto";
import useStyles from "./ReportSettingsViewStyles";
import ReportRow from "../../models/Report";
import StatCard from "../../components/statCard/StatCard";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import { useRootStore } from "../../RootStateContext";
import AutomaticReportGenerationModal from "../../components/automaticReportGenerationModal/AutomaticReportGenerationModal";
import { ReportSettingRow, ReportSetting, CreateEmailReportSetting, CreateSftpReportSetting } from "../../models/ReportSetting";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import useResponsivity from "../../hooks/useResponsivity";
import ReportSettingTable, { Column } from "../../components/table/ReportSettingTable";
import { TabPanel } from "../../components/tabPanel/TabPanel";

interface IReportConfigViewProps {
  reportSettings?: ReportSettingRow[];
  deleteSetting: () => Promise<void>;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  search: (s: string) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
  setSearchJustActivated: (s: boolean) => void;
  saveReportSetting: (reportSetting: CreateEmailReportSetting | CreateSftpReportSetting) => void;
}

export default observer(({ reportSettings,
  setPage,
  deleteSetting,
  setPageSize,
  page,
  rowsPerPage,
  sort,
  setSortDirection,
  search,
  resetAllOptions,
  activeSearchValue,
  setSearchJustActivated,
  saveReportSetting }: IReportConfigViewProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchInput = useRef<HTMLInputElement>();
  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");
  const [tabState, setTabState] = React.useState(0); // Tab control
  const [reportSettingModalOpen, setReportSettingModalOpen] = useState<boolean>(false);
  const [reportSettingDeleteModalOpen, setReportSettingDeleteModalOpen] = useState<boolean>(false);

  const { reportStore } = useRootStore();
  const { fetchingReportSettings } = reportStore;
  const { isMobile } = useResponsivity();

  const resetParams = (): void => {
    resetAllOptions();
    setTabState(0);
    if (searchInput && searchInput.current) searchInput.current.value = "";
  };

  const reportColumns: Column[] = [
    {
      id: "title",
      label: t("reportSettingsView.title"),
      minWidth: 100,
    },
    {
      id: "reportTransferType",
      label: t("reportSettingsView.reportTransferType"),
      minWidth: 100,
    },
    {
      id: "reportType",
      label: t("reportSettingsView.reportType"),
      minWidth: 100,
    },
    {
      id: "email",
      label: t("reportSettingsView.email"),
      minWidth: 100,
    },
    {
      id: "sftpServer",
      label: t("reportSettingsView.server"),
      minWidth: 100,
    },
    {
      id: "sftpFolder",
      label: t("reportSettingsView.folder"),
      minWidth: 100,
    },
    {
      id: "sftpUserName",
      label: t("reportSettingsView.username"),
      minWidth: 100,
    },
  ];

  function a11yProps(index: any): any {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const doSearch = (): void => {
    const searchVal = searchInput && searchInput.current && searchInput.current.value;
    if (searchVal !== undefined) {
      if (searchVal.length <= 2) {
        setSearchErrorState(true);
        setSearchErrorText(t("common.searchErrorText"));
        return;
      }
      setSearchErrorState(false);
      setSearchErrorText("");
      setSearchJustActivated(true);
      search(searchVal);
    }
  };

  const handleReportTabChange = (event: React.ChangeEvent<unknown>, newValue: number): void => {
    setTabState(newValue);
  };

  const deleteRow = (setting: ReportSettingRow): void => {
    reportStore.selectedReportSetting = setting;
    setReportSettingDeleteModalOpen(true);
  };

  return (
      <>
          <AutomaticReportGenerationModal open={reportSettingModalOpen} handleClose={() => setReportSettingModalOpen(false)} submit={saveReportSetting} />
          <ConfirmationModal
            open={reportSettingDeleteModalOpen}
            text={t("reportView.confirmReportDelete")}
            handleClose={() => setReportSettingDeleteModalOpen(false)}
            submit={deleteSetting}
          />
          <Container className={`${classes.container} ${isMobile && classes.containerMobile}`}>
              <Grid container className={classes.reportsHeadGrid}>
                  <Grid item container xs={12} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Typography variant="h4" className={classes.headerTop}>{t("reportSettingsView.settings")}</Typography>
                  </Grid>
                  <Grid item container xs={12} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        id="reportsview-open-settings"
                        onClick={() => setReportSettingModalOpen(true)}
                        className={classes.automationButton}
                      >
                          {t("reportSettingsView.newSetting")}
                      </Button>
                  </Grid>
              </Grid>
          </Container>

          <Container className={`${classes.tableContainer} ${isMobile && classes.containerMobile}`}>
              <AppBar className={classes.tabBar} position="static">
                  <Tabs value={tabState} onChange={handleReportTabChange}>
                      <Tab label={t("reportSettingsView.settingsList")} {...a11yProps(0)} />
                  </Tabs>
              </AppBar>
              <TabPanel value={tabState} index={0}>

                  {fetchingReportSettings ? (<PendingCircle />) : (
                      <Paper style={{
                        overflowX: "hidden",
                      }}
                      >
                          <ReportSettingTable<ReportSettingRow>
                            columns={reportColumns}
                            rows={reportSettings!}
                            handlePageChange={setPage}
                            handlePageSizeChange={setPageSize}
                            count={0}
                            currentPage={page}
                            currentRowsPerPage={rowsPerPage}
                            sort={sort}
                            setSortDirection={setSortDirection}
                            deleteRow={deleteRow}
                          />
                      </Paper>
                  )}
                  { !fetchingReportSettings && reportSettings && reportSettings.length === 0 && (
                      <div className={classes.noReports}>
                          <Typography className={classes.headerContainer} variant="h6" component="h6" id="reportsettingsview-searched-with">
                              {!activeSearchValue ? t("reportSettingsView.noSettings") : t("reportSettingsView.noSettingsWithSearch", {
                                searchWord: activeSearchValue,
                              })}
                          </Typography>
                      </div>
                  ) }
              </TabPanel>
              <TabPanel value={tabState} index={1}>
                  <Paper />
              </TabPanel>

          </Container>
      </>
  );
});

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles(
  {
    infoWindow:
    {
      backgroundColor: "white",
      boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      padding: "10px",
      position: "relative",
      transform: "translate(-50%, -130%)",
      minWidth: "230px",
      minHeight: "70px",
      borderRadius: "5px",
    },
    infoWindowHeaderSection:
    {
      width: "100%",
    },
    infoWindowSection:
    {
      width: "100%",
      padding: "8px 5px 8px 5px",
      whiteSpace: "break-spaces",
    },
    infoWindowSectionSmall:
    {
      "& .MuiTypography-root":
        {
          fontSize: "11px",
        },

    },
    infoHeader:
    {},
    infoHeaderBold:
    {
      fontWeight: "bold",
      color: "#EE145B",
    },
    meterList:
    {
      width: "100%",
      maxHeight: "200px",
      overflowY: "auto",
      overflowX: "hidden",
      marginTop: "10px",
    },

    selected: {
      backgroundColor: "#C2DACC",
    },

    meterNumberText: {
      fontWeight: "bold",
    },
  },
));

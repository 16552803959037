import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useNavigate, useParams } from "react-router";

import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import SingleLocationView from "../SingleLocationView";
import { useRootStore } from "../../../RootStateContext";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";
import { loginRequest } from "../../../authConfig";
import acquireToken from "../../../api/MsalUtil";
import { UserForLocationAccessEditing, UserForResidentCreation, UserInfo } from "../../../models/UserInfo";

interface ISingleLocationRootViewMatchParams extends Params<string> {
    locationId: string;
}

export default observer((): JSX.Element => {
  const match = useParams<ISingleLocationRootViewMatchParams>();
  const navigate = useNavigate();
  const store = useRootStore().locationStore;
  const { location } = store;
  const [forbidden, setForbidden] = useState(false);
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const usersStore = useRootStore().userStore;
  const { usersOfLocation, allUsers, residentUsers, fetchingLocationUsers, totalUsers } = usersStore;
  const waterMeterStore = useRootStore().meterStore;
  const { metersOfLocation } = waterMeterStore;
  const { totalMetersInTable } = waterMeterStore;
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const { fetchingMeters } = waterMeterStore;
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchJustActivated, setSearchJustActivated] = useState(false);
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const sort = (field: string): void => {
    setSortBy(field);
  };
  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("");
    setSortDirection("desc");
    setSearchValue("");
    setPage(0);
  };

  const saveUser = async (u: UserForResidentCreation): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await usersStore.saveNewResidentUserForLocation(response.idToken, u);
          if (usersStore.error === undefined) {
            toast.info(t("userManagementView.creationSuccess"), {
              toastId: "UserCreatedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await usersStore.saveNewResidentUserForLocation(currentUserToken, u);
        if (usersStore.error === undefined) {
          toast.info(t("userManagementView.creationSuccess"), {
            toastId: "UserCreatedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  const grantAccessToUser = async (u: UserForLocationAccessEditing): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await usersStore.updateLocationAccess(response.idToken, u);
          if (usersStore.error === undefined) {
            toast.info(t("userManagementView.userAccessSetSuccess"), {
              toastId: "UserCreatedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await usersStore.updateLocationAccess(currentUserToken, u);
        if (usersStore.error === undefined) {
          toast.info(t("userManagementView.userAccessSetSuccess"), {
            toastId: "UserCreatedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("Access grant failed");
      }
    }
  };

  const removeAccessFromUser = async (u: UserForLocationAccessEditing): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await usersStore.removeResidentFromLocation(response.idToken, u);
          if (usersStore.error === undefined) {
            toast.info(t("userManagementView.userAccessToLocationRemoved"), {
              toastId: "userAccessToLocationRemovedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await usersStore.removeResidentFromLocation(currentUserToken, u);
        if (usersStore.error === undefined) {
          toast.info(t("userManagementView.userAccessToLocationRemoved"), {
            toastId: "userAccessToLocationRemovedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    const { locationId } = match;
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      (async () => {
        const response = await acquireToken(instance, request);
        if (response) {
          setCurrentUserToken(response.idToken);
          await waterMeterStore.loadMetersOfLocationPaged(locationId!, response.idToken, page, pageSize);
          await usersStore.loadAllUsers(response.idToken);
          await usersStore.loadUsersOfLocationPaged(locationId!, response.idToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived);
          await store.loadLocation(response.idToken, locationId!)
            .catch(() => {
              if (mounted) {
                setForbidden(true);
              }
            });
        }
      })();
    } else {
      waterMeterStore.loadMetersOfLocationPaged(locationId!, currentUserToken, page, pageSize);
      usersStore.loadAllUsers(currentUserToken);
      usersStore.loadUsersOfLocationPaged(locationId!, currentUserToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived);
      store.loadLocation(currentUserToken, locationId!)
        .catch(() => {
          if (mounted) {
            setForbidden(true);
          }
        });
    }

    return function cleanup() {
      mounted = false;
      store.clearState();
    };
  }, [accounts, instance, match, match.locationId, store, archived, page, pageSize, sortBy, sortDirection, usersStore]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  if (location === null || location === undefined) {
    return (
        <PendingCircle />
    );
  }
  return (
      <SingleLocationView
        location={location}
        metersOfLocation={metersOfLocation}
        setPage={setPage}
        setPageSize={setPageSize}
        totalMeters={totalMetersInTable}
        page={page}
        rowsPerPage={pageSize}
        fetchingMeters={fetchingMeters}
        sort={sort}
        setSortDirection={setSortDirection}
        setArchived={setArchived}
        search={setSearchValue}
        setSearchJustActivated={setSearchJustActivated}
        resetAllOptions={resetAllOptions}
        activeSearchValue={searchValue}
        usersOfLocation={usersOfLocation}
        allUsers={allUsers}
        saveUser={saveUser}
        residentUsers={residentUsers}
        grantAccessToUser={grantAccessToUser}
        removeUserFromLocation={removeAccessFromUser}
        totalUsers={totalUsers}
        fetchingLocationUsers={fetchingLocationUsers}
      />
  );
});

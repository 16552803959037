import { action, makeObservable, observable, runInAction } from "mobx";
import { getAllMetersPaged,
  getMeter, getMeterCount,
  getMetersOfLocationPaged, getMetersOfOrganizationPaged,
  getWeeklyConsumption, giveMeterToOrganization,
  updateMeter,
  updateMultipleMeters, removeMeterFromOrganization, getMetersWithoutCustomer, getMetersForSelect } from "../api/WaterMeterApi";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import { WaterMeter, WaterMeterJustId, WaterMeterWithLocation } from "../models/WaterMeter";
import { getMeterExcel, getMeterReport } from "../api/ReportApi";

export default class MeterStore extends BaseStore {
  allActiveMeters?: WaterMeter[];

  metersOfLocation?: WaterMeter[];

  metersOfOrganization?: WaterMeter[];

  selectableMetersToCustomer?: WaterMeter[];

  meter?: WaterMeter;

  totalMetersOfUser = 0;

  totalMetersInTable = 0;

  fetchingMeters = true;

  fetchingMeter = true;

  fetchingMeterCount = true;

  creatingReport = false;

  downloadingMeterExcel = false;

  updatedMeters: WaterMeter[] = [];

  updatingMetersInProgress = false;

  fetchingWeeklyConsumption = true;

  weeklyConsumption = 0;

  addMeterToOrganizationMeters: WaterMeter[] = [];

  addMeterToOrganizationMetersAvailable = 0;

  fetchingAddMeterToOrganizationMeters = false;

  loadMeter = async (currentUserToken: string, meterId: string): Promise<void> => {
    this.fetchingMeter = true;
    try {
      const conf = await getMeter(currentUserToken, meterId);
      runInAction(() => {
        this.meter = conf;
        this.fetchingMeter = false;
      });
    } catch (e) {
      this.setError("Virhe yksittäisen mittarin haussa");
      console.error(e.stack);
    }
  }

  loadMetersWithoutCustomer = async (currentUserToken: string): Promise<void> => {
    try {
      const meters = await getMetersWithoutCustomer(currentUserToken);
      runInAction(() => {
        this.selectableMetersToCustomer = meters;
      });
    } catch (e) {
      this.setError("Virhe valittavien mittareiden haussa");
    }
  }

  updateMeter = async (currentUserToken: string, meter: WaterMeterWithLocation): Promise<WaterMeter> => {
    try {
      const meterToUpdate = {
        ...meter,
        locationId: meter.meterLocation?.id,
      };

      delete meterToUpdate.meterLocation;

      const updated = await updateMeter(currentUserToken, meter.id!, meterToUpdate);

      runInAction(() => {
        this.meter = updated;
        this.updateItemInVisibleMeters(updated);
      });
      return updated;
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.meterUpdateError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  private updateItemInVisibleMeters(updated: WaterMeter): void {
    this.allActiveMeters = this.allActiveMeters?.map((item) => {
      if (item.id === updated.id) {
        const filteredMeter = Object.fromEntries(Object.entries(updated).filter(([k, v]) => k === "locationId" || v != null));
        const item2 = {
        ...item,
        };
        delete item2.locationId;

        return {
          ...item2, ...filteredMeter,
        };
      }
      return item;
    });
  }

  addMeterToOrganization = async (currentUserToken: string, waterMeter: WaterMeterJustId, organizationId: string): Promise<WaterMeter> => {
    try {
      const updated = await giveMeterToOrganization(currentUserToken, waterMeter.id, waterMeter, organizationId);
      runInAction(() => {
        if (this.metersOfOrganization?.length === 0) {
          this.metersOfOrganization = [updated];
        } else {
          this.metersOfOrganization = this.metersOfOrganization?.concat(updated);
        }
        this.selectableMetersToCustomer = this.selectableMetersToCustomer?.filter((m) => m.id !== waterMeter.id);
      });
      return updated;
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.meterUpdateError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  removeMeterFromOrganization = async (currentUserToken: string, waterMeter: WaterMeter): Promise<WaterMeter> => {
    try {
      const updated = await removeMeterFromOrganization(currentUserToken, waterMeter.id, waterMeter);
      runInAction(() => {
        this.meter = updated;
        this.metersOfOrganization = this.metersOfOrganization?.filter((m) => m.id !== waterMeter.id);
      });
      return updated;
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.meterUpdateError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  loadMeters = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<void> => {
    try {
      this.fetchingMeters = true;
      const meterResp = await getAllMetersPaged(userToken, page, pageSize, sortBy, sortDirection, archived, search);
      runInAction(() => {
        this.allActiveMeters = meterResp.content;
        this.totalMetersInTable = meterResp.totalElements;
        this.fetchingMeters = false;
      });
    } catch (e) {
      this.setError("Virhe laitteiden haussa");
      this.fetchingMeters = false;
      console.error(e.stack);
    }
  }

  /** loadMeterReport = async (userToken: string, createReport: CreateReport): Promise<Blob> => {
    try {
      this.creatingReport = true;
      // todo: later this will send createReport instead of dates, needs other features first
      const responseBlob = await getMeterReport(userToken, new Date(createReport.startDate), new Date(createReport.endDate));
      runInAction(() => {
        this.creatingReport = false;
      });
      return responseBlob;
    } catch (e) {
      this.setError("Virhe raportin luomisessa");
      this.creatingReport = false;
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  } */

  downloadMeterExcel = async (userToken: string): Promise<Blob> => {
    try {
      this.downloadingMeterExcel = true;
      const response = await getMeterExcel(userToken);
      this.downloadingMeterExcel = false;
      return response;
    } catch (e) {
      this.downloadingMeterExcel = false;
      this.setError("Virhe excelin luomisessa");
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  updateMultipleMetersWithFile = async (userToken: string, file: File, organizationId: string): Promise<void> => {
    try {
      this.updatingMetersInProgress = true;
      const meters = await updateMultipleMeters(userToken, file, organizationId);
      runInAction(() => {
        this.updatedMeters = meters;
        this.updatingMetersInProgress = false;
        this.updatedMeters.forEach((updatedMeter) => {
          this.updateItemInVisibleMeters(updatedMeter);
        });
      });
    } catch (e) {
      this.setError(i18n.t("locationstore.locationsFetchError"));
      console.error(e.stack);
      this.updatingMetersInProgress = false;
    }
  }

  getTotalMeterCount = async (userToken: string) :Promise<void> => {
    try {
      this.fetchingMeterCount = true;
      const meterResp = await getMeterCount(userToken);
      runInAction(() => {
        this.totalMetersOfUser = meterResp;
        this.fetchingMeterCount = false;
      });
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.metersOfLocationFetchError"));
      this.totalMetersOfUser = 0;
    }
  }

  loadMetersOfLocationPaged = async (locationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<void> => {
    try {
      this.fetchingMeters = true;
      const meterResp = await getMetersOfLocationPaged(locationId, userToken, page, pageSize, sortBy, sortDirection, archived);
      runInAction(() => {
        this.metersOfLocation = meterResp.content;
        this.totalMetersInTable = meterResp.totalElements;
        this.fetchingMeters = false;
      });
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.metersOfLocationFetchError"));
      this.fetchingMeters = false;
      this.metersOfLocation = [];
      console.error(e.stack);
    }
  }

  loadMetersOfOrganizationPaged = async (organizationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<void> => {
    try {
      this.fetchingMeters = true;
      const meterResp = await getMetersOfOrganizationPaged(organizationId, userToken, page, pageSize, sortBy, sortDirection, archived);
      runInAction(() => {
        this.metersOfOrganization = meterResp.content;
        this.totalMetersInTable = meterResp.totalElements;
        this.fetchingMeters = false;
      });
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.metersOfOrganizationFetchError"));
      this.fetchingMeters = false;
      this.metersOfOrganization = [];
      console.error(e.stack);
    }
  }

  loadWeeklyConsumption = async (userToken: string) : Promise<void> => {
    try {
      this.fetchingWeeklyConsumption = true;
      const consumptionResp = await getWeeklyConsumption(userToken);

      runInAction(() => {
        this.weeklyConsumption = consumptionResp.consumption;
        this.fetchingWeeklyConsumption = false;
      });
    } catch (e) {
      this.setError(i18n.t("waterMeterStore.metersOfLocationFetchError"));
      this.fetchingWeeklyConsumption = false;
      this.weeklyConsumption = 0;
      console.error(e.stack);
    }
  }

  // FOR DYNAMICALLY POPULATED SELECTS
  loadAddMeterToOrganizationMeters = async (userToken: string, search: string, abortSignal?: AbortSignal): Promise<void> => {
    try {
      this.fetchingAddMeterToOrganizationMeters = true;
      const metersResp = await getMetersForSelect(userToken, 0, 15, abortSignal, search);
      runInAction(() => {
        if (abortSignal && !abortSignal.aborted && metersResp) {
          this.addMeterToOrganizationMeters = metersResp.content.filter((m) => m.customerOrganizationName === null);

          if (metersResp.totalElements > 15) { this.addMeterToOrganizationMetersAvailable = metersResp.totalElements - 15; } else { this.addMeterToOrganizationMetersAvailable = 0; }
        } else {
          this.addMeterToOrganizationMeters = [];
          this.addMeterToOrganizationMetersAvailable = 0;
        }
        this.fetchingAddMeterToOrganizationMeters = false;
      });
    } catch (e) {
      this.fetchingAddMeterToOrganizationMeters = false;
      this.setError(i18n.t("waterMeterStore.metersFetchError"));
      console.error(e.stack);
    }
  }

  clearState = (): void => {
    runInAction(() => {
      this.allActiveMeters = [];
      this.metersOfLocation = [];
      this.metersOfOrganization = [];
      this.totalMetersInTable = 0;
      this.totalMetersOfUser = 0;
      this.fetchingMeters = false;
      this.fetchingMeter = false;
      this.fetchingMeterCount = false;
      this.error = undefined;
      this.meter = undefined;
      this.updatedMeters = [];
      this.creatingReport = false;
      this.updatingMetersInProgress = false;
      this.downloadingMeterExcel = false;
      this.fetchingWeeklyConsumption = false;
      this.weeklyConsumption = 0;
      this.selectableMetersToCustomer = [];

      this.addMeterToOrganizationMeters = [];
      this.addMeterToOrganizationMetersAvailable = 0;
      this.fetchingAddMeterToOrganizationMeters = false;
    });
  }

  clearAddMeterToOrganizationMeters = () : void => {
    runInAction(() => {
      this.addMeterToOrganizationMeters = [];
      this.addMeterToOrganizationMetersAvailable = 0;
      this.fetchingAddMeterToOrganizationMeters = false;
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      allActiveMeters: observable,
      selectableMetersToCustomer: observable,
      metersOfLocation: observable,
      totalMetersOfUser: observable,
      metersOfOrganization: observable,
      totalMetersInTable: observable,
      fetchingMeters: observable,
      fetchingMeter: observable,
      fetchingMeterCount: observable,
      creatingReport: observable,
      updatedMeters: observable,
      updatingMetersInProgress: observable,
      meter: observable,
      downloadingMeterExcel: observable,
      fetchingWeeklyConsumption: observable,
      weeklyConsumption: observable,
      addMeterToOrganizationMeters: observable,
      addMeterToOrganizationMetersAvailable: observable,
      fetchingAddMeterToOrganizationMeters: observable,
      loadMetersWithoutCustomer: action,
      loadWeeklyConsumption: action,
      addMeterToOrganization: action,
      removeMeterFromOrganization: action,
      updateMultipleMetersWithFile: action,
      loadMetersOfLocationPaged: action,
      loadMetersOfOrganizationPaged: action,
      downloadMeterExcel: action,
      loadMeter: action,
      loadMeters: action,
      loadAddMeterToOrganizationMeters: action,
      clearState: action,
      // loadMeterReport: action,
      getTotalMeterCount: action,
    });
  }
}

import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  tabPanel: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },

  topContainer:
  {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    margin: `${CSS_GLOBALS.mainContainerMargin !== null ? `${CSS_GLOBALS.mainContainerMargin} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  addNewMeterButton: {
    marginBottom: 10,
  },
  headerContainer: {
    marginBottom: 50,
  },
  noMeters: {
    marginTop: 50,
    padding: 10,
  },
  downloadMetersButton: {
    marginLeft: 10,
  },
  downloadingExcel: {
    color: theme.palette.secondary.light,
    display: "inline-block",
    verticalAlign: "bottom",
  },
  metersTitle: {
    marginTop: 50,
  },
  searchInput: {
    width: "240px",
    marginTop: "7px",
  },
  metersHeadGrid:
  {},
  metersHeadCardsContainer: {
    gap: "15px",
  },
  searchInputContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "stretch",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
  },
  activeSearchWord: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
    color: theme.palette.primary.dark,
  },
  searchButton: {
    height: "45px",
    marginLeft: 15,
    borderRadius: 5,
  },
  clearSearchButton: {
    height: "45px",
    marginLeft: 15,
    borderRadius: 5,
  },
  headerTop: {
    color: "#04444B",
  },
  mapButton: {
    height: "35px",
  },

  containerMobile: {
    padding: "0px 0px 20px 0px",
  },

  searchInputMobile: {
    width: "240px",
    marginTop: "15px",
    marginRight: "10px",
    float: "right",
  },

  clearSearchButtonMobile: {
    height: "40px",
    float: "right",
    borderRadius: 5,
    marginTop: "15px",
    marginRight: "15px",
  },

  activeSearchWordMobile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
    marginRight: "15px",
    color: theme.palette.primary.dark,
  },

  tabBarMobile: {
    marginTop: 0,
  },

  mapButtonMobile: {
    height: "35px",
    float: "right",
    marginRight: "15px",
    marginTop: "20px",
  },

  topContainerMobile: {
    padding: "0px 10px 15px 10px !important",
  },

}));

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    /* display: "flex", */
    overflowY: "auto",
    height: "100%",
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },

  contentMobile: {
    height: "calc(100% - 56px)",
    overflowY: "auto",
  },
}));

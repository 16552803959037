import { action, makeObservable, observable, runInAction } from "mobx";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import Alert, { AlertType } from "../models/Alert";
import { getAlertsOfLocationPaged, getAlertsOfMeterPaged, getAllAlertsPaged, getUnreadAlertCount, getUnreadAlertCountOfMeter, markAllAlertsRead, updateAlert } from "../api/AlertsApi";

export default class AlertStore extends BaseStore {
  allAlerts?: Alert[];

  totalUnreadAlertCount = 0;

  totalAlertsInTable = 0;

  fetchingAlerts = false;

  fetchingAlertCount = false;

  updatingAlertInProgress = false;

  unreadCountOfMeter = 0;

  updateAlert = async (currentUserToken: string, alert: Alert, comment: string): Promise<Alert> => {
    try {
      const alertWithComment = {
      ...alert, comment,
      };
      console.log(alertWithComment);
      const updated = await updateAlert(currentUserToken, alert.id!, alertWithComment);
      runInAction(() => {
        this.updateItemInVisibleAlerts(updated);
        this.totalUnreadAlertCount -= 1;
      });
      return updated;
    } catch (e) {
      this.setError(i18n.t("alertStore.alertUpdateError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  private updateItemInVisibleAlerts(updated: Alert): void {
    this.allAlerts = this.allAlerts?.map((item) => {
      if (item.id === updated.id) {
        return updated;
      }
      return item;
    });
  }

  loadAlerts = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string, alertTypeValue?: string, meterTypeValue?: string): Promise<void> => {
    try {
      this.fetchingAlerts = true;
      const alertResp = await getAllAlertsPaged(userToken, page, pageSize, sortBy, sortDirection, archived, search, alertTypeValue, meterTypeValue);
      runInAction(() => {
        this.allAlerts = alertResp.content;
        this.totalAlertsInTable = alertResp.totalElements;
        this.fetchingAlerts = false;
      });
    } catch (e) {
      this.setError(i18n.t("alertStore.alertFetchError"));
      this.fetchingAlerts = false;
      console.error(e.stack);
    }
  }

  getUnreadAlertCount = async (userToken: string, fetchedByPolling: boolean) :Promise<void> => {
    try {
      this.fetchingAlertCount = !fetchedByPolling;
      const meterResp = await getUnreadAlertCount(userToken);
      runInAction(() => {
        this.totalUnreadAlertCount = meterResp;
        this.fetchingAlertCount = false;
      });
    } catch (e) {
      this.setError(i18n.t("alertStore.alertFetchError"));
      this.totalUnreadAlertCount = 0;
      this.fetchingAlertCount = false;
    }
  }

  getUnreadAlertCountOfMeter = async (userToken: string, meterId: string) :Promise<void> => {
    try {
      const meterResp = await getUnreadAlertCountOfMeter(userToken, meterId);
      runInAction(() => {
        this.unreadCountOfMeter = meterResp;
      });
    } catch (e) {
      this.setError(i18n.t("alertStore.alertFetchError"));
      this.unreadCountOfMeter = 0;
    }
  }

  loadAlertsOfLocationPaged = async (locationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<void> => {
    try {
      this.fetchingAlerts = true;
      const meterResp = await getAlertsOfLocationPaged(locationId, userToken, page, pageSize, sortBy, sortDirection, archived);
      runInAction(() => {
        this.allAlerts = meterResp.content;
        this.totalAlertsInTable = meterResp.totalElements;
        this.fetchingAlerts = false;
      });
    } catch (e) {
      this.setError(i18n.t("alertStore.alertFetchError"));
      this.fetchingAlerts = false;
      this.allAlerts = [];
      console.error(e.stack);
    }
  }

  loadAlertsOfMeterPaged = async (meterId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<void> => {
    try {
      this.fetchingAlerts = true;
      const meterResp = await getAlertsOfMeterPaged(meterId, userToken, page, pageSize, sortBy, sortDirection, search);
      runInAction(() => {
        this.allAlerts = meterResp.content;
        this.totalAlertsInTable = meterResp.totalElements;
        this.fetchingAlerts = false;
      });
    } catch (e) {
      this.setError(i18n.t("alertStore.alertFetchError"));
      this.fetchingAlerts = false;
      this.allAlerts = [];
      console.error(e.stack);
    }
  }

  markAllRead = async (userToken: string) : Promise<void> => {
    try {
      await markAllAlertsRead(userToken);
      /* runInAction(() => {

      }); */
    } catch (e) {
      this.setError(i18n.t("alertStore.alertFetchError"));
      console.error(e.stack);
    }
  }

  clearState = (): void => {
    runInAction(() => {
      this.allAlerts = [];
      // this.totalUnreadAlertCount = 0;
      this.totalAlertsInTable = 0;
      this.unreadCountOfMeter = 0;
      this.fetchingAlerts = false;
      this.fetchingAlertCount = false;
      this.updatingAlertInProgress = false;
      this.error = undefined;
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      allAlerts: observable,
      totalUnreadAlertCount: observable,
      totalAlertsInTable: observable,
      unreadCountOfMeter: observable,
      fetchingAlerts: observable,
      fetchingAlertCount: observable,
      updatingAlertInProgress: observable,
      updateAlert: action,
      getUnreadAlertCount: action,
      loadAlertsOfLocationPaged: action,
      loadAlertsOfMeterPaged: action,
      loadAlerts: action,
      markAllRead: action,
    });
  }
}

import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Button, IconButton, TableSortLabel } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Minimize, Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import useStyles from "./TableStyles";
import PendingCircle from "../PendingCircle/PendingCircle";
import useResponsivity from "../../hooks/useResponsivity";
import { GeoJSONLocation } from "../../models/Map";
import { isValidLocation } from "../../utils/MapUtils";

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "right";
  format?: (t: any) => string;
  booleanType?: boolean;
  nestedInside?: Record<string, unknown>;
  disableSort?: boolean;
}

const StyledTableRowWithSubRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(4n+1)": {
      backgroundColor: "white",
    },
  },
}))(TableRow);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
  },
}))(TableRow);

type Generic = {
  [key: string]: unknown
}

interface ITableProps<T extends Generic> {
  columns: Column[];
  rows: T[];
  editRow?: (s: T) => void;
  cellClickedCallback?: (d: T) => void;
  addressCellClickedCallback?: (d: T) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (size: number) => void;
  count: number;
  currentPage: number;
  currentRowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void
}

interface IRowProps<T extends Generic> {
  row: T;
  columns: Column[];
  editRow?: (s: T) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onAddressClick?: (e: React.MouseEvent<HTMLElement>) => void;
  rowIndex: string;
}

const generateId = (prefix : string, id: string) : string => `${prefix}-${id}`;

// eslint-disable-next-line no-bitwise
const isFloat = (n : any) : boolean => n === +n && n !== (n | 0);

// eslint-disable-next-line no-bitwise
const isInteger = (n : any) : boolean => n === +n && n === (n | 0);

const renderCell = (column : Column, row: any, value : any, t: any, classes: ReturnType<typeof useStyles>) : JSX.Element => {
  if (column.id === "meterCheck" && column.booleanType) { return <Checkbox onClick={(e) => e.stopPropagation()} />; }

  if (column.id === "latestReadingTimeStamp" && value !== null) {
    return (
        <span>
            {format(parseISO(value as string), "dd.MM.yyyy' 'HH:mm")}
        </span>
    );
  }

  if (column.id === "connectionStatusCombined" && value !== null) {
    if (value.connectionStatus === "OFFLINE") {
      return (
          <span className={classes.offline} title={t("meterView.statusTooltip")}>
              {t("connectionStatus.offlineFor", {
                days: value.connectionStatusDays,
              })}
          </span>
      );
    }
    return (
        <span className={value.connectionStatus === "INITIAL" ? classes.initial : value.connectionStatus === "ONLINE" ? classes.online : ""} title={t("meterView.statusTooltip")}>
            {t(`connectionStatus.${value.connectionStatus}`)}
        </span>
    );
  }

  if (column.id === "address") {
    if (isValidLocation(row.location)) {
      return (
          <span
            className={classes.addressSpan}
          >
              {value as string}
          </span>
    );
    }
    return <span>{value as string}</span>;
  }

  if (column.id === "type" && value !== null) {
    return (
        <span title={t("meterView.typeToolTip")}>
            {t(`meterType.${value}`)}
        </span>
    );
  }

  if ((isFloat(value) || isInteger(value)) && column.id !== "locationName" && column.id !== "meterNumber") {
    return <span>{(value as number).toFixed(3).replace(".", ",")}</span>;
  }

  if ((column.id === "consumptionWeek" || column.id === "consumptionMonth" || column.id === "consumptionYear")) {
    return <span title={t("meterView.missingReadingsTooltip")}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  }

  if (!column.format) return <span title={value as string}>{value as string}</span>;

  return <span title={column.format(value)}>{column.format(value)}</span>;
};

const responsivityFilter = (f: Column, isMobile: boolean) : boolean => {
  const responsiveShow = [
    "meterNumber",
    "address",
    "type",
    /* "connectionStatusCombined",
     "consumptionWeek",
    "consumptionMonth",
    "consumptionYear",
    "ownerOrganizationName",
    "customerOrganizationName",
    "locationName",
    "latestReadingCubicMeters",
    "latestReadingTimeStamp",
    "ownerOrganizationName",
    "customerOrganizationName", */
  ];

  return !isMobile || (isMobile && responsiveShow.includes(f.id));
};

function RowElement<T extends Generic>({ row, columns, editRow, onClick, onAddressClick, rowIndex }: IRowProps<T>): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();
  return (
      <StyledTableRow
        className={classes.root}
        hover
        role="checkbox"
        tabIndex={-1}
        key={Object.keys(row)[0]}
        style={
              onClick !== undefined
                ? {
                  cursor: "pointer",
                } : {}
            }
      >
          {columns.filter((f) => responsivityFilter(f, isMobile)).map((column) => {
                const value = row[column.id];

                return (
                    <TableCell
                      onClick={column.id === "address" && isValidLocation(row.location as GeoJSONLocation) ? onAddressClick : onClick}
                      component="th"
                      scope="row"
                      key={generateId("meter-col", `${column.id}-${rowIndex}`)}
                      align={column.align}
                      className={`${classes.borderBottom} ${column.id === "consumptionWeek" || column.id === "latestReadingCubicMeters" || column.id === "connectionStatus" ? classes.borderLeft : ""}`}
                    >
                        {renderCell(column, row, value, t, classes)}
                    </TableCell>
                );
              })}
          <TableCell
            className={`
                        ${classes.borderBottom} 
                        ${classes.editButton}`}
            style={{
                  padding: "0",
            }}
          >
              {editRow && !isMobile
                        && (
                            <Button
                              className={classes.downloadButton}
                              onClick={() => editRow(row)}
                              role="presentation"
                              variant="outlined"
                              id={generateId("meter-edit-button", rowIndex)}
                            >
                                <CreateIcon style={{
                                  verticalAlign: "middle",
                                }}
                                />
                            </Button>
                        )}
          </TableCell>
      </StyledTableRow>
  );
}

export default function<T extends Generic> ({ columns, rows, editRow, cellClickedCallback, addressCellClickedCallback, handlePageChange, handlePageSizeChange, count, currentPage, currentRowsPerPage, sort, setSortDirection }: ITableProps<T>): JSX.Element {
  const classes = useStyles();
  const [currentSortedBy, setCurrentSortedBy] = useState("");
  const [asc, setAsc] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();

  const setSort = (field: string): void => {
    if (currentSortedBy === field) {
      const newDirection = asc ? "desc" : "asc";
      setSortDirection(newDirection);
      setAsc(!asc);
    } else {
      setAsc(false);
      setSortDirection("desc");
      setCurrentSortedBy(field);
      sort(field);
    }
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handlePageSizeChange(+event.target.value);
  };

  return (
      <div className={`${classes.root} ${isMobile ? classes.rootMobile : ""}`}>
          <TableContainer className={`${classes.tableContainer} ${isMobile ? classes.tableContainerMobile : ""}`}>
              <Table aria-label="collapsible table">
                  <TableHead>
                      <TableRow className={classes.setHeaders}>
                          <TableCell colSpan={5} />
                          {!isMobile && (
                              <TableCell colSpan={3} align="center">
                                  {t("table.consumption")}
                                  {" "}
                                  (m&sup3;)
                              </TableCell>
                          )}
                          {!isMobile && (<TableCell colSpan={3} align="center">{t("table.latestReading")}</TableCell>)}

                          {!isMobile && <TableCell colSpan={1} align="center" />}
                      </TableRow>
                      <TableRow className={classes.root}>

                          {columns.filter((f) => responsivityFilter(f, isMobile)).map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  maxWidth: column.maxWidth,
                                  fontWeight: "bold",
                                }}
                                className={column.id === "consumptionWeek"
                                || column.id === "latestReadingCubicMeters"
                                || column.id === "connectionStatus" ? `${classes.borderLeft} ${isMobile ? classes.tableHead : ""}` : `${isMobile ? classes.tableHead : ""}`}
                              >
                                  {column.disableSort
                                    ? <>{column.label}</>
                                    : (
                                        <TableSortLabel
                                          className={classes.sortLabel}
                                          active={currentSortedBy === column.id}
                                          onClick={() => setSort(column.id)}
                                          direction={asc ? "asc" : "desc"}
                                        >
                                            {column.id === "meterNumber" && isMobile ? "#" : column.label}
                                        </TableSortLabel>
                                    ) }
                              </TableCell>
                          ))}
                          <TableCell />
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      { rows.length > 0 && rows.map((row, idx) => (
                          <RowElement onAddressClick={() => addressCellClickedCallback && addressCellClickedCallback(row)} onClick={() => cellClickedCallback && cellClickedCallback(row)} editRow={editRow} columns={columns} row={row} key={JSON.stringify(row)} rowIndex={idx.toString()} />
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
          {/* rows.length === 0 && <PendingCircle customStyles={classes.spinner} /> */}
          <TablePagination
            labelRowsPerPage={t("table.rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} (${count})`}
            rowsPerPageOptions={[25, 100]}
            component="div"
            count={count !== undefined ? count : rows.length}
            rowsPerPage={currentRowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </div>
  );
};

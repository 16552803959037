import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { AppBar, Button, Container, Typography, Grid, Paper, TextField, Tabs, Tab, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import "@fontsource/roboto";
import { Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import useStyles from "./AlertsViewStyles";
import StatCard from "../../components/statCard/StatCard";
import Alert from "../../models/Alert";
import Table, { Column } from "../../components/table/TableWithPagination";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import SingleAlertModal from "../../components/singleAlertModal/SingleAlertModal";
import MapModal from "../../components/googleMaps/AlertMap/AlertMapModal";
import { AlertMarker } from "../../models/Map";
import AlertSettingsView from "./alertSettings/AlertSettingsView";
import { UserInfo } from "../../models/UserInfo";
import { UserRole } from "../../models/UserRole";
import useResponsivity from "../../hooks/useResponsivity";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import { TabPanel } from "../../components/tabPanel/TabPanel";

interface IMeterConfigViewProps {
  alerts?: Alert[];
  mapAlerts: AlertMarker[];
  markAlertAsChecked: (alert: Alert, comment: string) => void;
  markAllAlertsRead: () => void;
  totalUnreadAlertsCount?: number;
  fetchingAlertCount: boolean;
  fetchingAlerts: boolean;
  totalAlertCount: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  search: (s: string | undefined) => void;
  setAlertTypeValue: (s: string | undefined) => void;
  setMeterTypeRootValue: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
  currentUser: UserInfo;
}

export default observer(({ alerts,
  mapAlerts,
  markAlertAsChecked,
  markAllAlertsRead,
  totalUnreadAlertsCount,
  fetchingAlertCount,
  fetchingAlerts,
  totalAlertCount,
  setPage,
  setPageSize,
  page,
  rowsPerPage,
  sort,
  setSortDirection,
  search,
  setAlertTypeValue,
  setMeterTypeRootValue,
  setSearchJustActivated,
  resetAllOptions,
  activeSearchValue,
  currentUser }: IMeterConfigViewProps): JSX.Element => {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState<number>(0);

  const searchInput = useRef<HTMLInputElement>();
  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");

  const [selectedAlert, setSelectedAlert] = useState<Alert | undefined>(undefined);

  const [mapModalOpen, setMapModalOpen] = useState<boolean>(false);
  const [alertType, setAlertTypeState] = useState<string | undefined >("");

  const alertTypeInput = useRef<HTMLInputElement>();
  const meterTypeInput = useRef<HTMLInputElement>();
  const [meterTypeValue, setMeterTypeValue] = useState("");

  const [confirmMarkAllReadModalOpen, setConfirmMarkAllReadModalOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number): void => {
    setCurrentTab(newValue);
    setSelectedAlert(undefined);
  };

  const handleAlertTypeChange = (event: SelectChangeEvent<string>): void => {
      setAlertTypeState(event.target.value as string);
  };

  const handleMeterTypeChange = (event: SelectChangeEvent<string>): void => {
      setMeterTypeValue(event.target.value as string);
  };

  const columns: Column[] = [
    {
      id: "meterNumber",
      label: t("waterMeter.meterNumber"),
      minWidth: 170,
    },
    {
      id: "alertStartedAt",
      label: t("alert.startedAt"),
      minWidth: 170,
    },
    {
      id: "alertEndedAt",
      label: t("alert.endedAt"),
      minWidth: 170,
    },
    {
      id: "comment",
      label: t("alert.comment"),
      minWidth: 170,
    },
    {
      id: "address",
      label: t("alert.address"),
      minWidth: 170,
    },
    {
      id: "alertType",
      label: t("alert.type"),
      minWidth: 170,
    },
    {
      id: "meterType",
      label: t("alert.meterType"),
      minWidth: 170,
    },
    {
      id: "meterModel",
      label: t("waterMeter.meterModel"),
      minWidth: 170,
    },
  ];

  const filterAlertTypes = (role: string): string[] => {
    if (role === UserRole.OV_RESIDENT) {
      return ["BURST", "CONSUMPTION_WEEK", "CONSUMPTION_MONTH", "CONSUMPTION_YEAR", "WATER_LEAK", "LOW_TEMPERATURE"];
    }

    return ["BURST", "CONNECTION_ERROR", "CONSUMPTION_WEEK", "CONSUMPTION_MONTH", "CONSUMPTION_YEAR", "LOW_TEMPERATURE", "NO_WATER", "WATER_LEAK", "REVERSE_FLOW"];
  };

  const filterMeterTypes = ["COLD", "WARM"];

  const doSearch = (): void => {
    const searchVal = searchInput && searchInput.current && searchInput.current.value;
    const alertTypeVal = alertTypeInput && alertTypeInput.current && alertTypeInput.current.value;

    // if (searchVal !== undefined || alertTypeVal !== undefined) {
      /* if (searchVal.length <= 2) {
        setSearchErrorState(true);
        setSearchErrorText(t("common.searchErrorText"));
        return;
      } */
      setSearchErrorState(false);
      setSearchErrorText("");
      search(searchVal);
      setAlertTypeValue(alertTypeVal);
      setMeterTypeRootValue(meterTypeValue);
      setSearchJustActivated(true);
    // }
  };

  const openAlertModal = (a:Alert): void => {
    setSelectedAlert(a);
  };
  const closeAlertModal = (): void => {
    setSelectedAlert(undefined);
  };

  const markAlert = (alert: Alert, comment: string) : void => {
    markAlertAsChecked(alert, comment);
    setSelectedAlert(undefined);
  };

  const resetParams = (): void => {
    resetAllOptions();
    setSearchErrorState(false);
    setSearchErrorText("");
    setAlertTypeState("");
    if (searchInput && searchInput.current) searchInput.current.value = "";
    if (alertTypeInput && alertTypeInput.current) alertTypeInput.current.value = "";
  };

  const markAllRead = () : void => {
    markAllAlertsRead();
  };

  return (
      <>
          <SingleAlertModal submit={markAlert} open={selectedAlert !== undefined} handleClose={closeAlertModal} alert={selectedAlert} />
          <MapModal open={mapModalOpen} handleClose={() => setMapModalOpen(false)} markers={mapAlerts} />
          <ConfirmationModal
            open={confirmMarkAllReadModalOpen}
            text={t("alertView.confirmMarkAllRead")}
            handleClose={() => setConfirmMarkAllReadModalOpen(false)}
            submit={markAllRead}
          />
          <Container className={`${classes.container2} ${isMobile ? classes.containerMobile : ""}`}>
              <Grid container className={classes.metersHeadGrid} item xs={12} justifyContent={isMobile ? "center" : "flex-start"}>
                  <Typography variant="h4" className={classes.headerTop}>{t("navigation.alerts")}</Typography>
              </Grid>
              <Grid container className={classes.metersHeadGrid}>
                  <Grid
                    container
                    item
                    xs={isMobile ? 12 : 3}
                    justifyContent={isMobile ? "center" : "flex-start"}
                    alignItems="center"
                  >
                      {totalUnreadAlertsCount !== undefined && (
                          <Grid item>
                              <StatCard
                                icon={totalUnreadAlertsCount ? "NotificationImportant" : "CheckCircle"}
                                header={totalUnreadAlertsCount.toString()}
                                content={t("alertView.unreadAlerts")}
                                id="alertsview-totalalerts-card"
                                loadingState={fetchingAlertCount}
                              />
                          </Grid>
                                  )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={isMobile ? 12 : 9}
                    justifyContent="flex-end"
                    direction={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "center" : "flex-start"}
                    className={isMobile ? classes.searchInputWrapperMobile : classes.searchInputWrapper}
                  >
                      <TextField
                        inputRef={searchInput}
                        onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                        className={`${isMobile ? classes.searchInputMobile : classes.searchInput}`}
                        id="alertsview-search-input"
                        size="small"
                        variant="outlined"
                        error={searchErrorState}
                        helperText={searchErrorText}
                        placeholder={t("alertView.searchHelpText2")}
                        InputLabelProps={{
                                          shrink: true,
                        }}
                      />
                      <div>
                          <InputLabel id="alertTypeLabel" className={classes.alertViewSelectLabel}>{t("alert.type")}</InputLabel>
                          <Select
                            inputRef={alertTypeInput}
                            labelId="alertTypeLabel"
                            id="alertTypeLabelId"
                            value={alertType}
                            onChange={handleAlertTypeChange}
                            className={classes.alertViewSelect}
                            displayEmpty
                          >
                              <MenuItem value="" key="">{t("alertView.alertViewSelectAll")}</MenuItem>
                              {filterAlertTypes(currentUser.userRole).map((type: string, index) => (
                                  <MenuItem value={type} key={type}>
                                      {t(`alertType.${type}`)}
                                  </MenuItem>
                                            ))}
                          </Select>
                      </div>
                      <div>
                          <InputLabel id="meterTypeLabel" className={classes.alertViewSelectLabel}>{t("alert.meterType")}</InputLabel>
                          <Select
                            inputRef={meterTypeInput}
                            labelId="meterTypeLabel"
                            id="meterTypeLabelId"
                            value={meterTypeValue}
                            onChange={handleMeterTypeChange}
                            className={classes.alertViewSelect}
                            displayEmpty
                          >
                              <MenuItem value="" key="">{t("alertView.alertViewSelectAll")}</MenuItem>
                              {filterMeterTypes.map((type: string, index) => (
                                  <MenuItem value={type} key={type}>
                                      {t(`meterType.${type}`)}
                                  </MenuItem>
                                            ))}
                          </Select>
                      </div>

                      <Button
                        id="alertsview-search-button"
                        onClick={() => doSearch()}
                        className={classes.searchButton}
                        variant="contained"
                        color="primary"
                      >
                          {t("meterView.search")}

                      </Button>

                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.clearButtonContainer}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                      <Button
                        color="secondary"
                        variant="contained"
                        id="alertsview-search-clear"
                        onClick={resetParams}
                        className={`${isMobile ? classes.clearSearchButtonMobile : classes.clearSearchButton}`}
                      >
                          {t("meterView.defaultSettings")}
                      </Button>
                      {activeSearchValue && (
                          <Typography className={isMobile ? classes.activeSearchWordMobile : classes.activeSearchWord} variant="h6">
                              {t("meterView.searchedWithWord", {
                                            searchWord: activeSearchValue,
                                          })}
                          </Typography>
                                  )}
                  </Grid>
                  {currentUser.userRole !== UserRole.OV_RESIDENT && (!fetchingAlerts && alerts && alerts?.length > 0) && (
                      <Grid
                        container
                        item
                        xs={12}
                        className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                          <Button
                            id="alertsview-mark-all-read"
                            onClick={() => setConfirmMarkAllReadModalOpen(true)}
                            className={isMobile ? classes.markAllButtonMobile : classes.markAllButton}
                            variant="contained"
                            color="secondary"
                          >
                              {t("alertView.markAllRead")}

                          </Button>
                          <Button
                            id="alertsview-open-map-button"
                            onClick={() => setMapModalOpen(true)}
                            className={isMobile ? classes.mapButtonMobile : classes.mapButton}
                            variant="contained"
                            color="secondary"
                          >
                              {t("alertView.openMap")}

                          </Button>
                      </Grid>
                              )}
              </Grid>
          </Container>
          <Container className={classes.container}>
              <AppBar className={`${classes.tabBar} ${isMobile && classes.tabBarMobile}`} position="static">
                  <Tabs value={currentTab} onChange={handleChange}>
                      <Tab label={t("navigation.alerts")} id="alerts-tab" aria-controls="alerts-tab" />
                      <Tab label={t("alertView.myAlertSettings")} id="alert-settings-tab" aria-controls="alert-settings-tab" />
                  </Tabs>
              </AppBar>
              <TabPanel value={currentTab} index={0}>
                  <Paper className={`${classes.tabContentWrapper} ${isMobile && classes.tabContentWrapperMobile}`}>
                      <Container className={classes.tableContainer}>
                          {fetchingAlerts && (<PendingCircle />)}
                          {!fetchingAlerts && alerts && alerts?.length > 0 && (
                              <Paper style={{
                                    overflowX: "hidden",
                              }}
                              >
                                  <Table<Alert>
                                    columns={columns}
                                    count={totalAlertCount}
                                    cellClickedCallback={(alarm: Alert) => openAlertModal(alarm)}
                                    rows={alerts || []}
                                    handlePageChange={setPage}
                                    handlePageSizeChange={setPageSize}
                                    currentPage={page}
                                    currentRowsPerPage={rowsPerPage}
                                    sort={sort}
                                    setSortDirection={setSortDirection}
                                    onClickIcon={<Edit />}
                                  />
                              </Paper>
                              )}
                          { !fetchingAlerts && alerts && alerts.length === 0 && (
                              <div>
                                  <Typography className={classes.headerContainer} variant="h6" component="h6" id="alertsview-searched-with">
                                      {!activeSearchValue ? t("alertView.noAlerts") : t("alertView.noAlertsWithSearch", {
                                            searchWord: activeSearchValue,
                                          })}
                                  </Typography>
                              </div>
                              ) }
                      </Container>
                  </Paper>
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                  <Paper className={`${classes.tabContentWrapper} ${isMobile && classes.tabContentWrapperMobile}`}>
                      <AlertSettingsView />
                  </Paper>
              </TabPanel>
          </Container>
      </>
  );
});

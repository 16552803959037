import React, { useRef, useState } from "react";
import { Button, FormControl, Typography, Select, FormHelperText, Accordion, AccordionDetails, AccordionSummary, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArticleIcon from "@mui/icons-material/Article";
import { convertToStaticDateString } from "./Util";
import useStyles from "./ReportCreatorStyles";
import { useRootStore } from "../../RootStateContext";
import { Organization } from "../../models/Organization";
import useResponsivity from "../../hooks/useResponsivity";
import { CreateFullMeterReport, CreateSingleMeterReport } from "../../models/CreateReport";
import SingleMeterReportCreator from "./SingleReportCreator";
import { ReportType } from "../../models/ReportSetting";

type Generic = {
  [key: string]: unknown
}

interface IReportSelectorProps<T extends Generic> {
  creatingReport: boolean;
  singleReportCreator: boolean;
  submit: (createReport: CreateFullMeterReport | CreateSingleMeterReport) => void;
  customerOrganizations?: Organization[];
  meterId?: string;
}

export default function<T extends Generic> ({ submit, creatingReport, singleReportCreator, customerOrganizations, meterId }: IReportSelectorProps<T>): JSX.Element {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const { t } = useTranslation();

  const [organizationId, setOrganizationId] = useState("");

  const { reportStore } = useRootStore();

  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [reportType, setTypeState] = useState<ReportType>("METER_REPORT_SOLTEQ_INWORKS_CSV");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const reportIncludesStartDate = (): boolean => (reportType === "METER_REPORT_XLSX" || reportType === "METER_REPORT_TAMPUURI_CSV");

  const handleSubmit = (): void => {
    if (reportType !== null && reportIncludesStartDate() && startDate !== null && endDate !== null) {
      const sd = convertToStaticDateString(startDate);
      const ed = convertToStaticDateString(endDate);

      if (singleReportCreator) {
        submit({
          startDate: sd,
          endDate: ed,
          reportType,
          meterId,
        } as CreateSingleMeterReport);
      } else {
        submit({
          startDate: sd,
          endDate: ed,
          reportType,
          customerOrganizationId: organizationId || undefined,
        } as CreateFullMeterReport);
      }
    }
    if (reportType !== null && !reportIncludesStartDate() && endDate !== null) {
      const startANDendDate = convertToStaticDateString(endDate);

      if (singleReportCreator) {
        submit({
          startDate: startANDendDate,
          endDate: startANDendDate,
          reportType,
          meterId,
        } as CreateSingleMeterReport);
      } else {
        submit({
          startDate: startANDendDate,
          endDate: startANDendDate,
          reportType,
          customerOrganizationId: organizationId || undefined,
        } as CreateFullMeterReport);
      }
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setTypeState(event.target.value as ReportType);
    }
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
      setOrganizationId(event.target.value as string);
  };

  if (singleReportCreator) {
    return (
        <SingleMeterReportCreator
          selectedReportType={reportType}
          creatingReport={creatingReport}
          handleSubmit={handleSubmit}
          handleTypeChange={handleTypeChange}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
        />
    );
  }

  return (
      <Accordion className={classes.createReport}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="create-report-header"
            className={classes.createTitle}
          >
              <ArticleIcon />
              <Typography className={classes.createTitleText} component="span">{t("table.createReport")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
              {customerOrganizations
                      && (
                          <div className={classes.typeSelector}>
                              <FormControl>
                                  <InputLabel id="organizationSelectLabel">{t("userManagementView.organization")}</InputLabel>
                                  <Select
                                    labelId="orgLabel"
                                    id="orgLabelId"
                                    value={organizationId}
                                    onChange={handleOrganizationDropdownChange}
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                  >
                                      <MenuItem key="" value="">-- Ei valintaa --</MenuItem>
                                      {customerOrganizations.map((org) => (
                                          <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                                      ))}
                                  </Select>
                                  <FormHelperText>{t("reports.organizationHelperText")}</FormHelperText>
                              </FormControl>
                          </div>
                      )}
              <SingleMeterReportCreator
                selectedReportType={reportType}
                creatingReport={creatingReport}
                handleSubmit={handleSubmit}
                handleTypeChange={handleTypeChange}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
          </AccordionDetails>
      </Accordion>
  );
};

import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  tableContainer:
  {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  topContainer:
  {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  addNewLocationButton: {
    marginBottom: 10,
  },
  createReportButton: {
    float: "right",
    marginBottom: 10,
    "& .MuiSvgIcon-root": {
      top: -1,
      position: "relative",
      paddingRight: 2,
      fontSize: "1.2rem",
    },
  },
  headerContainer: {
    marginBottom: 50,
  },
  noReports: {
    marginTop: 50,
  },
  reportsTitle: {
    marginTop: 50,
  },
  searchInput: {
    textAlign: "right",
    whiteSpace: "nowrap",
    justifySelf: "flex-end",
  },
  searchButton: {
    height: "100%",
    marginLeft: 15,
    borderRadius: 5,
  },

  activeSearchWord: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
    color: theme.palette.primary.dark,
  },
  reportsHeadGrid:
  {},
  reportsHeadCardsContainer:
  {
    paddingTop: "15px",
  },
  searchInputContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "stretch",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
  },
  clearSearchButton: {
    height: "45px",
    marginLeft: 15,
    borderRadius: 5,
  },
  headerTop: {
    color: "#04444B",
  },
  reportsTableButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "25px 0px 20px 25px",
  },

  tabPanelPaper:
  {
    padding: "16px",
  },
  tabPanel:
  {
    "& .MuiBox-root":
    {
      padding: "0px",
    },
  },
  automationButton:
  {
    marginTop: "20px",
  },

  containerMobile: {
    padding: "0px 0px 20px 0px",
  },

}));

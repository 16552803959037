import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  section: {
    marginBottom: 25,
    backgroundColor: "white",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
  },
  button: {
    marginRight: 15,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
    marginRight: 15,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { useRootStore } from "../../../RootStateContext";
import { loginRequest } from "../../../authConfig";
import ReportSettingsView from "../ReportSettingsView";
import acquireToken from "../../../api/MsalUtil";
import { CreateEmailReportSetting, CreateSftpReportSetting, ReportSettingRow } from "../../../models/ReportSetting";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchValue, setSearchValue] = useState("");
  const [searchJustActivated, setSearchJustActivated] = useState(false);
  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const { reportStore } = useRootStore();
  const { automaticReportSettings } = reportStore;

  const resetAllOptions = (): void => {
    setSortBy("createdAt");
    setSortDirection("desc");
    setSearchValue("");
  };

  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const deleteSetting = async (): Promise<void> => {
    const reportSetting = reportStore.selectedReportSetting;
    if (!reportSetting) {
      return;
    }

    if (currentUserToken) {
      await reportStore.deleteReportSetting(currentUserToken, reportSetting);

      if (reportStore.error === undefined) {
        toast.info(t("reportSettingsView.reportSettingDeleted"), {
          toastId: "reportSettingDeletedToast",
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);
      if (response) {
        await reportStore.deleteReportSetting(response.idToken, reportSetting);
        if (reportStore.error === undefined) {
          toast.info(t("reportSettingsView.reportSettingDeleted"), {
            toastId: "reportSettingDeletedToast",
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    }
  };

  const saveReportSetting = async (reportSetting: CreateEmailReportSetting | CreateSftpReportSetting): Promise<void> => {
    if (currentUserToken) {
      if (reportSetting.reportTransferType === "EMAIL") {
        await reportStore.createEmailReportSetting(currentUserToken, reportSetting as CreateEmailReportSetting);
      } else {
        await reportStore.createSftpReportSetting(currentUserToken, reportSetting as CreateSftpReportSetting);
      }

      if (reportStore.error === undefined) {
        toast.info(t("reportSettingsView.reportSettingSaved"), {
          toastId: "reportSettingSavedToast",
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);
      if (response) {
        if (reportSetting.reportTransferType === "EMAIL") {
          await reportStore.createEmailReportSetting(response.idToken, reportSetting as CreateEmailReportSetting);
        } else {
          await reportStore.createSftpReportSetting(response.idToken, reportSetting as CreateSftpReportSetting);
        }
      }
      if (reportStore.error === undefined) {
        toast.info(t("reportSettingsView.reportSettingSaved"), {
          toastId: "reportSettingSavedToast",
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const loadReportSettings = useCallback(async (userToken:string, mounted:boolean, showSpinner:boolean): Promise<void> => {
    if (searchValue && searchJustActivated) {
      setPage(0);
      setSearchJustActivated(false);
    }
    await reportStore.loadReportSettings(userToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, searchValue)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
  }, [reportStore, page, pageSize, searchJustActivated, searchValue, sortBy, sortDirection]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          const meterReportsPromise = await loadReportSettings(response.idToken, mounted, true);
        }
      } else {
        const meterReportsPromise = await loadReportSettings(currentUserToken, mounted, true);
      }
    })();
    return function cleanup() {
      mounted = false;
      reportStore.clearState();
    };
  }, [accounts, instance, loadReportSettings, reportStore]);

  return (
      <ReportSettingsView
        saveReportSetting={saveReportSetting}
        reportSettings={automaticReportSettings}
        deleteSetting={deleteSetting}
        setPage={handlePageChange}
        setPageSize={handlePageSizeChange}
        page={page}
        rowsPerPage={pageSize}
        sort={sort}
        setSortDirection={setSortDirection}
        search={setSearchValue}
        resetAllOptions={resetAllOptions}
        activeSearchValue={searchValue}
        setSearchJustActivated={setSearchJustActivated}
      />
  );
});

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
    closeIcon: {
        position: "absolute",
        left: "calc(100% - 65px)",
        width: "40px !important",
        height: "40px !important",
        color: "white",
        transform: "translateY(10px)",
      },
      sectionHeader: {
        position: "relative",
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.primary.light,
        "& .MuiTypography-h6": {
          color: theme.palette.primary.contrastText,
          lineHeight: "60px",
          marginLeft: "15px",
        },
        height: "60px",
      },
}));

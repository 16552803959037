import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    position: "relative", // IMPORTANT

    boxShadow: theme.shadows[5],
    minWidth: "20%",
    "& .MuiStepConnector-line": {
      marginLeft: 15,
    },

    width: "80vh",
    height: "80vh",
  },
  illegalDeepSubElementHoverHack:
  {
    "& > div > div > div > div > div:nth-child(2) > div:nth-child(2):hover":
    {
      cursor: "progress !important",
    },
  },
  mapLoadingIndicatorWrapper:
  {
    zIndex: 10,
    width: "185px",
    height: "30px",
    color: "white",
    position: "absolute",
    boxSizing: "border-box",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingLeft: "5px",
    paddingRight: "15px",
    marginTop: "60px",
    marginLeft: "12px",

    "& p":
    {
      lineHeight: "30px",
      fontWeight: "bold",
      letterSpacing: "0.04em",
    },
  },

  mapActionButton: {
    position: "absolute !important" as "absolute",
    zIndex: 1,

    background: "none rgb(255, 255, 255) !important",
    border: "0px !important",
    borderRadius: "2px !important",
    height: "40px !important",
    width: "40px !important",
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px !important",
  },

  setDefaultLocationButton: {
    right: "10px",
    bottom: "115px",
  },

}));

import React, { useEffect, useReducer, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { Button,
  Fade,
  Typography,
  FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { observer } from "mobx-react-lite";
import useStyles from "./MetersOfOrganizationStyles";
import { WaterMeter, WaterMeterJustId } from "../../../../models/WaterMeter";
import ModalHeader from "../../../../components/modalComponents/modalHeader";
import ModalPortalTarget from "../../../../stores/ModalPortalTarget";
import { tokenAction } from "../../../../api/MsalUtil";
import { useRootStore } from "../../../../RootStateContext";
import { uuid } from "../../../../utils/BasicUtils";

interface IModalProps {
    meters?: WaterMeter[];
    open: boolean;
    handleClose: () => void;
    submit: (meter: WaterMeterJustId) => void;
}

export default observer(({ open, handleClose, submit }: IModalProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { meterStore } = useRootStore();
  const [validationState, setValidationState] = useState(false);

  const [meterState, setMeterState] = useState<WaterMeter | null>(null);
  const [meterSearchInputState, setMeterSearchInputState] = useState("");

  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const [pendingDataFetches, setPendingDataFetches] = useState<string[]>([]);

  const clear = (): void => {
    setValidationState(false);
    setMeterState(null);
    setMeterSearchInputState("")
    handleClose();
  };

  const saveMeterToOrganization = async (): Promise<void> => {
    if (meterState && validationState === false) {
      submit({
        id: meterState.id,
      });
      clear();
    } else {
      setValidationState(true);
    }
  };

  const handleMeterDropdownChange = (event: React.SyntheticEvent, value: WaterMeter | null, reason: AutocompleteChangeReason): void => {
    switch (reason) {
      case "clear":
        setMeterState(null);
        setMeterSearchInputState("");
        break;
      case "selectOption":
        if (value) {
          setMeterState(value);
          setMeterSearchInputState(`${value?.meterNumber}, ${value?.address}`);
        }
        break;
      default:
          break;
    }
  };

  const onInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason): void => {
    if (reason !== "reset") {
      if (value === "") {
        setMeterState(null);
        setMeterSearchInputState("");
      } else {
        setValidationState(false);
        setMeterSearchInputState(`${value}`);
      }
    }
  };

  useEffect(() => {
    if (meterSearchInputState.length <= 2) {
      meterStore.clearAddMeterToOrganizationMeters();
      return undefined;
    }

    if (!open) { return undefined; }

    const controller = new AbortController();
    const { signal } = controller;
    const id = uuid();

    const search = meterSearchInputState;

    (async () => {
      setPendingDataFetches((fetches) => [...fetches, id]);
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);

        await meterStore.loadAddMeterToOrganizationMeters(idToken, search, signal);
        setPendingDataFetches((fetches) => fetches.filter((f) => f !== id));
      }, currentUserToken, accounts, instance);
    })();

    return () => {
      controller.abort();
      setPendingDataFetches((fetches) => fetches.filter((f) => f !== id));
    };
  }, [accounts, instance, meterSearchInputState, meterStore]);

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <ModalHeader headerText={t("organizationView.addMeterToOrganization")} clear={clear} id="addLocationTitle" />
              <div className={classes.section}>
                  <FormControl className={classes.formControlWrapper}>
                      <div className={classes.subsection}>
                          <Typography className={classes.meterSelectionTitle} component="h2">{t("organizationView.meter")}</Typography>
                          {meterStore.addMeterToOrganizationMeters
                                    ? (
                                        <FormControl className={classes.formControl}>
                                            <Autocomplete
                                              disablePortal
                                              selectOnFocus
                                              clearOnBlur
                                              handleHomeEndKeys
                                              loading={pendingDataFetches.length > 0}
                                              id="addMeterToOrganizationSelect"
                                              onChange={(e, v, r) => { handleMeterDropdownChange(e, v as WaterMeter, r); }}
                                              options={
                                                meterStore.addMeterToOrganizationMetersAvailable > 0
                                                      ? [...meterStore.addMeterToOrganizationMeters, `+ ${meterStore.addMeterToOrganizationMetersAvailable} tulosta`]
                                                      : meterStore.addMeterToOrganizationMeters
                                                    }
                                              renderInput={(params) => <TextField {...params} label={t("organizationView.selectMeter")} />}
                                              renderOption={(props, option) => {
                                                      if (typeof option !== "string") {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {`${option.meterNumber}, ${option.address}`}
                                                            </li>
                                                        );
                                                      }
                                                      return (
                                                          <li {...props} key="add_meter_to_org_additional_options">
                                                              {option as string}
                                                          </li>
                                                      );
                                              }}
                                              value={meterState}
                                              inputValue={meterSearchInputState}
                                              getOptionLabel={(option) => (typeof option !== "string" ? `${option.meterNumber}, ${option.address}` : option)}
                                              isOptionEqualToValue={(option, value) => (typeof value !== "undefined" && typeof value !== "string" && typeof option !== "string" && option.id === value.id)}
                                              onInputChange={onInputChange}
                                              filterOptions={(x) => x}
                                              noOptionsText={t("organizationView.noMeterOptions")}
                                              getOptionDisabled={(option) => typeof option === "string"}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                        </FormControl>
                                    )
                                    : (<div>{t("organizationView.noSelectableMeters")}</div>)}
                      </div>
                  </FormControl>
              </div>
              <Grid
                container
                justifyContent="flex-end"
                style={{
                padding: "10px",
                }}
              >
                  {validationState && (
                      <Typography
                        variant="h6"
                        className={classes.mandatoryError}
                        id="addMeterToOrganizationModal-mandatoryerror"
                      >
                          {t("locationCreation.requiredValuesMissing")}
                      </Typography>
                  )}

                  <Button
                    onClick={() => clear()}
                    variant="contained"
                    color="secondary"
                    id="CancelInfoChangesButton"
                    style={{
                      marginRight: "10px",
                    }}
                  >
                      {t("common.cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveMeterToOrganization}
                    className={classes.okButton}
                    id="addMeterToOrganizationModal-save"
                  >
                      {t("locationCreation.finish")}
                  </Button>
              </Grid>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
});

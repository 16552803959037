import React, { useRef, useState } from "react";
import { Button, FormControl, Typography, Select, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

import { fi } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import TextField from "@mui/material/TextField";
import useStyles from "./ReportCreatorStyles";
import { useRootStore } from "../../RootStateContext";
import useResponsivity from "../../hooks/useResponsivity";

type Generic = {
    [key: string]: unknown
  }

interface IReportSelectorProps<T extends Generic> {
    selectedReportType: string;
    handleTypeChange: (event: SelectChangeEvent<string>) => void;
    handleSubmit: () => void;
    creatingReport: boolean;
    setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
    startDate: null | Date;
    endDate: null | Date;
  }

export default function SingleMeterReportCreator<T extends Generic>({ selectedReportType,
  handleTypeChange,
  handleSubmit,
  creatingReport,
  setStartDate,
  setEndDate,
  startDate,
  endDate }: IReportSelectorProps<T>): JSX.Element {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const { t } = useTranslation();

  const { reportStore } = useRootStore();
  const { reportTypes } = reportStore;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const reportIncludesStartDate = (): boolean => (selectedReportType === "METER_REPORT_XLSX" || selectedReportType === "METER_REPORT_TAMPUURI_CSV");

    return (
        <FormControl>
            <div className={classes.typeSelector}>
                <InputLabel id="reportTypeLabel">{t("locationView.type")}</InputLabel>
                <Select
                  labelId="reportTypeLabel"
                  id="reportTypeLabelId"
                  value={selectedReportType}
                  onChange={handleTypeChange}
                  style={{
                    backgroundColor: "white",
                  }}
                >
                    {reportTypes.map((type: string, index) => (
                        <MenuItem value={type} key={type}>
                            {t(`reportType.${type}`)}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            { reportIncludesStartDate()
                && (
                    <Typography component="span" className={classes.blueText}>
                        {t("reports.youCanCreateReportOfMetersReadings")}
                    </Typography>
                )}
            { !reportIncludesStartDate()
                && (
                    <Typography component="span" className={classes.blueText}>
                        {t("reports.youCanCreateReportOfMetersReadingsFromSingleDay")}
                    </Typography>
                )}
            {(startDate && reportIncludesStartDate()) && (
                <Typography component="span" className={classes.blueText}>
                    {t("reports.infoAboutStartDayLogic")}
                </Typography>
            )}

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                {reportIncludesStartDate() && (
                    <div id="startDayPicker" className={classes.datePicker}>
                        <DatePicker
                          label={t(`reports.startDate`)}
                          value={startDate}
                          inputFormat="dd.MM.yyyy"
                          disableFuture
                          onChange={(newValue : any) => {
                            setStartDate(newValue);
                          }}
                          maxDate={endDate}
                          disabled={creatingReport}
                          renderInput={(params : any) => <TextField {...params} />}
                        />
                    </div>
                )}
                <div id="endDayPicker" className={classes.datePicker}>
                    <DatePicker
                      label={reportIncludesStartDate() ? t(`reports.endDate`) : t(`reports.reportReadingDate`)}
                      value={endDate}
                      inputFormat="dd.MM.yyyy"
                      disableFuture
                      onChange={(newValue : any) => {
                        setEndDate(newValue);
                      }}
                      minDate={startDate}
                      disabled={creatingReport}
                      renderInput={(params : any) => <TextField {...params} />}
                    />
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={creatingReport}
                      className={classes.createReportButton}
                      style={isMobile ? {
                        marginLeft: "0",
                      } : {}}
                    >
                        {t("reports.generateReport")}
                    </Button>
                </div>
            </LocalizationProvider>
            {/* creatingReport && (
                            <Typography className={classes.inProgressText} component="span">
                                <HourglassTopIcon />
                                {t("reports.reportCreatingInProgress")}
                            </Typography>
                        ) */}
        </FormControl>
    )
  }

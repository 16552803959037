/* eslint-disable react/destructuring-assignment */
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertMarker } from "../../../models/Map";
import useStyles from "./MarkerInfoWindowStyles";

interface IMarkerInfoWindowProps
{
    markerData?: AlertMarker;
    lat?: number;
    lng?: number;
}

export default function ({ markerData, lat, lng }:IMarkerInfoWindowProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
      <div
        className={classes.infoWindow}
        style={{
      display: markerData ? "block" : "none",
        }}
      >
          <div className={`${classes.infoWindowSection} ${classes.infoHeader}`}>
              <Typography className={classes.infoHeaderBold}>{markerData?.address}</Typography>
          </div>

          <div className={classes.meterList}>
              {markerData !== undefined && markerData.alerts.map((alert, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${i}_${alert.name}_${alert.type}_${alert.started}`} className={`${classes.infoWindowSection} ${classes.infoWindowSectionSmall}`}>
                      <Typography>{`${alert.name} ${alert.type ? t(`alertType.${alert.type}`) : ""}`}</Typography>
                  </div>
          ))}
          </div>

      </div>
  );
};

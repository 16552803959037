import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  panel: {
    height: "calc(100% - 48px)",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
}));

import { del,
  delWithToken,
  getWithAccessToken,
  postFileWithAccessToken,
  postWithAccessToken,
  putWithAccessToken } from "./HttpUtils";
import Location, { LocationResponseWrapper } from "../models/Location";
import CreateLocation from "../models/CreateLocation";

const api = process.env.REACT_APP_API_URL || "";

export const getLocations = async (userToken: string): Promise<Location[]> => await getWithAccessToken(`${api}/service/locations/`, userToken) as Location[];

export const getLocationsPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<LocationResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";

  return await getWithAccessToken(`${api}/service/locationspaged/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}`, userToken) as LocationResponseWrapper;
};

export const getLocationsForSelect = async (userToken: string, page: number, pageSize: number, abortSignal?: AbortSignal, search = ""): Promise<LocationResponseWrapper | undefined> => {
  const useSearch = search !== "" ? `&search=${search}` : "";
  try {
    const response = await getWithAccessToken(`${api}/service/locationspaged/?page=${page}&size=${pageSize}${useSearch}`, userToken, undefined, abortSignal) as LocationResponseWrapper;
    if (response) return response as LocationResponseWrapper;
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const uploadMultipleLocations = async (userToken: string, fileToUpload: File): Promise<Location[]> => await postFileWithAccessToken(`${api}/service/location/uploadLocations`, fileToUpload, userToken) as Location[];
export const uploadMultipleLocationsToCustomerOrganization = async (userToken: string, fileToUpload: File, organizationId: string): Promise<Location[]> => await postFileWithAccessToken(`${api}/service/location/uploadLocations/${organizationId}`, fileToUpload, userToken) as Location[];

export const getLocation = async (userToken: string, locationId: string): Promise<Location> => await getWithAccessToken(`${api}/service/location/${locationId}`, userToken) as Location;
export const saveNewLocation = async (userToken: string, location: CreateLocation):Promise<Location> => await postWithAccessToken(`${api}/service/location`, location, userToken) as Location;
export const deleteLocation = async (userToken: string, location: Location): Promise<void> => delWithToken(`${api}/service/location/${location.id}`, userToken);

export const getLocationCount = async (currentUserToken: string): Promise<number> => await getWithAccessToken(`${api}/service/locationcount`, currentUserToken) as number;

export const editLocation = async (location: Location, id: string, currentUserToken: string): Promise<Location> => await putWithAccessToken(`${api}/service/location/${id}`, currentUserToken, location) as Location;

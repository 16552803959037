/* eslint-disable */

import { Organization, OrganizationWithWaterPrices } from "../models/Organization";

// @ts-ignore
export const uuid = () : string => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
/* eslint-enable */

export const covertOrganizationToWaterPriceOrganization = (organization: Organization) : OrganizationWithWaterPrices => {
    const toPrune = [
        "_etag",
        "residentUsersActive",
        "applicationAccess",
        "createdBy",
        "createdDate",
        "defaultMapView",
        "lastModifiedBy",
        "lastModifiedByDate",
    ]

    const waterPriceOrganization = {
        ...organization,
    };

    toPrune.forEach((p) => {
        delete waterPriceOrganization[p as keyof Organization];
    });

    // Hack in case organizationDetails returns null for active
    waterPriceOrganization.active = true;

    return {
        ...waterPriceOrganization,
        waterPrice: {
            price: 0,
            hotWaterPrice: 0,
            coldWaterPrice: 0,
        },
    };
}

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { toJS } from "mobx";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { useRootStore } from "../../../RootStateContext";
import AlertsView from "../AlertsView";
import { loginRequest } from "../../../authConfig";
import acquireToken, { tokenAction } from "../../../api/MsalUtil";
import Alert from "../../../models/Alert";
import AlertStore from "../../../stores/AlertStore";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const alertsStore = useRootStore().alertStore;
  const { mapStore } = useRootStore();

  const { totalUnreadAlertCount, allAlerts, fetchingAlerts, fetchingAlertCount, totalAlertsInTable } = alertsStore;
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("alertStartedAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState<string|undefined>("");
  const [alertTypeValue, setAlertTypeValue] = useState<string | undefined>("");
  const [meterTypeRootValue, setMeterTypeRootValue] = useState("");
  const [searchJustActivated, setSearchJustActivated] = useState(false);

  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;

  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("alertStartedAt");
    setSortDirection("desc");
    setSearchValue("");
    setAlertTypeValue("");
    setMeterTypeRootValue("");
    setPage(0);
  };

  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const fetchData = async (userToken: string): Promise<void> => {
    if (searchJustActivated) {
      setPage(0);
      setSearchJustActivated(false);
    }

    const unreadPromise = alertsStore.getUnreadAlertCount(userToken, false);
    const alertsPromise = alertsStore.loadAlerts(userToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, searchValue, alertTypeValue, meterTypeRootValue);
    await Promise.allSettled([unreadPromise, alertsPromise]);
  };

  const markAlertAsChecked = async (alert: Alert, comment: string): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);

      if (response) {
        await alertsStore.updateAlert(response.idToken, alert, comment);
        if (alertsStore.error === undefined) {
          toast.info(t("alertView.alertMarkAsRed"), {
            toastId: "AlertCheckedToast",
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await alertsStore.updateAlert(currentUserToken, alert, comment);
      if (alertsStore.error === undefined) {
        toast.info(t("alertView.alertMarkAsRed"), {
          toastId: "AlertCheckedToast",
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const markAllAlertsRead = async () : Promise<void> => {
    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        await alertsStore.markAllRead(idToken);

        toast.info(t("alertView.allMarkedReadSuccess"), {
          toastId: "AllAlertCheckedToast",
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }, currentUserToken, accounts, instance);
    })();
  };

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          await fetchData(response.idToken);
        }
      } else {
        await fetchData(currentUserToken);
      }
    })();
    return function cleanup() {
      mounted = false;
      alertsStore.clearState();
      mapStore.clearState();
    };
  }, [page, pageSize, sortBy, sortDirection, archived, searchValue, alertsStore, mapStore, accounts, instance, alertTypeValue, meterTypeRootValue]); // DO NOT ADD currentUserToken

  if (!currentUser || forbidden) {
    return <UnauthorizedComponent />;
  }

  return (
      <AlertsView
        alerts={allAlerts}
        mapAlerts={mapStore.mapAlerts}
        markAlertAsChecked={markAlertAsChecked}
        markAllAlertsRead={markAllAlertsRead}
        totalUnreadAlertsCount={totalUnreadAlertCount}
        fetchingAlertCount={fetchingAlertCount}
        fetchingAlerts={fetchingAlerts}
        totalAlertCount={totalAlertsInTable}
        setPage={handlePageChange}
        setPageSize={handlePageSizeChange}
        page={page}
        rowsPerPage={pageSize}
        sort={sort}
        setSortDirection={setSortDirection}
        search={setSearchValue}
        setAlertTypeValue={setAlertTypeValue}
        setMeterTypeRootValue={setMeterTypeRootValue}
        setSearchJustActivated={setSearchJustActivated}
        resetAllOptions={resetAllOptions}
        activeSearchValue={searchValue}
        currentUser={currentUser}
      />
  );
});

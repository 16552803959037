import { Box, Card, Container, Fade, Grid, Paper, Typography, TextField, Button, ButtonGroup, FormControl, Popover, AppBar, Tab, Tabs } from "@mui/material";

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { fi } from "date-fns/locale";

import { useMsal } from "@azure/msal-react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import moment from "moment";
import { DatePicker, StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router";
import useStyles from "./SingleMeterViewStyles";
import { WaterMeter } from "../../models/WaterMeter";
// import RouteDisplay from "../../components/routeDisplay/RouteDisplay";
import StatCard from "../../components/statCard/StatCard";
import { useRootStore } from "../../RootStateContext";
import acquireToken from "../../api/MsalUtil";
import { loginRequest } from "../../authConfig";
import OVChartTooltip from "../../components/charts/OVChartTooltip";
import { renderData, DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, displayableDate } from "../../utils/DataUtils";
import { GraphTimeModel } from "../../models/GraphTimeModel";
import { getScaleForMoment, getScaleDescription, getActiveDateIntervalPrevious, getActiveDateIntervalCurrent, getDatePickerValue, getDatePickerViews } from "../../utils/GraphUtils";
import Alert from "../../models/Alert";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import Table, { Column } from "../../components/table/TableWithPagination";
import SingleAlertModal from "../../components/singleAlertModal/SingleAlertModal";
import ReportCreator from "../../components/reportCreator/ReportCreator";
import InfoModal from "../../components/infoModal/InfoModal";
import useResponsivity from "../../hooks/useResponsivity";
import { CreateSingleMeterReport } from "../../models/CreateReport";
import { TabPanel } from "../../components/tabPanel/TabPanel";
import { useWhitelabelTheme } from "../../materialUITheme";

interface ISingleMeterViewProps {
  meter: WaterMeter;
  meterLoading: boolean;
  alerts?: Alert[];
  markAlertAsChecked: (alert: Alert, comment: string) => void;
  fetchingAlertCount: boolean;
  fetchingAlerts: boolean;
  totalAlertCount: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  unreadAlerts: number;
  getReport: (createReport: CreateSingleMeterReport) => void;
  creatingReport: boolean;
}

export default observer(({ meter, meterLoading, alerts, markAlertAsChecked, fetchingAlerts,
  totalAlertCount, setPage, page, rowsPerPage, sort, setSortDirection, setPageSize,
  unreadAlerts, getReport, creatingReport }: ISingleMeterViewProps): JSX.Element => {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dataStore } = useRootStore();
  const { instance, accounts } = useMsal();
  const [tabState, setTabState] = useState<number>(0); // Tab control
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [scale, setScale] = useState<string>("daily");
  const [formatScale, setFormatScale] = useState<string>("weekly");
  const [selectedAlert, setSelectedAlert] = useState<Alert | undefined>(undefined);
  const theme = useWhitelabelTheme();

  const [graphTime, setGraphTime] = useState<GraphTimeModel>({
    timeBase: moment(),
    get day_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("day").format(DEFAULT_DATETIME_FORMAT); },
    get day_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("day").format(DEFAULT_DATETIME_FORMAT); },
    get week_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
    get week_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
    get month_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("month").format(DEFAULT_DATE_FORMAT); },
    get month_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("month").format(DEFAULT_DATE_FORMAT); },
    get year_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("year").format(DEFAULT_DATE_FORMAT); },
    get year_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("year").format(DEFAULT_DATE_FORMAT); },
  });

  const [creatingReportInfoModalOpen, setCreatingReportInfoModalOpen] = useState<boolean>(false);

  // const currentPageName = meter.meterNumber ? meter.meterNumber : "";
  // const currentPageLink = meter.id ? meter.id : "";

  function a11yProps(index: any): any {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSingleMeterTabChange = (event: React.ChangeEvent<unknown>, newValue: number): void => {
    setTabState(newValue);
  };

  const handleDatePickerClick = (event : React.MouseEvent<Element, MouseEvent>) : void => {
    if (scale !== "daily") { setAnchorEl(event.currentTarget); }
  };

  const handleClose = () : void => {
    setAnchorEl(null);
  };

  const columns: Column[] = [
    {
      id: "alertType",
      label: t("alert.type"),
      minWidth: 230,
    },
    {
      id: "alertStartedAt",
      label: t("alert.startedAt"),
      minWidth: 170,
    },
    {
      id: "alertEndedAt",
      label: t("alert.endedAt"),
      minWidth: 170,
    },
    {
      id: "alertCheckedAt",
      label: t("alert.checkedAt"),
      minWidth: 170,
    },
    {
      id: "comment",
      label: t("alert.comment"),
      minWidth: 170,
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "singlemeter-date-popover" : undefined;

  const getActualScalePair = (scale: string) : { scale: string; formatScale: string } => {
    if (scale === "hourly") {
      return {
        scale: "hourly", formatScale: "hourly",
      };
    } if (scale === "daily") {
      return {
        scale: "daily", formatScale: "weekly",
      };
    } if (scale === "monthly") {
      return {
        scale: "daily", formatScale: "daily",
      };
    } if (scale === "yearly") {
      return {
        scale: "monthly", formatScale: "yearly",
      };
    }

    return {
      scale: "", formatScale: "",
    };
  };

  useEffect(() => {
    let mounted = true;

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    (async () => {
      const response = await acquireToken(instance, request);

      if (response) {
        if (scale === "hourly") {
          await dataStore.loadConsumptionDataForMeter(response.idToken, meter.id, graphTime.day_start, graphTime.day_end, "hourly", "hourly", true, true, true);
        } else if (scale === "daily") {
          await dataStore.loadConsumptionDataForMeter(response.idToken, meter.id, graphTime.week_start, graphTime.week_end, "daily", "weekly", true, true, true);
        } else if (scale === "monthly") {
          await dataStore.loadConsumptionDataForMeter(response.idToken, meter.id, graphTime.month_start, graphTime.month_end, "daily", "daily", true, true, true);
        } else if (scale === "yearly") {
          await dataStore.loadConsumptionDataForMeter(response.idToken, meter.id, graphTime.year_start, graphTime.year_end, "monthly", "yearly", true, true, true);
        }
      }
    })();

    return function cleanup() {
      mounted = false;
      dataStore.clearState();
    };
  }, [accounts, instance, meter.id, dataStore, scale, graphTime.timeBase, graphTime.week_start, graphTime.week_end, graphTime.month_start, graphTime.month_end, graphTime.year_start, graphTime.year_end, graphTime.day_start, graphTime.day_end]);

  const changeTimeBase = (nextOrPrev: string) : void => {
    setGraphTime((prev) => {
      let new_time_base = null;
      if (nextOrPrev === "next") {
        new_time_base = prev.timeBase.clone().add(1, getScaleForMoment(scale));
      } else {
        new_time_base = prev.timeBase.clone().subtract(1, getScaleForMoment(scale));
      }

      return {
        timeBase: new_time_base,
        get day_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("day").format(DEFAULT_DATETIME_FORMAT); },
        get day_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("day").format(DEFAULT_DATETIME_FORMAT); },
        get week_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
        get week_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
        get month_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("month").format(DEFAULT_DATE_FORMAT); },
        get month_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("month").format(DEFAULT_DATE_FORMAT); },
        get year_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("year").format(DEFAULT_DATE_FORMAT); },
        get year_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("year").format(DEFAULT_DATE_FORMAT); },
      };
    });
  };

  const setTimeBase = (base: any) : void => {
    const newBase = moment(base);
    setGraphTime((prev) => ({
      timeBase: newBase,
      get day_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("day").format(DEFAULT_DATETIME_FORMAT); },
      get day_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("day").format(DEFAULT_DATETIME_FORMAT); },
      get week_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
      get week_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
      get month_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("month").format(DEFAULT_DATE_FORMAT); },
      get month_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("month").format(DEFAULT_DATE_FORMAT); },
      get year_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("year").format(DEFAULT_DATE_FORMAT); },
      get year_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("year").format(DEFAULT_DATE_FORMAT); },
    }));

    handleClose();
  };

  const handleSetScale = (newScale: string) : void => {
    setTimeBase(moment());
    setScale(newScale);

    const { formatScale } = getActualScalePair(newScale);
    setFormatScale(formatScale);
  };

  const openAlertModal = (a:Alert): void => {
    setSelectedAlert(a);
  };

  const closeAlertModal = (): void => {
    setSelectedAlert(undefined);
  };

  const markAlert = (alert: Alert, comment: string) : void => {
    markAlertAsChecked(alert, comment);
    setSelectedAlert(undefined);
  };

  return (
      <>
          <SingleAlertModal submit={markAlert} open={selectedAlert !== undefined} handleClose={closeAlertModal} alert={selectedAlert} />
          <InfoModal
            open={creatingReportInfoModalOpen}
            handleClose={() => setCreatingReportInfoModalOpen(false)}
            okFunc={() => navigate("/reports")}
            headerText="Luodaan"
            explainText={t("reports.reportCreatingInProgress")}
            okBtnText={t("reports.toReports")}
            dismissBtnText={t("reports.continue")}
          />
          <Fade in>
              <Box>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                      <div className={classes.datePickPopover}>
                          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                              <StaticDatePicker
                                label={t(`singleMeter.startDate`)}
                                value={graphTime.timeBase.format("YYYY-MM-DD")}
                                inputFormat="dd.MM.yyyy"
                                disableFuture
                                onChange={setTimeBase}
                                minDate={new Date(moment()
                                  .subtract(11, "years")
                                  .format("YYYY-MM-DD"))}
                                maxDate={new Date(moment()
                                  .format("YYYY-MM-DD"))}
                                views={getDatePickerViews(scale)}
                                showToolbar={false}
                                renderInput={(params : any) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </div>
                  </Popover>
                  <Container className={`${classes.container} ${isMobile && classes.containerMobile}`}>
                      <Grid container justifyContent={isMobile ? "center" : "flex-start"}>
                          <Typography variant="h4" className={classes.meterNumberTop} id="singlemeter-meternumberhead">{meter.meterNumber}</Typography>
                      </Grid>
                      <Grid container justifyContent={isMobile ? "center" : "flex-start"} className={classes.cardsTop}>
                          <StatCard
                            icon={meter.connectionStatusCombined.connectionStatus === "ONLINE" ? "CheckCircle" : "CalendarToday"}
                            header="Status"
                            heavyContent={meter.connectionStatusCombined.connectionStatus}
                            id="singlemeter-status-card"
                            loadingState={meterLoading}
                          />
                          <StatCard
                            className={classes.singleMeterStatCardTop}
                            icon="Opacity"
                            header={t("singleMeter.lastReading")}
                            heavyContent={`${meter.latestReadingCubicMeters} m³`}
                            content={meter.latestReadingTimeStamp !== null ? format(parseISO(meter.latestReadingTimeStamp as unknown as string), "dd.MM.yyyy' 'HH:mm") : "-"}
                            id="singlemeter-reading-card"
                            loadingState={meterLoading}
                          />
                          <Card variant="elevation" elevation={2} className={`${classes.reportCreatorCard} ${isMobile && classes.reportCreatorCardMobile}`}>
                              <ReportCreator meterId={meter.id} submit={(r) => { setCreatingReportInfoModalOpen(true); getReport(r as CreateSingleMeterReport); }} creatingReport={creatingReport} singleReportCreator />
                          </Card>
                      </Grid>
                      <Card className={`${classes.topCard} ${isMobile && classes.topCardMobile}`} variant="elevation" elevation={2}>
                          <Typography variant="h5">{t("singleMeter.meterInfo")}</Typography>
                          <Grid
                            container
                            item
                            alignItems="center"
                            xs={12}
                            display="grid"
                            gridTemplateColumns={isMobile ? "1fr" : "repeat(4, min-content)"}
                            columnGap={isMobile ? "0" : "3%"}
                            rowGap="15px"
                          >
                              <Grid item xs={6} className={classes.topCardDataTitle}>
                                  {t("singleMeter.meterName")}
                              </Grid>
                              <Grid item xs={6} id="singlemeter-meternumber">
                                  {meter.meterNumber}
                              </Grid>
                              <Grid item xs={6} className={classes.topCardDataTitle}>
                                  {t("singleMeter.placeOfUseId")}
                              </Grid>
                              <Grid item xs={6} id="singlemeter-location">
                                  {meter.locationName ? meter.locationName : "-"}
                              </Grid>
                              <Grid item xs={6} className={classes.topCardDataTitle}>
                                  {t("singleMeter.model")}
                              </Grid>
                              <Grid item xs={6} id="singlemeter-model">
                                  {meter.modelName ? meter.modelName : "-"}
                              </Grid>
                              <Grid item xs={6} className={classes.topCardDataTitle}>
                                  DevEUI
                              </Grid>
                              <Grid item xs={6} id="singlemeter-deveui">
                                  {meter.devEUI}
                              </Grid>
                              <Grid item xs={6} className={classes.topCardDataTitle}>
                                  {t("singleMeter.address")}
                              </Grid>
                              <Grid item xs={6} id="singlemeter-address">
                                  {meter.address ? meter.address : "-"}
                              </Grid>
                          </Grid>
                      </Card>
                      <AppBar className={`${classes.tabBar} ${isMobile && classes.tabBarMobile}`} position="static">
                          <Tabs value={tabState} onChange={handleSingleMeterTabChange}>
                              <Tab label={t("singleMeter.tabHeaderConsumption")} {...a11yProps(0)} />
                              <Tab
                                label={t("singleMeter.alertsOfMeter", {
                                  alertCount: unreadAlerts,
                                })}
                                {...a11yProps(1)}
                              />
                          </Tabs>
                      </AppBar>
                      <TabPanel value={tabState} index={0}>
                          <Paper
                            className={`${classes.tabPanelPaper} ${isMobile && classes.tabPanelPaperMobile}`}
                            variant="elevation"
                            elevation={2}
                            style={{
                              height: "auto",
                            }}
                          >
                              <Grid container spacing={1}>
                                  <Grid item container xs={isMobile ? 12 : 6} className={classes.consumptionTabLeftWrapper}>
                                      {!isMobile && (
                                          <Grid item container xs={12} wrap="wrap" alignItems="center" justifyContent="center" alignContent="flex-start" direction="column">
                                              <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                                                  <Button
                                                    id="singlemeter-scale-hour"
                                                    className={scale === "hourly" ? classes.scaleSelected : ""}
                                                    onClick={() => {
                                                      handleSetScale("hourly");
                                                    }}
                                                  >
                                                      {t("singleMeter.day")}
                                                  </Button>
                                                  <Button
                                                    id="singlemeter-scale-week"
                                                    className={scale === "daily" ? classes.scaleSelected : ""}
                                                    onClick={() => {
                                                      handleSetScale("daily");
                                                    }}
                                                  >
                                                      {t("singleMeter.week")}
                                                  </Button>
                                                  <Button
                                                    id="singlemeter-scale-month"
                                                    className={scale === "monthly" ? classes.scaleSelected : ""}
                                                    onClick={() => {
                                                      handleSetScale("monthly");
                                                    }}
                                                  >
                                                      {t("singleMeter.month")}
                                                  </Button>
                                                  <Button
                                                    id="singlemeter-scale-year"
                                                    className={scale === "yearly" ? classes.scaleSelected : ""}
                                                    onClick={() => {
                                                      handleSetScale("yearly");
                                                    }}
                                                  >
                                                      {t("singleMeter.year")}
                                                  </Button>
                                              </ButtonGroup>
                                              <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                                                  <Button id="singlemeter-prev-interval" onClick={() => changeTimeBase("previous")}><ArrowBackIosIcon /></Button>
                                                  <Button id="singlemeter-calendar-button" onClick={handleDatePickerClick}>{getDatePickerValue(scale, graphTime)}</Button>
                                                  <Button id="singlemeter-next-interval" onClick={() => changeTimeBase("next")}><ArrowForwardIosIcon /></Button>
                                              </ButtonGroup>
                                          </Grid>
                                      )}
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        justifyContent={isMobile ? "center" : "flex-start"}
                                        className={classes.consumptionCardContainer}
                                      >
                                          <StatCard
                                            icon="Opacity"
                                            iconColor="#00707d"
                                            header={getScaleDescription(scale, false)}
                                            heavyContent={`${renderData(dataStore.meterConsumptionDataPrevious.totalConsumption)} m³`}
                                            content={getActiveDateIntervalPrevious(scale, graphTime)}
                                            id="singlemeter-consumption-card-previous"
                                            loadingState={dataStore.fetchingMeterConsumptionData}
                                          />
                                          <StatCard
                                            icon="Opacity"
                                            header={getScaleDescription(scale, true)}
                                            heavyContent={`${renderData(dataStore.meterConsumptionData.totalConsumption)} m³`}
                                            content={getActiveDateIntervalCurrent(scale, graphTime)}
                                            id="singlemeter-consumption-card-current"
                                            loadingState={dataStore.fetchingMeterConsumptionData}
                                            className={classes.singleMeterNextConsumptionCard}
                                          />

                                      </Grid>
                                  </Grid>
                                  <Grid item container xs={isMobile ? 12 : 6}>
                                      <ResponsiveContainer width="100%" aspect={2} className={classes.chartContainer}>
                                          {dataStore.fetchingMeterConsumptionData ? (
                                              <PendingCircle />
                                          ) : (
                                              <BarChart data={dataStore.meterConsumptionData.timeValueSeries} id="singlemeter-consumption-chart">
                                                  <CartesianGrid strokeDasharray="6 6" vertical={false} />
                                                  <XAxis
                                                    dataKey="rawDate"
                                                    tickFormatter={(v, i) => displayableDate(v, formatScale)}
                                                  />
                                                  <YAxis
                                                    name="Kulutus"
                                                    label={{
                                                                      value: "m³",
                                                                      position: "insideLeft",
                                                                      offset: -5,
                                                    }}
                                                    hide={isMobile}
                                                  />
                                                  <Tooltip content={(
                                                      <OVChartTooltip
                                                        unit="m³"
                                                        legend1={getScaleDescription(scale, false)}
                                                        legend2={getScaleDescription(scale, true)}
                                                        formatScale={formatScale}
                                                      />
                                                  )}
                                                  />
                                                  <Legend />

                                                  <Bar dataKey="data2" fill={theme.palette.consumptionGraph.dark} name={getScaleDescription(scale, false)} />
                                                  <Bar dataKey="data1" fill={theme.palette.consumptionGraph.main} name={getScaleDescription(scale, true)} />
                                                  {/* <Bar dataKey="consumption_previous" fill="#00707d" /> */}
                                              </BarChart>
                                              )}
                                      </ResponsiveContainer>
                                  </Grid>
                                  {isMobile && (
                                      <Grid item container xs={12} wrap="wrap" alignItems="center" justifyContent="center" alignContent="center" direction="column">
                                          <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                                              <Button
                                                id="singlemeter-scale-hour"
                                                className={scale === "hourly" ? classes.scaleSelected : ""}
                                                onClick={() => {
                                                  handleSetScale("hourly");
                                                }}
                                              >
                                                  {t("singleMeter.day")}
                                              </Button>
                                              <Button
                                                id="singlemeter-scale-week"
                                                className={scale === "daily" ? classes.scaleSelected : ""}
                                                onClick={() => {
                                                  handleSetScale("daily");
                                                }}
                                              >
                                                  {t("singleMeter.week")}
                                              </Button>
                                              <Button
                                                id="singlemeter-scale-month"
                                                className={scale === "monthly" ? classes.scaleSelected : ""}
                                                onClick={() => {
                                                  handleSetScale("monthly");
                                                }}
                                              >
                                                  {t("singleMeter.month")}
                                              </Button>
                                              <Button
                                                id="singlemeter-scale-year"
                                                className={scale === "yearly" ? classes.scaleSelected : ""}
                                                onClick={() => {
                                                  handleSetScale("yearly");
                                                }}
                                              >
                                                  {t("singleMeter.year")}
                                              </Button>
                                          </ButtonGroup>
                                          <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                                              <Button id="singlemeter-prev-interval" onClick={() => changeTimeBase("previous")}><ArrowBackIosIcon /></Button>
                                              <Button id="singlemeter-calendar-button" onClick={handleDatePickerClick}>{getDatePickerValue(scale, graphTime)}</Button>
                                              <Button id="singlemeter-next-interval" onClick={() => changeTimeBase("next")}><ArrowForwardIosIcon /></Button>
                                          </ButtonGroup>
                                      </Grid>
                                  )}
                              </Grid>

                          </Paper>

                      </TabPanel>
                      <TabPanel value={tabState} index={1}>
                          <Card
                            className={classes.paper}
                            variant="elevation"
                            elevation={2}
                            style={{
                              height: "auto",
                              width: "100%",
                            }}
                          >
                              {fetchingAlerts && (<PendingCircle />)}
                              {!fetchingAlerts && alerts && alerts?.length > 0 && (
                                  <>
                                      <Card
                                        className={classes.unreadAlertCard}
                                      >
                                          <NotificationsActiveIcon />
                                          <span className={classes.unreadAlertText}>
                                              {unreadAlerts}
                                              {" "}
                                              {t("alertView.unreadAlerts")}
                                          </span>
                                      </Card>
                                      <Table<Alert>
                                        columns={columns}
                                        count={totalAlertCount}
                                        cellClickedCallback={(alarm: Alert) => openAlertModal(alarm)}
                                        rows={alerts || []}
                                        handlePageChange={setPage}
                                        handlePageSizeChange={setPageSize}
                                        currentPage={page}
                                        currentRowsPerPage={rowsPerPage}
                                        sort={sort}
                                        setSortDirection={setSortDirection}
                                        onClickIcon={<Edit />}
                                      />
                                  </>
                              )}
                              {!fetchingAlerts && alerts && alerts.length === 0 && (
                                  <div className={classes.noData}><Typography className={classes.headerContainer} variant="h5" id="alertsview-no-alerts" component="h5">{t("alertView.noAlerts")}</Typography></div>
                              )}
                          </Card>
                      </TabPanel>
                  </Container>
              </Box>
          </Fade>
      </>
  );
});

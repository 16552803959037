import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { AppBar, Box, Button, Container, Grid, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { FileCopyOutlined } from "@mui/icons-material";
import LocationTable, { Column } from "../../components/table/TableWithPagination";
import "@fontsource/roboto";
import NewLocationModal from "../../components/newLocationModal/NewLocationModal";
import DeleteLocationModal from "../../components/deleteLocationModal/DeleteLocationModal";
import useStyles from "./LocationsViewStyles";
import Location from "../../models/Location";
import CreateLocation from "../../models/CreateLocation";
import { Organization } from "../../models/Organization";
import UploadExcelFileModal from "../../components/uploadExcelFileModal/UploadExcelFileModal";
import StatCard from "../../components/statCard/StatCard";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import { useRootStore } from "../../RootStateContext";
import EditLocationModal from "../../components/editLocationModal/EditLocationModal";
import { OrganizationType, UserInfo } from "../../models/UserInfo";
import { UserRole } from "../../models/UserRole";
import useResponsivity from "../../hooks/useResponsivity";
import { TabPanel } from "../../components/tabPanel/TabPanel";

interface ILocationConfigViewProps {
  saveNewLocation: (location: CreateLocation) => Promise<void>;
  deleteLocation: (location: Location) => Promise<void>;
  editLocation: (location: Location) => Promise<void>;
  removeLocation: (location: Location) => Promise<void>;
  uploadLocationsFile: (file: File, organizationId?: string) => Promise<void>;
  organizations?: Organization[];
  locations?: Location[];
  totalLocationsForCard: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalLocations: number;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  setArchived: (a: boolean) => void;
  search: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  creatingLocationsInProgress: boolean;
  activeSearchValue?: string;
  currentUser: UserInfo;
}

function a11yProps(index: any): any {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default observer(({ saveNewLocation,
  deleteLocation,
  uploadLocationsFile,
  editLocation,
  removeLocation,
  organizations,
  locations,
  setPage,
  setPageSize,
  totalLocations,
  page,
  totalLocationsForCard,
  rowsPerPage,
  sort,
  setSortDirection,
  setArchived,
  setSearchJustActivated,
  search,
  resetAllOptions,
  creatingLocationsInProgress,
  activeSearchValue, currentUser }: ILocationConfigViewProps): JSX.Element => {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [excelSelectorModalOpen, setExcelSelectorModalOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletableLocation, setDeletableLocation] = useState({} as Location);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchInput = useRef<HTMLInputElement>();
  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");

  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [editableLocation, setEditableLocation] = useState({} as Location);

  const locationsStore = useRootStore().locationStore;
  const { fetchingLocations, fetchingLocationsCount } = locationsStore;

  const resetParams = (): void => {
    resetAllOptions();
    if (searchInput && searchInput.current) searchInput.current.value = "";
  };

  const getUsersTableItems = (): Column[] => {
    let columns: Column[] = [
      /* {
              id: "locationCheck",
              label: "",
              booleanType: true,
            }, */
      {
        id: "identifier", label: t("locationView.identifier"), minWidth: 170,
      },
      {
        id: "ownerOrganizationName",
        label: t("locationView.customer"),
        minWidth: 170,
      },
      {
        id: "streetAddress",
        label: t("locationView.streetAddress"),
        minWidth: 170,
      },
      {
        id: "postalCode",
        label: t("locationView.postalCode"),
        minWidth: 170,
      },
      {
        id: "city",
        label: t("locationView.city"),
        minWidth: 170,
      },
      {
        id: "country",
        label: t("locationView.country"),
        minWidth: 170,
      },
      /* {
              id: "type", label: t("locationView.type"), minWidth: 170,
            }, */
    ];
    if (currentUser && (currentUser.usersOrganizationType === OrganizationType.OMAVESI_HOUSING || currentUser.usersOrganizationType === OrganizationType.OTHER)) {
      columns = columns.filter((item) => item.id !== "ownerOrganizationName");
    }
    return columns;
  };

  const addLocationForDeletion = (location: Location): void => {
    const locationToArchive: Location = JSON.parse(JSON.stringify(location));
    locationToArchive.archived = true;
    setDeletableLocation(locationToArchive);
    setDeleteModalOpen(true);
  };

  const cellClickedCallback = (location: Location): void => {
    navigate(`/location/${location.id}`);
  };

  const doSearch = (): void => {
    const searchVal = searchInput && searchInput.current && searchInput.current.value;
    if (searchVal !== undefined) {
      if (searchVal.length <= 2) {
        setSearchErrorState(true);
        setSearchErrorText(t("common.searchErrorText"));
        return;
      }
      setSearchErrorState(false);
      setSearchErrorText("");
      setSearchJustActivated(true);
      search(searchVal);
    }
  };

  const setEditLocation = (location: Location): void => {
    const locationToEdit: Location = JSON.parse(JSON.stringify(location));
    setEditableLocation(locationToEdit);
    setEditLocationModalOpen(true);
  };

  return (
      <>
          <UploadExcelFileModal
            locationCreationForm
            open={excelSelectorModalOpen}
            handleClose={() => setExcelSelectorModalOpen(false)}
            submit={uploadLocationsFile}
            requestSent={creatingLocationsInProgress}
            showOrganizations={currentUser.userRole === UserRole.OV_ADMIN}
            organizations={organizations}
          />
          <EditLocationModal
            location={editableLocation}
            organizations={organizations}
            open={editLocationModalOpen}
            handleClose={() => setEditLocationModalOpen(false)}
            removeLocation={removeLocation}
            submit={editLocation}
            showCustomerSelection={currentUser.usersOrganizationType === OrganizationType.RETAILER || currentUser.usersOrganizationType === OrganizationType.WATER_COMPANY
                                 || currentUser.usersOrganizationType === OrganizationType.SUPERADMIN}
          />
          <NewLocationModal
            organizations={organizations}
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            submit={saveNewLocation}
            showCustomerSelection={currentUser.usersOrganizationType === OrganizationType.RETAILER || currentUser.usersOrganizationType === OrganizationType.WATER_COMPANY
                                || currentUser.usersOrganizationType === OrganizationType.SUPERADMIN}
          />
          <DeleteLocationModal open={deleteModalOpen} handleClose={() => setDeleteModalOpen(false)} submit={deleteLocation} location={deletableLocation} />
          <Container className={`${classes.container} ${isMobile ? classes.topContainerMobile : classes.topContainer}`}>
              <Grid container className={classes.locationsHeadGrid}>
                  <Grid container item xs={isMobile ? 12 : 3} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Typography variant="h4" className={classes.headerTop} component="span">Käyttöpaikat</Typography>
                  </Grid>
                  {!isMobile && (
                      <Grid item xs={9}>
                          <div className={classes.searchInputContainer}>
                              <TextField
                                inputRef={searchInput}
                                onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                                className={classes.searchInput}
                                id="locationsview-search-input"
                                placeholder={t("locationView.searchHelpText2")}
                                size="small"
                                variant="outlined"
                                error={searchErrorState}
                                helperText={searchErrorText}
                                InputLabelProps={{
                              shrink: true,
                                }}
                              />
                              <Button id="locationsview-search-button" onClick={() => doSearch()} className={classes.searchButton} variant="contained" color="primary">{t("locationView.search")}</Button>
                              <Button
                                color="secondary"
                                variant="contained"
                                id="locationsview-search-clear"
                                onClick={resetParams}
                                className={classes.clearSearchButton}
                              >
                                  {t("locationView.defaultSettings")}
                              </Button>
                          </div>
                          {activeSearchValue && (
                              <Typography className={classes.activeSearchWord} variant="h6">
                                  {t("locationView.searchedWithWord", {
                                searchWord: activeSearchValue,
                              })}
                              </Typography>
                      )}
                      </Grid>
)}
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.locationsHeadCardsContainer}
                    justifyContent={isMobile ? "center" : "flex-start"}
                    alignItems="center"
                  >
                      <Grid item>
                          <StatCard
                            icon="Apartment"
                            header={totalLocationsForCard.toString()}
                            content={t("locationView.consumers")}
                            id="locationsview-consumers-card"
                            loadingState={fetchingLocationsCount}
                          />
                      </Grid>
                      {/* <Grid
                        item
                        style={{
                          marginLeft: "15px",
                        }}
                      >
                          <StatCard
                            icon="Room"
                            header={totalLocations.toString()}
                            content={t("locationView.addresses")}
                            id="locationsview-addresses-card"
                            loadingState={fetchingLocations}
                          />
                      </Grid> */}
                  </Grid>
              </Grid>
          </Container>

          <Container className={`${classes.container} ${isMobile ? classes.containerMobile : classes.botContainer}`}>
              <AppBar className={classes.tabBar} position="static">
                  <Tabs value={0} onChange={() => 0}>
                      <Tab label={t("locationView.locations")} {...a11yProps(0)} />
                  </Tabs>
              </AppBar>
              <TabPanel
                value={0}
                index={0}
              >
                  {!isMobile && (
                      <div className={classes.locationTableButtonContainer}>
                          <Button
                            onClick={() => setModalOpen(true)}
                            color="primary"
                            variant="outlined"
                            id="AddNewLocationButton"
                            className={`${classes.addNewLocationButton} ${classes.buttonShadow}`}
                          >
                              {t("locationView.addNewLocation")}
                          </Button>

                          <Button
                            onClick={() => setExcelSelectorModalOpen(true)}
                            color="primary"
                            variant="outlined"
                            id="AddMultipleLocationsButton"
                            className={`${classes.addLocationsWithFileButton} ${classes.buttonShadow}`}
                          >
                              <FileCopyOutlined />
                              {t("locationView.addMultipleLocationsWithFile")}
                          </Button>
                      </div>
)}

                  {fetchingLocations ? (<PendingCircle />) : (
                      <Paper style={{
                        overflowX: "hidden",
                      }}
                      >
                          {isMobile && (
                              <>
                                  {activeSearchValue && (
                                      <Typography className={classes.activeSearchWordMobile} variant="h6">
                                          {t("locationView.searchedWithWord", {
                                            searchWord: activeSearchValue,
                                          })}
                                      </Typography>
                                  )}
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    id="locationsview-search-clear"
                                    onClick={resetParams}
                                    className={classes.clearSearchButtonMobile}
                                  >
                                      {t("locationView.defaultSettings")}
                                  </Button>
                                  <TextField
                                    inputRef={searchInput}
                                    onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                                    className={classes.searchInputMobile}
                                    id="locationsview-search-input"
                                    placeholder={t("locationView.searchHelpText2")}
                                    size="small"
                                    variant="outlined"
                                    error={searchErrorState}
                                    helperText={searchErrorText}
                                    InputLabelProps={{
                              shrink: true,
                                    }}
                                  />

                              </>
                          )}
                          <LocationTable<Location>
                            columns={getUsersTableItems()}
                            rows={locations || []}
                            cellClickedCallback={cellClickedCallback}
                            handlePageChange={setPage}
                            handlePageSizeChange={setPageSize}
                            count={totalLocations}
                            currentPage={page}
                            currentRowsPerPage={rowsPerPage}
                            sort={sort}
                            editRow={(location:Location) => setEditLocation(location)}
                            setSortDirection={setSortDirection}
                          />
                      </Paper>
                  )}
                  { !fetchingLocations && locations && locations.length === 0 && (
                      <div className={classes.noLocations}>
                          <Typography className={classes.headerContainer} variant="h6" component="span">
                              {!activeSearchValue ? t("locationView.noLocations") : t("locationView.noLocationsWithSearch", {
                                searchWord: activeSearchValue,
                              })}
                          </Typography>
                      </div>
                  ) }

              </TabPanel>

          </Container>

      </>
  );
});

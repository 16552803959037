import { AccountInfo, IPublicClientApplication, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

interface MsalRequest
{
    scopes: Array<any> | [];
    account: AccountInfo;
}

// USE THIS INSTEAD OF acquireTokenSilent DIRECTLY
export default async function acquireToken(
  instance : IPublicClientApplication,
  request : MsalRequest,
) : Promise<AuthenticationResult | void> {
  try {
    const response = await instance.acquireTokenSilent(request);
    return response;
  } catch (error) {
    // GENERAL MSAL ERROR HANDLING GOES HERE

    // InteractionRequiredAuthError
    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect(request);
    }

    // Fallback is to try to redirect the user to back to login
    // return instance.loginRedirect();
    return instance.loginRedirect();
  }
}

export async function tokenAction(
  actionFn: (idToken: string) => void,
  currentUserToken: string | undefined,
  accounts: AccountInfo[],
  instance: IPublicClientApplication,
  ) : Promise<void> {
  if (!currentUserToken) {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    const response = await acquireToken(instance, request);

    if (response) {
      return actionFn(response.idToken);
    }
    throw new Error("Could not get acquireToken response (MsalUtil->tokenAction)");
  } else {
    return actionFn(currentUserToken);
  }
}

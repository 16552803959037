import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    padding: "0px",

    "& .MuiTableRow-head": {
      backgroundColor: "white",
      color: "black",

      "& .MuiTableCell-sizeSmall": {
        backgroundColor: "white",
        color: "black",
        padding: "3px 24px 3px 16px",
      },
      "& .MuiCircularProgress-root": {
        marginTop: "5% !important",
      },
    },
    "& .MuiTableCell-body":
    {
      padding: "5px 24px 5px 16px",
    },
    "& .MuiTableCell-root": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .MuiTableCell-head": {
      color: theme.palette.primary.contrastText,
      borderBottom: "1px black solid",
    },
    "& .PrivateSwitchBase-root-166":
    {
      padding: "0 !important",
    },
    "& > *": {},
  },
  boldRoot: {
    "& .MuiTableCell-root": {
      fontWeight: "bold",
    },
    "& .MuiTableRow-head": {
      backgroundColor: "white",
      color: "black",

      "& .MuiTableCell-sizeSmall": {
        backgroundColor: "white",
        color: "black",
        padding: "3px 24px 3px 16px",
      },
      "& .MuiCircularProgress-root": {
        marginTop: "5% !important",
      },
    },
  },
  borderLeft: {
    borderLeft: "1px black solid",
  },
  colorfulText: {
    color: theme.palette.primary.main,
  },
  borderBottom: {
    borderBottom: "1px black solid",
  },
  setHeaders:
  {
    color: theme.palette.primary.main,
    "& .MuiTableCell-head":
    {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  container: {
    maxHeight: 440,
  },
  deleteIcon: {
    cursor: "pointer",
  },
  editButton: {
    margin: 0,
  },
  offline: {
    color: theme.palette.important.main,
  },
  online: {
    color: theme.palette.primary.main,
  },
  initial: {
    color: theme.palette.secondary.light,
  },
  subrow: {
    borderBottom: "unset !important",
  },
  subrowContainer: {
    borderTop: `1px solid ${grey[500]}`,
  },
  jsonCell: {
    whiteSpace: "pre-wrap",
  },
  sortLabel: {
    color: `${theme.palette.primary.main} !important`,
    "& .MuiSvgIcon-root": {
      color: `${theme.palette.primary.main} !important`,

    },
  },
  downloadButton: {
    padding: 2,
  },
  spinner: {
    marginTop: "5%",
  },
  tableContainer: {
    padding: "0px",
  },

  tableHead: {
    minWidth: "0 !important",
  },

  rootMobile: {
    "& .MuiTableCell-root": {
      whiteSpace: "normal !important",
    },

    "& .MuiTableCell-body":
    {
      padding: "2px 6px 2px 6px",
    },
  },

  tableContainerMobile: {
    padding: "5px",
    boxSizing: "border-box",

    "& table > thead > tr > th": {
      padding: "0 !important",
    },

    "& table > tbody > tr > th:first-child": {
      wordBreak: "break-all",
      minWidth: "65px",
    },
    "& table > tbody > tr > th": {
      minWidth: "0px",
      padding: "4px 4px 4px 0px !important",
    },
  },

  addressSpan: {
    color: theme.palette.important.main,
    textDecoration: "underline",
    "&:hover": {
      color: "#00A0B4 !important",
    },
  },

  locationAddressSpan: {
    color: theme.palette.important.main,
    textDecoration: "underline",
  },
}));

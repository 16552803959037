import React from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./InfoModalStyles";
import useResponsivity from "../../hooks/useResponsivity";
import ModalHeader from "../modalComponents/modalHeader";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  headerText: string;
  explainText: string;
  okBtnText: string;
  dismissBtnText: string;
  okFunc: () => void;
}

export default function ({ open, handleClose, okFunc, headerText, explainText, okBtnText, dismissBtnText }: IModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();

  const handleOkFunc = (): void => {
    handleClose();
    okFunc();
  };

  return ReactDOM.createPortal(<Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
    open={open}
    onClose={handleClose}
  >
      <Fade in={open}>
          <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
              <ModalHeader headerText={headerText} clear={handleClose} id="singleAlertModalTitle" />
              <div className={classes.contentSection}>
                  <Typography variant="h6" component="h3">{explainText}</Typography>
              </div>
              <div className={classes.buttons}>
                  <Button
                    className={classes.okFuncButton}
                    onClick={handleOkFunc}
                    variant="contained"
                    color="primary"
                  >
                      {okBtnText}
                  </Button>
                  <Button
                    className={classes.dismissBtn}
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                  >
                      {dismissBtnText}
                  </Button>
              </div>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
};

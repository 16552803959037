import { action, makeObservable, observable, runInAction } from "mobx";

import moment from "moment";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import { getConsumption, getConsumptionForMeter } from "../api/DataApi";
import { ConstructedConsumptionData, MeterConsumptionDataWrapper, ConsumptionTimeValueSeries, ConstructedMeterConsumptionData, MeterConsumptionTimeValueSeries } from "../models/DataModel";
import { displayableDate, previousInterval, DEFAULT_DATE_FORMAT, roundNumber } from "../utils/DataUtils";
import { WaterPrice } from "../models/Pricing";

export default class DataStore extends BaseStore {
    fetchingMeterConsumptionData = true;

    fetchingConsumptionData = true;

    meterConsumptionData: ConstructedMeterConsumptionData = {
      consumptionSeries: [],
      dateSeries: [],
      dateTimeSeries: [],
      zonedDateTimeSeries: [],
      devEUI: "",
      distributedValues: undefined,
      scale: "",
      meterId: "",
      timeZone: "",
      timeValueSeries: [],
      totalConsumption: 0,
    }

    meterConsumptionDataPrevious: ConstructedMeterConsumptionData = {
      consumptionSeries: [],
      dateSeries: [],
      dateTimeSeries: [],
      zonedDateTimeSeries: [],
      devEUI: "",
      distributedValues: undefined,
      scale: "",
      meterId: "",
      timeZone: "",
      timeValueSeries: [],
      totalConsumption: 0,
    }

    overviewConsumptionData: ConstructedConsumptionData = {
      coldConsumptionSeries: [],
      hotConsumptionSeries: [],
      dateSeries: [],
      dateTimeSeries: [],
      zonedDateTimeSeries: [],
      scale: "",
      timeZone: "",
      timeValueSeries: [],
      totalConsumptionCold: 0,
      totalConsumptionHot: 0,
      consumptionPriceCold: 0,
      consumptionPriceHot: 0,
      hasColdSeries: false,
      hasHotSeries: false,
    }

loadConsumptionDataForMeter = async (userToken: string, meterId: string, startDate: string, endDate: string, scale: string, formatScale: string, fill: boolean, distribute: boolean, loadPrevious: boolean): Promise<void> => {
  try {
    this.fetchingMeterConsumptionData = true;
    // eslint-disable-next-line no-param-reassign
    startDate = moment(startDate).clone().format(DEFAULT_DATE_FORMAT);
    // eslint-disable-next-line no-param-reassign
    endDate = moment(endDate).clone().format(DEFAULT_DATE_FORMAT);

    const resp1 = await getConsumptionForMeter(userToken, meterId, startDate, endDate, scale, fill, distribute);
    const pDate = previousInterval(startDate, endDate, formatScale);
    const resp2 = await getConsumptionForMeter(userToken, meterId, pDate.startDate, pDate.endDate, scale, fill, distribute);

    const dateSeriesName = "zonedDateTimeSeries";

    runInAction(() => {
      // Construct the main timeValueSeries
      const timeValueSeries : MeterConsumptionTimeValueSeries[] = [];

      for (let i = 0; i < resp1[dateSeriesName].length; i += 1) {
        const date = displayableDate(resp1[dateSeriesName][i], formatScale);
        const longDate = displayableDate(resp1[dateSeriesName][i], formatScale, false);
        const rawDate = resp1[dateSeriesName][i];
        const data1 = resp1.consumptionSeries[i];
        const data2 = resp2.consumptionSeries[i];

        timeValueSeries.push({
          date,
          longDate,
          rawDate,
          data1,
          data2,
        });
      }

      const totalConsumption = resp1.consumptionSeries.reduce((a, b) => a + b, 0);
      const totalConsumption2 = resp2.consumptionSeries.reduce((a, b) => a + b, 0);

      const constructedConsumptionData : ConstructedMeterConsumptionData = {
        ...resp1, timeValueSeries, totalConsumption,
      };

      const constructedConsumptionData2 : ConstructedMeterConsumptionData = {
        ...resp1, totalConsumption: totalConsumption2,
      };

      this.meterConsumptionData = constructedConsumptionData;
      this.meterConsumptionDataPrevious = constructedConsumptionData2;

      this.fetchingMeterConsumptionData = false;
    });
  } catch (e) {
    this.setError("Virhe kulutusdata haussa");
    this.fetchingMeterConsumptionData = false;
    console.error(e.stack);
  }
}

loadConsumptionData = async (userToken: string, startDate: string, endDate: string, scale: string, formatScale: string, fill: boolean, distribute: boolean, loadPrevious: boolean, waterPrice?: WaterPrice): Promise<void> => {
  try {
    this.fetchingConsumptionData = true;

    // eslint-disable-next-line no-param-reassign
    startDate = moment(startDate).clone().format(DEFAULT_DATE_FORMAT);
    // eslint-disable-next-line no-param-reassign
    endDate = moment(endDate).clone().format(DEFAULT_DATE_FORMAT);

    const resp1 = await getConsumption(userToken, startDate, endDate, scale, fill, distribute);
    const pDate = previousInterval(startDate, endDate, formatScale);

    const dateSeriesName = "zonedDateTimeSeries";

    runInAction(() => {
      // Construct the main timeValueSeries
      const timeValueSeries : ConsumptionTimeValueSeries[] = [];
      let hasColdSeries = false;
      let hasHotSeries = false;

      if (resp1.coldConsumptionSeries && resp1.coldConsumptionSeries.length > 0) { hasColdSeries = true; }

      if (resp1.hotConsumptionSeries && resp1.hotConsumptionSeries.length > 0) { hasHotSeries = true; }

      for (let i = 0; i < resp1.dateSeries.length; i += 1) {
        const date = displayableDate(resp1.dateSeries[i], formatScale);
        const longDate = displayableDate(resp1[dateSeriesName][i], formatScale, false);
        const rawDate = resp1[dateSeriesName][i];

        const timeValue : ConsumptionTimeValueSeries = {
          date,
          longDate,
          rawDate,
        };

        if (hasColdSeries) { timeValue.coldConsumption = resp1.coldConsumptionSeries![i]; }

        if (hasHotSeries) { timeValue.hotConsumption = resp1.hotConsumptionSeries![i]; }

        timeValueSeries.push(timeValue);
      }

      let totalConsumptionCold;
      let totalConsumptionHot;
      let consumptionPriceCold;
      let consumptionPriceHot;

      if (hasColdSeries) {
        totalConsumptionCold = resp1.coldConsumptionSeries!.reduce((a, b) => a + b, 0);
        consumptionPriceCold = waterPrice?.coldWaterPrice ? roundNumber(totalConsumptionCold * waterPrice.coldWaterPrice, 2) : -1;
      }

      if (hasHotSeries) {
        totalConsumptionHot = resp1.hotConsumptionSeries!.reduce((a, b) => a + b, 0);
        consumptionPriceHot = waterPrice?.hotWaterPrice ? roundNumber(totalConsumptionHot * waterPrice.hotWaterPrice, 2) : -1;
      }

      const constructedConsumptionData : ConstructedConsumptionData = {
        ...resp1,
        timeValueSeries,
        totalConsumptionHot,
        totalConsumptionCold,
        hasColdSeries,
        hasHotSeries,
        consumptionPriceCold,
        consumptionPriceHot,
      };

      this.overviewConsumptionData = constructedConsumptionData;

      this.fetchingConsumptionData = false;
    });
  } catch (e) {
    this.setError("Virhe kulutusdata haussa");
    this.fetchingConsumptionData = false;
    console.error(e.stack);
  }
}

  clearState = (): void => {
    runInAction(() => {
      this.fetchingConsumptionData = false;
      this.fetchingMeterConsumptionData = false;

      this.meterConsumptionData = {
        consumptionSeries: [],
        dateSeries: [],
        dateTimeSeries: [],
        zonedDateTimeSeries: [],
        devEUI: "string",
        distributedValues: undefined,
        scale: "string",
        meterId: "string",
        timeZone: "string",
        timeValueSeries: [],
        totalConsumption: 0,
      };

      this.meterConsumptionDataPrevious = {
        consumptionSeries: [],
        dateSeries: [],
        dateTimeSeries: [],
        zonedDateTimeSeries: [],
        devEUI: "",
        distributedValues: undefined,
        scale: "",
        meterId: "",
        timeZone: "",
        timeValueSeries: [],
        totalConsumption: 0,
      };

      this.overviewConsumptionData = {
        coldConsumptionSeries: [],
        hotConsumptionSeries: [],
        dateSeries: [],
        dateTimeSeries: [],
        zonedDateTimeSeries: [],
        scale: "",
        timeZone: "",
        timeValueSeries: [],
        totalConsumptionCold: 0,
        totalConsumptionHot: 0,
        consumptionPriceCold: 0,
        consumptionPriceHot: 0,
        hasColdSeries: false,
        hasHotSeries: false,
      };
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      fetchingMeterConsumptionData: observable,
      fetchingConsumptionData: observable,
      meterConsumptionData: observable,
      meterConsumptionDataPrevious: observable,
      overviewConsumptionData: observable,
      loadConsumptionDataForMeter: action,
      loadConsumptionData: action,
    });
  }
}

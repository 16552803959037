import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const drawerWidthOpen = 297;
export const drawerWidthClosed = 60;

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  drawer: {
    width: `100%`,

  },

  appBar: {
    width: `100%`,
  },
  drawerPaper: {
    width: `100%`,
    backgroundColor: theme.palette.primary.dark,
  },
  content: {
    flexGrow: 1,
  },
  navItem: {
    textDecoration: "none",
    color: "white",
    "& .MuiListItemIcon-root": {
      color: "white",
    },
  },
  adminNavItem: {
    margin: "0 auto 0 auto",
    backgroundColor: theme.palette.secondary.light,
    textDecoration: "none",
    color: "white",
    "& .MuiListItemIcon-root": {
      color: "white",
    },
    "& .MuiListItem-button": {
      backgroundColor: theme.palette.primary.dark,
      transition: "150ms all linear",
    },
    "& .MuiListItem-button.selected": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  userNavItems: {
    "& .MuiList-padding": {
      paddingBottom: "0",
    },
  },
  activeAlertsContainer: {
    minWidth: "56px",
  },
  adminNavItems: {
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
    },
  },

  bottomNavContainer: {

    width: "100vw",

  },

  navigationAction: {
    color: "white !important",
  },

  bottomNavComp: {
    justifyContent: "flex-start !important",
    backgroundColor: "rgb(52, 122, 131) !important",
    overflowX: "auto",
    scrollbarWidth: "none",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

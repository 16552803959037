import { observable, action, makeObservable, runInAction } from "mobx";
import BaseStore from "./BaseStore";

export default class UIStateStore extends BaseStore {
  drawerOpen = false;

  handleDrawerToggle = (): void => {
    runInAction(() => {
      this.drawerOpen = !this.drawerOpen;
    });
  };

  setDrawerOpen = () : void => {
    runInAction(() => {
      this.drawerOpen = true;
    });
  }

  setDrawerClosed = () : void => {
    runInAction(() => {
      this.drawerOpen = false;
    });
  }

  clearState = (): void => {
    runInAction(() => {
      this.drawerOpen = false;
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      drawerOpen: observable,
      handleDrawerToggle: action,
      setDrawerOpen: action,
      setDrawerClosed: action,
    });
  }
}

import { AuthenticationResult } from "@azure/msal-browser";
import { ConsumptionDataWrapper, MeterConsumptionDataWrapper } from "../models/DataModel";
import { get, getWithAccessToken } from "./HttpUtils";

const api = process.env.REACT_APP_API_URL || "";

export const getConsumptionForMeter = async (userToken: string, meterId: string, startDate: string, endDate: string, scale: string, fill: boolean, distribute: boolean): Promise<MeterConsumptionDataWrapper> => {
  const useEndDate = endDate !== undefined ? `&endDate=${endDate}` : "";
  const useScale = scale !== undefined ? `&scale=${scale}` : "";
  const useFill = fill !== undefined ? `&fill=${fill}` : "";
  const useDistribute = distribute !== undefined ? `&distribute=${distribute}` : "";
  return await getWithAccessToken(`${api}/service/consumptions/meters/${meterId}?startDate=${startDate}${useEndDate}${useScale}${useFill}${useDistribute}`, userToken) as MeterConsumptionDataWrapper;
};

export const getConsumption = async (userToken: string, startDate: string, endDate: string, scale: string, fill: boolean, distribute: boolean): Promise<ConsumptionDataWrapper> => {
  const useEndDate = endDate !== undefined ? `&endDate=${endDate}` : "";
  const useScale = scale !== undefined ? `&scale=${scale}` : "";
  const useFill = fill !== undefined ? `&fill=${fill}` : "";
  const useDistribute = distribute !== undefined ? `&distribute=${distribute}` : "";
  return await getWithAccessToken(`${api}/service/consumptions/overview?startDate=${startDate}${useEndDate}${useScale}${useFill}${useDistribute}`, userToken) as ConsumptionDataWrapper;
};

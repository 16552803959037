import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  FormLabel,
  TextField,
  Typography, Grid, Select, MenuItem, Stepper, Step, StepLabel, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStyles from "./EditOrganizationModalStyles";
import { useRootStore } from "../../../RootStateContext";
import { Organization, OrganizationForCreation } from "../../../models/Organization";
import { UserInfo } from "../../../models/UserInfo";
import ModalHeader from "../../../components/modalComponents/modalHeader";
import i18n from "../../../i18n"
import ModalPortalTarget from "../../../stores/ModalPortalTarget";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (o: Organization, id: string) => void;
    currentUser?: UserInfo;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

interface OrganizationType {
  id: number;
  type: string;
}

const OrganizationTypes: string[] = [
  "OMAVESI_HOUSING",
  "OMAVESI_HOUSE_MAINTENANCE",
  "OTHER",
];
export default observer(({ open, handleClose, submit, currentUser }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { organizationStore } = useRootStore();
  const { selectedOrganization } = useRootStore().organizationStore;
  const { serviceLevels } = useRootStore().organizationStore;
  const [activeStep, setActiveStep] = useState(0);
  const steps = currentUser?.usersOrganizationType === "RETAILER" ? [t("userManagementView.organizationCreationFirstStep"), t("userManagementView.organizationCreationSecondStep")]
    : [t("userManagementView.organizationCreationFirstStep")];

  const [orgNameErrorState, setOrgNameErrorState] = useState<boolean>(false);
  const [orgNameErrorText, setOrgNameErrorText] = useState<string>("");

  const [orgTypeErrorState, setOrgTypeErrorState] = useState<boolean>(false);
  const [orgTypeErrorText, setOrgTypeErrorText] = useState<string>("");

  const [organizationLevelState, setOrganizationLevelState] = useState<string>("");

  const [organizationType, setOrganizationType] = useState<string>(selectedOrganization?.organizationType || "");
  const [orgNameState, setOrgNameState] = useState<string>(selectedOrganization?.name || "");
  const [orgAccountState, setOrgAccountState] = useState<string>(selectedOrganization?.accountId || "");

  useEffect(() => {
    if (selectedOrganization?.applicationAccesses !== undefined && selectedOrganization?.applicationAccesses !== null && selectedOrganization?.applicationAccesses[0]) {
      setOrganizationLevelState(selectedOrganization.applicationAccesses[0]);
    } else {
      setOrganizationLevelState("");
    }
    setOrganizationType(selectedOrganization?.organizationType || "");
    setOrgNameState(selectedOrganization?.name || "");
    setOrgAccountState(selectedOrganization?.accountId || "");
  }, [selectedOrganization]);

  const close = (): void => {
    organizationStore.setSelectedOrganization(undefined);
    setActiveStep(0);
    handleClose();
  };

  const saveOrganization = (): void => {
    let accountId = "";
    let orgName = "";

    if (orgAccountState) accountId = orgAccountState;
    if (orgNameState) orgName = orgNameState;
    if (organizationType === "") {
      setOrgTypeErrorState(true);
      setOrgTypeErrorText(i18n.t("userManagementView.orgTypeRequiredError"));
    } else {
      const orgData : Organization = {
        id: organizationStore?.selectedOrganization?.id || "",
        name: orgName,
        organizationType,
        accountId,
        active: true,
        applicationAccesses: organizationLevelState !== "" ? [organizationLevelState] : undefined,
        parentId: organizationStore?.selectedOrganization?.parentId || null,
      };

      submit(orgData, organizationStore?.selectedOrganization?.id || "");
      close();
    }
  };

  const handleOrganizationLevelChange = (e: SelectChangeEvent<string>): void => {
    setOrganizationLevelState(e.target.value as string);
  };

  const handleOrganizationTypeChange = (e: SelectChangeEvent<string>): void => {
    if (e.target.value) {
      setOrganizationType(e.target.value as string);
    }
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = async (): Promise<void> => {
    if (activeStep === 0) {
      let localOrgNameError = false;

      if (orgNameState === "") {
        localOrgNameError = true;
        setOrgNameErrorState(true);
        setOrgNameErrorText(i18n.t("userManagementView.orgNameRequiredError"));
      } else {
        localOrgNameError = false;
        setOrgNameErrorState(false);
        setOrgNameErrorText("");
      }

      if (localOrgNameError) {
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isNextButtonDisabled = (): boolean => false;

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("userManagementView.basicInfo")}
                </FormLabel>
                <div
                  className={classes.subsection}
                  style={{
                    marginTop: "16px",
                  }}
                >
                    <TextField
                      id="orgNameInputForEdit"
                      label={t("userManagementView.orgName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={orgNameErrorState}
                      helperText={orgNameErrorText}
                      variant="outlined"
                      fullWidth
                      value={orgNameState}
                      onChange={(e) => setOrgNameState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgAccountIdInputForEdit"
                      label={t("userManagementView.orgAccountId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={false}
                      helperText=""
                      variant="outlined"
                      fullWidth
                      value={orgAccountState}
                      onChange={(e) => setOrgAccountState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="orgTypeSelectLabel" shrink>{t("userManagementView.orgType")}</InputLabel>
                        <Select
                          labelId="orgTypeSelectLabel"
                          id="orgTypeSelectInput"
                          value={organizationType}
                          onChange={handleOrganizationTypeChange}
                          displayEmpty
                          error={orgTypeErrorState}
                        >
                            <MenuItem value="" key="orgtype-null">{t("userManagementView.chooseOrgType")}</MenuItem>
                            {OrganizationTypes.map((orgType, key) => (
                                <MenuItem key={orgType} value={orgType}>{t(`organizationType.${orgType}`)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

            </>
        );
      case 1: return (
          <>
              <FormLabel className={classes.genFormLabel2}>
                  {t("userManagementView.omavesiProductHeader")}
              </FormLabel>
              <div className={classes.subsection}>
                  <Select
                    key="select"
                    labelId="organizationLevelSelectEdit"
                    id="organizationLevelSelectEdit"
                    value={organizationLevelState}
                    onChange={handleOrganizationLevelChange}
                    displayEmpty
                  >
                      <MenuItem value="" key="product-null">{t("userManagementView.noProduct")}</MenuItem>
                      {serviceLevels.map((level) => (
                          <MenuItem key={level} value={level}>{t(`userManagementView.${level}_text`)}</MenuItem>

                      ))}
                  </Select>
              </div>
          </>
      );
      default:
        return <></>;
    }
  };

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={close}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <ModalHeader headerText={t("userManagementView.editOrg")} clear={close} id="editOrgTitle" />
              {getStepContent(activeStep)}

              <div className={classes.navigation}>

                  <div className={classes.navButtons}>

                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              {steps.length > 1 && (
                                  <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                  >
                                      {t("userManagementView.orgBack")}
                                  </Button>
                                      )}
                          </Grid>

                          <Grid item xs={6}>
                              <Button
                                variant="contained"
                                id="editOrgButton"
                                className={classes.nextButton}
                                color="primary"
                                onClick={activeStep === steps.length - 1 ? saveOrganization : handleNext}
                              >
                                  {activeStep === steps.length - 1 ? t("userManagementView.orgFinish") : t("userManagementView.orgNext")}
                              </Button>
                          </Grid>
                      </Grid>

                  </div>
                  {steps.length > 1 && (
                      <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                              <Step key={label}>
                                  <StepLabel>{label}</StepLabel>
                              </Step>
                                  ))}
                      </Stepper>
                          )}
              </div>
          </div>
      </Fade>
                               </Modal>, ModalPortalTarget);
});

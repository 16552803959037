import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  card: {
    maxWidth: "310px",
    minWidth: "260px",
    minHeight: "84px",
    maxHeight: "120px",
    "& .MuiCardContent-root": {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "10px",
      paddingTop: "0px",
    },
  },
  cardIcon:
  {
    color: theme.palette.secondary.dark,
    fontSize: "40px",
    float: "left",
  },
  muiCardHeader:
  {
    padding: "10px 10px 0px 10px",
  },
  statCardHeaderContainer: {
    width: "100%",
    overflow: "hidden",
    height: "40px",
  },
  cardHeader: {
    marginLeft: "55px",
    fontWeight: "bold",
    color: "#014961",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "14pt",
  },
  cardHeaderOf: {/* lineHeight: "60px", */},
  cardContent:
  {
    marginLeft: "55px",
  },
  cardHeavyContent:
  {
    marginLeft: "55px",
    fontWeight: "bold",
    color: "#014961",
    fontSize: "1.3rem",
  },
  cardContentWithHeavy: {
    marginTop: "5px",
  },
}));

import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import useResponsivity from "../../hooks/useResponsivity";
import useStyles from "./TabPanelStyles";

type TabPanelProps = {
    index: number;
    value: number;
    other?: any[];
    children: ReactNode
    style?: React.CSSProperties;
}

export const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps): JSX.Element => {
    const classes = useStyles();
    const { children, value, index, style, ...other } = props;
    const { isMobile } = useResponsivity();

    return (
        <div
          className={classes.panel}
          role="tabpanel"
          hidden={value !== index}
          id={`ov-tabpanel-${index}`}
          aria-labelledby={`ov-tab-${index}`}
          style={style}
          {...other}
        >
            {value === index && (children)}
        </div>
    );
  }

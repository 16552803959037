import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../../css_globals";

export default makeStyles((theme: Theme) => createStyles({

  root: {
    width: "100%",
    padding: "0px",

    "& .MuiTableRow-head": {
      backgroundColor: "white",
      color: "black",

      "& .MuiTableCell-sizeSmall": {
        backgroundColor: "white",
        color: "black",
        padding: "3px 24px 3px 16px",
      },
      "& .MuiCircularProgress-root": {
        marginTop: "5% !important",
      },
    },
    "& .Mui-checked": {
      color: `${theme.palette.primary.dark} !important`,
    },
    "& .MuiTableCell-body":
        {
          padding: "5px 24px 0 16px",
        },
    "& .MuiTableCell-root": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .MuiTableCell-head": {
      color: theme.palette.primary.dark,
      borderBottom: "1px black solid",
    },
    "& .PrivateSwitchBase-root-166":
        {
          padding: "0 !important",
        },
    "& > *": {},
  },
  alertsOnContainer: {
    alignContent: "start",
  },
  consumptionSettingsContainer: {
    "& .MuiFilledInput-input": {
      padding: "13px 12px 10px",
    },
  },
  alertInfoIcon: {
    transform: "translate(5px, 4px)",
  },
  h6Title: {
    color: theme.palette.primary.dark,
  },
  consumptionSettingsLabel: {
    paddingTop: 18,
    whiteSpace: "nowrap",
  },
  smsSetting: {
    paddingTop: 6,
  },
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  alertType: {
    textTransform: "uppercase",
    color: theme.palette.primary.dark,
  },
  tabBar: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    boxShadow: "none",
    display: "grid",
    padding: 15,
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    "grid-template-columns": "1fr auto 1fr",
    marginTop: 20,
    "& .MuiTab-wrapper": {
      display: "inline",
    },
    "& .MuiSvgIcon-root": {
      top: 0,
      position: "relative",
      paddingRight: 2,
      fontSize: "1.2rem",
    },
  },
  tabPanel: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  topContainer:
  {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  tableContainer: {
    padding: 10,
    minWidth: "95%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  container: {
    padding: "0px 0px 20px 0px",
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headerContainer: {
    marginBottom: 50,
  },
  metersHeadGrid: {
    marginTop: 10,
  },
  metersHeadCardsContainer:
  {
    paddingTop: "15px",
  },
  infoButton: {
    float: "right",
    height: "35px",
  },
  mapButton: {
    margin: "8px 0 2px 0",
  },
  headerTop: {
    color: "#04444B",
  },

  consumptionSettingsLabelMobile: {
    paddingRight: "10px",
    whiteSpace: "normal",
  },

  rootMobile: {
      "& .MuiTableCell-sizeSmall": {
        padding: "3px 5px 3px 5px !important",
      },
  },

  metersHeadGridMobile: {
    padding: "0 15px 0 15px",
  },

  mapButtonMobile: {
    margin: "8px 0 2px 15px",
  },

  tresholdInput: {},
}));

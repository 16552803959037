import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useNavigate, useParams } from "react-router";

import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import SingleOrganizationView from "../SingleOrganizationView";
import { useRootStore } from "../../../RootStateContext";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";
import { loginRequest } from "../../../authConfig";
import acquireToken from "../../../api/MsalUtil";
import { UserForResidentCreation } from "../../../models/UserInfo";
import { WaterMeter, WaterMeterJustId } from "../../../models/WaterMeter";
import { Organization } from "../../../models/Organization";

interface ISingleOrganizationRootViewMatchParams extends Params<string> {
    organizationId: string;
}

export default observer((): JSX.Element => {
  const match = useParams<ISingleOrganizationRootViewMatchParams>();
  const [forbidden, setForbidden] = useState(false);
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();

  const store = useRootStore().organizationStore;
  const { organization } = store;

  const usersStore = useRootStore().userStore;
  const { usersOfOrganization, allUsers } = usersStore;

  const waterMeterStore = useRootStore().meterStore;
  const { totalMetersInTable, fetchingMeters } = waterMeterStore;

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchJustActivated, setSearchJustActivated] = useState(false);
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const sort = (field: string): void => {
    setSortBy(field);
  };
  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("");
    setSortDirection("desc");
    setSearchValue("");
    setPage(0);
  };

  const saveUser = async (u: UserForResidentCreation): Promise<void> => {
    // todo: add user to organization from here
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          // await usersStore.saveNewResidentUserForOrganization(response.idToken, u);
          if (usersStore.error === undefined) {
            toast.info(t("userManagementView.creationSuccess"), {
              toastId: "UserCreatedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        // await usersStore.saveNewResidentUserForOrganization(currentUserToken, u);
        if (usersStore.error === undefined) {
          toast.info(t("userManagementView.creationSuccess"), {
            toastId: "UserCreatedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  const addMeterToOrganization = async (meter: WaterMeterJustId, selectedOrganizationId: string): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await waterMeterStore.addMeterToOrganization(response.idToken, meter, selectedOrganizationId);
          if (store.error === undefined) {
            toast.info(t("organizationView.meterAdded"), {
              toastId: "MeterAddedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await waterMeterStore.addMeterToOrganization(currentUserToken, meter, selectedOrganizationId);
        if (store.error === undefined) {
          toast.info(t("organizationView.meterAdded"), {
            toastId: "MeterAddedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  const takeMeterFromOrganization = async (meter: WaterMeter): Promise<void> => {
    // todo: this and delete
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await waterMeterStore.removeMeterFromOrganization(response.idToken, meter);
          if (store.error === undefined) {
            toast.info(t("organizationView.meterDeleted"), {
              toastId: "MeterRemovedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        await waterMeterStore.removeMeterFromOrganization(currentUserToken, meter);
        if (store.error === undefined) {
          toast.info(t("organizationView.meterDeleted"), {
            toastId: "MeterRemovedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  const addOrganizationAccessToOrganization = async (m: Organization): Promise<void> => {
    // todo: this and delete access
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          //   await waterMeterStore.saveNewResidentUserForOrganization(response.idToken, u);
          if (store.error === undefined) {
            toast.info(t("organizationView.organizationAccessGiven"), {
              toastId: "OrganizationAccessToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("user creation failed");
        }
      }
    } else {
      try {
        // await usersStore.saveNewResidentUserForOrganization(currentUserToken, u);
        if (store.error === undefined) {
          toast.info(t("organizationView.organizationAccessGiven"), {
            toastId: "OrganizationAccessToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("user creation failed");
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    const { organizationId } = match;
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      (async () => {
        const response = await acquireToken(instance, request);
        if (response) {
          setCurrentUserToken(response.idToken);
          await usersStore.loadUsersOfOrganizationPaged(organizationId!, response.idToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived);
          await store.loadOrganization(response.idToken, organizationId!)
            .catch(() => {
              if (mounted) {
                setForbidden(true);
              }
            });
        }
      })();
    } else {
      usersStore.loadUsersOfOrganizationPaged(organizationId!, currentUserToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived);
      store.loadOrganization(currentUserToken, organizationId!)
        .catch(() => {
          if (mounted) {
            setForbidden(true);
          }
        });
    }

    return function cleanup() {
      mounted = false;
      store.clearState();
    };
  }, [accounts, instance, match, match.organizationId, store, archived, page, pageSize, sortBy, sortDirection, usersStore]);

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  if (organization === null || organization === undefined) {
    return (
        <PendingCircle />
    );
  }
  return (
      <SingleOrganizationView
        organization={organization}
        setPage={setPage}
        setPageSize={setPageSize}
        totalMeters={totalMetersInTable}
        page={page}
        rowsPerPage={pageSize}
        fetchingMeters={fetchingMeters}
        sort={sort}
        setSortDirection={setSortDirection}
        setArchived={setArchived}
        search={setSearchValue}
        setSearchJustActivated={setSearchJustActivated}
        resetAllOptions={resetAllOptions}
        activeSearchValue={searchValue}
        usersOfOrganization={usersOfOrganization}
        allUsers={allUsers}
        saveUser={saveUser}
        addMeterToOrganization={addMeterToOrganization}
        takeMeterFromOrganization={takeMeterFromOrganization}
        addOrganizationAccessToOrganization={addOrganizationAccessToOrganization}
      />
  );
});

import React, { useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  TextField,
  Typography,
  FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./NewLocationModalStyles";
import CreateLocation from "../../models/CreateLocation";
import { Organization } from "../../models/Organization";
import ModalHeader from "../modalComponents/modalHeader";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
    organizations?: Organization[];
    open: boolean;
    handleClose: () => void;
    submit: (d: CreateLocation) => void;
    showCustomerSelection: boolean;
}

const formFieldErrorReducer = (state: any, action : {target: string; valueToCheck: string | undefined}) : any => {
  const isError = typeof action.valueToCheck === "undefined" ? true : action.valueToCheck === "";
  return {
    ...state,
    [`${action.target}Error`]: isError,
    [`${action.target}ErrorTextShown`]: isError ? state[`${action.target}ErrorText`] : "",

  };
};

export default function ({ organizations, open, handleClose, submit, showCustomerSelection }: IModalProps): JSX.Element {
  const { t } = useTranslation();

  const initialFormFieldErrorState = {
    /* identifierError: false,
    identifierErrorTextShown: "",
    identifierErrorText: t("locationCreation.identifierErrorText"), */

    addressError: false,
    addressErrorTextShown: "",
    addressErrorText: t("locationCreation.addressErrorText"),

    zipError: false,
    zipErrorTextShown: "",
    zipErrorText: t("locationCreation.zipErrorText"),

    cityError: false,
    cityErrorTextShown: "",
    cityErrorText: t("locationCreation.cityErrorText"),

    countryError: false,
    countryErrorTextShown: "",
    countryErrorText: t("locationCreation.countryErrorText"),
  };

  const classes = useStyles();

  const [missingValues, setMissingValues] = useState(false);
  const [typeState, setTypeState] = useState("OTHER");
  const identifier = useRef<HTMLInputElement>();

  const streetAddress = useRef<HTMLInputElement>();
  const city = useRef<HTMLInputElement>();
  const postalCode = useRef<HTMLInputElement>();
  const country = useRef<HTMLInputElement>();
  const [ownerOrganizationIdState, setOwnerOrganizationIdState] = useState("");
  const [formFieldErrorState, dispatchFormFieldState] = useReducer(formFieldErrorReducer, initialFormFieldErrorState);

  const clear = (): void => {
    setTypeState("");
    handleClose();
  };

  // todo: type hidden from pilot users
  const handleTypeChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setTypeState(event.target.value as string);
    }
  };

  // todo: organizations are selectable only if user is in Digita organization
  // by default new locations are created to current users organization
  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setOwnerOrganizationIdState(event.target.value as string);
    }
  };

  // const requiredValuesMissing = (): boolean => (!streetAddress.current?.value || !postalCode.current?.value || !identifier.current?.value || !city.current?.value || !country.current?.value);
  const requiredValuesMissing = (): boolean => {
    /* dispatchFormFieldState({
      target: "identifier", valueToCheck: identifier.current?.value,
    }); */

    dispatchFormFieldState({
      target: "address", valueToCheck: streetAddress.current?.value,
    });

    dispatchFormFieldState({
      target: "zip", valueToCheck: postalCode.current?.value,
    });

    dispatchFormFieldState({
      target: "city", valueToCheck: city.current?.value,
    });

    dispatchFormFieldState({
      target: "country", valueToCheck: country.current?.value,
    });

    return (!streetAddress.current?.value || !postalCode.current?.value /* || !identifier.current?.value */ || !city.current?.value || !country.current?.value);
  };

  const saveLocation = (): void => {
    if (!requiredValuesMissing()) {
      setMissingValues(false);
      const location: CreateLocation = {
        // type: typeState,
        identifier: identifier.current?.value || "",
        ownerOrganizationId: ownerOrganizationIdState || undefined,
        streetAddress: streetAddress.current?.value || "",
        postalCode: postalCode.current?.value || "",
        city: city.current?.value || "",
        country: country.current?.value || "",
        createdByUserID: "",
      };
      submit(location);
      clear();
    } else {
      setMissingValues(true);
    }
  };

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <ModalHeader headerText={t("locationCreation.addLocation")} clear={clear} id="addLocationTitle" />
              <div className={classes.section}>
                  <FormControl className={classes.formControlWrapper}>
                      {showCustomerSelection && (
                          <div className={classes.subsection2}>
                              {organizations
                                        ? (
                                            <FormControl style={{
                                              minWidth: 240,
                                            }}
                                            >
                                                <InputLabel id="ownerOrganizationLabel">{t("locationView.customer")}</InputLabel>
                                                <Select
                                                  labelId="ownerOrganizationLabel"
                                                  id="ownerOrganizationLabelId"
                                                  value={ownerOrganizationIdState}
                                                  onChange={handleOrganizationDropdownChange}
                                                >
                                                    {organizations.map((organization: Organization, index) => (
                                                        <MenuItem key={organization.id} value={organization.id}>
                                                            {organization.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )
                                        : (<div>Ei organisaatioita</div>)}
                          </div>
                              )}
                      {/*       <div className={classes.subsection}>
                                  <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="h2">{t("locationView.type")}</Typography>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="locationTypeLabel">{t("locationView.type")}</InputLabel>
                                      <Select
                                        labelId="locationTypeLabel"
                                        id="locationType"
                                        value={typeState}
                                        onChange={handleTypeChange}
                                        defaultValue="OTHER"
                                      >
                                          <MenuItem value="HOUSING_COMPANY">{t("locationType.HOUSING_COMPANY")}</MenuItem>
                                          <MenuItem value="COMPANY">{t("locationType.COMPANY")}</MenuItem>
                                          <MenuItem value="OTHER">{t("locationType.OTHER")}</MenuItem>
                                      </Select>
                                  </FormControl>
                              </div> */}
                      <div className={classes.subsection}>
                          <TextField
                            id="locationIdentifierInput"
                            label={t("locationView.identifier")}
                            InputLabelProps={{
                                      shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            inputRef={identifier}
                            defaultValue=""
                            error={formFieldErrorState.identifierError}
                            helperText={formFieldErrorState.identifierErrorTextShown}
                          />
                      </div>

                      <div className={classes.subsection}>
                          <TextField
                            id="streetAddressInput"
                            InputLabelProps={{
                                      shrink: true,
                            }}
                            fullWidth
                            required
                            label={t("locationView.streetAddress")}
                            variant="outlined"
                            inputRef={streetAddress}
                            error={formFieldErrorState.addressError}
                            helperText={formFieldErrorState.addressErrorTextShown}
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="postalCodeInput"
                            InputLabelProps={{
                                      shrink: true,
                            }}
                            fullWidth
                            required
                            label={t("locationView.postalCode")}
                            variant="outlined"
                            inputRef={postalCode}
                            error={formFieldErrorState.zipError}
                            helperText={formFieldErrorState.zipErrorTextShown}
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="cityInput"
                            InputLabelProps={{
                                      shrink: true,
                            }}
                            fullWidth
                            required
                            label={t("locationView.city")}
                            variant="outlined"
                            inputRef={city}
                            error={formFieldErrorState.cityError}
                            helperText={formFieldErrorState.cityErrorTextShown}
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="countryInput"
                            InputLabelProps={{
                                      shrink: true,
                            }}
                            fullWidth
                            required
                            label={t("locationView.country")}
                            variant="outlined"
                            inputRef={country}
                            error={formFieldErrorState.countryError}
                            helperText={formFieldErrorState.countryErrorTextShown}
                          />
                      </div>
                  </FormControl>
              </div>
              <div className={classes.navigation}>

                  <div className={classes.modalButtons}>
                      {missingValues && (<Typography variant="h6" component="span" className={classes.mandatoryError} id="newlocationmodal-mandatoryerror">{t("locationCreation.requiredValuesMissing")}</Typography>)}
                      {showCustomerSelection && <Typography component="div" className={classes.customerInfo}>{t("locationView.customerInfo")}</Typography>}

                      <Button
                        onClick={() => clear()}
                        variant="contained"
                        color="secondary"
                        id="CancelInfoChangesButton"
                        className={classes.cancelButton}
                      >
                          {t("common.cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveLocation}
                        id="newlocationmodal-save"
                      >
                          {t("locationCreation.finish")}
                      </Button>
                  </div>

              </div>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
};

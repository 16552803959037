import React from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button, Typography } from "@mui/material";
import useStyles from "./DeleteLocationModalStyles";
import Location from "../../models/Location";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  submit: (location: Location) => void;
  location: Location
}

export default function ({ open, handleClose, submit, location }: IModalProps): JSX.Element {
  const classes = useStyles();

  const handleSubmit = (): void => {
    handleClose();
    submit(location);
  };

  return ReactDOM.createPortal(<Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
              timeout: 500,
    }}
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <div className={classes.section}>
                  <Typography variant="h6" component="span">Haluatko varmasti arkistoida valitun laitteen?</Typography>
              </div>
              <div className={classes.buttons}>
                  <Button
                    className={classes.deleteButton}
                    onClick={handleSubmit}
                    style={{
                              borderRadius: 15,
                    }}
                    variant="contained"
                  >
                      Kyllä
                  </Button>
                  <Button
                    disableElevation
                    onClick={handleClose}
                    style={{
                              borderRadius: 15,
                    }}
                    color="primary"
                    variant="contained"
                  >
                      Peruuta
                  </Button>
              </div>
          </div>
      </Fade>
                               </Modal>, ModalPortalTarget);
};

import { observable, action, makeObservable, runInAction } from "mobx";
import i18n from "i18next";
import BaseStore from "./BaseStore";
import { Organization } from "../models/Organization";
import { getSubOrganizationsAsList,
  getOrganizationsPaged,
  saveNewOrganization,
  deleteOrganization,
  editOrganization,
  getOrganization } from "../api/OrganizationApi";

export default class OrganizationStore extends BaseStore {
  allOmaVesiOrganizations?: Organization[];

  allOrganizations?: Organization[];

  organization?: Organization;

  totalOrganizations = 0;

  selectedOrganization?: Organization = undefined;

  selectedOrganizations: Organization[] = [];

  serviceLevels = ["OMAVESI_BRONZE", "OMAVESI_SILVER"];

  fetchingOrganizations = false;

  totalOrganizationsForCard = 0;

  loadAllSubOrganizations = async (userToken: string): Promise<void> => {
    try {
      const locationResp = await getSubOrganizationsAsList(userToken);
      runInAction(() => {
        this.allOmaVesiOrganizations = locationResp;
      });
    } catch (e: any) {
      this.setError(i18n.t("organizationStore.organizationFetchError"));
      console.error(e.stack);
    }
  };

  loadOrganization = async (userToken: string, organizationId: string): Promise<void> => {
    try {
      this.fetchingOrganizations = true;
      const conf = await getOrganization(userToken, organizationId);
      runInAction(() => {
        this.organization = conf;
        this.fetchingOrganizations = false;
      });
    } catch (e: any) {
      this.fetchingOrganizations = false;
      this.setError("Virhe yksittäisen organisaation haussa");
      console.error(e.stack);
    }
  }

  loadOrganizationsPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<void> => {
    try {
      this.fetchingOrganizations = true;
      const usersResp = await getOrganizationsPaged(userToken, page, pageSize, sortBy, sortDirection, search);
      runInAction(() => {
        this.allOrganizations = usersResp.content;
        this.totalOrganizations = usersResp.totalElements;
        this.totalOrganizationsForCard = usersResp.totalElements;
        this.fetchingOrganizations = false;
      });
    } catch (e) {
      this.setError(i18n.t("userStore.usersFetchError"));
      this.allOrganizations = [];
      console.error(e.stack);
      this.fetchingOrganizations = false;
    }
  };

  toggleOrganizationSelection = (o: Organization): void => {
    if (this.selectedOrganizations.includes(o)) {
      this.selectedOrganizations = this.selectedOrganizations.filter((e) => e !== o);
    } else {
      this.selectedOrganizations = [...this.selectedOrganizations, o];
    }
  };

  setSelectedOrganization = (o: Organization | undefined): void => {
    this.selectedOrganization = o;
  };

  saveNewOrganization = async (userToken: string, orgData: Organization): Promise<any> => {
    try {
      const p = await saveNewOrganization(userToken, orgData);
      runInAction(() => {
        this.allOmaVesiOrganizations?.push(p);
        this.allOrganizations?.push(p);
        this.totalOrganizations++;
        this.totalOrganizationsForCard++;
      });
      return p;
    } catch (e) {
      this.setError(i18n.t("organizationStore.orgCreateError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  deleteOrganization = async (userToken: string, orgId: string): Promise<void> => {
    try {
      await deleteOrganization(userToken, orgId);
      runInAction(() => {
        this.allOmaVesiOrganizations = this.allOmaVesiOrganizations?.filter((e) => e.id !== orgId);
        this.allOrganizations = this.allOrganizations?.filter((e) => e.id !== orgId);
      });
    } catch (e) {
      this.setError(i18n.t("organizationStore.orgDeletionError"));
      console.error(e.stack);
    }
  }

  updateOrganization = async (userToken: string, org: Organization, id: string): Promise<Organization> => {
    try {
      const o = await editOrganization(userToken, org, id);
      runInAction(() => {
        const orgInOrgs = this.allOrganizations?.find((e) => e.id === id);
        if (orgInOrgs) {
          this.allOrganizations?.splice(this.allOrganizations?.indexOf(orgInOrgs), 1, o);
        }
      });

      return o;
    } catch (e) {
      this.setError(i18n.t("organizationStore.orgUpdatingError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  clearState = (): void => {
    runInAction(() => {
      this.allOmaVesiOrganizations = [];
      this.allOrganizations = [];
      this.selectedOrganization = undefined;
      this.selectedOrganizations = [];
      this.fetchingOrganizations = false;
      this.error = undefined;
    });
  };

  constructor() {
    super();
    makeObservable(this, {
      allOmaVesiOrganizations: observable,
      organization: observable,
      allOrganizations: observable,
      serviceLevels: observable,
      selectedOrganization: observable,
      selectedOrganizations: observable,
      fetchingOrganizations: observable,
      totalOrganizationsForCard: observable,
      loadOrganization: action,
      loadAllSubOrganizations: action,
      loadOrganizationsPaged: action,
      toggleOrganizationSelection: action,
      setSelectedOrganization: action,
      saveNewOrganization: action,
      deleteOrganization: action,
      updateOrganization: action,
      clearState: action,
    });
  }
}

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  chartTooltipWrapper:
    {
      margin: "0px",
      padding: "10px",
      backgroundColor: "rgb(255, 255, 255)",
      border: "1px solid rgb(204, 204, 204)",
      whiteSpace: "nowrap",
    },
  chartTooltipLabel:
    {
      margin: 0,
    },
}));

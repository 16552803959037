import { AppBar, Box, Card, Container, Fade, Paper, Tab, Tabs, Typography, Button, CardContent, Grid } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./SingleLocationViewStyles";
import Location from "../../models/Location";
import MetersOfLocation from "./managementComponents/metersOfLocation/MetersOfLocation";
import { WaterMeter } from "../../models/WaterMeter";
import UsersOfLocation from "./managementComponents/usersOfLocation/UsersOfLocation";
import { UserForLocationAccessEditing, UserForResidentCreation, UserInfo } from "../../models/UserInfo";
import useResponsivity from "../../hooks/useResponsivity";
import { TabPanel } from "../../components/tabPanel/TabPanel";

interface ISingleLocationViewProps {
  location: Location;
  metersOfLocation?: WaterMeter[];
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalMeters: number;
  page: number;
  rowsPerPage: number;
  fetchingMeters: boolean;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  setArchived: (a: boolean) => void;
  search: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
  allUsers?: UserInfo[];
  residentUsers?: UserInfo[];
  usersOfLocation?: UserInfo[];
  totalUsers: number;
  fetchingLocationUsers: boolean;
  saveUser: (s: UserForResidentCreation) => void;
  grantAccessToUser: (s: UserForLocationAccessEditing) => void;
  removeUserFromLocation: (u: UserForLocationAccessEditing) => void;
}

export default function ({ location, metersOfLocation, setPage, setPageSize, totalMeters,
  page, rowsPerPage, fetchingMeters,
  sort, setSortDirection, setArchived, search, totalUsers, fetchingLocationUsers,
  setSearchJustActivated, resetAllOptions, residentUsers,
  activeSearchValue, allUsers, usersOfLocation, saveUser, grantAccessToUser, removeUserFromLocation }: ISingleLocationViewProps): JSX.Element {
    const { isMobile } = useResponsivity();
  const classes = useStyles();
  const { t } = useTranslation();

  function a11yProps(index: any): any {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [archivedState, setArchivedState] = React.useState(0); // Tab control

  const handleChangeArchived = (event: React.ChangeEvent<any>, newValue: number): void => {
    setArchivedState(newValue);
  };

  return (
      <Fade in>
          <Box>
              <Container className={`${classes.container} ${isMobile && classes.containerMobile}`}>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={isMobile ? "center" : "flex-start"}
                    style={isMobile ? {
 textAlign: "center",
                    } : {}}
                  >
                      <Typography variant="h4" className={classes.locationNameTop} id="singlelocation-identifierhead">{`${location.streetAddress}`}</Typography>
                  </Grid>
                  <Card className={classes.topCard} variant="elevation" elevation={2}>
                      <Typography variant="h5">{t("singleLocation.locationInfo")}</Typography>
                      <Grid
                        container
                        item
                        alignItems="center"
                        xs={12}
                        display="grid"
                        gridTemplateColumns={isMobile ? "1fr" : "repeat(4, min-content)"}
                        columnGap={isMobile ? "0" : "3%"}
                        rowGap="15px"
                      >

                          <Grid item xs={6} className={classes.topCardDataTitle}>
                              {t("singleLocation.customer")}
                          </Grid>
                          <Grid item xs={6} id="singlelocation-ownerorg">
                              {location.ownerOrganizationName}
                          </Grid>

                          <Grid item xs={6} className={classes.topCardDataTitle}>
                              {t("singleLocation.address")}
                          </Grid>
                          <Grid item xs={6} id="singlelocation-street">
                              {location.streetAddress}
                          </Grid>

                          <Grid item xs={6} className={classes.topCardDataTitle}>
                              {t("singleLocation.city")}
                          </Grid>
                          <Grid item xs={6} id="singlelocation-city">
                              {location.city}
                          </Grid>

                          <Grid item xs={6} className={classes.topCardDataTitle}>
                              {t("singleLocation.placeOfUseId")}
                          </Grid>
                          <Grid item xs={6} id="singlelocation-identifier">
                              {location.identifier}
                          </Grid>

                          <Grid item xs={6} className={classes.topCardDataTitle}>
                              {t("singleLocation.zip")}
                          </Grid>
                          <Grid item xs={6} id="singlelocation-zip">
                              {location.postalCode}
                          </Grid>

                          <Grid item xs={6} className={classes.topCardDataTitle}>
                              {t("singleLocation.country")}
                          </Grid>
                          <Grid item xs={6} id="singlelocation-country">
                              {location.country}
                          </Grid>
                      </Grid>
                  </Card>

                  <AppBar className={classes.tabBar} position="static">

                      <Tabs value={archivedState} onChange={handleChangeArchived}>
                          <Tab label={t("singleLocation.meters")} {...a11yProps(0)} />
                          <Tab label={t("singleLocation.users")} {...a11yProps(1)} />
                      </Tabs>

                  </AppBar>

                  <TabPanel value={archivedState} index={0}>
                      <Card className={classes.paper} variant="elevation" elevation={2}>
                          <Typography className={classes.h5title} component="h5" variant="h5">{t("locationView.metersOfLocation")}</Typography>
                          <MetersOfLocation
                            metersOfLocation={metersOfLocation}
                            setPage={setPage}
                            setPageSize={setPageSize}
                            totalMeters={totalMeters}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            fetchingMeters={fetchingMeters}
                            sort={sort}
                            setSortDirection={setSortDirection}
                            setArchived={setArchived}
                            search={search}
                            setSearchJustActivated={setSearchJustActivated}
                            resetAllOptions={resetAllOptions}
                            activeSearchValue={activeSearchValue}
                          />
                      </Card>
                  </TabPanel>
                  <TabPanel value={archivedState} index={1}>
                      <Card className={classes.paper} variant="elevation" elevation={2}>
                          <Typography className={classes.h5title} component="h5" variant="h5">{t("locationView.usersOfLocation")}</Typography>
                          <UsersOfLocation
                            location={location}
                            usersOfLocation={usersOfLocation}
                            residentUsers={residentUsers}
                            saveUser={saveUser}
                            grantAccessToUser={grantAccessToUser}
                            totalUsers={totalUsers}
                            fetchingLocationUsers={fetchingLocationUsers}
                            removeUserFromLocation={removeUserFromLocation}
                          />
                      </Card>
                  </TabPanel>
              </Container>
          </Box>
      </Fade>

  );
};

import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  createReport: {
    width: "23%",
    "& .MuiCollapse-root": { /* backgroundColor: "#EEFDFF", */ },
    "&.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "10px 0 0 10px !important",
    },
    borderRadius: "4px !important",
    borderColor: theme.palette.secondary.dark,
    marginTop: "10px !important",
    marginLeft: "10px !important",
  },
  createTitle: {
    color: theme.palette.secondary.dark,
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.dark,
    },
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  typeSelector: {
    marginBottom: 12,
    "& label + .MuiInput-formControl": {
      marginTop: "5px !important",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  organizationSelector: {},
  createTitleText: {
    marginTop: 1,
    marginLeft: 3,
    color: theme.palette.secondary.dark,
  },
  popOverContent: {
    margin: 10,
  },
  inProgressText: {
    color: theme.palette.secondary.light,
  },
  createReportButton: {
    marginTop: 10,
    marginLeft: 10,
  },
  datePicker: {
    margin: "5px 0px",
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      backgroundColor: "white",
    },
  },
}));

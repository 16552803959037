import { AuthenticationResult } from "@azure/msal-browser";
import { ConsumptionDataWrapper, MeterConsumptionDataWrapper } from "../models/DataModel";
import { AlertMarker, MeterMarker, Viewport } from "../models/Map";
import { viewportToAreaRequest } from "../utils/MapUtils";
import { get, getWithAccessToken } from "./HttpUtils";

const api = process.env.REACT_APP_API_URL || "";

export const getMapAlerts = async (userToken: string, viewport: Viewport, abortSignal: AbortSignal): Promise<AlertMarker[]> => {
  const area = viewportToAreaRequest(viewport);
  try {
    const response = await getWithAccessToken(`${api}/service/alert/map/v1${area}`, userToken, undefined, abortSignal) as { data: AlertMarker[] };
    if (response) return response.data as AlertMarker[];
    return [];
  } catch (err) {
    return [];
  }
};

export const getMapMeters = async (userToken: string, viewport: Viewport, abortSignal: AbortSignal): Promise<MeterMarker[]> => {
  const area = viewportToAreaRequest(viewport);
  try {
    const response = await getWithAccessToken(`${api}/service/meters/map/v1${area}`, userToken, undefined, abortSignal) as { data: MeterMarker[] };
    if (response) return response.data as MeterMarker[];
    return [];
  } catch (err) {
    return [];
  }
};

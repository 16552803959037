import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: "20%",
    "& .MuiStepConnector-line": {
      marginLeft: 15,
    },
  },
  container: {
    paddingTop: 20,
    minWidth: "98%",
  },
  headerContainer: {
    marginBottom: 50,
  },
  locationNameDisplay: {
    marginLeft: 10,
    marginTop: 10,
  },
  addButton: {
    margin: "10px 0px 15px 10px",
    color: "white",
  },
  modalButtons: {
    textAlign: "end",
  },
  sectionHeader: {
    padding: theme.spacing(2, 2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
    },
  },
  okButton: {
    marginRight: 10,
  },
  subsection: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2, 2),
  },
  formControl: {
    minWidth: 240,
    width: "100%",
  },
  section: {},
  input: {
    width: "100%",
  },
  formControlWrapper: {
    width: "100%",
  },
  mandatoryError: {
    color: "#EE145B",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  meterSelectionTitle: {
    marginBottom: "10px",
    display: "block",
  },
  navigation: {
    padding: theme.spacing(6, 6, 6),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  navButtons: {
    display: "block",
    marginBottom: "15%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  buttonShadow: {
    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  selectResidentButton: {
    marginLeft: 10,
  },
  gridContainer: {
    marginBottom: 50,
  },
  locationTableButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px 0px 10px 1rem",
  },
  h5title: {
    fontSize: "1rem",
    lineHeight: "1.1",
    padding: "1rem !important",
    margin: "0rem !important",
    float: "left",
    color: theme.palette.primary.main,
  },
  noData: {
    marginTop: 45,
    textAlign: "start",
    padding: 15,
    width: "100%",
  },
}));

import moment from "moment";

export const DEFAULT_DATE_FORMAT = "Y-MM-DD";
export const DEFAULT_DATETIME_FORMAT = "Y-MM-DD HH[:]00";

export function displayableDate(date: string, scale: string, short = true) : string {
  if (date.length === 0 || date === "auto") {
    return "";
  }

  if (short) {
    if (scale === "hourly") {
      return moment(date).format("HH[:]mm");
    }
    if (scale === "daily") {
      return moment(date).format("D.M");
    }
    if (scale === "weekly") {
      return moment(date).format("dd");
    }
    if (scale === "monthly") {
      return moment(date).format("MMM");
    }
    if (scale === "yearly") {
      return moment(date).format("MMM");
    }
  } else {
    if (scale === "hourly") {
      return moment(date).format("DD.MM.YYYY [kello] HH[:]mm");
    }
    if (scale === "daily") {
      return moment(date).format("dd DD.MM.YYYY");
    }
    if (scale === "weekly") {
      return moment(date).format("dd DD.MM.YYYY");
    }
    if (scale === "monthly") {
      return moment(date).format("MMM DD.MM.YYYY");
    }
    if (scale === "yearly") {
      return moment(date).format("MMMM YYYY");
    }
  }

  return "";
}

export function previousInterval(startDate: string, endDate: string, scale: string, preserveTime = false) : {startDate: string, endDate: string} {
  if (scale === "hourly") {
    if (preserveTime) {
      return {
        startDate: moment(startDate).clone().subtract(1, "days").format(DEFAULT_DATETIME_FORMAT),
        endDate: moment(endDate).clone().subtract(1, "days").format(DEFAULT_DATETIME_FORMAT),
      };
    }

    return {
      startDate: moment(startDate).clone().subtract(1, "days").format(DEFAULT_DATE_FORMAT),
      endDate: moment(endDate).clone().subtract(1, "days").format(DEFAULT_DATE_FORMAT),
    };
  }
  if (scale === "daily") {
    return {
      startDate: moment(startDate).clone().subtract(1, "months").startOf("month")
        .format(DEFAULT_DATE_FORMAT),
      endDate: moment(endDate).clone().subtract(1, "months").endOf("month")
        .format(DEFAULT_DATE_FORMAT),
    };
  }
  if (scale === "weekly") {
    return {
      startDate: moment(startDate).clone().startOf("isoWeek").subtract(1, "days")
        .startOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
      endDate: moment(startDate).clone().startOf("isoWeek").subtract(1, "days")
        .endOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
    };
  }
  if (scale === "monthly") {
    return {
      startDate: moment(startDate).clone().startOf("month").subtract(1, "days")
        .startOf("month")
        .format(DEFAULT_DATE_FORMAT),
      endDate: moment(startDate).clone().startOf("month").subtract(1, "days")
        .endOf("month")
        .format(DEFAULT_DATE_FORMAT),
    };
  }
  if (scale === "yearly") {
    return {
      startDate: moment(startDate).clone().startOf("year").subtract(1, "days")
        .startOf("year")
        .format(DEFAULT_DATE_FORMAT),
      endDate: moment(startDate).clone().startOf("year").subtract(1, "days")
        .endOf("year")
        .format(DEFAULT_DATE_FORMAT),
    };
  }

  return {
    startDate: "",
    endDate: "",
  };
}

// eslint-disable-next-line no-bitwise
export const isInteger = (n : any) : boolean => n === +n && n === (n | 0);

// eslint-disable-next-line no-bitwise
export const isFloat = (n : any) : boolean => n === +n && n !== (n | 0);

export const renderData = (data: any) : string => {
  if ((isFloat(data) || isInteger(data))) {
    return (data as number).toFixed(3).replace(".", ",");
  }

  return data;
};

export const roundNumber = (num: number, scale: number): number => {
  if (!(`${num}`).includes("e")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return +(`${Math.round(`${num}e+${scale}`)}e-${scale}`);
  }
  const arr = (`${num}`).split("e");
  let sig = ""
  if (+arr[1] + scale > 0) {
    sig = "+";
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`);
}

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    whiteSpace: "pre-wrap",
  },
  headSection: {
    width: "100%",
    height: "10%",
    color: "white",
    "& .MuiTypography-root":
    {
      fontSize: "1.25rem",
    },
    boxSizing: "border-box",
    padding: "16px 16px 16px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "rgb(51, 179, 195)",
  },
  contentSection: {
    padding: "16px 16px 16px",
    width: "100%",
    height: "calc(100% - 40%)",
    boxSizing: "border-box",

    "& .MuiTypography-root":
    {
      fontSize: "1.15rem",
      color: "black",
    },
    backgroundColor: "white",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    padding: "16px 16px 16px",
    backgroundColor: "white",
  },
  button: {},
  okFuncButton: {},

  dismissBtn: {
    marginLeft: "15px",
  },

  fullScreenModal: {
    width: "100vw",
    height: "90vh",
  },

  fullScreenPaper: {
    width: "95%",
    height: "55%",
  },
}));

import { TabContextValue } from "@mui/lab";
import { observer } from "mobx-react-lite";
import React from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { displayableDate, renderData } from "../../utils/DataUtils";
import useStyles from "./OVChartTooltipStyles";

interface ExtendedTooltipProps<TValue, TName> extends TooltipProps<ValueType, NameType> {
    unit: string;
    legend1?: string;
    legend2?: string;
    formatScale?: string;
}

export default function ({ active, payload, label, unit, legend1, legend2, formatScale }: ExtendedTooltipProps<ValueType, NameType>): JSX.Element {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
        <div className={classes.chartTooltipWrapper}>
            <p className={classes.chartTooltipLabel}>{`${formatScale ? displayableDate(label, formatScale, false) : label}`}</p>
            <p className={classes.chartTooltipLabel}>{`${legend1}: ${renderData(payload[0].value)} ${unit}`}</p>
            {typeof payload[1] !== "undefined" && legend2 !== null && (
                <p className={classes.chartTooltipLabel}>{`${legend2}: ${renderData(payload[1].value)} ${unit}`}</p>
            )}

        </div>
    );
  }

  return <></>;
};

import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./responsivity_globals.css";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import App from "./App";
import { RootStateProvider } from "./RootStateContext";
import { MSALConfig, MSALHandler } from "./authConfig";
import { WhitelabelConfiguration } from "./assets/clientconfiguration/whitelabelConfig";

const { host, hostname, href, origin, pathname, port, protocol, search } = window.location;
WhitelabelConfiguration.setHostName(hostname);
const handlerConfig = WhitelabelConfiguration.getMSALConfig();
const msalHandler = new MSALHandler(handlerConfig);

const msalInstance = new PublicClientApplication(msalHandler.config);
const domNode = document.getElementById("root");
const root = createRoot(domNode!);

root.render((
    <MsalProvider instance={msalInstance}>
        <RootStateProvider>
            <App />
        </RootStateProvider>
    </MsalProvider>
))

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useNavigate, useParams } from "react-router";
import { useWhatChanged } from "@simbathesailor/use-what-changed";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import SingleMeterView from "../SingleMeterView";
import { useRootStore } from "../../../RootStateContext";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";
import { loginRequest } from "../../../authConfig";
import acquireToken from "../../../api/MsalUtil";
import Alert from "../../../models/Alert";
import { CreateSingleMeterReport } from "../../../models/CreateReport";

interface ISingleMeterRootViewMatchParams extends Params<string> {
  meterId: string;
}

export default observer((): JSX.Element => {
  const match = useParams<ISingleMeterRootViewMatchParams>();
  const navigate = useNavigate();
  const { meterStore } = useRootStore();
  const reportsStore = useRootStore().reportStore;
  const { creatingMeterReport } = reportsStore;
  const { meter } = meterStore;
  const [forbidden, setForbidden] = useState(false);
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const alertsStore = useRootStore().alertStore;
  const { unreadCountOfMeter, allAlerts, fetchingAlerts, fetchingAlertCount, totalAlertsInTable } = alertsStore;

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("alertStartedAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const [searchJustActivated, setSearchJustActivated] = useState(false);

  const fetchData = async (userToken: string, meterId: string): Promise<void> => {
    if (searchValue && searchJustActivated) {
      setPage(0);
      setSearchJustActivated(false);
    }
    await meterStore.loadMeter(userToken, meterId);
    await alertsStore.getUnreadAlertCountOfMeter(userToken, meterId);
    await alertsStore.loadAlertsOfMeterPaged(meterId, userToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, searchValue);
  };

  useEffect(() => {
    let mounted = true;
    const { meterId } = match;
    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          await fetchData(response.idToken, meterId!);
        }
      } else {
        await fetchData(currentUserToken, meterId!);
      }
    })();
    return function cleanup() {
      mounted = false;
      alertsStore.clearState();
    };
  }, [page, pageSize, sortBy, sortDirection, archived, searchValue, accounts, instance, match, match.meterId, meterStore]);

  const markAlertAsChecked = async (alert: Alert, comment: string): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);

      if (response) {
        await alertsStore.updateAlert(response.idToken, alert, comment);
        if (alertsStore.error === undefined) {
          toast.info(t("alertView.alertMarkAsRed"), {
            toastId: "AlertCheckedToast",
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await alertsStore.updateAlert(currentUserToken, alert, comment);
      if (alertsStore.error === undefined) {
        toast.info(t("alertView.alertMarkAsRed"), {
          toastId: "AlertCheckedToast",
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  // use this to just create new report to report page (no download)
  const createMeterReport = (createReport: CreateSingleMeterReport): void => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        reportsStore.createSingleMeterReport(response.idToken, createReport);
      }).catch((e) => {
        //
      });
    } else {
      reportsStore.createSingleMeterReport(currentUserToken, createReport);
    }
  };

  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  if (meter === null || meter === undefined) {
    return (
        <PendingCircle />
    );
  }
  return (
      <SingleMeterView
        meterLoading={meterStore.fetchingMeter}
        meter={meter}
        alerts={allAlerts}
        markAlertAsChecked={markAlertAsChecked}
        fetchingAlertCount={fetchingAlertCount}
        fetchingAlerts={fetchingAlerts}
        totalAlertCount={totalAlertsInTable}
        setPage={handlePageChange}
        setPageSize={handlePageSizeChange}
        page={page}
        rowsPerPage={pageSize}
        sort={sort}
        setSortDirection={setSortDirection}
        unreadAlerts={unreadCountOfMeter}
        getReport={createMeterReport}
        creatingReport={creatingMeterReport}
      />
  );
});

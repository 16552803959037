import { AppBar, Button, Container, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Edit } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import Table, { Column } from "../../components/table/TableWithPagination";
import { useWhitelabelTheme } from "../../materialUITheme";
import { Organization } from "../../models/Organization";
import { OrganizationType, UserInfo, UserInfoForCreation, UserInfoForUpdating } from "../../models/UserInfo";
import { useRootStore } from "../../RootStateContext";
import CreateUserModal from "./createUserModal/CreateUserModal";
import CreateOrganizationModal from "./createOrganizationModal/CreateOrganizationModal";
import EditOrganizationModal from "./editOrganizationModal/EditOrganizationModal";
import useStyles from "./OrganizationsAndUsersViewStyles";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import StatCard from "../../components/statCard/StatCard";
import Location from "../../models/Location";
import EditUserModal from "./editUserModal/EditUserModal";
import useResponsivity from "../../hooks/useResponsivity";
import { TabPanel } from "../../components/tabPanel/TabPanel";
import ImportUsersModal from "./importUsersModal/ImportUsersModal";

interface IUsersViewProps {
  organizations: Organization[];
  editableOrganizations: Organization[];
  saveNewOrganization: (organization: Organization) => Promise<void>;
  updateOrganization: (organization: Organization, id: string) => Promise<void>;
  deleteOrganization: () => Promise<void>;
  saveUser: (u: UserInfoForCreation) => Promise<void>;
  updateUser: (user: UserInfoForUpdating, id: string) => Promise<void>;
  removeUser: (user: UserInfo) => Promise<void>;
  users?: UserInfo[];
  // OPTIONS FOR USERS TABLE
  setUserPage: (page: number) => void;
  setUserPageSize: (pageSize: number) => void;
  userSort: (s: string) => void;
  setOrgPage: (page: number) => void;
  setOrgPageSize: (pageSize: number) => void;
  orgSort: (s: string) => void;
  totalUsers: number;
  totalOrgs: number;
  userPage: number;
  userRowsPerPage: number;
  setUserSortDirection: (s: string) => void;
  userSearch: (s: string) => void;
  orgPage: number;
  orgRowsPerPage: number;
  setOrgSortDirection: (s: string) => void;
  orgSearch: (s: string) => void;
  usersLoading: boolean;
  orgsLoading: boolean;
  resetAll: () => void;
  totalUsersForCard: number;
  totalOrganizationsForCard: number;
  fetchingUsers: boolean;
  fetchingOrganizations: boolean;
  activeUsersSearchValue?: string;
  activeOrganizationsSearchValue?: string;
  currentUser?: UserInfo;
  locations: Location[];
  showOrganizations: boolean;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  importUsers: (file: File, organizationId: string) => void;
  updatingUsersInProgress: boolean;
}

export default observer(({ organizations,
  users,
  saveNewOrganization,
  updateOrganization,
  deleteOrganization,
  updateUser,
  saveUser,
  removeUser,
  setUserPage,
  setUserPageSize,
  userSort,
  setOrgPage,
  setOrgPageSize,
  orgSort,
  userPage,
  userRowsPerPage,
  setUserSortDirection,
  userSearch,
  orgPage,
  orgRowsPerPage,
  setOrgSortDirection,
  orgSearch,
  usersLoading,
  orgsLoading,
  currentUser,
  totalUsersForCard,
  totalOrganizationsForCard,
  fetchingOrganizations,
  fetchingUsers,
  resetAll,
  activeUsersSearchValue,
  activeOrganizationsSearchValue, locations, editableOrganizations,
  showOrganizations,
  currentTab,
  setCurrentTab,
  importUsers,
  updatingUsersInProgress }: IUsersViewProps): JSX.Element => {
  const { isMobile } = useResponsivity();
  const { userStore } = useRootStore();
  const { organizationStore } = useRootStore();
  const classes = useStyles();
  const { t } = useTranslation();
  const [userEditModalOpen, setUserEditModalOpen] = useState(false);
  const [orgEditModalOpen, setOrgEditModalOpen] = useState(false);
  const [userCreationModalOpen, setUserCreationModalOpen] = useState(false);
  const [userExcelUploadModalOpen, setUserExcelUploadModalOpen] = useState(false);
  const [orgCreationModalOpen, setOrgCreationModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteOrganizationModalOpen, setDeleteOrganizationModalOpen] = useState(false);
  const navigate = useNavigate();
  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");

  const theme = useWhitelabelTheme();

  const searchInput = useRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<any>, newValue: number): void => {
    setCurrentTab(newValue);
    userStore.resetUserSelection();
    resetAll();
  };

  const closeUserCreationModal = (): void => {
    setUserCreationModalOpen(false);
  };

  const closeImportUsersModal = (): void => {
    setUserExcelUploadModalOpen(false);
  };

  const closeOrgCreationModal = (): void => {
    setOrgCreationModalOpen(false);
  };

  const closeUserEditModal = (): void => {
    setUserEditModalOpen(false);
  };

  const closeOrgEditModal = (): void => {
    setOrgEditModalOpen(false);
  };

  const cellClickedCallbackForUser = (user: UserInfo): void => {
    userStore.setSelectedUser(user);
    setUserEditModalOpen(true);
  };

  const editOrganizationClicked = (org: Organization): void => {
    if (org.organizationType === OrganizationType.OMAVESI_HOUSING || org.organizationType === OrganizationType.OMAVESI_HOUSE_MAINTENANCE || org.organizationType === OrganizationType.OTHER) {
      organizationStore.setSelectedOrganization(org);
      setOrgEditModalOpen(true);
    }
  };

  const editUserClicked = (u: UserInfo): void => {
    userStore.setSelectedUser(u);
    setUserEditModalOpen(true);
  };

  const cellClickedCallbackForOrg = (organization: Organization): void => {
    navigate(`/organization/${organization.id}`);
  };

  const doSearch = (): void => {
    const searchVal = searchInput?.current?.value || "";

    if (searchVal.length <= 2) {
      setSearchErrorState(true);
      setSearchErrorText(t("common.searchErrorText"));
      return;
    }
    setSearchErrorState(false);
    setSearchErrorText("");

    if (searchVal !== undefined && searchInput && searchInput.current) {
      switch (currentTab) {
        case 0:
          userSearch(searchVal);
          break;

        case 1:
          orgSearch(searchVal);
          break;

        default:
          break;
      }
      searchInput.current.value = "";
    }
  };

  // TODO: move store calls to rootview (?)
  const deleteAllSelected = async (): Promise<void> => {
    await userStore.deleteUsers(userStore.selectedUsers);
    if (userStore.error === undefined) {
      toast.info(t("userManagementView.deleteSuccess"), {
        toastId: "UserDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  };

  const organizationColumns: Column[] = [
    {
      id: "name",
      label: "Nimi",
      minWidth: 100,
    },
    {
      id: "organizationType", label: "Tyyppi", minWidth: 170,
    },
    {
      id: "accountId",
      label: "Asiakastunnus",
      minWidth: 170,
    },
  ];

  const userColumns: Column[] = [
    {
      id: "firstName",
      label: "Etunimi",
      minWidth: 170,
    },
    {
      id: "lastName",
      label: "Sukunimi",
      minWidth: 170,
    },
    {
      id: "email",
      label: "Sähköposti",
      minWidth: 170,
    },
    {
      id: "mobilePhone",
      label: "Puhelinnumero",
      minWidth: 170,
    },
    {
      id: "organizationName",
      label: "Organisaatio",
      minWidth: 170,
    },
    {
      id: "userRole",
      label: "Rooli",
      minWidth: 170,
    },
    /*    {
      id: "active",
      label: "Aktiivinen",
      minWidth: 170,
      booleanType: true,
    }, */
  ];

  const resetParams = (): void => {
    resetAll();
    setSearchErrorState(false);
    setSearchErrorText("");
    if (searchInput && searchInput.current) searchInput.current.value = "";
  };

  return (
      <>
          <EditUserModal removeUser={removeUser} handleClose={closeUserEditModal} submit={updateUser} open={userEditModalOpen} showOrganizations={showOrganizations && userStore.selectedUser?.usersOrganizationType !== OrganizationType.WATER_COMPANY} organizations={editableOrganizations} />
          <CreateUserModal handleClose={closeUserCreationModal} submit={saveUser} open={userCreationModalOpen} locations={locations} currentUser={currentUser} showOrganizations={showOrganizations} organizations={editableOrganizations} />
          <ImportUsersModal handleClose={closeImportUsersModal} submit={importUsers} open={userExcelUploadModalOpen} organizations={editableOrganizations} updatingUsersInProgress={updatingUsersInProgress} />
          <CreateOrganizationModal handleClose={closeOrgCreationModal} submit={saveNewOrganization} open={orgCreationModalOpen} currentUser={currentUser} />
          <EditOrganizationModal handleClose={closeOrgEditModal} submit={updateOrganization} open={orgEditModalOpen} currentUser={currentUser} />
          <ConfirmationModal
            open={deleteModalOpen}
            text={t("userManagementView.deleteConfirmation", {
              ids: userStore.selectedUsers.map((e) => `${e.firstName} ${e.lastName}`)
                .join(", "),
            })}
            handleClose={() => setDeleteModalOpen(false)}
            submit={deleteAllSelected}
          />
          <ConfirmationModal
            open={deleteOrganizationModalOpen}
            text={t("userManagementView.confirmOrgDelete")}
            handleClose={() => setDeleteModalOpen(false)}
            submit={deleteOrganization}
          />

          <Container className={`${classes.container} ${isMobile ? classes.topContainerMobile : ""}`}>
              <Grid container>
                  <Grid item container xs={isMobile ? 12 : 3} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Typography variant="h4" className={classes.headerTop}>{t("userManagementView.userManagement")}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                      <div className={classes.searchInputContainer}>
                          {/* Uncomment when fixed  <TextField
                            inputRef={searchInput}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") doSearch();
                            }}
                            className={classes.searchInput}
                            id="usermanagementview-search-input"
                            placeholder={t("userManagementView.searchHelpText")}
                            size="small"
                            variant="outlined"
                            error={searchErrorState}
                            helperText={searchErrorText}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Button id="metersview-search-button" onClick={() => doSearch()} className={classes.searchButton} variant="contained" color="primary">{t("common.search")}</Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            id="usermanagementview-search-clear"
                            onClick={resetParams}
                            className={classes.clearSearchButton}
                          >
                              {t("meterView.defaultSettings")}
                          </Button> */}
                      </div>
                      {(activeOrganizationsSearchValue) && (
                          <Typography className={classes.activeSearchWord} variant="h6">
                              {t("meterView.searchedWithWord", {
                                searchWord: activeOrganizationsSearchValue,
                              })}
                          </Typography>
                      )}
                      {(activeUsersSearchValue) && (
                          <Typography className={classes.activeSearchWord} variant="h6">
                              {t("meterView.searchedWithWord", {
                                searchWord: activeUsersSearchValue,
                              })}
                          </Typography>
                      )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.cardsContainer}
                    justifyContent={isMobile ? "center" : "flex-start"}
                    alignItems="center"
                  >
                      <StatCard
                        icon="Person"
                        loadingState={fetchingUsers}
                        header={totalUsersForCard.toString()}
                        content={t("userManagementView.amountOfUsers")}
                        id="usersview-users-card"
                      />
                      {showOrganizations && (
                          <StatCard
                            className={classes.secondCard}
                            icon="Apartment"
                            loadingState={fetchingOrganizations}
                            header={totalOrganizationsForCard.toString()}
                            content={t("userManagementView.amountOfOrganizations")}
                            id="organizationsview-organizations-card"
                          />
                      ) }

                  </Grid>
              </Grid>
          </Container>

          <Container className={`${classes.container} ${isMobile ? classes.tableContainerMobile : ""}`}>

              {userStore.selectedUsers.length > 0 && (
                  <Button
                    style={{
                      backgroundColor: theme.palette.error.dark,
                      marginLeft: 25,
                      color: "white",
                    }}
                    variant="contained"
                    id="deleteSelectedUsers"
                    onClick={() => setDeleteModalOpen(true)}
                  >
                      {t("userManagementView.deleteSelectedUsers")}
                  </Button>
              )}
              <Container className={`${classes.container} ${isMobile ? classes.tableContainerMobile : ""}`}>
                  <AppBar className={`${classes.tabBar} ${isMobile && classes.tabBarMobile}`} position="static">
                      <Tabs value={currentTab} onChange={handleChange}>
                          <Tab label={t("userManagementView.users")} id="users-tab" aria-controls="users-tab" />
                          {showOrganizations && (
                              <Tab label={t("userManagementView.organizations")} id="organizations-tab" aria-controls="organizations-tab" />
                          )}
                      </Tabs>
                  </AppBar>
                  <TabPanel value={currentTab} index={0}>
                      <Paper className={`${classes.tabContentWrapper} ${isMobile && classes.tabContentWrapperMobile}`}>
                          <Button
                            color="primary"
                            variant="contained"
                            id="AddNewUserButton"
                            className={classes.addButton}
                            onClick={() => setUserCreationModalOpen(true)}
                          >
                              {t("userManagementView.createNewUser")}
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            id="addMultipleUsersButton"
                            className={classes.addButton}
                            onClick={() => setUserExcelUploadModalOpen(true)}
                            style={{
                              marginLeft: "15px",
                            }}
                          >
                              {t("userManagementView.importUsers")}
                          </Button>
                          <Container className={classes.tableContainer}>
                              {fetchingUsers && (<PendingCircle />)}
                              {!fetchingUsers && users && users?.length > 0 && (
                                  <Paper style={{
                            overflowX: "hidden",
                                  }}
                                  >

                                      <Table<UserInfo>
                                        columns={userColumns}
                                        count={userStore.totalUsers}
                                        rows={users || []}
                                        handlePageChange={setUserPage}
                                        handlePageSizeChange={setUserPageSize}
                                        currentPage={userPage}
                                        currentRowsPerPage={userRowsPerPage}
                                        sort={userSort}
                                        setSortDirection={setUserSortDirection}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        cellClickedCallback={() => {} /* cellClickedCallbackForUser */}
                                        toggleRowSelection={userStore.toggleUserSelection}
                                        onClickIcon={<Edit />}
                                        editRow={editUserClicked}
                                      />
                                  </Paper>
                      )}
                              { !fetchingUsers && users && users.length === 0 && (
                                  <div className={classes.noUsers}>
                                      <Typography className={classes.headerContainer} variant="h6" component="h6" id="usersView-searched-with">
                                          {!activeUsersSearchValue ? t("userManagementView.noUsers") : t("userManagementView.noUsersWithSearch", {
                                    searchWord: activeUsersSearchValue,
                                  })}
                                      </Typography>
                                  </div>
                      ) }
                          </Container>
                      </Paper>
                  </TabPanel>
                  {showOrganizations && (
                      <TabPanel value={currentTab} index={1}>
                          <Paper className={`${classes.tabContentWrapper} ${isMobile && classes.tabContentWrapperMobile}`}>
                              <Button
                                className={classes.addButton}
                                color="primary"
                                variant="contained"
                                id="AddNewOrganizationButton"
                                onClick={() => setOrgCreationModalOpen(true)}
                              >
                                  {t("userManagementView.createNewOrganization")}
                              </Button>
                              <Container className={classes.tableContainer}>
                                  {fetchingOrganizations && (<PendingCircle />)}
                                  {!fetchingOrganizations && organizations && organizations?.length > 0 && (
                                      <Paper style={{
                                overflowX: "hidden",
                                      }}
                                      >

                                          <Table<Organization>
                                            columns={organizationColumns}
                                            count={organizationStore.totalOrganizations}
                                            rows={organizations}
                                            handlePageChange={setOrgPage}
                                            handlePageSizeChange={setOrgPageSize}
                                            currentPage={orgPage}
                                            currentRowsPerPage={orgRowsPerPage}
                                            sort={orgSort}
                                            setSortDirection={setOrgSortDirection}
                                            cellClickedCallback={cellClickedCallbackForOrg}
                                            editRow={editOrganizationClicked}
                                          />
                                      </Paper>
                          )}
                                  { !fetchingOrganizations && organizations && organizations.length === 0 && (
                                      <div className={classes.noUsers}>
                                          <Typography className={classes.headerContainer} variant="h6" component="h6" id="orgsView-searched-with">
                                              {!activeOrganizationsSearchValue ? t("userManagementView.noOrgs") : t("userManagementView.noOrganizationsWithSearch", {
                                        searchWord: activeOrganizationsSearchValue,
                                      })}
                                          </Typography>
                                      </div>
                          ) }
                              </Container>
                          </Paper>
                      </TabPanel>
                  )}
              </Container>
          </Container>
      </>
  );
});

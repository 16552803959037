import React, { ChangeEventHandler, useEffect, useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Button,
  Fade,
  TextField,
  Typography,
  Select,
  MenuItem,
  Grid,
  Switch, Tab, Tabs, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

import { truncateSync } from "fs";
import useStyles from "./AutomaticReportGenerationModalStyles";
import { CreateEmailReportSetting, CreateSftpReportSetting, ReportSetting, ReportType } from "../../models/ReportSetting";
import acquireToken from "../../api/MsalUtil";
import { loginRequest } from "../../authConfig";
import { useRootStore } from "../../RootStateContext";
import useResponsivity from "../../hooks/useResponsivity";
import ModalHeader from "../modalComponents/modalHeader";
import { TabPanel } from "../tabPanel/TabPanel";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (d: CreateEmailReportSetting | CreateSftpReportSetting) => void;
}

const sendDelays = [1, 2, 6, 12, 24, 36, 48, 60, 72];

export default function ({ open, handleClose, submit }: IModalProps): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();

  const classes = useStyles();
  const [missingValues, setMissingValues] = useState(false);
  const [missingValuesText, setMissingValuesText] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  // EMAIL CONFIG STATES
  const [sendingActiveState, setSendingActiveState] = useState(true);
  const [titleState, setTitleState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [delayInHoursState, setDelayInHoursState] = useState(1);
  const [reportTypeState, setReportTypeState] = useState<ReportType>("METER_REPORT_XLSX");
  const [delayOnState, setDelayOnStateState] = useState(false);

  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const { reportStore } = useRootStore();
  const { instance, accounts } = useMsal();
  const { reportTypes } = reportStore;

  // SFTP CONFIG STATES
  const [sendingActiveStateSFTP, setSendingActiveStateSFTP] = useState(true);
  const [titleStateSFTP, setTitleStateSFTP] = useState("");
  const [delayInHoursStateSFTP, setDelayInHoursStateSFTP] = useState(1);
  const [reportTypeStateSFTP, setReportTypeStateSFTP] = useState<ReportType>("METER_REPORT_XLSX");
  const [delayOnStateSFTP, setDelayOnStateStateSFTP] = useState(false);
  const [serverState, setServerState] = useState("");
  const [folderState, setFolderState] = useState("");
  const [usernameState, setUsernameState] = useState("");
  const [passwordState, setPasswordState] = useState("");

  // Required states
  const [emailTitleError, setEmailTitleError] = useState(false);
  const [emailEmailError, setEmailEmailError] = useState(false);
  const [emailTypeError, setEmailTypeError] = useState(false);

  const [sftpTitleError, setSftpTitleError] = useState(false);
  const [sftpServerError, setSftpServerError] = useState(false);
  const [sftpFolderError, setSftpFolderError] = useState(false);
  const [sftpUserError, setSftpUserError] = useState(false);
  const [sftpPasswordError, setSftpPasswordError] = useState(false);
  const [sftpTypeError, setSftpTypeError] = useState(false);

  const clear = (): void => {
    // EMAIL RESET
    setSendingActiveState(true);
    setTitleState("");
    setEmailState("");
    setDelayInHoursState(1);
    setReportTypeState("METER_REPORT_XLSX");
    setDelayOnStateState(false);

    // SFTP RESET
    setSendingActiveStateSFTP(true);
    setTitleStateSFTP("");
    setDelayInHoursStateSFTP(1);
    setReportTypeStateSFTP("METER_REPORT_XLSX");
    setDelayOnStateStateSFTP(false);
    setServerState("");
    setFolderState("");
    setUsernameState("");
    setPasswordState("");

    // Validation reset
    setEmailTitleError(false);
    setEmailEmailError(false);
    setEmailTypeError(false);

    setSftpTitleError(false);
    setSftpServerError(false);
    setSftpFolderError(false);
    setSftpUserError(false);
    setSftpPasswordError(false);
    setSftpTypeError(false);

    handleClose();
  };

  const validateFields = (emailOrSftp: "EMAIL" | "SFTP"): boolean => {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let valid = true;

    if (emailOrSftp === "EMAIL") {
      if (titleState.length === 0) { valid = false; setEmailTitleError(true); } else { setEmailTitleError(false); }
      if (!reg.test(emailState)) { valid = false; setEmailEmailError(true); } else { setEmailEmailError(false); }
      if (!reportTypeState) { valid = false; setEmailTypeError(true); } else { setEmailTypeError(false); }
    } else {
      if (titleStateSFTP.length === 0) { valid = false; setSftpTitleError(true); } else { setSftpTitleError(false); }
      if (serverState.length === 0) { valid = false; setSftpServerError(true); } else { setSftpServerError(false); }
      // if (folderState.length === 0) { valid = false; setSftpFolderError(true); } else { setSftpFolderError(false); }
      // if (usernameState.length === 0) { valid = false; setSftpUserError(true); } else { setSftpUserError(false); }
      // if (passwordState.length === 0) { valid = false; setSftpPasswordError(true); } else { setSftpPasswordError(false); }
      if (!reportTypeStateSFTP) { valid = false; setSftpTypeError(true); } else { setSftpTypeError(false); }
    }

    return valid;
  };

  const saveReportSetting = () : void => {
    // EMAIL
    if (activeTab === 0) {
      if (!validateFields("EMAIL")) return;
      const automaticReport: CreateEmailReportSetting = {
        isActive: true,
        title: titleState || "",
        delayOn: delayOnState,
        delayInHours: delayInHoursState,
        reportType: reportTypeState,
        reportTransferType: "EMAIL",
        email: emailState || "",
      };
      submit(automaticReport);
    } else { // SFTP
      if (!validateFields("SFTP")) return;
      const automaticReport: CreateSftpReportSetting = {
        isActive: true,
        title: titleStateSFTP || "",
        delayOn: delayOnState,
        delayInHours: delayInHoursStateSFTP,
        reportType: reportTypeStateSFTP,
        reportTransferType: "SFTP",
        sftpServer: serverState,
        sftpUserName: usernameState,
        sftpPassword: passwordState,
        sftpFolder: folderState,
      };
      submit(automaticReport);
    }

    clear();
  };

  // EMAIL TAB HANDLERS
  const handleDelayOnChange = (): void => {
    setDelayOnStateState((currentDelayState) => {
      if (!currentDelayState && sendingActiveState) return !currentDelayState;
      return false;
    });
  };

  const handleActiveChange = (): void => {
    setSendingActiveState((currentSendingState) => {
      if (currentSendingState) {
        setDelayOnStateState(false);
        return !currentSendingState;
      }
      return !currentSendingState;
    });
  };

  const handleTitleOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setTitleState(event.target.value as string);
  };

  const handleEmailOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setEmailState(event.target.value as string);
  };

  const handleDelayHoursDropdownChange = (event: SelectChangeEvent<number>): void => {
    if (event.target.value) {
      setDelayInHoursState(event.target.value as number);
    }
  };

  const handleReportTypeDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setReportTypeState(event.target.value as string as ReportType);
    }
  };

  // SETTINGS TABS
  const handleSettingsTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) : void => {
    setActiveTab(newValue);
  };

  // SFTP TAB HANDLERS
  const handleDelayOnChangeSFTP = (): void => {
    setDelayOnStateStateSFTP((currentDelayState) => {
      if (!currentDelayState && sendingActiveStateSFTP) return !currentDelayState;
      return false;
    });
  };

  const handleActiveChangeSFTP = (): void => {
    setSendingActiveStateSFTP((currentSendingState) => {
      if (currentSendingState) {
        setDelayOnStateStateSFTP(false);
        return !currentSendingState;
      }
      return !currentSendingState;
    });
  };

  const handleTitleChangeSFTP = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setTitleStateSFTP(event.target.value as string);
  };

  const handleDelayHoursDropdownChangeSFTP = (event: SelectChangeEvent<number>): void => {
    if (event.target.value) {
      setDelayInHoursStateSFTP(event.target.value as number);
    }
  };

  const handleReportTypeDropdownChangeSFTP = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setReportTypeStateSFTP(event.target.value as string as ReportType);
    }
  };

  const handleServerOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setServerState(event.target.value as string);
  };

  const handleFolderOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setFolderState(event.target.value as string);
  };

  const handleUsernameOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setUsernameState(event.target.value as string);
  };

  const handlePasswordOnChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setPasswordState(event.target.value as string);
  };

  /* useEffect(() => {
    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          await reportStore.getCurrentReportSetting(response.idToken);
        }
      } else {
        await reportStore.getCurrentReportSetting(currentUserToken);
      }

      setValues();
    })();
  }, [accounts, instance, reportStore, open]); */

  return ReactDOM.createPortal(<Modal
    className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
              <ModalHeader headerText={isMobile ? t("reportView.autoGenerationTitleShort") : t("reportView.autoGenerationTitle")} clear={clear} id="automaticGenerationTitle" />
              <Tabs onChange={handleSettingsTabChange} value={activeTab}>
                  <Tab label="Sähköposti" />
                  <Tab label="SFTP" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                  <div className={classes.section}>

                      {/* <div className={classes.subsection}>
                                  <Grid container>
                                      <Grid
                                        container
                                        item
                                        xs={6}
                                        className={classes.formLeft}
                                      >
                                          {t("reportView.active")}
                                      </Grid>
                                      <Grid item xs={6}>
                                          <Switch
                                            id="automaticGenerationActiveSwitch"
                                            onClick={() => handleActiveChange()}
                                            checked={sendingActiveState}
                                          />
                                          {sendingActiveState && (<span className={classes.onColour}>{t("reportView.on")}</span>)}
                                          {!sendingActiveState && (<span className={classes.offColour}>{t("reportView.off")}</span>)}
                                      </Grid>
                                  </Grid>
                              </div> */}
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveState ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.title")}
                                  {" "}
                                  *
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationTitleInput"
                                    InputLabelProps={{
                                                shrink: true,
                                    }}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    value={titleState}
                                    onChange={handleTitleOnChange}
                                    disabled={!sendingActiveState}
                                    error={emailTitleError}
                                    helperText={emailTitleError ? "Tämä kenttä on pakollinen" : ""}
                                    FormHelperTextProps={{
 className: classes.helperText,
                                    }}
                                  />
                              </Grid>
                          </Grid>
                      </div>

                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveState ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.email")}
                                  {" "}
                                  *
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationEmailInput"
                                    InputLabelProps={{
                                            shrink: true,
                                    }}
                                    fullWidth
                                    required
                                    type="email"
                                    variant="outlined"
                                    value={emailState}
                                    onChange={handleEmailOnChange}
                                    disabled={!sendingActiveState}
                                    error={emailEmailError}
                                    helperText={emailEmailError ? "Syötä sähköpostiosoite" : ""}
                                    FormHelperTextProps={{
                                              className: classes.helperText,
                                    }}
                                  />
                              </Grid>
                          </Grid>
                      </div>

                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveState ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.reportType")}
                                  {" "}
                                  *
                              </Grid>
                              <Grid item xs={6}>

                                  <Select
                                    labelId="reportTypeLabel"
                                    id="reportTypeLabelId"
                                    value={reportTypeState}
                                    onChange={handleReportTypeDropdownChange}
                                    disabled={!sendingActiveState}
                                    error={emailTypeError}
                                  >
                                      {reportTypes.map((type: string, index) => (
                                          <MenuItem value={type} key={type}>
                                              {t(`reportType.${type}`)}
                                          </MenuItem>
                ))}
                                  </Select>
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={classes.formLeft}
                              >
                                  {t("reportView.delayOn")}
                              </Grid>
                              <Grid item xs={6}>
                                  <Switch
                                    id="delayActiveSwitch"
                                    onClick={() => handleDelayOnChange()}
                                    checked={delayOnState}
                                  />
                                  {delayOnState && (<span className={classes.onColour}>{t("reportView.on")}</span>)}
                                  {!delayOnState && (<span className={classes.offColour}>{t("reportView.off")}</span>)}
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={delayOnState ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.delayInHours")}
                              </Grid>
                              <Grid item xs={6}>
                                  <Select
                                    id="automaticGenerationDelaySelect"
                                    label={t("reportView.hours")}
                                    value={delayInHoursState}
                                    onChange={handleDelayHoursDropdownChange}
                                    disabled={!delayOnState}
                                  >
                                      {sendDelays.map((d) => <MenuItem key={d} value={d}>{d}</MenuItem>)}
                                  </Select>
                              </Grid>
                          </Grid>
                      </div>
                      {/* <div className={classes.subsection}>
                                  {t("reportView.autoGenerationExplanation1")}
                                  <br />
                                  {" "}
                                  {t("reportView.autoGenerationExplanation2")}
                                  <br />
                                  <br />
                                  {t("reportView.autoGenerationExplanation3")}
                              </div> */}
                  </div>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                  <div className={classes.section}>

                      {/* <div className={classes.subsection}>
                                  <Grid container>
                                      <Grid
                                        container
                                        item
                                        xs={6}
                                        className={classes.formLeft}
                                      >
                                          {t("reportView.active")}
                                      </Grid>
                                      <Grid item xs={6}>
                                          <Switch
                                            id="automaticGenerationActiveSwitchSFTP"
                                            onClick={() => handleActiveChangeSFTP()}
                                            checked={sendingActiveStateSFTP}
                                          />
                                          {sendingActiveStateSFTP && (<span className={classes.onColour}>{t("reportView.on")}</span>)}
                                          {!sendingActiveStateSFTP && (<span className={classes.offColour}>{t("reportView.off")}</span>)}
                                      </Grid>
                                  </Grid>
                                              </div> */}
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.title")}
                                  {" "}
                                  *
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationTitleInputSFTP"
                                    InputLabelProps={{
                                              shrink: true,
                                    }}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    value={titleStateSFTP}
                                    onChange={handleTitleChangeSFTP}
                                    disabled={!sendingActiveState}
                                    error={sftpTitleError}
                                    helperText={sftpTitleError ? "Tämä kenttä on pakollinen" : ""}
                                    FormHelperTextProps={{
                                              className: classes.helperText,
                                    }}
                                  />
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.server")}
                                  {" "}
                                  *
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationServerInput"
                                    InputLabelProps={{
                                            shrink: true,
                                    }}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    value={serverState}
                                    onChange={handleServerOnChange}
                                    disabled={!sendingActiveStateSFTP}
                                    error={sftpServerError}
                                    helperText={sftpServerError ? "Tämä kenttä on pakollinen" : ""}
                                    FormHelperTextProps={{
                                              className: classes.helperText,
                                    }}
                                  />
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.folder")}
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationPathInput"
                                    InputLabelProps={{
                                            shrink: true,
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    value={folderState}
                                    onChange={handleFolderOnChange}
                                    disabled={!sendingActiveStateSFTP}
                                  />
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.username")}
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationUsernameInput"
                                    InputLabelProps={{
                                            shrink: true,
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    value={usernameState}
                                    onChange={handleUsernameOnChange}
                                    disabled={!sendingActiveStateSFTP}
                                  />
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.password")}
                              </Grid>
                              <Grid item xs={6}>
                                  <TextField
                                    id="automaticGenerationPasswordInput"
                                    InputLabelProps={{
                                            shrink: true,
                                    }}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    value={passwordState}
                                    onChange={handlePasswordOnChange}
                                    disabled={!sendingActiveStateSFTP}
                                  />
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={sendingActiveStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.reportType")}
                              </Grid>
                              <Grid item xs={6}>

                                  <Select
                                    labelId="reportTypeLabel"
                                    id="reportTypeLabelIdSFTP"
                                    value={reportTypeStateSFTP}
                                    onChange={handleReportTypeDropdownChangeSFTP}
                                    disabled={!sendingActiveStateSFTP}
                                    required
                                  >
                                      {reportTypes.map((type: string, index) => (
                                          <MenuItem value={type} key={type}>
                                              {t(`reportType.${type}`)}
                                          </MenuItem>
                                              ))}
                                  </Select>
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={classes.formLeft}
                              >
                                  {t("reportView.delayOn")}
                              </Grid>
                              <Grid item xs={6}>
                                  <Switch
                                    id="delayActiveSwitch"
                                    onClick={() => handleDelayOnChangeSFTP()}
                                    checked={delayOnStateSFTP}
                                  />
                                  {delayOnStateSFTP && (<span className={classes.onColour}>{t("reportView.on")}</span>)}
                                  {!delayOnStateSFTP && (<span className={classes.offColour}>{t("reportView.off")}</span>)}
                              </Grid>
                          </Grid>
                      </div>
                      <div className={classes.subsection}>
                          <Grid container>
                              <Grid
                                container
                                item
                                xs={6}
                                className={delayOnStateSFTP ? classes.formLeft : classes.formLeft && classes.offColour}
                              >
                                  {t("reportView.delayInHours")}
                              </Grid>
                              <Grid item xs={6}>
                                  <Select
                                    id="automaticGenerationDelaySelect"
                                    label={t("reportView.hours")}
                                    value={delayInHoursStateSFTP}
                                    onChange={handleDelayHoursDropdownChangeSFTP}
                                    disabled={!delayOnStateSFTP}
                                  >
                                      {sendDelays.map((d) => <MenuItem key={d} value={d}>{d}</MenuItem>)}
                                  </Select>
                              </Grid>
                          </Grid>
                      </div>
                      {/* <div className={classes.subsection}>
                                  {t("reportView.autoGenerationExplanation1")}
                                  <br />
                                  {" "}
                                  {t("reportView.autoGenerationExplanation2")}
                                  <br />
                                  <br />
                                  { t("reportView.autoGenerationExplanation3") }
                              </div> */}
                  </div>
              </TabPanel>

              <div className={classes.navigation}>
                  <Grid container item xs={12}>
                      <Grid container item xs={12} justifyContent="flex-end">
                          {missingValues && (
                              <Typography
                                style={{
                                          display: "block",
                                }}
                                variant="h6"
                                component="span"
                                className={classes.mandatoryError}
                                id="newlocationmodal-mandatoryerror"
                              >
                                  {missingValuesText}
                              </Typography>
                                  )}
                      </Grid>
                      <Grid container item xs={12} justifyContent="flex-end">
                          <Button
                            onClick={() => clear()}
                            variant="contained"
                            color="secondary"
                            id="automaticgenerationmodal-cancel"
                            className={classes.cancelButton}
                          >
                              {t("common.cancel")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={saveReportSetting}
                            id="automaticgenerationmodal-save"
                          >
                              {t("reportView.saveGenerationSettings")}
                          </Button>
                      </Grid>
                  </Grid>
              </div>
          </div>
      </Fade>
                               </Modal>, ModalPortalTarget);
};

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { drawerWidthOpen } from "../navigationBar/NavigationBarStyles";

export default makeStyles((theme: Theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: { },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  nameAndUserMenuContainer: {
    display: "block",
  },
  contents: {
    height: "64px",
    justifyContent: "flex-end",
  },
  orgAndUserNameContainer: {
    display: "grid",
  },
  userNameUnderOrganization: {
    fontSize: "small !important",
  },
  languageSelector: {
    marginRight: "20px",
  },
  selectedLanguage: {
    color: "white",
    backgroundColor: theme.palette.primary.light,
  },
  languageButton: {
    color: "white",
  },
  logout: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "center",
    height: "40%",
    color: "white",
  },
  user: {
    display: "flex",
    flexDirection: "column",
  },
  userName: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "center",
    height: "60%",
  },
  omavesiLogoContainer: {
    width: "auto",
    height: "50%",
    marginRight: "auto",

    "@media (max-width:370px)": {
      display: "none",
    },
  },

  omavesiLogo: {
    width: "auto",
    height: "100%",
  },

  logoWhenMobile: {
    marginLeft: "0px",
  },

  logoWhenDrawerOpen: {
    marginLeft: "297px",
  },

  logoWhenDrawerClosed: {
    marginLeft: "70px",
  },
}));

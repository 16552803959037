import React from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./ConfirmationModalStyle";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  submit: () => void;
  text: string;
}

export default function ({ open, handleClose, submit, text }: IModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmit = (): void => {
    handleClose();
    submit();
  };

  return ReactDOM.createPortal(<Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
              timeout: 500,
    }}
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <div className={classes.section}>
                  <Typography variant="h6" component="h2" className={classes.modalText}>{text}</Typography>
              </div>
              <div className={classes.buttons}>
                  <Button
                    className={classes.deleteButton}
                    onClick={handleSubmit}
                    variant="contained"
                    color="warning"
                  >
                      {t("common.yes")}
                  </Button>
                  <Button
                    disableElevation
                    onClick={handleClose}
                    color="primary"
                    variant="contained"
                  >
                      {t("common.no")}
                  </Button>
              </div>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
};

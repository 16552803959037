/* eslint-disable react/destructuring-assignment */
import { Typography, Box } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeterMarker } from "../../../models/Map";
import { MapMeter } from "../../../models/WaterMeter";
import useStyles from "./MarkerInfoWindowStyles";

interface IMarkerInfoWindowProps
{
    markerData?: MeterMarker;
    lat?: number;
    lng?: number;
    selectedOptionId?: string | undefined;
}

export default function ({ markerData, lat, lng, selectedOptionId }:IMarkerInfoWindowProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedMeterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    selectedMeterRef.current?.scrollIntoView({
        behavior: "smooth", block: "center",
    });
  }, [selectedOptionId]);

  return (
      <div
        className={classes.infoWindow}
        style={{
          display: markerData ? "block" : "none",
        }}
      >
          <div className={`${classes.infoWindowHeaderSection} ${classes.infoHeader}`}>
              <Typography className={classes.infoHeaderBold}>{markerData?.address}</Typography>
          </div>

          <div className={classes.meterList}>
              {markerData !== undefined && markerData.meters.map((meter) => (
                  <div
                    key={meter.name}
                    className={`
                    ${classes.infoWindowSection} 
                    ${classes.infoWindowSectionSmall} 
                    ${selectedOptionId && selectedOptionId === meter.meterId ? classes.selected : ""}`}
                    ref={selectedOptionId && selectedOptionId === meter.meterId ? selectedMeterRef : undefined}
                  >
                      <Typography>
                          <Box component="span" className={classes.meterNumberText}>{meter.name}</Box>
                          {" "}
                          {`${meter.type ? t(`meterType.${meter.type}`) : ""}`}
                      </Typography>
                  </div>
              ))}
          </div>

      </div>
  );
};

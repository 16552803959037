import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { Typography } from "@mui/material";
import MetersTable, { Column } from "../../../../components/table/TableWithPagination";
import { WaterMeter } from "../../../../models/WaterMeter";
import PendingCircle from "../../../../components/PendingCircle/PendingCircle";
import useStyles from "../LocationManagementComponentsStyles";

interface ILocationManagementViewProps {
  metersOfLocation?: WaterMeter[];
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalMeters: number;
  page: number;
  rowsPerPage: number;
  fetchingMeters: boolean;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  setArchived: (a: boolean) => void;
  search: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
}

export default function ({ metersOfLocation, setPage, setPageSize, totalMeters,
  page, rowsPerPage, fetchingMeters,
  sort, setSortDirection, setArchived, search,
  setSearchJustActivated, resetAllOptions,
  activeSearchValue }: ILocationManagementViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const classes = useStyles();

  const columns: Column[] = [
    {
      id: "meterNumber", label: t("waterMeter.meterNumber"), minWidth: 170,
    },
    {
      id: "address",
      label: t("waterMeter.address"),
      minWidth: 170,
    },
    {
      id: "devEUI",
      label: t("waterMeter.devEUI"),
      minWidth: 170,
    },
    {
      id: "type",
      label: t("waterMeter.type"),
      minWidth: 170,
    },
  ];

  const cellClickedCallback = (waterMeter: WaterMeter): void => {
    navigate(`/meter/${waterMeter.id}`);
  };

  if (fetchingMeters) {
    return (
        <PendingCircle />
    );
  }

  if (!fetchingMeters && (!metersOfLocation || metersOfLocation.length === 0)) {
    return (
        <div className={classes.noData}><Typography className={classes.headerContainer} variant="h5" component="h5">{t("locationView.noMeters")}</Typography></div>
    );
  }

  return (
      <MetersTable<WaterMeter>
        columns={columns}
        rows={metersOfLocation || []}
        handlePageChange={setPage}
        handlePageSizeChange={setPageSize}
        count={totalMeters}
        currentPage={page}
        currentRowsPerPage={25}
        sort={sort}
        setSortDirection={setSortDirection}
      />

  );
};

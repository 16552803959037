import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "35%",
    "& .MuiStepConnector-line": {
      marginLeft: 15,
    },
  },
  container: {
    padding: "15px 20px",
    backgroundColor: "white",
  },
  modalHeader: {
    backgroundColor: blue[100],
  },
  sectionHeader: {
    padding: theme.spacing(2, 2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
    },
  },
  okButton: {},
  backButton: {
    float: "left",
  },
  nextButton: {
    float: "right",
  },
  navigation: {
    /* padding: theme.spacing(6, 6, 6), */
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
    height: "60px",
  },
  navButtons: {
    display: "block",
    marginBottom: "15%",
  },
  modalButtons: {
    textAlign: "end",
    display: "flex",
    gap: "15px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "100%",
  },
  subsection: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2, 2),
    display: "flex",
  },
  titleColumn: {
    padding: "5px 0",
    fontWeight: "bold",
  },
  valueColumn: {
    padding: "5px 0",
  },

  fullScreenModal: {
    width: "100vw",
    height: "90vh",
  },

  fullScreenPaper: {
    width: "95%",
    height: "55%",
  },
}));

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    minWidth: "20%",
    width: "20%",
    "& .MuiStepConnector-line": {
      marginLeft: 15,
    },
  },
  modalHeader: {
    backgroundColor: blue[100],
  },
  sectionHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    "& .MuiTypography-h6": {
      color: theme.palette.primary.contrastText,
      lineHeight: "60px",
      marginLeft: "15px",
    },
    height: "60px",
  },
  section: {
    backgroundColor: "white",
  },
  input: {
    width: "100%",
  },
  backButton: {
    float: "left",
  },
  nextButton: {
    float: "right",
  },
  navigation: {
    padding: "10px",
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
  },
  navButtons: {
    display: "block",
    marginBottom: "15%",
  },
  modalButtons: {
    textAlign: "end",
  },
  okButton: {
    marginRight: 10,
  },
  cancelButton: {
    marginRight: 10,
  },
  onColour: {
    color: theme.palette.primary.light,
  },
  offColour: {
    color: theme.palette.grey.A400,
    alignItems: "center",
  },
  subsection: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "10px 16px 10px 16px",
    display: "flex",
  },
  subsection2: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "5px 16px 5px 16px",
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  formControlWrapper:
  {
    width: "100%",
  },
  mandatoryError:
  {
    color: "#EE145B",
    marginBottom: "10px",
  },
  formLeft:
  {
    justifyItems: "flex-start",
    alignItems: "center",
    fontWeight: "bold",
  },

  fullScreenModal: {
    width: "100vw",
    height: "90vh",
  },

  fullScreenPaper: {
    width: "95%",
    height: "80%",
  },

  closeIcon: {
    position: "absolute",
    left: "calc(100% - 65px)",
    width: "40px !important",
    height: "40px !important",
    color: "white",
    transform: "translateY(10px)",
  },

  helperText: {
    marginLeft: 0,
  },
}));

import { Container, Typography } from "@mui/material";
import React from "react";
import useStyles from "./UnauthorizedComponentStyles";

export default function (): JSX.Element {
  const classes = useStyles();
  return (
      <Container className={classes.container}>
          <Typography className={classes.headerContainer} variant="h4" component="span">Ei pääsyoikeutta</Typography>
      </Container>
  );
};

import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  TextField,
  Typography,
  FormLabel,
  Select,
  MenuItem, Stepper, Step, StepLabel, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

import { observer } from "mobx-react-lite";
import useStyles from "./CreateOrganizationModalStyles";
import "./createOrganizationModal.css";
import { OrganizationForCreation } from "../../../models/Organization";
import { useRootStore } from "../../../RootStateContext";
import { UserInfo } from "../../../models/UserInfo";
import useResponsivity from "../../../hooks/useResponsivity";
import ModalHeader from "../../../components/modalComponents/modalHeader";
import ModalPortalTarget from "../../../stores/ModalPortalTarget";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  submit: (u: any) => void;
  currentUser?: UserInfo;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

interface OrganizationType {
  id: number;
  type: string;
}

const OrganizationTypes: OrganizationType[] = [
  {
    id: 1,
    type: "OMAVESI_HOUSING",
  },
  {
    id: 2,
    type: "OMAVESI_HOUSE_MAINTENANCE",
  },
  {
    id: 3,
    type: "OTHER",
  },
];

export default observer(({ open,
  handleClose,
  submit,
  currentUser }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { serviceLevels } = useRootStore().organizationStore;
  const [activeStep, setActiveStep] = useState(0);
  const steps = currentUser?.usersOrganizationType === "RETAILER" ? [t("userManagementView.organizationCreationFirstStep"), t("userManagementView.organizationCreationSecondStep")]
    : [t("userManagementView.organizationCreationFirstStep")];
  const [organizationType, setOrganizationType] = useState<string>("");

  const [orgNameErrorState, setOrgNameErrorState] = useState<boolean>(false);
  const [orgNameErrorText, setOrgNameErrorText] = useState<string>("");

  const [orgTypeErrorState, setOrgTypeErrorState] = useState<boolean>(false);
  const [orgTypeErrorText, setOrgTypeErrorText] = useState<string>("");

  const [organizationLevelState, setOrganizationLevelState] = useState<string>("");

  const [orgNameState, setOrgNameState] = useState<string>("");
  const [orgAccountState, setOrgAccountState] = useState<string>("");

  const { isMobile } = useResponsivity();

  const close = (): void => {
    setOrgNameState("");
    setOrgAccountState("");
    setActiveStep(0);

    setOrgNameErrorState(false);
    setOrgNameErrorText("");
    setOrganizationLevelState("");
    setOrganizationType("");
    handleClose();
  };

  const saveOrganization = (): void => {
    let accountId = "";
    let orgName = "";

    if (orgAccountState) accountId = orgAccountState;
    if (orgNameState) orgName = orgNameState;

    if (organizationType === "") {
      setOrgTypeErrorState(true);
      setOrgTypeErrorText(t("userManagementView.orgTypeRequiredError"));
    } else {
      const orgData: OrganizationForCreation = {
        name: orgName,
        organizationType,
        accountId,
        applicationAccess: organizationLevelState !== "" ? organizationLevelState : undefined,
      };

      submit(orgData);
      close();
    }
  };

  const handleOrganizationTypeChange = (e: SelectChangeEvent<string>): void => {
    if (e.target.value) {
      setOrganizationType(e.target.value as string);
    }
  };

  const handleOrganizationLevelChange = (e: SelectChangeEvent<string>): void => {
    setOrganizationLevelState(e.target.value as string);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = async (): Promise<void> => {
    if (activeStep === 0) {
      let localOrgNameError = false;

      if (orgNameState === "") {
        localOrgNameError = true;
        setOrgNameErrorState(true);
        setOrgNameErrorText(t("userManagementView.orgNameRequiredError"));
      } else {
        localOrgNameError = false;
        setOrgNameErrorState(false);
        setOrgNameErrorText("");
      }

      if (localOrgNameError) {
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isNextButtonDisabled = (): boolean => false;

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("userManagementView.basicInfo")}
                </FormLabel>
                <div
                  className={classes.subsection}
                  style={{
                    marginTop: "16px",
                  }}
                >
                    <TextField
                      id="orgNameInput"
                      label={t("userManagementView.orgName")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={orgNameErrorState}
                      helperText={orgNameErrorText}
                      variant="outlined"
                      fullWidth
                      value={orgNameState}
                      onChange={(e) => setOrgNameState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <TextField
                      id="orgAccountIdInput"
                      label={t("userManagementView.orgAccountId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={false}
                      helperText=""
                      variant="outlined"
                      fullWidth
                      value={orgAccountState}
                      onChange={(e) => setOrgAccountState(e.target.value)}
                    />
                </div>
                <div className={classes.subsection}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="orgTypeSelectLabel" shrink>{t("userManagementView.orgType")}</InputLabel>
                        <Select
                          labelId="orgTypeSelectLabel"
                          id="orgTypeSelectInput"
                          value={organizationType}
                          onChange={handleOrganizationTypeChange}
                          displayEmpty
                          error={orgTypeErrorState}
                        >
                            <MenuItem value="" key="orgtype-null">{t("userManagementView.chooseOrgType")}</MenuItem>
                            {OrganizationTypes.map((orgType) => (
                                <MenuItem key={orgType.id} value={orgType.type}>{t(`organizationType.${orgType.type}`)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {/* <div className={classes.infoTextContainer}>
                    <div className={classes.infoText}>{t("userManagementView.housingOrganizationHasPrices")}</div>
                            </div> */}
            </>
        );
      case 1:
        return (
            <>
                <FormLabel className={classes.genFormLabel2}>
                    {t("userManagementView.omavesiProductHeader")}
                </FormLabel>
                <div className={classes.subsection}>
                    <Select
                      key="select"
                      labelId="organizationLevelSelect"
                      id="organizationLevelSelect"
                      value={organizationLevelState}
                      onChange={handleOrganizationLevelChange}
                      displayEmpty
                    >
                        <MenuItem value="" key="product-null">{t("userManagementView.noProduct")}</MenuItem>
                        {serviceLevels.map((level) => (
                            <MenuItem key={level} value={level}>{t(`userManagementView.${level}_text`)}</MenuItem>

                        ))}
                    </Select>
                </div>
            </>
        );
      default:
        return <></>;
    }
  };

  return ReactDOM.createPortal(<Modal
    className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
    open={open}
    onClose={close}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
              <ModalHeader headerText={t("userManagementView.addOrg")} clear={close} id="addOrgTitle" />
              {getStepContent(activeStep)}
              <div className={classes.navigation}>

                  <div className={classes.navButtons}>
                      {steps.length > 1 && (
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                          >
                              {t("userManagementView.orgBack")}
                          </Button>
                              )}
                      {steps.length <= 1 && (
                          <Button
                            variant="contained"
                            id="closeCreateUserModal"
                            className={classes.backButton}
                            color="secondary"
                            onClick={close}
                          >
                              {t("userManagementView.cancel")}
                          </Button>
                              )}

                      <Button
                        variant="contained"
                        disabled={isNextButtonDisabled()}
                        className={classes.nextButton}
                        color="primary"
                        onClick={activeStep === steps.length - 1 ? saveOrganization : handleNext}
                      >
                          {activeStep === steps.length - 1 ? t("userManagementView.orgFinish") : t("userManagementView.orgNext")}
                      </Button>
                  </div>
                  {steps.length > 1 && (
                      <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                              <Step key={label}>
                                  <StepLabel>{label}</StepLabel>
                              </Step>
                                  ))}
                      </Stepper>
                          )}
              </div>
          </div>
      </Fade>
                               </Modal>, ModalPortalTarget);
});

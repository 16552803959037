import React, { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useStyles from "./MarkerStyles";

// These are somewhat magic props, especially $hover
// We never explicitly give it to these Marker components, but instead GoogleMapReact gives it to them implicitly
// when we include these components inside <GoogleMapReact></GoogleMapReact>
// https://github.com/google-map-react/google-map-react/blob/master/API.md#child-component-api
// https://github.com/google-map-react/google-map-react
interface IMarkerProps {
    lat: number;
    lng: number;
    text?: string;
    $hover?: boolean;
    data?: object;
    id?: string;
    selectedMarkerId?: string | undefined;
}

export default function ({ lat, lng, text, id, $hover, data, selectedMarkerId } : IMarkerProps): JSX.Element {
  const classes = useStyles();

  return (
      <LocationOnIcon
        className={classes.marker}
        style={{
          cursor: $hover ? "pointer" : "grab",
          color: selectedMarkerId && selectedMarkerId === id ? "#EE145B" : "",
        }}
        fontSize="large"
      />
  );
};

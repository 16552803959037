import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import GetApp from "@mui/icons-material/GetApp";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { Button, TableSortLabel } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import useStyles from "./TableStyles";
import { ReportRow } from "../../models/Report";
import useResponsivity from "../../hooks/useResponsivity";

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (t: any) => string;
  booleanType?: boolean;
  nestedInside?: Record<string, unknown>;
  disableSort?: boolean;
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
  },
}))(TableRow);

type Generic = {
  [key: string]: unknown
}

interface ITableProps<T extends Generic> {
  columns: Column[];
  rows: T[];
  deleteRow?: (s: T) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (size: number) => void;
  count: number;
  currentPage: number;
  currentRowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void
}

interface IRowProps<T extends Generic> {
  row: T;
  columns: Column[];
  deleteRow?: (s: T) => void;
  rowIndex: string;
}

const generateId = (prefix : string, id: string) : string => `${prefix}-${id}`;

const renderSettingCell = (column : Column, row: any, value : any, t : any) : JSX.Element => {
  const v = value === null || value === undefined ? "-" : value;

  if (column.id === "reportType") {
    return <span>{t(`reportType.${value}`)}</span>;
  }

  if (!column.format) return <span>{v as string}</span>;
  return <span>{column.format(v)}</span>;
};

const responsivityFilter = (f: Column, isMobile: boolean) : boolean => {
  const responsiveShow = [
    "title",
    "reportTransferType",
    "reportType",
  ];

  return !isMobile || (isMobile && responsiveShow.includes(f.id));
};

function RowElement<T extends Generic>({ row, columns, deleteRow, rowIndex }: IRowProps<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();
  return (
      <StyledTableRow
        className={classes.root}
        hover
        role="checkbox"
        tabIndex={-1}
        key={Object.keys(row)[0]}
      >
          {columns.filter((f) => responsivityFilter(f, isMobile)).map((column) => {
                const value = row[column.id];
                return (
                    <TableCell
                      component="th"
                      scope="row"
                      key={column.id}
                      align={column.align}
                      className={`${classes.borderBottom}`}
                    >
                        {renderSettingCell(column, row, value, t)}
                    </TableCell>
                );
              })}
          {!isMobile && (
              <TableCell
                className={`
                        ${classes.borderBottom} 
                        ${classes.editButton}`}
                style={{
                  padding: "0",
                }}
              >
                  {deleteRow && (
                      <Button
                        className={classes.downloadButton}
                        onClick={() => deleteRow(row)}
                        role="presentation"
                        id={generateId("setting-delete-button", rowIndex)}
                      >
                          <DeleteForever style={{
                                  verticalAlign: "middle",
                          }}
                          />
                          {" "}
                          {t("common.delete")}
                      </Button>

                        )}
              </TableCell>
)}
      </StyledTableRow>
  );
}

export default function<T extends Generic> ({ columns, rows, deleteRow, handlePageChange, handlePageSizeChange, count, currentPage, currentRowsPerPage, sort, setSortDirection }: ITableProps<T>): JSX.Element {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const [currentSortedBy, setCurrentSortedBy] = useState("");
  const [asc, setAsc] = useState(false);
  const { t } = useTranslation();

  const setSort = (field: string): void => {
    if (currentSortedBy === field) {
      const newDirection = asc ? "desc" : "asc";
      setSortDirection(newDirection);
      setAsc(!asc);
    } else {
      setAsc(false);
      setSortDirection("desc");
      setCurrentSortedBy(field);
      sort(field);
    }
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handlePageSizeChange(+event.target.value);
  };

  return (
      <Paper className={`${classes.root} ${isMobile ? classes.rootMobile : ""}`}>
          <TableContainer className={`${classes.tableContainer} ${isMobile ? classes.tableContainerMobile : ""}`}>
              <Table aria-label="collapsible table" size="small">
                  <TableHead>
                      <TableRow className={classes.root}>
                          {columns.filter((f) => responsivityFilter(f, isMobile)).map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: "bold",
                                }}
                              >
                                  {column.disableSort
                                    ? <>{column.label}</>
                                    : (
                                        <TableSortLabel
                                          className={classes.sortLabel}
                                          active={currentSortedBy === column.id}
                                          onClick={() => setSort(column.id)}
                                          direction={asc ? "asc" : "desc"}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) }
                              </TableCell>
                          ))}
                          {!isMobile && <TableCell />}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      { rows.length > 0 && rows.map((row, idx) => (
                          <RowElement deleteRow={deleteRow} columns={columns} row={row} key={JSON.stringify(row)} rowIndex={idx.toString()} />
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
          {/* rows.length === 0 && <PendingCircle customStyles={classes.spinner} /> */}
          <TablePagination
            labelRowsPerPage={t("table.rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} (${count})`}
            rowsPerPageOptions={[25, 100]}
            component="div"
            count={count !== undefined ? count : rows.length}
            rowsPerPage={currentRowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
  );
};

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Fade, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import { useMsal } from "@azure/msal-react";

import PushPinIcon from "@mui/icons-material/PushPin";
import { toast } from "react-toastify";
import useStyles from "./AlertMapModalStyles";
import { AlertMarker } from "../../../models/Map";
import Marker from "../mapModules/Marker";
import { boundsToViewport, distanceToMouse, getMapOptions } from "../../../utils/MapUtils";
import MarkerInfoWindow from "../mapModules/AlertMarkerInfoWindow";
import { useRootStore } from "../../../RootStateContext";
import { uuid } from "../../../utils/BasicUtils";
import { loginRequest } from "../../../authConfig";
import acquireToken, { tokenAction } from "../../../api/MsalUtil";
import ModalPortalTarget from "../../../stores/ModalPortalTarget";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    markers: AlertMarker[];
}

const defaultZoom = 9;
const defaultLat = 60.212145;
const defaultLng = 24.914675;

export default function ({ open, handleClose, markers }: IModalProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const { instance, accounts } = useMsal();
  const { mapStore, authenticationStore } = useRootStore();
  const mapMarkers = mapStore.mapAlerts;
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const [pendingDataFetches, setPendingDataFetches] = useState<string[]>([]);

  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>(undefined);
  const [drawnMarkers, setDrawnMakers] = useState<AlertMarker[]>([]);
  const [activeMarker, setActiveMarker] = useState<AlertMarker | undefined>(undefined);
  const [currentViewport, setCurrentViewport] = useState<google.maps.LatLngBounds | undefined>(undefined);
  const [firstLoadDone, setFirstLoadDone] = useState<boolean>(false);

  const [zoom, setZoom] = useState<number | undefined>(authenticationStore?.currentUser?.defaultMapView?.zoomLevel || defaultZoom);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: authenticationStore?.currentUser?.defaultMapView?.location.coordinates[1] || defaultLat,
    lng: authenticationStore?.currentUser?.defaultMapView?.location.coordinates[0] || defaultLng,
  });

  const clear = (): void => {
    setFirstLoadDone(false);
    mapStore.clearState();
    setActiveMarker(undefined);
    // setNeedToSetTargetAfterFetch(false);
    // openToTargetSetter(false);
    setZoom(authenticationStore?.currentUser?.defaultMapView?.zoomLevel || defaultZoom);
    setCenter({
      lat: authenticationStore?.currentUser?.defaultMapView?.location.coordinates[1] || defaultLat,
      lng: authenticationStore?.currentUser?.defaultMapView?.location.coordinates[0] || defaultLng,
    });
    handleClose();
  };

  const handleApiLoaded = (map : google.maps.Map) : void => {
    setMapRef(map);
    setIsApiLoaded(true);
  };

  const handleMarkerClick = (hoverKey: any, childProps: any) : void => {
    setActiveMarker(mapMarkers.find((m) => m.address === hoverKey));
  };

  const handleMapClick = () : void => {
    setActiveMarker(undefined);
  };

  const handleViewportChange = (map : google.maps.Map) : void => {
    if (!mapRef) return;
    setCurrentViewport(mapRef.getBounds() || undefined);
    setZoom(mapRef.getZoom());
  };

  const handleTilesLoaded = () : void => {
    if (!firstLoadDone) {
      setFirstLoadDone(true);

      if (!mapRef) return;
      setCurrentViewport(mapRef.getBounds() || undefined);
      setZoom(mapRef.getZoom());
    }
  };

  const setDefaultLocation = async () : Promise<void> => {
    const center = mapRef?.getCenter();
    const location = {
      lat: center?.lat(),
      lng: center?.lng(),
    };
    const zoom = mapRef?.getZoom();
    const orgId = authenticationStore.currentUser?.organizationId;

    if (location && zoom && orgId) {
      (async () => {
        await tokenAction(async (idToken) => {
          setCurrentUserToken(idToken);
          await authenticationStore.setDefaultMapLocation(idToken, orgId, location, zoom);

          toast.info(t("googleMap.defaultLocationSet"), {
            toastId: "MapDefaultLocationSet",
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }, currentUserToken, accounts, instance);
      })();
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const id = uuid();

    if (zoom && zoom > 8) {
      (async () => {
        if (currentViewport) {
          setPendingDataFetches((fetches) => [...fetches, id]);
          const viewport = boundsToViewport(currentViewport);

          if (!currentUserToken) {
            const request = {
              ...loginRequest,
              account: accounts[0],
            };
            const response = await acquireToken(instance, request);

            if (response) {
              setCurrentUserToken(response.idToken);
              await mapStore.loadMapAlerts(response.idToken, viewport, signal);
              setPendingDataFetches((fetches) => fetches.filter((f) => f !== id));
            }
          } else {
            await mapStore.loadMapAlerts(currentUserToken, viewport, signal);
            setPendingDataFetches((fetches) => fetches.filter((f) => f !== id));
          }
        }

        setActiveMarker(undefined);
      })();
    }

    return () => {
      controller.abort();
      setPendingDataFetches((fetches) => fetches.filter((f) => f !== id));
    };
  }, [accounts, currentViewport, instance, mapStore]);

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={`${classes.paper} ${pendingDataFetches.length > 0 ? classes.illegalDeepSubElementHoverHack : ""}`}>
              {pendingDataFetches.length > 0 && (
                  <div className={classes.mapLoadingIndicatorWrapper}>
                      <Typography>{t("meterView.mapDataLoadingText")}</Typography>
                  </div>
                      )}

              <IconButton
                className={`${classes.mapActionButton} ${classes.setDefaultLocationButton}`}
                onClick={setDefaultLocation}
                size="large"
                title={t("googleMap.setDefaultLocation")}
              >
                  <PushPinIcon />
              </IconButton>

              <GoogleMapReact
                bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
                          libraries: ["geometry"],
                }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals // This has to be here, if one wants to use onGoogleApiLoaded or google.maps.anything
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map)}
                distanceToMouse={distanceToMouse} // Have to use custom mouse distance function, the native one is awful
                options={getMapOptions()} // Map options is a huge object, so it has been "hidden" away
                onChildClick={handleMarkerClick}
                onClick={handleMapClick}
                onDragEnd={handleViewportChange}
                onZoomAnimationEnd={handleViewportChange}
                onTilesLoaded={handleTilesLoaded}
              >
                  {/* Every component put in here gets certain props automagically https://github.com/google-map-react/google-map-react/blob/master/API.md#child-component-api */}
                  {mapMarkers.map((marker, i) => (
                      <Marker
                        key={marker.address}
                        lat={marker.coordinates[1]}
                        lng={marker.coordinates[0]}
                      />
                          ))}

                  <MarkerInfoWindow markerData={activeMarker} lat={activeMarker?.coordinates[1]} lng={activeMarker?.coordinates[0]} />
              </GoogleMapReact>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
};

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Grid, Paper, Typography, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import "@fontsource/roboto";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { useMsal } from "@azure/msal-react";

import StatCard from "../../components/statCard/StatCard";
import { useRootStore } from "../../RootStateContext";
import OVChartTooltip from "../../components/charts/OVChartTooltip";
import { GraphTimeModel } from "../../models/GraphTimeModel";
import { getActiveDateIntervalPrevious, getDatePickerValue, getScaleDescription, getScaleForMoment } from "../../utils/GraphUtils";
import useStyles from "./HomePageStyles";
import useResponsiveStyles from "./HomePageStylesResp";
import acquireToken from "../../api/MsalUtil";
import { loginRequest } from "../../authConfig";
import { OrganizationType, UserInfo } from "../../models/UserInfo";
import { UserRole } from "../../models/UserRole";
import { renderData, DEFAULT_DATE_FORMAT, displayableDate, DEFAULT_DATETIME_FORMAT } from "../../utils/DataUtils";
import { WaterPrice } from "../../models/Pricing";
import useResponsivity from "../../hooks/useResponsivity";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import { useWhitelabelTheme } from "../../materialUITheme";

interface IMeterConfigViewProps {
  totalLocationsForCard: number;
  totalAlertsForCard: number;
  totalMetersForCard: number;
  fetchingMeterCount: boolean;
  fetchingAlertCount: boolean;
  fetchingLocationsCount: boolean;
  currentUser: UserInfo;
}

export default observer(({ totalMetersForCard, totalAlertsForCard, totalLocationsForCard, fetchingAlertCount, fetchingLocationsCount, fetchingMeterCount, currentUser }: IMeterConfigViewProps): JSX.Element => {
  const { isMobile } = useResponsivity();
  const classes = !isMobile ? useStyles() : useResponsiveStyles();
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const { dataStore, authenticationStore } = useRootStore();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [formatScale, setFormatScale] = useState<string>("weekly");
  const [scale, setScale] = useState<string>("daily");

  const theme = useWhitelabelTheme();

  const [graphTime, setGraphTime] = useState<GraphTimeModel>({
    timeBase: moment(),
    get day_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("day").format(DEFAULT_DATETIME_FORMAT); },
    get day_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("day").format(DEFAULT_DATETIME_FORMAT); },
    get week_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
    get week_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
    get month_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("month").format(DEFAULT_DATE_FORMAT); },
    get month_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("month").format(DEFAULT_DATE_FORMAT); },
    get year_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("year").format(DEFAULT_DATE_FORMAT); },
    get year_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("year").format(DEFAULT_DATE_FORMAT); },
  });

  const open = Boolean(anchorEl);
  const id = open ? "singlemeter-date-popover" : undefined;

  const getActualScalePair = (scale: string) : { scale: string; formatScale: string } => {
    if (scale === "hourly") {
      return {
        scale: "hourly", formatScale: "hourly",
      };
    } if (scale === "daily") {
      return {
        scale: "daily", formatScale: "weekly",
      };
    } if (scale === "monthly") {
      return {
        scale: "daily", formatScale: "daily",
      };
    } if (scale === "yearly") {
      return {
        scale: "monthly", formatScale: "yearly",
      };
    }

    return {
      scale: "", formatScale: "",
    };
  };

  const handleDatePickerClick = (event : React.MouseEvent<Element, MouseEvent>) : void => {
    if (scale !== "daily") { setAnchorEl(event.currentTarget); }
  };

  const handleClose = () : void => {
    setAnchorEl(null);
  };

  const changeTimeBase = (nextOrPrev: string) : void => {
    setGraphTime((prev) => {
      let new_time_base = null;
      if (nextOrPrev === "next") {
        new_time_base = prev.timeBase.clone().add(1, getScaleForMoment(scale));
      } else {
        new_time_base = prev.timeBase.clone().subtract(1, getScaleForMoment(scale));
      }

      return {
        timeBase: new_time_base,
        get day_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("day").format(DEFAULT_DATETIME_FORMAT); },
        get day_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("day").format(DEFAULT_DATETIME_FORMAT); },
        get week_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
        get week_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
        get month_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("month").format(DEFAULT_DATE_FORMAT); },
        get month_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("month").format(DEFAULT_DATE_FORMAT); },
        get year_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("year").format(DEFAULT_DATE_FORMAT); },
        get year_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("year").format(DEFAULT_DATE_FORMAT); },
      };
    });
  };

  const setTimeBase = (base: any) : void => {
    const newBase = moment(base);
    setGraphTime((prev) => ({
      timeBase: newBase,
      get day_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("day").format(DEFAULT_DATETIME_FORMAT); },
      get day_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("day").format(DEFAULT_DATETIME_FORMAT); },
      get week_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
      get week_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("isoWeek").format(DEFAULT_DATE_FORMAT); },
      get month_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("month").format(DEFAULT_DATE_FORMAT); },
      get month_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("month").format(DEFAULT_DATE_FORMAT); },
      get year_start() : string { return (this as GraphTimeModel).timeBase.clone().startOf("year").format(DEFAULT_DATE_FORMAT); },
      get year_end() : string { return (this as GraphTimeModel).timeBase.clone().endOf("year").format(DEFAULT_DATE_FORMAT); },
    }));

    handleClose();
  };

  const handleSetScale = (newScale: string) : void => {
    setTimeBase(moment());
    setScale(newScale);

    const { formatScale } = getActualScalePair(newScale);
    setFormatScale(formatScale);
  };

  const renderHead = () : string => {
    const defaultHead = "Etusivu";

    if (authenticationStore.currentUser?.userRole !== UserRole.OV_RESIDENT) { return defaultHead; }

    if (authenticationStore.currentUser?.locationAccesses
      && authenticationStore.currentUser?.locationAccesses.length === 1) {
        const loc = authenticationStore.currentUser?.locationAccesses[0].address;
        if (loc.streetAddress && loc.postalCode && loc.city) return `${loc.streetAddress}, ${loc.postalCode}, ${loc.city}`;
    }

    return defaultHead;
  };

  useEffect(() => {
    let mounted = true;

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    (async () => {
      const response = await acquireToken(instance, request);

      if (response) {
        if (scale === "daily") {
          await dataStore.loadConsumptionData(response.idToken, graphTime.week_start, graphTime.week_end, "daily", "weekly", true, true, true, currentUser?.waterPrice);
        } else if (scale === "monthly") {
          await dataStore.loadConsumptionData(response.idToken, graphTime.month_start, graphTime.month_end, "daily", "daily", true, true, true, currentUser?.waterPrice);
        } else if (scale === "yearly") {
          await dataStore.loadConsumptionData(response.idToken, graphTime.year_start, graphTime.year_end, "monthly", "monthly", true, true, true, currentUser?.waterPrice);
        }
      }
    })();

    return function cleanup() {
      mounted = false;
      dataStore.clearState();
    };
  }, [dataStore, scale, graphTime.timeBase, graphTime.week_start, graphTime.week_end, graphTime.month_start, graphTime.month_end, graphTime.year_start, graphTime.year_end, accounts, instance]);

  return (
      <>
          <Container className={`${classes.container} ${classes.topContainer}`}>
              <Grid container>
                  <Grid container item xs={12} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Typography variant="h4" className={classes.headerTop}>{renderHead()}</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.headCardsContainer}
                    justifyContent={isMobile ? "center" : "flex-start"}
                    alignItems="center"
                  >
                      {((currentUser.userRole === UserRole.OV_ADMIN || currentUser.userRole === UserRole.OV_CUSTOMER_ADMIN) && (
                          <>
                              <StatCard
                                icon="Devices"
                                header={totalMetersForCard.toString()}
                                content={t("meterView.totalmeters")}
                                id="metersview-totalmeters-card"
                                loadingState={fetchingMeterCount}
                              />
                              <StatCard
                                icon={totalAlertsForCard ? "NotificationImportant" : "CheckCircle"}
                                header={totalAlertsForCard.toString()}
                                className={classes.secondCard}
                                content={t("alertView.unreadAlerts")}
                                id="alertsview-totalalerts-card"
                                loadingState={fetchingAlertCount}
                              />
                              <StatCard
                                icon="Apartment"
                                header={totalLocationsForCard.toString()}
                                className={classes.secondCard}
                                content={t("locationView.consumers")}
                                id="locationsview-consumers-card"
                                loadingState={fetchingLocationsCount}
                              />
                          </>
                      ))}

                      {currentUser.userRole === UserRole.OV_RESIDENT && (
                          <StatCard
                            icon={totalAlertsForCard ? "NotificationImportant" : "CheckCircle"}
                            header={totalAlertsForCard.toString()}
                            className={classes.secondCard}
                            content={t("alertView.unreadAlerts")}
                            id="alertsview-totalalerts-card"
                            loadingState={fetchingAlertCount}
                          />
                      )}
                  </Grid>
              </Grid>
          </Container>
          <Paper className={`${classes.homePageMain}`} elevation={2}>
              <Grid container item xs={12}>
                  <Grid container item xs={12} justifyContent="center">
                      <Typography variant="h6" className={classes.consumptionTitle}>{t("homePage.waterConsumption")}</Typography>
                  </Grid>
                  <Grid item container xs={isMobile ? 12 : 7} className={classes.chartConsumptionInfoWrapper}>
                      <Grid item xs={6}>
                          <span className={classes.infoText}>{t("homePage.coldWater")}</span>
                          <span className={classes.coldText}>
                              {renderData(dataStore.overviewConsumptionData.totalConsumptionCold) || "-"}
                              {"\u00A0"}
                              m³
                          </span>
                      </Grid>
                      <Grid item xs={6}>
                          {currentUser.usersOrganizationType !== OrganizationType.WATER_COMPANY && (
                              <span>
                                  <span className={classes.infoText}>{t("homePage.hotWater")}</span>
                                  <span className={classes.hotText}>
                                      {renderData(dataStore.overviewConsumptionData.totalConsumptionHot) || "-"}
                                      {"\u00A0"}
                                      m³
                                  </span>
                              </span>
                          )}
                      </Grid>
                      {currentUser.usersOrganizationType !== OrganizationType.WATER_COMPANY && currentUser.usersOrganizationType !== OrganizationType.OTHER && (
                          <>
                              <Grid item xs={6}>
                                  <span className={classes.infoText}>Euroa </span>
                                  <span className={classes.coldText}>
                                      {dataStore.overviewConsumptionData.consumptionPriceCold === -1 ? "-" : dataStore.overviewConsumptionData.consumptionPriceCold}
                                      {"\u00A0"}
                                      €
                                  </span>
                              </Grid>
                              <Grid item xs={6}>
                                  <span className={classes.infoText}>Euroa </span>
                                  <span className={classes.hotText}>
                                      {dataStore.overviewConsumptionData.consumptionPriceHot === -1 ? "-" : dataStore.overviewConsumptionData.consumptionPriceHot}
                                      {"\u00A0"}
                                      €
                                  </span>
                              </Grid>
                          </>
                      )}

                  </Grid>
                  {!isMobile && (
                      <Grid item container xs={5} wrap="wrap" alignItems="center" justifyContent="center" alignContent="flex-end" direction="column">
                          <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                              {/* <Button id="singlemeter-scale-custom" onClick={handleClick}><CalendarTodayIcon /></Button> */}
                              <Button id="singlemeter-scale-week" className={scale === "daily" ? classes.scaleSelected : ""} onClick={() => { handleSetScale("daily"); }}>{t("singleMeter.week")}</Button>
                              <Button id="singlemeter-scale-month" className={scale === "monthly" ? classes.scaleSelected : ""} onClick={() => { handleSetScale("monthly"); }}>{t("singleMeter.month")}</Button>
                              <Button id="singlemeter-scale-year" className={scale === "yearly" ? classes.scaleSelected : ""} onClick={() => { handleSetScale("yearly"); }}>{t("singleMeter.year")}</Button>
                          </ButtonGroup>
                          <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                              <Button id="singlemeter-prev-interval" onClick={() => changeTimeBase("previous")}><ArrowBackIosIcon /></Button>
                              <Button id="singlemeter-calendar-button" onClick={handleDatePickerClick}>{getDatePickerValue(scale, graphTime)}</Button>
                              <Button id="singlemeter-next-interval" onClick={() => changeTimeBase("next")}><ArrowForwardIosIcon /></Button>
                          </ButtonGroup>
                      </Grid>
                  )}
                  <Grid item xs={12} className={classes.homePageGraphContainer}>
                      <ResponsiveContainer width="100%" aspect={2} className={classes.homePageGraphResponsiveContainer}>
                          {dataStore.fetchingConsumptionData ? (
                              <PendingCircle />
                        ) : (
                            <BarChart
                              data={dataStore.overviewConsumptionData.timeValueSeries}
                              id="singlemter-consumption-chart"
                              barSize={isMobile ? 10 : undefined}
                            >
                                <CartesianGrid strokeDasharray="6 6" vertical={false} />
                                <XAxis
                                  dataKey="rawDate"
                                  tickFormatter={(v, i) => displayableDate(v, formatScale)}
                                />
                                <YAxis
                                  name="Kulutus"
                                  label={{
                                  value: "m³",
                                  position: "insideLeft",
                                  offset: -5,
                                  }}
                                  hide={isMobile}
                                />
                                {/* <YAxis
                                name="Kulutus"
                                label={{
                                  value: "m³", position: "insideRight",
                                }}
                                yAxisId="hotWaterAxis"
                                orientation="right"
                              /> */}
                                <Tooltip content={(
                                    <OVChartTooltip
                                      unit="m³"
                                      legend1={t("homePage.coldWater")}
                                      legend2={`${dataStore.overviewConsumptionData.hasHotSeries ? t("homePage.hotWater") : null}`}
                                      formatScale={formatScale}
                                    />
                                )}
                                />
                                <Legend />
                                <Bar dataKey="coldConsumption" fill={theme.palette.hotColdGraph.main} name={isMobile ? `${t("homePage.coldWater")} (m³)` : t("homePage.coldWater")} />
                                {(currentUser.usersOrganizationType !== OrganizationType.WATER_COMPANY && dataStore.overviewConsumptionData.hasHotSeries) && (<Bar dataKey="hotConsumption" fill={theme.palette.hotColdGraph.dark} name={t("homePage.hotWater")} />)}
                                {/* <Bar dataKey="consumption_previous" fill="#00707d" /> */}
                            </BarChart>
                        )}

                      </ResponsiveContainer>
                      {isMobile && (
                          <Grid container item xs={12} wrap="wrap" alignItems="center" justifyContent="center" alignContent="center" direction="column" className={classes.MobileScaleButtons}>
                              <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                                  {/* <Button id="singlemeter-scale-custom" onClick={handleClick}><CalendarTodayIcon /></Button> */}
                                  <Button id="singlemeter-scale-week" className={scale === "daily" ? classes.scaleSelected : ""} onClick={() => { handleSetScale("daily"); }}>{t("singleMeter.week")}</Button>
                                  <Button id="singlemeter-scale-month" className={scale === "monthly" ? classes.scaleSelected : ""} onClick={() => { handleSetScale("monthly"); }}>{t("singleMeter.month")}</Button>
                                  <Button id="singlemeter-scale-year" className={scale === "yearly" ? classes.scaleSelected : ""} onClick={() => { handleSetScale("yearly"); }}>{t("singleMeter.year")}</Button>
                              </ButtonGroup>
                              <ButtonGroup variant="outlined" className={classes.consumptionTabDateSelectGroup}>
                                  <Button id="singlemeter-prev-interval" onClick={() => changeTimeBase("previous")}><ArrowBackIosIcon /></Button>
                                  <Button id="singlemeter-calendar-button" onClick={handleDatePickerClick}>{getDatePickerValue(scale, graphTime)}</Button>
                                  <Button id="singlemeter-next-interval" onClick={() => changeTimeBase("next")}><ArrowForwardIosIcon /></Button>
                              </ButtonGroup>
                          </Grid>
                      )}
                  </Grid>
                  {currentUser.usersOrganizationType !== OrganizationType.WATER_COMPANY && currentUser.usersOrganizationType !== OrganizationType.OTHER && currentUser?.waterPrice && (
                      <Grid item xs={12}>
                          <Card className={classes.pricesCard}>
                              <Grid container>
                                  <Grid item xs={4} className={classes.centerText}>
                                      <Grid item xs={12}><span className={classes.priceInfoText}>{t("pricing.basePrice")}</span></Grid>
                                      <Grid item xs={12}>
                                          <span className={classes.coldPriceText}>
                                              {currentUser?.waterPrice.price || 0}
                                              {" "}
                                              € / kk
                                          </span>

                                      </Grid>

                                  </Grid>
                                  <Grid item xs={4} className={classes.centerText}>
                                      <Grid item xs={12}><span className={classes.priceInfoTextCold}>{t("pricing.cold")}</span></Grid>
                                      <Grid item xs={12}>
                                          <span className={classes.coldPriceText}>
                                              {currentUser?.waterPrice.coldWaterPrice || 0}
                                              {" "}
                                              € / m³
                                          </span>

                                      </Grid>

                                  </Grid>
                                  <Grid item xs={4} className={classes.centerText}>
                                      <Grid item xs={12}><span className={classes.priceInfoTextHot}>{t("pricing.warm")}</span></Grid>
                                      <Grid item xs={12}>
                                          <span className={classes.coldPriceText}>
                                              {currentUser?.waterPrice.hotWaterPrice || 0}
                                              {" "}
                                              € / m³
                                          </span>

                                      </Grid>

                                  </Grid>
                              </Grid>
                          </Card>
                      </Grid>
                      ) }
              </Grid>

          </Paper>

      </>
  );
});

import { useState, useEffect } from "react";
import { WhitelabelConfiguration } from "../assets/clientconfiguration/whitelabelConfig";

async function getLogo() : Promise<string> {
    return WhitelabelConfiguration.getLogo();
}

export function useLogo() : any {
    const [logo, setLogo] = useState<string>();

    useEffect(() => {
        const onWlcUpdated = async () : Promise<void> => {
            const t = await getLogo();
            setLogo(t);
        };

        window.addEventListener("wlcupdated", onWlcUpdated);
        onWlcUpdated();

        return () => window.removeEventListener("wlcupdated", onWlcUpdated);
    }, []);

    return logo ?? "";
}

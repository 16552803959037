import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

export default makeStyles(() => createStyles({
  container: {
    paddingTop: 20,
    minWidth: "80%",
  },
  headerContainer: {
    marginBottom: 50,
  },
}));

import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import WaterDamageIcon from "@mui/icons-material/WaterDamage";
import SpeedIcon from "@mui/icons-material/Speed";
import { FileCopyOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import EuroIcon from "@mui/icons-material/Euro";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useRootStore } from "../../RootStateContext";
import i18n from "../../i18n";
import useStyles from "./NavigationBarMobileStyles";
import { UserRole } from "../../models/UserRole";
import { OrganizationType } from "../../models/UserInfo";

export const drawerWidth = 240;
interface INavProps {
  userRole: UserRole;
  organizationType: OrganizationType;
  activeAlerts?: number;
}

export default observer(({ userRole, organizationType, activeAlerts }:INavProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useRootStore().uiStateStore;
  const { t } = useTranslation();

  const navigationComponents = [
    {
      name: t("navigation.home"),
      key: "home",
      icon: <HomeIcon />,
      id: "home",
    },
    {
      name: t("navigation.alerts"),
      key: "alerts",
      icon: <NotificationsActiveIcon />,
      id: "alerts",
    },
  ];

  const adminNavigationComponents = [
    {
      name: i18n.t("navigation.meters"),
      key: "meters",
      icon: <SpeedIcon />,
      id: "meters",
    },
    {
      name: i18n.t("navigation.locations"),
      key: "locations",
      icon: <WaterDamageIcon />,
      id: "locations",
    },
    {
      name: t("navigation.reports"),
      key: "reports",
      icon: <FileCopyOutlined />,
      id: "reports",
    }, {
      name: i18n.t("navigation.users"),
      key: "usermanagement",
      icon: <PeopleIcon />,
      id: "usermanagement",
    },
  ];

  const housingAdminNavigationComponents = [
    {
      name: i18n.t("navigation.prices"),
      key: "prices",
      icon: <EuroIcon />,
      id: "prices",
    },
  ];

  const navigationItems = () : JSX.Element[] => {
    const items : JSX.Element[] = [];

    navigationComponents.forEach((val, idx) => {
      if (val.key === "alerts") {
        items.push((
            <BottomNavigationAction
              className={classes.navigationAction}
              icon={(
                  <Badge
                    badgeContent={activeAlerts}
                    color={activeAlerts ? "warning" : "default"}
                  >
                      {val.icon}
                  </Badge>
              )}
              to={`/${val.key}`}
              component={Link}
              key={val.key}
            />
        ));
      } else {
        items.push((

            <BottomNavigationAction
              className={classes.navigationAction}
              to={`/${val.key}`}
              icon={val.icon}
              component={Link}
              key={val.key}
            />
        ));
      }
    });

    if (userRole === UserRole.OV_ADMIN) {
      adminNavigationComponents.forEach((val, idx) => {
        items.push((
            <BottomNavigationAction
              className={classes.navigationAction}
              to={`/${val.key}`}
              icon={val.icon}
              component={Link}
              key={val.key}
            />

        ));
      });
    }

    if (userRole === UserRole.OV_ADMIN && organizationType === OrganizationType.OMAVESI_HOUSING) {
      housingAdminNavigationComponents.forEach((val, idx) => {
        items.push((
            <BottomNavigationAction
              className={classes.navigationAction}
              icon={val.icon}
              to={`/${val.key}`}
              component={Link}
              key={val.key}
            />
        ));
      });
    }

    return items;
  };

  return (
      <Paper
        sx={{
          position: "fixed", bottom: 0, left: 0, right: 0,
        }}
        elevation={3}
        className={classes.bottomNavContainer}
      >
          <BottomNavigation className={classes.bottomNavComp} showLabels={false}>
              {navigationItems()}
          </BottomNavigation>
      </Paper>
  );
});

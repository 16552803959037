import { Grid, Typography } from "@mui/material";
import React from "react";
import useStyles from "./NoOmaVesiAccessComponentStyles";

export default function (): JSX.Element {
  const classes = useStyles();
  return (
      <Grid
        container
        className={classes.container}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
          <Typography className={classes.headerContainer} variant="h4" component="h1">Organisaatiollasi ei ole pääsyä Omaveteen</Typography>
          <Typography className={classes.text} variant="h5" component="h2">Voit kirjautua ulos valitsemalla &quot;Kirjaudu ulos&quot; oikean yläkulman valikosta</Typography>
      </Grid>
  );
};

abstract class BaseStore {
    error?: string;

    setError = (error: string): void => {
      this.error = error;
      setTimeout(this.clearError, 6000);
    }

    clearError = (): void => {
      this.error = undefined;
    }
}

export default BaseStore;

import { AuthenticationResult } from "@azure/msal-browser";
import { UserForLocationAccessEditing, UserForResidentCreation, UserInfo, UserInfoForCreation, UserInfoForUpdating, UserResponseWrapper } from "../models/UserInfo";
import { del, delWithToken, get, getWithAccessToken, post, postFileWithAccessToken, postWithAccessToken, put, putWithAccessToken } from "./HttpUtils";
import AlertSetting from "../models/AlertSetting";

const api = process.env.REACT_APP_API_URL || "";

export const getUsersPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<UserResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";

  return await getWithAccessToken(`${api}/service/user/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useSearch}`, userToken) as UserResponseWrapper;
};

export const deleteUser = async (userToken: string, user: UserInfo): Promise<void> => delWithToken(`${api}/service/user/${user.id}`, userToken);

export const saveNewUser = async (userToken: string, user: UserInfoForCreation): Promise<UserInfo> => await postWithAccessToken(`${api}/service/user`, user, userToken) as UserInfo;

export const saveNewResidentUserForLocation = async (userToken: string, user: UserForResidentCreation): Promise<UserInfo> => await postWithAccessToken(`${api}/service/userforlocation`, user, userToken) as UserInfo;

export const editUser = async (userToken: string, user: UserInfoForUpdating, id: string): Promise<UserInfo> => await putWithAccessToken(`${api}/service/user/${id}`, userToken, user) as UserInfo;

export const editUserLocationAccess = async (userToken: string, user: UserForLocationAccessEditing): Promise<UserInfo> => await putWithAccessToken(`${api}/service/userLocationAccess`, userToken, user) as UserInfo;

export const getUsersOfLocationPaged = async (locationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<UserResponseWrapper> => {
  let content: UserResponseWrapper;
  if (sortBy !== "" && sortBy !== undefined && sortBy !== null) {
    const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
    if (archived !== null && archived !== undefined) {
      content = await getWithAccessToken(`${api}/service/usersOfLocation/${locationId}/?page=${page}&size=${pageSize}&sort=${sortBy},${dir}&archived=${archived}`, userToken) as UserResponseWrapper;
    } else {
      content = await getWithAccessToken(`${api}/service/usersOfLocation/${locationId}/?page=${page}&size=${pageSize}&sort=${sortBy},${dir}`, userToken) as UserResponseWrapper;
    }
  } else if (archived !== null && archived !== undefined) {
    content = await getWithAccessToken(`${api}/service/usersOfLocation/${locationId}/?page=${page}&size=${pageSize}&archived=${archived}`, userToken) as UserResponseWrapper;
  } else {
    content = await getWithAccessToken(`${api}/service/usersOfLocation/${locationId}/?page=${page}&size=${pageSize}`, userToken) as UserResponseWrapper;
  }
  return content;
};

export const getUsersForSelect = async (userToken: string, page: number, pageSize: number, hasLocationAccesses?: boolean, abortSignal?: AbortSignal, search = ""): Promise<UserResponseWrapper | undefined> => {
  const useSearch = search !== "" ? `&search=${search}` : "";
  const useLocationFilter = (hasLocationAccesses === undefined || hasLocationAccesses === null) ? `` : `&hasExistingLocation=${hasLocationAccesses}`;
  try {
    const response = await getWithAccessToken(`${api}/service/user/?page=${page}&size=${pageSize}${useSearch}${useLocationFilter}`, userToken, undefined, abortSignal) as UserResponseWrapper;
    if (response) return response as UserResponseWrapper;
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const getUsersOfOrganizationPaged = async (organizationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<UserResponseWrapper> => {
  let content: UserResponseWrapper;
  if (sortBy !== "" && sortBy !== undefined && sortBy !== null) {
    const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
    if (archived !== null && archived !== undefined) {
      content = await getWithAccessToken(`${api}/service/usersOfOrganization/${organizationId}/?page=${page}&size=${pageSize}&sort=${sortBy},${dir}&archived=${archived}`, userToken) as UserResponseWrapper;
    } else {
      content = await getWithAccessToken(`${api}/service/usersOfOrganization/${organizationId}/?page=${page}&size=${pageSize}&sort=${sortBy},${dir}`, userToken) as UserResponseWrapper;
    }
  } else if (archived !== null && archived !== undefined) {
    content = await getWithAccessToken(`${api}/service/usersOfOrganization/${organizationId}/?page=${page}&size=${pageSize}&archived=${archived}`, userToken) as UserResponseWrapper;
  } else {
    content = await getWithAccessToken(`${api}/service/usersOfOrganization/${organizationId}/?page=${page}&size=${pageSize}`, userToken) as UserResponseWrapper;
  }
  return content;
};

export const getUsers = async (userToken: string): Promise<UserInfo[]> => {
  const content = await getWithAccessToken(`${api}/service/user`, userToken) as UserResponseWrapper;
  return content.content;
};

export const getUserDetails = async (userToken: string): Promise<UserInfo> => await getWithAccessToken(`${api}/service/userdetails`, userToken) as UserInfo;
export const updatePersonalAlertSetting = async (currentUserToken: string, settings: AlertSetting): Promise<AlertSetting> => await putWithAccessToken(`${api}/service/updateAlertSettings`, currentUserToken, settings) as AlertSetting;

export const batchDeleteUsers = async (userIds: string[]): Promise<string[]> => await post(`${api}/service/user/batchDelete`, userIds) as string[];

export const importMultipleUsers = async (userToken: string, fileToUpload: File, organizationId: string): Promise<any> => await postFileWithAccessToken(`${api}/service/user/${organizationId}/import`, fileToUpload, userToken) as any;

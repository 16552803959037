import { MSALConfig } from "../../authConfig";

export class WhitelabelConfiguration {
    static hostName?: string = undefined;

    // Hostnames order: local, devel, preprod, prod
    static config = {
        DEFAULT: {
            hostNames: ["localhost", "omavesi-devel.dgtiot.cinia.link", "digita-preprod.omavesi.fi", "digita.omavesi.fi"],
            theme: "defaultTheme",
            logo: "ov_logo_compressed_white.png",
            authConfig: {
                redirectUri: process.env.REACT_APP_REDIRECT_URL || "",
                postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL || "",
            },
        },

        WHITELABEL_ALVA: {
            hostNames: ["localhost-external1", "omavesi-devel-external1.dgtiot.cinia.link", "vesi-preprod.omavesi.fi", "vesi.alva.fi"],
            theme: "external1Theme",
            logo: "omavesi_logo_alva.png",
            authConfig: {
                redirectUri: process.env.REACT_APP_WHITELABEL_ALVA_REDIRECT_URL || "",
                postLogoutRedirectUri: process.env.REACT_APP_WHITELABEL_ALVA_REDIRECT_URL || "",
            },
        },

        WHITELABEL_EFFECTIO: {
            hostNames: ["localhost-external2"],
            theme: "external2Theme",
            logo: "omavesi_logo_effectio.png",
            authConfig: {
                redirectUri: process.env.REACT_APP_WHITELABEL_EFFECTIO_REDIRECT_URL || "",
                postLogoutRedirectUri: process.env.REACT_APP_WHITELABEL_EFFECTIO_REDIRECT_URL || "",
            },
        },
    }

    static setHostName(hostName: string) : void {
        WhitelabelConfiguration.hostName = hostName;
        const event = new CustomEvent("wlcupdated", {
            detail: hostName,
        });

        window.dispatchEvent(event);
    }

    static getMSALConfig() : MSALConfig {
        if (!WhitelabelConfiguration.hostName) { throw new Error(`WhiteLabelConfig:Invalid hostname ${WhitelabelConfiguration.hostName}`); }

        const { hostName } = WhitelabelConfiguration;

        const baseConfig: MSALConfig = {
            auth: {
                redirectUri: "",
                postLogoutRedirectUri: "",
                clientId: process.env.REACT_APP_APPLICATION_ID || "",
                authority: process.env.REACT_APP_CLOUD_INSTANCE || "",
                knownAuthorities: process.env.REACT_APP_KNOWN_AUTHORITIES?.split(",") || [],
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
        }

        const authConfig = Object.entries(this.config).find(([key, config]) => config.hostNames.includes(hostName))?.[1].authConfig;

        if (authConfig) {
            baseConfig.auth = {
                ...baseConfig.auth,
                ...authConfig,
            }

            return baseConfig;
        }

        throw new Error(`Could not find whitelabel configuration for hostname: ${hostName}`)
    }

    static getThemeName() : string {
        if (!WhitelabelConfiguration.hostName) { throw new Error(`WhiteLabelConfig:Invalid hostname ${WhitelabelConfiguration.hostName}`); }

        const { hostName } = WhitelabelConfiguration;

        const themeName = Object.entries(this.config).find(([key, config]) => config.hostNames.includes(hostName))?.[1].theme;

        if (themeName) {
            return themeName;
        }

        throw new Error(`Could not find whitelabel theme for hostname: ${hostName}`)
    }

    static async getLogo() : Promise<string> {
        if (!WhitelabelConfiguration.hostName) { throw new Error(`WhiteLabelConfig:Invalid hostname ${WhitelabelConfiguration.hostName}`); }

        const { hostName } = WhitelabelConfiguration;

        const logoName = Object.entries(this.config).find(([key, config]) => config.hostNames.includes(hostName))?.[1].logo;

        const logo = (await import(`../images/${logoName}`)).default as string
        return logo;
    }
}

import { action, makeObservable, observable, runInAction } from "mobx";
import { batchDeleteUsers,
  deleteUser,
  editUser,
  editUserLocationAccess,
  getUsers,
  getUsersForSelect,
  getUsersOfLocationPaged,
  getUsersOfOrganizationPaged,
  getUsersPaged,
  importMultipleUsers,
  saveNewResidentUserForLocation,
  saveNewUser } from "../api/UserApi";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import { UserForLocationAccessEditing, UserForResidentCreation, UserInfo, UserInfoForCreation, UserInfoForUpdating } from "../models/UserInfo";
import { UserRole } from "../models/UserRole";

export default class UserStore extends BaseStore {
  allUsers?: UserInfo[];

  usersOfLocation?: UserInfo[];

  usersOfOrganization?: UserInfo[];

  totalUsers = 0;

  fetchingLocationUsers = false;

  fetchingOrganizationsUsers = false;

  fetchingUsersPaged = false;

  fetchingAllUsers = false;

  selectedUser?: UserInfo = undefined;

  selectedUsers: UserInfo[] = [];

  totalUsersForCard = 0;

  residentUsers: UserInfo[] = [];

  updatingUsersInProgress = false;

  // FOR DYNAMICALLY POPULATED SELECTS
  userGrantAccessSelectUsers: UserInfo[] = [];

  userGrantAccessSelectUsersAvailable = 0;

  fetchingUserGrantAccessSelectUsers = false;

  // FOR DYNAMICALLY POPULATED SELECTS
  loadUserGrantAccessUsers = async (userToken: string, search: string, abortSignal?: AbortSignal): Promise<void> => {
    try {
      const filterUserHasLocationAccess = undefined;
      this.fetchingUserGrantAccessSelectUsers = true;
      const usersResp = await getUsersForSelect(userToken, 0, 15, filterUserHasLocationAccess, abortSignal, search);
      runInAction(() => {
        if (abortSignal && !abortSignal.aborted && usersResp) {
          if (this.usersOfLocation) {
            this.userGrantAccessSelectUsers = usersResp.content.filter((u) => !this.usersOfLocation?.find((u2) => u2.id === u.id));
          } else {
            this.userGrantAccessSelectUsers = usersResp.content;
          }

          if (usersResp.totalElements > 15) { this.userGrantAccessSelectUsersAvailable = usersResp.totalElements - 15; } else { this.userGrantAccessSelectUsersAvailable = 0; }
        } else {
          this.userGrantAccessSelectUsers = [];
          this.userGrantAccessSelectUsersAvailable = 0;
        }
        this.fetchingUserGrantAccessSelectUsers = false;
      });
    } catch (e) {
      this.fetchingUserGrantAccessSelectUsers = false;
      this.setError(i18n.t("locationstore.locationsFetchError"));
      console.error(e.stack);
    }
  }

  loadAllUsers = async (userToken: string): Promise<void> => {
    try {
      this.fetchingAllUsers = true;
      const response = await getUsers(userToken);
      runInAction(() => {
        this.allUsers = response;
        if (this.allUsers) {
          this.residentUsers = this.allUsers.filter((e) => e.userRole === UserRole.OV_RESIDENT);
          this.totalUsersForCard = this.allUsers.length;
        }
        this.fetchingAllUsers = false;
      });
    } catch (e: any) {
      this.setError("Virhe käyttäjien haussa");
      console.error(e.stack);
      this.fetchingAllUsers = false;
    }
  };

  // todo: fetch total users count

  loadUsersOfLocationPaged = async (locationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<void> => {
    try {
      this.fetchingLocationUsers = true;
      const userResp = await getUsersOfLocationPaged(locationId, userToken, page, pageSize, sortBy, sortDirection, archived);
      runInAction(() => {
        this.usersOfLocation = userResp.content;
        this.totalUsers = userResp.totalElements;
        this.fetchingLocationUsers = false;
      });
    } catch (e: any) {
      this.setError(i18n.t("userStore.usersOfLocationFetchError"));
      this.allUsers = [];
      this.fetchingLocationUsers = false;
      console.error(e.stack);
    }
  };

  loadUsersOfOrganizationPaged = async (organizationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean): Promise<void> => {
    try {
      this.fetchingOrganizationsUsers = true;
      const userResp = await getUsersOfOrganizationPaged(organizationId, userToken, page, pageSize, sortBy, sortDirection, archived);
      runInAction(() => {
        this.usersOfOrganization = userResp.content;
        this.totalUsers = userResp.totalElements;
        this.fetchingOrganizationsUsers = false;
      });
    } catch (e: any) {
      this.setError(i18n.t("userStore.usersOfLocationFetchError"));
      this.allUsers = [];
      this.fetchingOrganizationsUsers = false;
      console.error(e.stack);
    }
  };

  loadUsersPaged = async (userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<void> => {
    try {
      this.fetchingUsersPaged = true;
      const usersResp = await getUsersPaged(userToken, page, pageSize, sortBy, sortDirection, search);
      runInAction(() => {
        this.allUsers = usersResp.content;
        this.totalUsers = usersResp.totalElements;
        this.fetchingUsersPaged = false;
      });
    } catch (e) {
      this.setError(i18n.t("userStore.usersFetchError"));
      this.allUsers = [];
      this.fetchingUsersPaged = false;
      console.error(e.stack);
    }
  };

  saveNewUser = async (userToken: string, user: UserInfoForCreation): Promise<UserInfo> => {
    try {
      const u = await saveNewUser(userToken, user);
      runInAction(() => {
        this.allUsers = this.allUsers?.concat(u);
        this.totalUsersForCard += 1;
        this.totalUsers += 1;
      });
      return u;
    } catch (e) {
      this.setError(i18n.t("userStore.userCreationError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  saveNewResidentUserForLocation = async (userToken: string, user: UserForResidentCreation): Promise<void> => {
    try {
      const u = await saveNewResidentUserForLocation(userToken, user);
      this.allUsers = this.allUsers?.concat(u);
      this.usersOfLocation = this.usersOfLocation?.concat(u);
      this.residentUsers = this.residentUsers?.concat(u);
      this.totalUsers += 1;
    } catch (e) {
      this.setError(i18n.t("userStore.userCreationError"));
      console.error(e.stack);
    }
  }

  updateLocationAccess = async (userToken: string, editAccess: UserForLocationAccessEditing): Promise<void> => {
    try {
      const updatedUser = await editUserLocationAccess(userToken, editAccess);
      runInAction(() => {
        this.usersOfLocation = this.usersOfLocation?.concat(updatedUser);
        this.totalUsers += 1;
      });
    } catch (e) {
      this.setError(i18n.t("userStore.userAccessSetError"));
      console.error(e.stack);
    }
  }

  removeResidentFromLocation = async (userToken: string, editAccess: UserForLocationAccessEditing): Promise<void> => {
    try {
      const removedUser = await editUserLocationAccess(userToken, editAccess);
      runInAction(() => {
        this.usersOfLocation = this.usersOfLocation?.filter((e) => e.id !== removedUser.id);
      });
    } catch (e) {
      this.setError(i18n.t("userStore.userUpdatingError"));
      console.error(e.stack);
    }
  }

  removeUser = async (userToken: string, user: UserInfo): Promise<void> => {
    try {
      await deleteUser(userToken, user);
      runInAction(() => {
        this.allUsers = this.allUsers?.filter((e) => e.id !== user.id);
        this.usersOfLocation = this.usersOfLocation?.filter((e) => e.id !== user.id);
        this.usersOfOrganization = this.usersOfOrganization?.filter((e) => e.id !== user.id);
        this.totalUsersForCard -= 1;
        this.totalUsers -= 1;
      });
    } catch (e) {
      this.setError(i18n.t("userStore.userDeletionError"));
      console.error(e.stack);
    }
  }

  updateUser = async (userToken: string, user: UserInfoForUpdating, id: string): Promise<UserInfo> => {
    try {
      const u = await editUser(userToken, user, id);
      runInAction(() => {
        const userInUsers = this.allUsers?.find((e) => e.id === id);
        if (userInUsers) {
          this.allUsers?.splice(this.allUsers?.indexOf(userInUsers), 1, u);
        }
      });

      return u;
    } catch (e) {
      this.setError(i18n.t("userStore.userUpdatingError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  setSelectedUser = (u: UserInfo): void => {
    runInAction(() => {
      this.selectedUser = u;
    });
  }

  toggleUserSelection = (u: UserInfo): void => {
    if (this.selectedUsers.includes(u)) this.selectedUsers = this.selectedUsers.filter((e) => e !== u);
    else this.selectedUsers = [...this.selectedUsers, u];
  }

  resetUserSelection = (): void => {
    runInAction(() => {
      this.selectedUsers = [];
      this.selectedUser = undefined;
    });
  }

  deleteUsers = async (users: UserInfo[]): Promise<void> => {
    try {
      const deleted: string[] = await batchDeleteUsers(users.map((e) => e.id || ""));
      runInAction(() => {
        this.allUsers = this.allUsers?.filter((d) => {
          if (!d.id) return false;
          return !deleted.includes(d.id);
        });
        const failureList = users.filter((e) => !e.id || !deleted.includes(e.id));
        if (failureList.length > 0) {
          this.setError(i18n.t("userStore.deleteFailedForXUsers", {
            ids: failureList.join(", "),
          }));
        }
        this.resetUserSelection();
      });
    } catch (e) {
      this.setError(i18n.t("userStore.deleteFailedAllUsers"));
      console.error(e.stack);
    }
  }

  importMultipleUserWithFile = async (userToken: string, file: File, organizationId: string): Promise<void> => {
    try {
      this.updatingUsersInProgress = true;
      const users = await importMultipleUsers(userToken, file, organizationId);
      console.log(JSON.stringify(users))
      runInAction(() => {
        // this.updatedMeters = meters;
        this.updatingUsersInProgress = false;

        /* this.updatedMeters.forEach((updatedMeter) => {
          this.updateItemInVisibleMeters(updatedMeter);
        }); */
      });
    } catch (e) {
      this.setError(i18n.t("userStore.importUserError"));
      console.error(e.stack);
      this.updatingUsersInProgress = false;
    }
  }

  clearState = (): void => {
    runInAction(() => {
      this.allUsers = [];
      this.usersOfLocation = [];
      this.totalUsers = 0;
      this.fetchingLocationUsers = false;
      this.fetchingOrganizationsUsers = false;
      this.fetchingAllUsers = false;
      this.fetchingUsersPaged = false;
      this.error = undefined;
      this.residentUsers = [];
      this.userGrantAccessSelectUsers = [];
      this.userGrantAccessSelectUsersAvailable = 0;
      this.fetchingUserGrantAccessSelectUsers = false;
    });
  };

  clearUserGrantAccessUsers = () : void => {
    runInAction(() => {
      this.userGrantAccessSelectUsers = [];
      this.userGrantAccessSelectUsersAvailable = 0;
      this.fetchingUserGrantAccessSelectUsers = false;
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      allUsers: observable,
      usersOfLocation: observable,
      totalUsers: observable,
      fetchingLocationUsers: observable,
      fetchingAllUsers: observable,
      selectedUser: observable,
      selectedUsers: observable,
      totalUsersForCard: observable,
      residentUsers: observable,
      fetchingOrganizationsUsers: observable,
      userGrantAccessSelectUsers: observable,
      userGrantAccessSelectUsersAvailable: observable,
      fetchingUserGrantAccessSelectUsers: observable,
      fetchingUsersPaged: observable,
      updatingUsersInProgress: observable,
      loadUsersPaged: action,
      saveNewUser: action,
      loadAllUsers: action,
      setSelectedUser: action,
      toggleUserSelection: action,
      resetUserSelection: action,
      removeUser: action,
      updateUser: action,
      loadUsersOfLocationPaged: action,
      loadUsersOfOrganizationPaged: action,
      removeResidentFromLocation: action,
      deleteUsers: action,
      clearState: action,
      saveNewResidentUserForLocation: action,
      updateLocationAccess: action,
      loadUserGrantAccessUsers: action,
    });
  }
}

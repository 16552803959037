import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({

  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding} !important`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headerContainer: {
    marginBottom: 50,
  },
  routeDisplay: {
    marginTop: 30,
    marginLeft: 30,
    display: "inline-flex",
  },
  locationNameDisplay: {
    marginLeft: 10,
    marginTop: 10,
  },
  routeDisplayElementWrapper: {
    marginRight: 10,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  gridContainer: {
    marginBottom: 50,
  },
  reportCreatorCard: {
    padding: "5px 16px",
  },
  paper: {
    marginBottom: 5,
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.text.secondary,
    height: "100%",
    width: "100%",
    "& .MuiTypography-h4": {
      color: theme.palette.text.primary,
    },
    "& .MuiButton-root": {
      marginRight: 10,
      marginLeft: 10,
      borderRadius: 25,
      paddingLeft: 15,
      paddingRight: 15,
      width: "25%",
      fontSize: "75%",
    },
    "& .MuiCardContent-root": {
      width: "100%",
    },
  },
  unreadAlertCard: {
    padding: 10,
    margin: 5,
  },
  unreadAlertText: {
    position: "relative",
    bottom: 3,
    paddingLeft: 5,
  },
  noData: {
    marginTop: 45,
    textAlign: "start",
    padding: 15,
    width: "100%",
  },
  topCard: {
    padding: "20px",
    marginTop: "10px",
    clear: "both",

    "& > .MuiGrid-root": {
      marginTop: "15px",
    },

    "& .MuiGrid-root > .MuiGrid-root": {
      whiteSpace: "nowrap",
    },
  },
  meterNumberTop: {
    color: "#04444B",
    marginBottom: "10px",
  },
  topCardDataTitle: {
    fontWeight: "bold",
  },
  tagAddInput: {
    marginTop: "6px",
  },
  tagAddbutton: {
    height: "45px",
    marginLeft: 15,
  },
  singleMeterStatCardTop: {},
  cardsTop:
  {
    gap: "15px",
  },
  tabPanelPaper:
  {
    padding: "16px",
  },
  tabPanel:
  {
    "& .MuiBox-root":
    {
      padding: "0px",
    },
  },
  consumptionTabLeftWrapper:
  {
    height: "100%",
  },
  consumptionTabDateSelectGroup:
  {
    "& .MuiButton-root":
    {
      color: "#00A0B4",
      fontWeight: "600",
      border: 0,
    },
    "& .MuiButton-root:hover":
    {
      border: 0,
    },
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  scaleSelected:
  {
    textDecoration: "underline !important",
    textUnderlineOffset: "5px",
  },
  datePickPopover:
  {
    padding: "15px",

    "& .MuiTextField-root":
    {
      marginTop: "10px",
    },

    "& .MuiButton-root":
    {

      marginTop: "15px",
    },
    "& .MuiButton-root:hover":
    {},
  },
  datePickText:
  {
    marginTop: "10px !important",
  },

  singleMeterNextConsumptionCard:
  {},

  consumptionCardContainer: {
    gap: "15px",
    marginTop: "15px",
  },

  containerMobile: {
    padding: "0 !important",
  },

  reportCreatorCardMobile: {
    maxWidth: "260px",
    boxSizing: "border-box",
  },

  topCardMobile: {
    margin: "10px 10px 0px 10px !important",
  },

  tabPanelPaperMobile: {
    padding: "0 0 10px 0",
  },

  tabBarMobile: {
    "& .MuiTabs-root": {
      overflow: "visible",
      overflowX: "visible",

      "& .MuiTabs-scroller": {
        overflow: "visible !important",
        overflowX: "visible !important",
      },
    },
  },
  chartContainer: {
    minHeight: "600px",
  },
}));

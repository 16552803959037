import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  TextField,
  Typography,
  FormControl, InputLabel, Select, MenuItem, Grid, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LocationCity } from "@mui/icons-material";
import useStyles from "./EditLocationModalStyles";
import { Organization } from "../../models/Organization";
import Location from "../../models/Location";
import useResponsivity from "../../hooks/useResponsivity";
import ModalHeader from "../modalComponents/modalHeader";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
    location: Location;
    organizations?: Organization[];
    open: boolean;
    handleClose: () => void;
    submit: (d: Location) => void;
    showCustomerSelection: boolean;
    removeLocation: (location: Location) => void;
}

const formFieldErrorReducer = (state: any, action : {target: string; valueToCheck: string | undefined}) : any => {
  const isError = typeof action.valueToCheck === "undefined" ? true : action.valueToCheck === "";
  return {
    ...state,
    [`${action.target}Error`]: isError,
    [`${action.target}ErrorTextShown`]: isError ? state[`${action.target}ErrorText`] : "",

  };
};

export default function ({ location, organizations, open, handleClose, submit, showCustomerSelection, removeLocation }: IModalProps): JSX.Element {
  const { t } = useTranslation();

  const { isMobile } = useResponsivity();

  const initialFormFieldErrorState = {
    /* identifierError: false,
    identifierErrorTextShown: "",
    identifierErrorText: t("locationCreation.identifierErrorText"), */

    addressError: false,
    addressErrorTextShown: "",
    addressErrorText: t("locationCreation.addressErrorText"),

    zipError: false,
    zipErrorTextShown: "",
    zipErrorText: t("locationCreation.zipErrorText"),

    cityError: false,
    cityErrorTextShown: "",
    cityErrorText: t("locationCreation.cityErrorText"),

    countryError: false,
    countryErrorTextShown: "",
    countryErrorText: t("locationCreation.countryErrorText"),
  };

  const classes = useStyles();

  const [missingValues, setMissingValues] = useState(false);
  const [typeState, setTypeState] = useState(location.type || "OTHER");

  const [identifierState, setIdentifier] = useState<Location["identifier"]>(location.city || null);

  const [streetAddressState, setStreetAddressState] = useState(location.city || "");
  const [cityState, setCityState] = useState(location.city || "");
  const [postalCodeState, setPostalCodeState] = useState(location.postalCode || "");
  const [countryState, setCountryState] = useState(location.country || "");
  const [ownerOrganizationIdState, setOwnerOrganizationIdState] = useState(location.ownerOrganizationId || "");
  const [formFieldErrorState, dispatchFormFieldState] = useReducer(formFieldErrorReducer, initialFormFieldErrorState);
  const [confirmLocationDeleteModalOpen, setConfirmLocationDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (location) {
      setIdentifier(location.identifier);
      setStreetAddressState(location.streetAddress);
      setPostalCodeState(location.postalCode);
      setCityState(location.city);
      setCountryState(location.country);
      setOwnerOrganizationIdState(location.ownerOrganizationId);
    }
  }, [location]);

  const clear = (): void => {
    setTypeState("");
    handleClose();
  };

  // todo: type hidden from pilot users
  const handleTypeChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setTypeState(event.target.value as string);
    }
  };

  // by default new locations are created to current users organization
  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      if (event.target.value === "own") {
        setOwnerOrganizationIdState("");
      } else {
        setOwnerOrganizationIdState(event.target.value as string);
      }
    }
  };

  // const requiredValuesMissing = (): boolean => (!streetAddress.current?.value || !postalCode.current?.value || !identifier.current?.value || !city.current?.value || !country.current?.value);
  const requiredValuesMissing = (): boolean => {
    /* dispatchFormFieldState({
      target: "identifier", valueToCheck: identifierState,
    }); */

    dispatchFormFieldState({
      target: "streetAddress", valueToCheck: streetAddressState,
    });

    dispatchFormFieldState({
      target: "zip", valueToCheck: postalCodeState,
    });

    dispatchFormFieldState({
      target: "city", valueToCheck: cityState,
    });

    dispatchFormFieldState({
      target: "country", valueToCheck: countryState,
    });

    return (!streetAddressState || !postalCodeState || !cityState || !countryState);
  };

  const saveLocation = (): void => {
    if (location && !requiredValuesMissing()) {
      setMissingValues(false);
      const editedLocation: Location = {
        id: location.id,
        ownerOrganizationId: ownerOrganizationIdState,
        identifier: identifierState || null,
        streetAddress: streetAddressState || "",
        postalCode: postalCodeState || "",
        city: cityState || "",
        country: countryState || "",
      };
      submit(editedLocation);
      clear();
    } else {
      setMissingValues(true);
    }
  };

  const deleteLocation = () : void => {
    if (location) {
      removeLocation(location);
      handleClose();
    }
  };

  return ReactDOM.createPortal(<>
      <ConfirmationModal
        open={confirmLocationDeleteModalOpen}
        text={t("locationView.confirmDeleteLocation")}
        handleClose={() => setConfirmLocationDeleteModalOpen(false)}
        submit={deleteLocation}
      />
      <Modal
        className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
        open={open}
        onClose={clear}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          <Fade in={open}>
              <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
                  <ModalHeader headerText={t("locationView.editLocation")} clear={clear} id="addLocationTitle" />
                  <div className={classes.section}>
                      <FormControl className={classes.formControlWrapper}>
                          {showCustomerSelection && (
                              <div className={classes.subsection2}>
                                  {organizations
                                        ? (
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="ownerOrganizationLabel">{t("locationView.customer")}</InputLabel>
                                                <Select
                                                  labelId="ownerOrganizationLabel"
                                                  id="ownerOrganizationLabelId"
                                                  value={ownerOrganizationIdState}
                                                  onChange={handleOrganizationDropdownChange}
                                                >
                                                    <MenuItem value="own">
                                                        - ei asiakasta - (Oma organisaatio)
                                                    </MenuItem>
                                                    {organizations.map((organization: Organization, index) => (
                                                        <MenuItem value={organization.id} key={organization.id}>
                                                            {organization.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )
                                        : (<div>Ei asiakasorganisaatioita</div>)}
                              </div>
                              )}
                          {/*       <div className={classes.subsection}>
                                  <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="h2">{t("locationView.type")}</Typography>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="locationTypeLabel">{t("locationView.type")}</InputLabel>
                                      <Select
                                        labelId="locationTypeLabel"
                                        id="locationType"
                                        value={typeState}
                                        onChange={handleTypeChange}
                                        defaultValue="OTHER"
                                      >
                                          <MenuItem value="HOUSING_COMPANY">{t("locationType.HOUSING_COMPANY")}</MenuItem>
                                          <MenuItem value="COMPANY">{t("locationType.COMPANY")}</MenuItem>
                                          <MenuItem value="OTHER">{t("locationType.OTHER")}</MenuItem>
                                      </Select>
                                  </FormControl>
                              </div> */}
                          <div className={classes.subsection}>
                              <TextField
                                id="locationIdentifierInput"
                                label={t("locationView.identifier")}
                                InputLabelProps={{
                                      shrink: true,
                                }}
                                fullWidth
                                variant="outlined"
                                value={identifierState}
                                onChange={(e) => setIdentifier(e.target.value)}
                                defaultValue=""
                                error={formFieldErrorState.identifierError}
                                helperText={formFieldErrorState.identifierErrorTextShown}
                              />
                          </div>

                          <div className={classes.subsection}>
                              <TextField
                                id="streetAddressInput"
                                InputLabelProps={{
                                      shrink: true,
                                }}
                                fullWidth
                                required
                                label={t("locationView.streetAddress")}
                                variant="outlined"
                                value={streetAddressState}
                                onChange={(e) => setStreetAddressState(e.target.value)}
                                error={formFieldErrorState.addressError}
                                helperText={formFieldErrorState.addressErrorTextShown}
                              />
                          </div>
                          <div className={classes.subsection}>
                              <TextField
                                id="postalCodeInput"
                                InputLabelProps={{
                                      shrink: true,
                                }}
                                fullWidth
                                required
                                label={t("locationView.postalCode")}
                                variant="outlined"
                                value={postalCodeState}
                                onChange={(e) => setPostalCodeState(e.target.value)}
                                error={formFieldErrorState.zipError}
                                helperText={formFieldErrorState.zipErrorTextShown}
                              />
                          </div>
                          <div className={classes.subsection}>
                              <TextField
                                id="cityInput"
                                InputLabelProps={{
                                      shrink: true,
                                }}
                                fullWidth
                                required
                                label={t("locationView.city")}
                                variant="outlined"
                                value={cityState}
                                onChange={(e) => setCityState(e.target.value)}
                                error={formFieldErrorState.cityError}
                                helperText={formFieldErrorState.cityErrorTextShown}
                              />
                          </div>
                          <div className={classes.subsection}>
                              <TextField
                                id="countryInput"
                                InputLabelProps={{
                                      shrink: true,
                                }}
                                fullWidth
                                required
                                label={t("locationView.country")}
                                variant="outlined"
                                value={countryState}
                                onChange={(e) => setCountryState(e.target.value)}
                                error={formFieldErrorState.countryError}
                                helperText={formFieldErrorState.countryErrorTextShown}
                              />
                          </div>
                      </FormControl>
                  </div>
                  <div className={classes.navigation}>

                      <Grid item className={classes.modalButtons} xs={12} justifyContent="space-between">
                          {missingValues && (<Typography variant="h6" component="span" className={classes.mandatoryError} id="newlocationmodal-mandatoryerror">{t("locationCreation.requiredValuesMissing")}</Typography>)}

                          {showCustomerSelection && <Typography component="div" className={classes.customerInfo}>{t("locationView.customerInfo")}</Typography>}
                          <Button
                            variant="contained"
                            className={classes.delButton}
                            onClick={() => setConfirmLocationDeleteModalOpen(true)}
                            id="newlocationmodal-delete"
                          >
                              {t("locationCreation.delete")}
                          </Button>
                          <Button
                            onClick={() => clear()}
                            variant="contained"
                            color="secondary"
                            id="CancelInfoChangesButton"
                            className={classes.cancelButton}
                          >
                              {t("common.cancel")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={saveLocation}
                            id="newlocationmodal-save"
                          >
                              {t("locationCreation.finish")}
                          </Button>
                      </Grid>

                  </div>
              </div>
          </Fade>
      </Modal>
  </>, ModalPortalTarget);
};

import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import Location from "../../../models/Location";
import { useRootStore } from "../../../RootStateContext";
import LocationsView from "../LocationsView";
import { loginRequest } from "../../../authConfig";
import CreateLocation from "../../../models/CreateLocation";
import acquireToken from "../../../api/MsalUtil";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const locationsStore = useRootStore().locationStore;
  const { locations, totalLocationsForCard, fetchingLocations, totalLocations, creatingLocationsInProgress } = locationsStore;

  const organizationsStore = useRootStore().organizationStore;
  const { allOmaVesiOrganizations } = organizationsStore;

  const authStore = useRootStore().authenticationStore;
  const { currentUser } = authStore;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [searchJustActivated, setSearchJustActivated] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const [currentUserId, setCurrentUserId] = useState("");
  const { instance, accounts } = useMsal();

  const resetAllOptions = (): void => {
    setArchived(false);
    setSortBy("");
    setSortDirection("desc");
    setSearchValue("");
  };

  const archive = (b: boolean): void => {
    setArchived(b);
  };
  const sort = (field: string): void => {
    setSortBy(field);
  };

  const handlePageChange = (p: number): void => {
    setPage(p);
  };

  const handlePageSizeChange = (p: number): void => {
    setPage(0);
    setPageSize(p);
  };

  const archiveLocation = async (location: Location): Promise<void> => {
    // await locationsStore.updateLocation(location);

    if (locationsStore.error === undefined) {
      toast.info(t("locationView.locationDeleted"), {
        toastId: "LocationDeletedToast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
      });
    }
  };

  const uploadLocationsFile = async (excelFile: File, organizationId?: string): Promise<void> => {
    if (currentUserToken) {
      await locationsStore.uploadMultipleLocationsWithFile(currentUserToken, excelFile, organizationId);
      if (locationsStore.error === undefined) {
        toast.info(t("locationView.locationsMassCreated", {
          createdCount: locationsStore.newlyCreatedLocations.length,
        }), {
          toastId: "LocationsMassCreatedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);

      if (response) { await locationsStore.uploadMultipleLocationsWithFile(response.idToken, excelFile, organizationId); }
      if (locationsStore.error === undefined) {
        toast.info(t("locationView.locationsMassCreated", {
          createdCount: locationsStore.newlyCreatedLocations.length,
        }), {
          toastId: "LocationsMassCreatedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const saveNewLocation = async (location: CreateLocation): Promise<void> => {
    const locationToBeSaved:CreateLocation = {
      type: location.type,
      identifier: location.identifier,
      streetAddress: location.streetAddress,
      postalCode: location.postalCode,
      city: location.city,
      country: location.country,
      ownerOrganizationId: location.ownerOrganizationId,
      createdByUserID: currentUserId,
    };
    if (currentUserToken) {
      await locationsStore.saveNewLocation(currentUserToken, locationToBeSaved);
      if (locationsStore.error === undefined) {
        toast.info(t("locationView.locationCreated"), {
          toastId: "LocationCreatedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);
      if (response) { locationsStore.saveNewLocation(response.idToken, locationToBeSaved); }
      if (locationsStore.error === undefined) {
        toast.info(t("locationView.locationCreated"), {
          toastId: "LocationCreatedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const editLocation = async (location: Location): Promise<void> => {
    if (currentUserToken) {
      await locationsStore.editLocation(currentUserToken, location);
      if (locationsStore.error === undefined) {
        toast.info(t("locationView.locationEdited"), {
          toastId: "LocationEditedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);
      if (response) { locationsStore.editLocation(response.idToken, location); }
      if (locationsStore.error === undefined) {
        toast.info(t("locationView.locationEdited"), {
          toastId: "LocationEditedToast",
          autoClose: 9000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  const removeLocation = async (location: Location): Promise<void> => {
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const response = await acquireToken(instance, request);
      if (response) {
        try {
          await locationsStore.removeLocation(response.idToken, location);
          if (locationsStore.error === undefined) {
            toast.info(t("locationView.locationRemoved"), {
              toastId: "locationRemovedToast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        } catch (e) {
          console.error("Location deletion failed");
        }
      }
    } else {
      try {
        await locationsStore.removeLocation(currentUserToken, location);
        if (locationsStore.error === undefined) {
          toast.info(t("locationView.locationRemoved"), {
            toastId: "locationRemovedToast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.error("Location deletion failed");
      }
    }
  };

  const loadLocationsAndOrganizations = useCallback(async (userToken:string, mounted:boolean): Promise<void> => {
    if (searchValue && searchJustActivated) {
      setPage(0);
      setSearchJustActivated(false);
    }
    await locationsStore.loadLocationsPaged(userToken, page, pageSize, sortBy === "" ? undefined : sortBy, sortDirection, archived, searchValue)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
    organizationsStore.loadAllSubOrganizations(userToken)
      .catch(() => {
        if (mounted) {
          setForbidden(true);
        }
      });
  }, [archived, locationsStore, organizationsStore, page, pageSize, searchJustActivated, searchValue, sortBy, sortDirection]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!currentUserToken) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        const response = await acquireToken(instance, request);

        if (response) {
          setCurrentUserToken(response.idToken);
          setCurrentUserId(response.uniqueId);

          const locationsAndOrganizationsPromise = loadLocationsAndOrganizations(response.idToken, mounted);
          const totalLocationCountPromise = locationsStore.getTotalLocationCount(response.idToken);

          await Promise.allSettled([locationsAndOrganizationsPromise, totalLocationCountPromise]);
        }
      } else {
        const locationsAndOrganizationsPromise = loadLocationsAndOrganizations(currentUserToken, mounted);
        const totalLocationCountPromise = locationsStore.getTotalLocationCount(currentUserToken);

        await Promise.allSettled([locationsAndOrganizationsPromise, totalLocationCountPromise]);
      }
    })();
    return function cleanup() {
      mounted = false;
      locationsStore.clearState();
      organizationsStore.clearState();
    };
  }, [page, pageSize, sortBy, sortDirection, archived, searchValue, accounts, instance, locationsStore, organizationsStore, loadLocationsAndOrganizations]); // DO NOT ADD currentUserToken

  if (!currentUser || forbidden) {
    return <UnauthorizedComponent />;
  }

  return (
      <LocationsView
        sort={sort}
        setSortDirection={setSortDirection}
        page={page}
        rowsPerPage={pageSize}
        setPage={handlePageChange}
        totalLocations={totalLocations}
        totalLocationsForCard={totalLocationsForCard}
        setPageSize={handlePageSizeChange}
        deleteLocation={archiveLocation}
        saveNewLocation={saveNewLocation}
        editLocation={editLocation}
        removeLocation={removeLocation}
        uploadLocationsFile={uploadLocationsFile}
        locations={locations}
        organizations={allOmaVesiOrganizations}
        setArchived={archive}
        search={setSearchValue}
        setSearchJustActivated={setSearchJustActivated}
        resetAllOptions={resetAllOptions}
        creatingLocationsInProgress={creatingLocationsInProgress}
        activeSearchValue={searchValue}
        currentUser={currentUser}
      />
  );
});

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { Button, Typography } from "@mui/material";
import { useRootStore } from "../../../../RootStateContext";
import useStyles from "./OrganizationsOfHousingManagementOrganizationStyles";
import PendingCircle from "../../../../components/PendingCircle/PendingCircle";
import { Organization } from "../../../../models/Organization";
import Table, { Column } from "../../../../components/table/TableWithPagination";

interface IOrganizationMeterManagementViewProps {
  organization: Organization;
  organizations?: Organization[];
  addOrganizationAccessToOrganization: (m: Organization) => void;
}

export default function ({ organization, organizations, addOrganizationAccessToOrganization }: IOrganizationMeterManagementViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const metersStore = useRootStore().meterStore;
  const { fetchingMeters, totalMetersInTable } = metersStore;
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [archived, setArchived] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();

  const [addOrganizationModalOpen, setAddOrganizationModalOpen] = useState(false);

  const organizationColumns: Column[] = [
    {
      id: "name",
      label: "Nimi",
      minWidth: 100,
    },
    {
      id: "organizationType", label: "Tyyppi", minWidth: 170,
    },
    {
      id: "accountId",
      label: "Asiakastunnus",
      minWidth: 170,
    },
  ];

  const sort = (field: string): void => {
    setSortBy(field);
  };

  if (fetchingMeters) {
    return (
        <PendingCircle />
    );
  }

  if (!organizations || organizations.length === 0) {
    return (
        <div className={classes.noData}><Typography className={classes.headerContainer} variant="h5" component="h5">{t("organizationView.noOrganizationsForManager")}</Typography></div>
    );
  }

  return (
      <>
          <Button
            color="primary"
            variant="contained"
            id="AddNewUserButton"
            className={classes.addButton}
            onClick={() => setAddOrganizationModalOpen(true)}
          >
              {t("userManagementView.createNewUser")}
          </Button>
          <Table<Organization>
            columns={organizationColumns}
            rows={organizations || []}
            handlePageChange={setPage}
            handlePageSizeChange={setPageSize}
            count={totalMetersInTable}
            currentPage={page}
            currentRowsPerPage={25}
            sort={sort}
            setSortDirection={setSortDirection}
          />
      </>

  );
};

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { useRootStore } from "../../../RootStateContext";
import ConsumerHomePage from "../HomePage";
import { loginRequest } from "../../../authConfig";
import acquireToken, { tokenAction } from "../../../api/MsalUtil";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";
import { UserRole } from "../../../models/UserRole";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);

  const metersStore = useRootStore().meterStore;
  const alertsStore = useRootStore().alertStore;
  const authStore = useRootStore().authenticationStore;
  const locationsStore = useRootStore().locationStore;

  const { totalMetersOfUser, fetchingMeterCount } = metersStore;
  const { totalUnreadAlertCount, fetchingAlertCount } = alertsStore;
  const { totalLocationsForCard, fetchingLocationsCount } = locationsStore;

  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const { currentUser } = authStore;

  useEffect(() => {
    let mounted = true;

    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        await metersStore.getTotalMeterCount(idToken);

        if (!authStore.currentUser) { await authStore.fetchUserDetails(idToken); }
        if (currentUser?.userRole !== UserRole.OV_RESIDENT) { await locationsStore.getTotalLocationCount(idToken); }
      }, currentUserToken, accounts, instance);
    })();

    return function cleanup() {
      mounted = false;
      metersStore.clearState();
      locationsStore.clearState();
    };
  }, [metersStore, accounts, instance, authStore]); // DO NOT ADD currentUserToken

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  if (!currentUser) {
    return <PendingCircle />;
  }

  return (
      <ConsumerHomePage
        totalMetersForCard={totalMetersOfUser}
        fetchingMeterCount={fetchingMeterCount}
        currentUser={currentUser}
        totalAlertsForCard={totalUnreadAlertCount}
        totalLocationsForCard={totalLocationsForCard}
        fetchingAlertCount={fetchingAlertCount}
        fetchingLocationsCount={fetchingLocationsCount}
      />
  );
});

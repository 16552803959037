import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  FormControl,
  TextField,
  Typography, Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material";

import fi from "date-fns/locale/fi";
import TextField2 from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { useMsal } from "@azure/msal-react";
import { UserForLocationAccessEditing, UserInfo } from "../../../../models/UserInfo";
import { UserRole } from "../../../../models/UserRole";
import ModalHeader from "../../../../components/modalComponents/modalHeader";
import { useRootStore } from "../../../../RootStateContext";
import useStyles from "./GrantAccessModalStyles";
import { convertToStaticDateString } from "../../../../components/reportCreator/Util";
import { tokenAction } from "../../../../api/MsalUtil";
import ModalPortalTarget from "../../../../stores/ModalPortalTarget";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (u: UserForLocationAccessEditing) => void;
    users?: UserInfo[];
    locationId: string;
}

export default observer(({ open, handleClose, submit, locationId }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userStore } = useRootStore();
  const [userRoleInLocation, setUserRoleInLocation] = useState(UserRole.OV_RESIDENT);
  const [accessStartedAt, setAccessStartedAt] = useState<null | Date>(null);

  const [userState, setUserState] = useState<UserInfo | undefined>(undefined);
  const [userSearchInputState, setUserSearchInputState] = useState("");

  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const authStore = useRootStore().authenticationStore;

  const close = (): void => {
    setUserState(undefined);
    setUserSearchInputState("");
    setAccessStartedAt(null);
    handleClose();
  };

  const saveNewAccessRight = (): void => {
    let staticAccessDate = null;

    if (userState && userState.identityUserId) {
      if (accessStartedAt === null) {
        console.log("Error: Access start date is required when location is selected");
        return;
      }

      staticAccessDate = convertToStaticDateString(accessStartedAt);

      const residentUser: UserForLocationAccessEditing = {
        identityUserId: userState.identityUserId,
        locationId,
        roleInLocation: userRoleInLocation,
        removeAccessFromUser: false,
        locationAccessStartedAt: staticAccessDate,
      };
      submit(residentUser);
      close();
    } else {
      console.log("error");
    }
  };

  const handleUserDropdownChange = (event: React.SyntheticEvent, value: UserInfo | null, reason: AutocompleteChangeReason): void => {
    switch (reason) {
      case "clear":
        setUserState(undefined);
        setUserSearchInputState("");
        break;
      case "selectOption":
        if (value) {
          setUserState(value);
          setUserSearchInputState(`${value.firstName} ${value.lastName}, ${value.email}`);
        }
        break;
      default:
          break;
    }
  };

  const onInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason): void => {
    if (reason !== "reset") {
      if (value === "") {
        setUserState(undefined);
        setUserSearchInputState("");
      } else {
        setUserSearchInputState(value);
      }
    }
  };

  useEffect(() => {
    if (userSearchInputState.length <= 2) {
      userStore.clearUserGrantAccessUsers();
      return undefined;
    }

    if (!open) { return undefined; }

    const controller = new AbortController();
    const { signal } = controller;
    const search = userSearchInputState.includes(",")
    ? userSearchInputState.substring(0, userSearchInputState.indexOf(","))
    : userSearchInputState;

    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        await userStore.loadUserGrantAccessUsers(idToken, search, signal);

        if (userState) { setUserState(userStore.userGrantAccessSelectUsers.find((l) => l.id === userState.id)); }
      }, currentUserToken, accounts, instance);
    })();

    return () => {
      controller.abort();
    };
  }, [accounts, instance, userSearchInputState, userStore]);

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={close}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <ModalHeader headerText={t("locationView.giveAccessToExistingUser")} clear={close} id="addUserTitle" />
              <div className={classes.subsection}>
                  <FormControl className={classes.formControl}>
                      <Typography>
                          {t("userManagementView.selectUserForAccessGrant")}
                      </Typography>
                      <Autocomplete
                        disablePortal
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        loading={userStore.fetchingUserGrantAccessSelectUsers}
                        id="userGrantAccessSelectLabelId"
                        onChange={(e, v, r) => { handleUserDropdownChange(e, v as UserInfo, r); }}
                        options={
                                  userStore.userGrantAccessSelectUsersAvailable > 0
                                  ? [...userStore.userGrantAccessSelectUsers, `+ ${userStore.userGrantAccessSelectUsersAvailable} tulosta`]
                                  : userStore.userGrantAccessSelectUsers
                                }
                        renderInput={(params) => <TextField {...params} label={t("locationView.selectGrantAccessUser")} />}
                        renderOption={(props, option) => {
                                  if (typeof option !== "string") {
                                    return (
                                        <li {...props} key={option.id}>
                                            {`${option.firstName} ${option.lastName}, ${option.email}`}
                                        </li>
                                    );
                                  }
                                  return (
                                      <li {...props} key="user_grant_additional_locations">
                                          {option as string}
                                      </li>
                                  );
                        }}
                        value={userState}
                        inputValue={userSearchInputState}
                        getOptionLabel={(option) => (typeof option !== "string" ? `${option.firstName} ${option.lastName}, ${option.email}` : option)}
                        isOptionEqualToValue={(option, value) => (typeof value !== "undefined" && typeof value !== "string" && typeof option !== "string" && option.id === value.id)}
                        onInputChange={onInputChange}
                        filterOptions={(x) => x}
                        noOptionsText={t("locationView.noGrantAccessUserOptions")}
                        getOptionDisabled={(option) => typeof option === "string"}
                      />
                  </FormControl>
              </div>
              <div
                className={classes.subsection}
                style={{
                          flexDirection: "column",
                          marginBottom: "15px",
                }}
              >
                  <FormControl className={classes.formControl}>
                      <Typography>
                          {t("userManagementView.selectAccessStartedAt")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                          <div id="addToLocationAccessStart" className={classes.datePicker}>
                              <DatePicker
                                label={t(`userManagementView.accessStartedAt`)}
                                value={accessStartedAt}
                                inputFormat="dd.MM.yyyy"
                                onChange={(newValue : any) => {
                                          setAccessStartedAt(newValue);
                                }}
                                renderInput={(params : any) => <TextField2 {...params} />}
                                showToolbar={false}
                              />
                          </div>
                      </LocalizationProvider>
                  </FormControl>
              </div>
              <div className={classes.navigation}>

                  <div className={classes.navButtons}>
                      <Button
                        variant="contained"
                        id="closeCreateUserModal"
                        className={classes.backButton}
                        color="secondary"
                        onClick={close}
                      >
                          {t("userManagementView.cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        id="createUserButton"
                        className={classes.nextButton}
                        color="primary"
                        onClick={saveNewAccessRight}
                      >
                          {t("userManagementView.saveUser")}
                      </Button>
                  </div>

              </div>
          </div>
      </Fade>
                               </Modal>, ModalPortalTarget);
});

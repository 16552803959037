import React, { useEffect, useReducer, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography, Grid, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import useStyles from "./EditUserModalStyles";
import { Action, ActionType, onInputChange, State, validateInput } from "../FormUtils";
import { useRootStore } from "../../../RootStateContext";
import { UserRole } from "../../../models/UserRole";
import { Organization } from "../../../models/Organization";
import { OrganizationType, UserInfo, UserInfoForUpdating } from "../../../models/UserInfo";
import useResponsivity from "../../../hooks/useResponsivity";
import ModalHeader from "../../../components/modalComponents/modalHeader";
import ConfirmationModal from "../../../components/confirmationModal/ConfirmationModal";
import ModalPortalTarget from "../../../stores/ModalPortalTarget";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (u: UserInfoForUpdating, id: string) => void;
    showOrganizations: boolean;
    organizations?: Organization[];
    removeUser: (user: UserInfo) => void;
}

const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.UPDATE) {
    const { name, value, hasError, error } = action.data;
    return {
      ...state,
      [name]: {
        ...state[name], value, hasError, error,
      },
    };
  }
  return state;
};
const initialValidationState = {
  firstName: {
    value: "", hasError: true, error: "",
  },
  email: {
    value: "", hasError: true, error: "",
  },
  lastName: {
    value: "", hasError: true, error: "",
  },
  role: {
    value: "", hasError: true, error: "",
  },
  organization: {
    value: "", hasError: true, error: "",
  },
  mobilePhone: {
    value: "", hasError: true, error: "",
  },
};

const selectableUserRoles:UserRole[] = [UserRole.OV_CUSTOMER_ADMIN, UserRole.OV_RESIDENT];

export default observer(({ open, handleClose, submit, organizations, showOrganizations, removeUser }: IModalProps): JSX.Element => {
  const user = useRootStore().userStore.selectedUser;
  const { isMobile } = useResponsivity();

  const classes = useStyles();
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(user?.active || true);
  const [validationState, dispatch] = useReducer(reducer, initialValidationState);
  const [organizationId, setOrganizationId] = useState("");
  const [userRole, setUserRole] = useState(user?.userRole || UserRole.OV_RESIDENT);

  const [disableUserRoleSelection, setDisableUserRoleSelection] = useState(true);

  const [confirmUserDeleteModalOpen, setConfirmUserDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "firstName",
        value: user?.firstName,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "lastName",
        value: user?.lastName,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "email",
        value: user?.email,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "mobilePhone",
        value: user?.mobilePhone,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "role",
        value: user?.userRole,
        hasError: false,
        error: "",
      },
    });
    dispatch({
      type: ActionType.UPDATE,
      data: {
        name: "organization",
        value: user?.organizationId,
        hasError: false,
        error: "",
      },
    });
    if ((user?.usersOrganizationType === null || user?.usersOrganizationType === OrganizationType.WATER_COMPANY || user?.usersOrganizationType === OrganizationType.RETAILER)) {
      setDisableUserRoleSelection(true);
    } else {
      setDisableUserRoleSelection(false);
    }
    setOrganizationId(user?.organizationId || "");
    setUserRole(user?.userRole || UserRole.OV_RESIDENT);
    setActive(user?.active || true);
  }, [user]);

  const isReadyToBeSaved = (): boolean => {
    let invalid = false;
    Object.keys(validationState).forEach((name) => {
      if (Object.prototype.hasOwnProperty.call(validationState, name)) {
        const item = validationState[name];
        if (item !== undefined) {
          const { value } = item;
          const { hasError, error } = validateInput(name, value);
          if (hasError) {
            console.log(`Error in field: ${name}, value: ${value}`);
            invalid = true;
          }
          if (name) {
            dispatch({
              type: ActionType.UPDATE,
              data: {
                name,
                value,
                hasError,
                error,
              },
            });
          }
        }
      }
    });
    return !invalid;
  };

  const close = (): void => {
    dispatch({
      type: ActionType.RESET, data: {},
    });
    setOrganizationId("");
    handleClose();
  };

  const saveUser = (): void => {
    if (isReadyToBeSaved()) {
      const u: UserInfoForUpdating = {
        id: user?.id,
        identityUserId: user?.identityUserId || "",
        firstName: validationState.firstName.value,
        lastName: validationState.lastName.value,
        email: validationState.email.value,
        active: active || false,
        role: userRole || UserRole[validationState.role.value as UserRole] || user?.userRole || UserRole.OV_RESIDENT,
        organizationId: organizationId || validationState.organization.value,
        mobilePhone: validationState.mobilePhone.value || undefined,
      };
      submit(u, user?.id || "");
      close();
    } else {
      console.log("error");
    }
  };

  const handleRoleDropDownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      onInputChange("role", event.target.value as string, dispatch);
      setUserRole(event.target.value as UserRole);
    }
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      onInputChange("organization", event.target.value as string, dispatch);
      setOrganizationId(event.target.value as string);
    }
  };

  const deleteUser = () : void => {
    if (user) {
      removeUser(user);
      handleClose();
    }
  };

  return ReactDOM.createPortal(<>
      <ConfirmationModal
        open={confirmUserDeleteModalOpen}
        text={t("userView.confirmDeleteUser")}
        handleClose={() => setConfirmUserDeleteModalOpen(false)}
        submit={deleteUser}
      />
      <Modal
        className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
        open={open}
        onClose={close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          <Fade in={open}>
              <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
                  <ModalHeader headerText={t("userManagementView.editUser")} clear={close} id="modifyUserTitle" />
                  <div className={classes.section}>
                      <div className={classes.subsection}>
                          <TextField
                            id="firstNameInput"
                            label={t("userManagementView.firstName")}
                            InputLabelProps={{
                                  shrink: true,
                            }}
                            required
                            error={validationState?.firstName?.error !== ""}
                            helperText={validationState?.firstName?.error}
                            onChange={(e) => onInputChange("firstName", e.target.value, dispatch)}
                            variant="outlined"
                            className={classes.firstName}
                            defaultValue={user?.firstName}
                          />
                          <div className={classes.divider} />
                          <TextField
                            className={classes.lastName}
                            id="lastNameInput"
                            required
                            error={validationState?.lastName?.error !== ""}
                            helperText={validationState?.lastName?.error}
                            onChange={(e) => onInputChange("lastName", e.target.value, dispatch)}
                            InputLabelProps={{
                                  shrink: true,
                            }}
                            label={t("userManagementView.lastName")}
                            variant="outlined"
                            defaultValue={user?.lastName}
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="emailInput"
                            fullWidth
                            required
                            error={validationState?.email?.error !== ""}
                            helperText={validationState?.email?.error}
                            onChange={(e) => onInputChange("email", e.target.value, dispatch)}
                            InputLabelProps={{
                                  shrink: true,
                            }}
                            label={t("userManagementView.email")}
                            variant="outlined"
                            defaultValue={user?.email}
                          />
                      </div>
                      <div className={classes.subsection}>
                          <TextField
                            id="phoneNumberInput"
                            fullWidth
                            required
                            error={validationState?.mobilePhone?.error !== ""}
                            helperText={validationState?.mobilePhone?.error}
                            onChange={(e) => onInputChange("mobilePhone", e.target.value, dispatch)}
                            InputLabelProps={{
                                  shrink: true,
                            }}
                            label={t("userManagementView.mobilePhone")}
                            variant="outlined"
                            defaultValue={user?.mobilePhone}
                          />
                      </div>
                      {showOrganizations && organizations
                          && (
                              <div className={classes.subsection}>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel required id="organizationSelectLabel">{t("userManagementView.organization")}</InputLabel>
                                      <Select
                                        error={validationState?.organization?.error !== ""}
                                        labelId="orgLabel"
                                        id="orgLabelId"
                                        value={organizationId || user?.organizationId}
                                        onChange={handleOrganizationDropdownChange}
                                      >
                                          {organizations.map((org) => (
                                              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{t("userManagementView.organizationEditHelperText")}</FormHelperText>
                                  </FormControl>
                              </div>

                          )}
                      <div className={classes.subsection}>
                          <FormControl className={classes.formControl}>
                              <InputLabel required id="userRoleLabel">{t("userManagementView.role")}</InputLabel>
                              <Select
                                labelId="roleLabel"
                                id="roleLabelId"
                                value={userRole}
                                onChange={handleRoleDropDownChange}
                                disabled={disableUserRoleSelection}
                              >
                                  {selectableUserRoles.map((role) => (
                                      <MenuItem value={role} key={role}>{t(`userRole.${role}`)}</MenuItem>
                                      ))}
                              </Select>
                              <FormHelperText>{t("userManagementView.roleHelperText")}</FormHelperText>
                          </FormControl>
                      </div>

                      {/*  <div className={classes.subsection}>
                              <FormControlLabel
                                control={(
                                    <Checkbox
                                      id="isUserActive"
                                      checked={active}
                                      onChange={() => { setActive((a) => !a); }}
                                      name={t("userManagementView.active")}
                                      color="primary"
                                    />
                                )}
                                label={t("userManagementView.active")}
                              />
                          </div> */}
                  </div>
                  <div className={classes.navigation}>
                      <Grid item className={classes.modalButtons} xs={12} justifyContent="space-between">
                          <Button
                            variant="contained"
                            className={classes.delButton}
                            onClick={() => setConfirmUserDeleteModalOpen(true)}
                            id="usermodal-delete"
                          >
                              {t("locationCreation.delete")}
                          </Button>
                          <Button
                            variant="contained"
                            id="closeEditUserModal"
                            className={classes.cancelButton}
                            color="secondary"
                            onClick={close}
                          >
                              {t("common.cancel")}
                          </Button>
                          <Button
                            variant="contained"
                            id="saveUserButton"
                            color="primary"
                            onClick={saveUser}
                          >
                              {t("common.save")}
                          </Button>
                      </Grid>

                  </div>
              </div>
          </Fade>
      </Modal>
                               </>, ModalPortalTarget);
});

import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  topContainer:
  {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  addNewLocationButton: {
    marginBottom: 10,
  },
  buttonShadow: {
    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  createReportButton: {
    float: "right",
    marginBottom: 10,
    "& .MuiSvgIcon-root": {
      top: -1,
      position: "relative",
      paddingRight: 2,
      fontSize: "1.2rem",
    },
  },
  addLocationsWithFileButton: {
    marginBottom: 10,
    marginLeft: 10,
  },
  headerContainer: {
    marginBottom: 50,
  },
  noLocations: {
    marginTop: 50,
    padding: 10,
  },
  locationsTitle: {
    marginTop: 50,
  },
  searchInput: {
    width: "240px",
    marginTop: "7px",
  },
  activeSearchWord: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
    color: theme.palette.primary.dark,
  },
  locationsHeadGrid:
  {},
  locationsHeadCardsContainer:
  {
    paddingTop: "15px",
  },
  searchInputContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "stretch",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
  },
  searchButton: {
    height: "45px",
    marginLeft: 15,
    borderRadius: 5,
  },
  clearSearchButton: {
    height: "45px",
    marginLeft: 15,
    borderRadius: 5,
  },
  headerTop: {
    color: "#04444B",
  },
  locationTableButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px 0px 10px 0px",
    margin: "10px 0px 0px 0px",
  },
  botContainer:
  {
    padding: `${CSS_GLOBALS.mainContainerPadding} !important`,
  },

  searchInputMobile: {
    width: "240px",
    marginTop: "15px",
    marginRight: "10px",
    float: "right",
  },

  clearSearchButtonMobile: {
    height: "40px",
    float: "right",
    borderRadius: 5,
    marginTop: "15px",
    marginRight: "15px",
  },

  activeSearchWordMobile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
    marginRight: "15px",
    color: theme.palette.primary.dark,
  },

  containerMobile: {
    padding: "0px 0px 20px 0px",
  },

  topContainerMobile: {
    padding: "0px 10px 15px 10px !important",
  },
}));

import React, { useMemo } from "react";
import LocationStore from "./stores/LocationStore";
import UIStateStore from "./stores/UIStateStore";
import AuthenticationStore from "./stores/AuthenticationStore";
import UserStore from "./stores/UserStore";
import MeterStore from "./stores/MeterStore";
import OrganizationStore from "./stores/OrganizationStore";
import ReportStore from "./stores/ReportStore";
import DataStore from "./stores/DataStore";
import AlertStore from "./stores/AlertStore";
import MapStore from "./stores/MapStore";

export interface RootStateContextValue {
    locationStore: LocationStore,
    uiStateStore: UIStateStore,
    authenticationStore: AuthenticationStore,
    userStore: UserStore,
    meterStore: MeterStore,
    organizationStore: OrganizationStore,
    reportStore: ReportStore,
    dataStore: DataStore,
    alertStore: AlertStore,
    mapStore: MapStore,
}

const RootStateContext = React.createContext<RootStateContextValue>({} as RootStateContextValue);

const locationStore = new LocationStore();
const uiStateStore = new UIStateStore();
const authenticationStore = new AuthenticationStore();
const userStore = new UserStore();
const meterStore = new MeterStore();
const organizationStore = new OrganizationStore();
const reportStore = new ReportStore();
const dataStore = new DataStore();
const alertStore = new AlertStore();
const mapStore = new MapStore();

export const RootStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const rootStateContextValue = useMemo(() => ({
        locationStore, uiStateStore, authenticationStore, userStore, meterStore, organizationStore, reportStore, dataStore, alertStore, mapStore,
    }), []);

    return (
        <RootStateContext.Provider value={rootStateContextValue}>
            {children}
        </RootStateContext.Provider>
    );
}

export const useRootStore = (): RootStateContextValue => React.useContext(RootStateContext);

export enum AlertType {
    WATER_LEAK = "WATER_LEAK",
    LOW_BATTERY = "LOW_BATTERY",
    OVERFLOW = "OVERFLOW",
    BURST = "BURST",
    REVERSE_FLOW = "REVERSE_FLOW",
    WRONG_INSTALLATION = "WRONG_INSTALLATION",
    TAMPER = "TAMPER",
    PERMANENT = "PERMANENT",
    CONSUMPTION = "CONSUMPTION",
    CONSUMPTION_WEEK = "CONSUMPTION_WEEK",
    CONSUMPTION_MONTH = "CONSUMPTION_MONTH",
    CONSUMPTION_YEAR = "CONSUMPTION_YEAR",
    CONNECTION_ERROR = "CONNECTION_ERROR",
    LOW_TEMPERATURE = "LOW_TEMPERATURE",
    NO_WATER = "NO_WATER"
}

type Alert = {
    id: string,
    alertType: AlertType;
    meterId: string;
    meterNumber: string;
    alertStartedAt: Date;
    alertCheckedAt: Date | undefined;
    alertEndedAt: Date | undefined;
    alertCanEnd?: boolean;
    address?: string;
    checkedByName: string;
    meterType?: string;
    meterModel?: string;
    comment?: string;
}

export default Alert;

export type AlertResponseWrapper = {
    content: Alert[];
    first: boolean;
    last: boolean;
    totalElements: number;
};

export type MapAlert =
{
    name: string;
    started: string;
    type: string | null;
}

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useIsAuthenticated, useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import moment from "moment";
import NavigationBar from "../../components/navigationBar/NavigationBar";
import NavigationBarMobile from "../../components/navigationBarMobile/NavigationBarMobile";
import Header from "../../components/header/Header";
import "react-toastify/dist/ReactToastify.css";
import { useWhitelabelTheme } from "../../materialUITheme";
import StoreErrorToaster from "../../stores/StoreErrorToaster";
import useStyles from "./MainViewStyles";
import LocationsRootView from "../locationsView/locationsRootView/LocationsRootView";
import { useRootStore } from "../../RootStateContext";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import UnauthorizedComponent from "../../components/unauthorizedComponent/UnauthorizedComponent";
import NoOmaVesiAccessComponent from "../../components/noOmaVesiAccessComponent/NoOmaVesiAccessComponent";
import SingleLocationRootView from "../singleLocationView/singleLocationRootView/SingleLocationRootView";
import { loginRequest } from "../../authConfig";
import MetersRootView from "../metersView/metersRootView/MetersRootView";
import SingleMeterRootView from "../singleMeterView/singleMeterRootView/SingleMeterRootView";
import ReportsRootView from "../reportsView/reportsRootView/ReportsRootView";
import acquireToken from "../../api/MsalUtil";
import OrganizationsAndUsersRootView from "../userManagement/organizationsAndUsersRootView/OrganizationsAndUsersRootView";
import HomePageRootView from "../homeView/HomePageRootView/HomePageRootView";
import AlertsRootView from "../alertsView/alertsViewRootView/AlertsRootView";
import PriceRootView from "../priceView/PriceRootView/PriceRootView";
import SingleOrganizationRootView
  from "../singleOrganizationView/singleOrganizationRootView/SingleOrganizationRootView";
import useResponsivity from "../../hooks/useResponsivity";
import ReportSettingsRootView from "../reportSettingsView/reportSettingsRootView/ReportSettingsRootView";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default observer((): JSX.Element => {
  const { isMobile } = useResponsivity();
  const authStore = useRootStore().authenticationStore;
  const alertsStore = useRootStore().alertStore;
  const classes = useStyles();
  const { error } = authStore;
  const { fetchingUserDetails, currentUser } = authStore;
  const { instance, accounts, inProgress } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const [userName, setUserName] = useState("");

  const isAuthenticated = useIsAuthenticated();

  const theme = useWhitelabelTheme();

  const signoutUrl = process.env.REACT_APP_SIGNOUT_URL || "";

  moment.updateLocale("fi", {
    months: ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"],
    monthsShort: ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
    weekdays: ["Sunnuntai", "Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai"],
    weekdaysShort: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
    weekdaysMin: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
  });

  const fetchData = async (userToken: string): Promise<void> => {
    await authStore.fetchUserDetails(userToken);
    await alertsStore.getUnreadAlertCount(userToken, false);
  };

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        const tokenResponse = await instance.handleRedirectPromise();
        if (tokenResponse) {
          const accounts = instance.getAllAccounts();

          if (accounts.length === 0) {
            instance.loginRedirect();
          }

          const request = {
            ...loginRequest,
            account: accounts[0],
          };

          if (!currentUserToken) {
            const response = await acquireToken(instance, request);
            if (response) {
              setCurrentUserToken(response.idToken);
              authStore.setIdToken(response.idToken);
              setUserName(currentUser?.userName || "");
              await fetchData(response.idToken);
            }
          } else {
            await fetchData(currentUserToken);
          }
        } else {
          instance.loginRedirect();
        }
      } else {
        const request = {
          ...loginRequest,
          account: instance.getAllAccounts()[0],
        };

        if (inProgress === InteractionStatus.None) {
          if (!currentUserToken) {
            const response = await acquireToken(instance, request);
            if (response) {
              setCurrentUserToken(response.idToken);
              authStore.setIdToken(response.idToken);
              setUserName(response.account?.username || "");
              await fetchData(response.idToken);
            }
          } else {
            await fetchData(currentUserToken);
          }
        }
      }
    })();

    return function cleanup() {
      authStore.clearState();
    };
  }, [isAuthenticated, inProgress, instance, authStore, alertsStore]); // DO NOT ADD currentUserToken

  const getUnReadAlertCount = async ():Promise<void> => {
    if (currentUserToken) {
      await alertsStore.getUnreadAlertCount(currentUserToken, true);
    } else {
      const request = {
        ...loginRequest,
        account: instance.getAllAccounts()[0],
      };
      const response = await acquireToken(instance, request);
      if (response) {
        await alertsStore.getUnreadAlertCount(response.idToken, true);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(getUnReadAlertCount, 60000);
    return () => clearInterval(timer);
  }, []);

  if (!isAuthenticated || fetchingUserDetails) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <PendingCircle />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
  }

  // Error means that backend didn't get them from the console == their organization doesn't have omavesi-access
  if ((error || !currentUser) && !fetchingUserDetails) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <Header userName={userName} />
                    <NoOmaVesiAccessComponent />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
  }

  if (currentUser) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <UnauthenticatedTemplate>
                        <UnauthorizedComponent />
                    </UnauthenticatedTemplate>
                    <Router>
                        <StoreErrorToaster />
                        <Header userName={currentUser?.userName} organizationName={currentUser?.organizationName} userRole={currentUser?.userRole} />
                        {!isMobile && <NavigationBar userRole={currentUser.userRole} organizationType={currentUser.usersOrganizationType} activeAlerts={alertsStore.totalUnreadAlertCount} />}
                        <Paper elevation={0} className={`${classes.content} ${isMobile ? classes.contentMobile : ""}`}>

                            <div className={classes.toolbar} />
                            <Routes>
                                <Route path="/meters" element={<MetersRootView />} />
                                <Route path="/" element={<Navigate to="/home" />} />
                                <Route path="/home" element={<HomePageRootView />} />
                                <Route path="/alerts" element={<AlertsRootView />} />
                                <Route path="/meter/:meterId" element={<SingleMeterRootView />} />
                                <Route path="/locations" element={<LocationsRootView />} />
                                <Route path="/location/:locationId" element={<SingleLocationRootView />} />
                                <Route path="/organization/:organizationId" element={<SingleOrganizationRootView />} />
                                <Route path="/reports" element={<ReportsRootView />} />
                                <Route path="/reportsettings" element={<ReportSettingsRootView />} />
                                <Route path="/prices" element={<PriceRootView />} />
                                <Route path="/usermanagement" element={<OrganizationsAndUsersRootView />} />
                            </Routes>
                        </Paper>
                        {isMobile && <NavigationBarMobile userRole={currentUser.userRole} organizationType={currentUser.usersOrganizationType} activeAlerts={alertsStore.totalUnreadAlertCount} />}
                    </Router>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
  }
  return (
      <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}><PendingCircle /></ThemeProvider>
      </StyledEngineProvider>
  );
});

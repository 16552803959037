import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import UsersTable, { Column } from "../../../../components/table/TableWithPagination";
import { UserForResidentCreation, UserInfo } from "../../../../models/UserInfo";
import { useRootStore } from "../../../../RootStateContext";
import useStyles from "./UsersOfOrganizationStyles";
import PendingCircle from "../../../../components/PendingCircle/PendingCircle";
import { Organization } from "../../../../models/Organization";

interface IOrganizationManagementViewProps {
  organization: Organization;
  usersOfOrganization?: UserInfo[];
  saveUser: (s: UserForResidentCreation) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  page: number;
  rowsPerPage: number
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  search: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
  allUsers?: UserInfo[];
}

export default function ({ organization, usersOfOrganization, saveUser, search, setPage, setPageSize, page, rowsPerPage, sort, setSortDirection, setSearchJustActivated, resetAllOptions, activeSearchValue, allUsers }: IOrganizationManagementViewProps): JSX.Element {
  const { t } = useTranslation();
  const usersStore = useRootStore().userStore;
  const { totalUsers, fetchingOrganizationsUsers } = usersStore;
  const classes = useStyles();

  const userColumns: Column[] = [
    {
      id: "firstName",
      label: t("user.firstName"),
      minWidth: 170,
    },
    {
      id: "lastName",
      label: t("user.lastName"),
      minWidth: 170,
    },
    {
      id: "email",
      label: t("user.email"),
      minWidth: 170,
    },
    {
      id: "userRole",
      label: "Rooli",
      minWidth: 170,
    },
  ];

  if (fetchingOrganizationsUsers) {
    return (
        <PendingCircle />
    );
  }

  if (!usersOfOrganization || usersOfOrganization.length === 0) {
    return (
        <div className={classes.noData}><Typography className={classes.headerContainer} variant="h5" component="h5">{t("organizationView.noUsersInOrganization")}</Typography></div>
    );
  }

  return (
      <UsersTable<UserInfo>
        columns={userColumns}
        rows={usersOfOrganization || []}
        handlePageChange={setPage}
        handlePageSizeChange={setPageSize}
        count={totalUsers}
        currentPage={page}
        currentRowsPerPage={25}
        sort={sort}
        setSortDirection={setSortDirection}
      />

  );
};

export interface MSALConfig {
  auth: {
    clientId: string;
    authority: string;
    knownAuthorities: string[];
    redirectUri: string;
    postLogoutRedirectUri: string;
  },
  // Intentionally like this
  cache: {
    cacheLocation: "sessionStorage";
    storeAuthStateInCookie: false;
  }
}

export class MSALHandler {
  config: MSALConfig = {
    auth: {
      clientId: "",
      authority: "",
      knownAuthorities: [],
      redirectUri: "",
      postLogoutRedirectUri: "",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  constructor(config: MSALConfig) {
    this.config.auth = {
      ...this.config.auth,
      ...config.auth,
    }

    this.config.cache = {
      ...this.config.cache,
      ...config.cache,
    }
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};

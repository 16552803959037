import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const drawerWidthOpen = 297;
export const drawerWidthClosed = 70;

export default makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  drawer: {
    width: `${drawerWidthClosed}px`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    "& + .MuiPaper-root": {
      width: `calc(100% - ${drawerWidthClosed}px)`,
      marginLeft: `${drawerWidthClosed}px`,
      transition: theme.transitions.create("marginLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    "&.drawerIsOpen + .MuiPaper-root":
    {
      width: `calc(100% - ${drawerWidthOpen}px)`,
      marginLeft: `${drawerWidthOpen}px`,
      transition: theme.transitions.create("marginLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    "&.drawerIsOpen":
    {
      width: `${drawerWidthOpen}px`,
    },
  },

  drawerIsClosed:
  {
    width: `${drawerWidthClosed}px !important`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidthOpen}px)`,
      marginLeft: `${drawerWidthOpen}px`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarLogoClass:
  {
    padding: "20px 50px 0px 50px",
  },
  drawerPaper: {
    width: `${drawerWidthOpen}px`,
    backgroundColor: theme.palette.primary.dark,

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navItem: {
    textDecoration: "none",
    color: "white",
    "& .MuiListItemIcon-root": {
      color: "white",
    },
  },
  adminNavItem: {
    backgroundColor: theme.palette.secondary.light,
    textDecoration: "none",
    color: "white",
    "& .MuiListItemIcon-root": {
      color: "white",
    },
    "& .MuiListItem-button": {
      backgroundColor: theme.palette.primary.dark,
      transition: "150ms all linear",
    },
    "& .MuiListItem-button.selected": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  userNavItems: {
    "& .MuiList-padding": {
      paddingBottom: "0",
    },
  },
  activeAlertsContainer: {
    minWidth: "56px",
  },
  adminNavItems: {
    "& .MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  omavesiLogo: {
    width: "100%",
  },
  navToggleButton:
  {
    position: "fixed",
    "z-index": "100000",
    left: `calc(${drawerWidthClosed}px - 30px / 2)`,
    /* top: "101px", */
    top: "18px",

    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    width: "30px !important",
    height: "30px !important",
    minHeight: "30px !important",

    "&.drawerIsOpen":
    {
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      left: `calc(${drawerWidthOpen}px - 30px / 2)`,
    },

    "& > span > svg":
    {
      "font-size": "1.0rem !important",
    },
  },
}));

import { observer } from "mobx-react-lite";
import React, { useState, useRef } from "react";
import { AppBar, Box, Button, Container, Tab, Tabs, Typography, Grid, TextField, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ReportTable, { Column } from "../../components/table/ReportTable";
import "@fontsource/roboto";
import useStyles from "./ReportsViewStyles";
import ReportRow from "../../models/Report";
import StatCard from "../../components/statCard/StatCard";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import { useRootStore } from "../../RootStateContext";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import useResponsivity from "../../hooks/useResponsivity";
import { TabPanel } from "../../components/tabPanel/TabPanel";

interface IReportConfigViewProps {
  meterReports?: ReportRow[];
  downloadReport: (report: ReportRow) => Promise<void>;
  deleteReport: () => Promise<void>;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalMeterReports: number;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  search: (s: string) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
  setSearchJustActivated: (s: boolean) => void;
  totalReportCountForCard: number;
}

export default observer(({ meterReports,
  setPage,
  downloadReport,
  deleteReport,
  setPageSize,
  totalMeterReports,
  page,
  rowsPerPage,
  sort,
  setSortDirection,
  totalReportCountForCard,
  search,
  resetAllOptions,
  activeSearchValue,
  setSearchJustActivated }: IReportConfigViewProps): JSX.Element => {
  const classes = useStyles();
  const [autoGenerationModalOpen, setAutoGenerationModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchInput = useRef<HTMLInputElement>();
  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");
  const [tabState, setTabState] = React.useState(0); // Tab control
  const [deleteReportModalOpen, setDeleteReportModalOpen] = useState<boolean>(false);

  const { reportStore } = useRootStore();
  const { fetchingMeterReports } = reportStore;
  const { isMobile } = useResponsivity();

  const resetParams = (): void => {
    resetAllOptions();
    setTabState(0);
    if (searchInput && searchInput.current) searchInput.current.value = "";
  };

  const reportColumns: Column[] = [
    /* {
       id: "id",
       label: "ID",
       minWidth: 50,
     },
   /*    {
       id: "timeRange",
       label: t("reportView.reportTimeRange"),
       minWidth: 50,
     }, */
    /* {
      id: "filename",
      label: t("reportView.reportFilename"),
      minWidth: 100,
    }, */
    /* {
      id: "size",
      label: t("reportView.reportSize"),
      minWidth: 25,
    }, */
    {
      id: "createdAt",
      label: t("reportView.reportCreatedAt"),
      minWidth: 100,
    },
    /* {
      id: "organizationId",
      label: t("reportView.reportOrganization"),
      minWidth: 100,
    }, */
    {
      id: "singleRelatedMeterNumber",
      label: t("reportView.singleRelatedMeterNumber"),
      minWidth: 75,
    },
    {
      id: "startDay",
      label: t("reportView.reportStartDay"),
      minWidth: 50,
    },
    {
      id: "endDay",
      label: t("reportView.reportEndDay"),
      minWidth: 50,
    },
    {
      id: "reportType",
      label: t("reportView.reportFormat"),
      minWidth: 25,
    },
    {
      id: "reportStatus",
      label: t("reportView.reportStatus"),
      minWidth: 25,
    },
    {
      id: "endedAt",
      label: t("reportView.reportEndedAt"),
      minWidth: 100,
    },
    {
      id: "generatedAutomatically",
      label: t("reportView.reportAutogenerated"),
      minWidth: 0,
      booleanType: true,
    },
  ];

  function a11yProps(index: any): any {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const doSearch = (): void => {
    const searchVal = searchInput && searchInput.current && searchInput.current.value;
    if (searchVal !== undefined) {
      if (searchVal.length <= 2) {
        setSearchErrorState(true);
        setSearchErrorText(t("common.searchErrorText"));
        return;
      }
      setSearchErrorState(false);
      setSearchErrorText("");
      setSearchJustActivated(true);
      search(searchVal);
    }
  };

  const handleReportTabChange = (event: React.ChangeEvent<unknown>, newValue: number): void => {
    setTabState(newValue);
  };

  const deleteRow = (report: ReportRow): void => {
    reportStore.setSelectedReport(report);
    setDeleteReportModalOpen(true);
  };

  const goToAutomaticReportGenerationSettings = () : void => {
    navigate(`/reportsettings`);
  };

  return (
      <>
          <ConfirmationModal
            open={deleteReportModalOpen}
            text={t("reportView.confirmReportDelete")}
            handleClose={() => setDeleteReportModalOpen(false)}
            submit={deleteReport}
          />
          <Container className={`${classes.container} ${isMobile && classes.containerMobile}`}>
              <Grid container className={classes.reportsHeadGrid}>
                  <Grid item container xs={12} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Typography variant="h4" className={classes.headerTop}>{t("reportView.reports")}</Typography>
                  </Grid>
                  {/* <Grid item xs={9}>
                      <div className={classes.searchInputContainer}>
                          <TextField
                            inputRef={searchInput}
                            onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                            className={classes.searchInput}
                            id="reportsview-search-input"
                            placeholder={t("reportView.searchHelpText2")}
                            size="small"
                            variant="outlined"
                            error={searchErrorState}
                            helperText={searchErrorText}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Button id="reportsview-search-button" onClick={() => doSearch()} className={classes.searchButton} variant="contained" color="primary">{t("reportView.search")}</Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            id="reportsview-search-clear"
                            onClick={resetParams}
                            className={classes.clearSearchButton}
                          >
                              {t("locationView.defaultSettings")}
                          </Button>
                      </div>
                      {activeSearchValue && (
                          <Typography className={classes.activeSearchWord} variant="h6">
                              {t("locationView.searchedWithWord", {
                                searchWord: activeSearchValue,
                              })}
                          </Typography>
                      )}
                            </Grid> */}
                  <Grid container item xs={isMobile ? 12 : 4} justifyContent={isMobile ? "center" : "flex-start"} className={classes.reportsHeadCardsContainer}>
                      {/* <Grid item xs={6}>
                          <StatCard
                            icon="InsertDriveFileRounded"
                            header="XXX"
                            content={t("reportView.amountOfLocationReports")}
                            id="reportsview-locationreports-card"
                          />
                      </Grid> */}

                      <StatCard
                        icon="InsertDriveFileRounded"
                        loadingState={fetchingMeterReports}
                        header={totalReportCountForCard.toString()}
                        content={t("reportView.amountOfMeterReports")}
                        id="reportsview-meterreports-card"
                      />

                  </Grid>
                  <Grid item container xs={12} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        id="reportsview-open-settings"
                        onClick={goToAutomaticReportGenerationSettings}
                        className={classes.automationButton}
                      >
                          {t("reportView.automaticSettings")}
                      </Button>
                  </Grid>
              </Grid>
          </Container>

          <Container className={`${classes.tableContainer} ${isMobile && classes.containerMobile}`}>
              <AppBar className={classes.tabBar} position="static">
                  <Tabs value={tabState} onChange={handleReportTabChange}>
                      <Tab label={t("reportView.meterReports")} {...a11yProps(0)} />
                      {/*  <Tab label={t("reportView.locationReports")} {...a11yProps(0)} /> */}
                  </Tabs>
              </AppBar>
              <TabPanel value={tabState} index={0}>

                  {fetchingMeterReports ? (<PendingCircle />) : (
                      <Paper style={{
                        overflowX: "hidden",
                      }}
                      >
                          <ReportTable<ReportRow>
                            columns={reportColumns}
                            rows={meterReports!}
                            handlePageChange={setPage}
                            handlePageSizeChange={setPageSize}
                            count={totalMeterReports}
                            currentPage={page}
                            currentRowsPerPage={rowsPerPage}
                            sort={sort}
                            setSortDirection={setSortDirection}
                            downloadReport={(report: ReportRow) => downloadReport(report)}
                            deleteRow={deleteRow}
                          />
                      </Paper>
                  )}
                  { !fetchingMeterReports && meterReports && meterReports.length === 0 && (
                      <div className={classes.noReports}>
                          <Typography className={classes.headerContainer} variant="h6" component="h6" id="reportsview-searched-with">
                              {!activeSearchValue ? t("reportView.noReports") : t("reportView.noReportsWithSearch", {
                                searchWord: activeSearchValue,
                              })}
                          </Typography>
                      </div>
                  ) }
              </TabPanel>
              <TabPanel value={tabState} index={1}>
                  <Paper />
              </TabPanel>

          </Container>
      </>
  );
});

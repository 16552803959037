import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({

  container: {
    paddingTop: 20,
    minWidth: "98%",
  },
  headerContainer: {
    marginBottom: 50,
  },
  locationNameDisplay: {
    marginLeft: 10,
    marginTop: 10,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  addButton: {
    margin: "10px 15px 15px 25px",
    color: "white",
  },
  buttonShadow: {
    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  selectResidentButton: {
    marginLeft: 10,
  },
  gridContainer: {
    marginBottom: 50,
  },
  locationTableButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px 0px 10px 1rem",
  },
  h5title: {
    fontSize: "1rem",
    lineHeight: "1.1",
    padding: "1rem !important",
    margin: "0rem !important",
    float: "left",
    color: theme.palette.primary.main,
  },
  noData: {
    marginTop: 45,
    textAlign: "start",
    padding: 15,
    width: "100%",
  },
  paper: {
    marginBottom: 5,
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.text.secondary,
    height: "100%",
    "& .MuiTypography-h4": {
      color: theme.palette.text.primary,
    },
    "& .MuiButton-root": {
      marginRight: 10,
      marginLeft: 10,
      borderRadius: 25,
      paddingLeft: 15,
      paddingRight: 15,
      width: "25%",
      fontSize: "75%",
    },
    "& .MuiCardContent-root": {
      width: "100%",
    },
  },
}));

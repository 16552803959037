import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography, SelectChangeEvent } from "@mui/material";
import fi from "date-fns/locale/fi";
import TextField2 from "@mui/material/TextField";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { FileCopyOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { UserInfo, UserInfoForCreation } from "../../../models/UserInfo";
import { Action, ActionType, generatePassword, onInputChange, PasswordLength, State, validateInput } from "../FormUtils";
import { UserRole } from "../../../models/UserRole";
import Location from "../../../models/Location";
import { Organization } from "../../../models/Organization";
import useResponsivity from "../../../hooks/useResponsivity";
import ModalHeader from "../../../components/modalComponents/modalHeader";
import { convertToStaticDateString } from "../../../components/reportCreator/Util";
import { useRootStore } from "../../../RootStateContext";
import useStyles from "./CreateUserModalStyles";
import { tokenAction } from "../../../api/MsalUtil";
import ModalPortalTarget from "../../../stores/ModalPortalTarget";

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    submit: (u: UserInfoForCreation) => void;
    locations: Location[];
    currentUser?: UserInfo;
    showOrganizations: boolean;
    organizations?: Organization[];
}

const initialValidationState = {
  firstName: {
    value: "", hasError: true, error: "",
  },
  email: {
    value: "", hasError: true, error: "",
  },
  password: {
    value: "", hasError: true, error: "",
  },
  lastName: {
    value: "", hasError: true, error: "",
  },
  role: {
    value: "", hasError: true, error: "",
  },
  organization: {
    value: "", hasError: true, error: "",
  },
  mobilePhone: {
    value: "", hasError: true, error: "",
  },
};

const selectableUserRoles:UserRole[] = [UserRole.OV_CUSTOMER_ADMIN, UserRole.OV_RESIDENT];

const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.UPDATE) {
    const { name, value, hasError, error } = action.data;
    return {
      ...state,
      [name]: {
        ...state[name], value, hasError, error,
      },
    };
  }
  if (action.type === ActionType.RESET) {
    return initialValidationState;
  }
  return state;
};

export default observer(({ open, handleClose, submit, currentUser, showOrganizations, organizations }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const password = useRef<HTMLInputElement>();
  const [validationState, dispatch] = useReducer(reducer, initialValidationState);
  const [copied, setCopied] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const locationsStore = useRootStore().locationStore;
  const [locationState, setLocationState] = useState<Location | undefined>(undefined);
  const [accessStartedAt, setAccessStartedAt] = useState<null | Date>(null);
  const { isMobile } = useResponsivity();

  const [locationSearchInputState, setLocationSearchInputState] = useState("");

  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const authStore = useRootStore().authenticationStore;

  const isReadyToBeSaved = (): boolean => {
    let invalid = false;
    Object.keys(validationState).forEach((name) => {
      if (Object.prototype.hasOwnProperty.call(validationState, name)) {
        const item = validationState[name];
        if (item !== undefined) {
          const { value } = item;
          const { hasError, error } = validateInput(name, value);
          if (hasError) invalid = true;
          if (name) {
            dispatch({
              type: ActionType.UPDATE,
              data: {
                name,
                value,
                hasError,
                error,
              },
            });
          }
        }
      }
    });
    return !invalid;
  };

  const close = (): void => {
    dispatch({
      type: ActionType.RESET, data: {},
    });
    setOrganizationId("");
    setLocationState(undefined);
    setShowLocationSelector(false);
    setAccessStartedAt(null);
    handleClose();
  };

  const copyPasswordToClipboard = (): void => {
    if (password?.current?.value !== "") {
      navigator.clipboard.writeText(password?.current?.value || "");
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const saveUser = (): void => {
    if (!showOrganizations) {
      validationState.organization.hasError = false;
      validationState.organization.error = "";
      if (currentUser && currentUser.organizationId) {
        validationState.organization.value = currentUser.organizationId;
        setOrganizationId(currentUser.organizationId);
      }
    }
    if (isReadyToBeSaved()) {
      let staticAccessDate = null;

      if (locationState) {
        if (accessStartedAt === null) {
          console.log("Error: Access start date is required when location is selected");
          return;
        }

        staticAccessDate = convertToStaticDateString(accessStartedAt);
      }

      const user: UserInfoForCreation = {
        password: validationState.password.value,
        firstName: validationState.firstName.value,
        lastName: validationState.lastName.value,
        email: validationState.email.value,
        active: true,
        locationId: locationState ? locationState.id : undefined,
        organizationId,
        mobilePhone: validationState.mobilePhone.value,
        role: UserRole[validationState.role.value as UserRole] || UserRole.OV_RESIDENT,
      };

      if (locationState && staticAccessDate) {
        user.locationAccessStartAt = staticAccessDate;
      }

      submit(user);
      close();
    } else {
      console.log("error");
    }
  };

  const autoGeneratePassword = (): void => {
    const pwd = generatePassword(PasswordLength.MEDIUM);
    if (password && password.current) password.current.value = pwd;
    onInputChange("password", pwd, dispatch);
  };

  const handleOrganizationDropdownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      onInputChange("organization", event.target.value as string, dispatch);
      setOrganizationId(event.target.value as string);
    }
  };

  const handleRoleDropDownChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      onInputChange("role", event.target.value as string, dispatch);
      if (event.target.value as string === "OV_RESIDENT") {
        setShowLocationSelector(true);
      } else {
        setShowLocationSelector(false);
      }
    }
  };

  const handleLocationDropdownChange = (event: React.SyntheticEvent, value: Location | null, reason: AutocompleteChangeReason): void => {
    switch (reason) {
      case "clear":
        setLocationState(undefined);
        setLocationSearchInputState("");
        break;
      case "selectOption":
        if (value) {
          setLocationState(value);
          setLocationSearchInputState(`${value?.streetAddress}, ${value?.postalCode} ${value?.city}`);
        }
        break;
      default:
          break;
    }
  };

  const onLocationSelectInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason): void => {
    if (reason !== "reset") {
      if (value === "") {
        setLocationState(undefined);
        setLocationSearchInputState("");
      } else {
        setLocationSearchInputState(value);
      }
    }
  };

  useEffect(() => {
    if (locationSearchInputState.length <= 2) {
      locationsStore.clearNewUserLocations();
      return undefined;
    }

    if (!open) { return undefined; }

    const controller = new AbortController();
    const { signal } = controller;
    const search = locationSearchInputState.includes(",")
    ? locationSearchInputState.substring(0, locationSearchInputState.indexOf(","))
    : locationSearchInputState;

    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        await locationsStore.loadNewUserLocations(idToken, search, signal);
        if (locationState) { setLocationState(locationsStore.newUserSelectLocations.find((l) => l.id === locationState.id)); }
      }, currentUserToken, accounts, instance);
    })();

    return () => {
      controller.abort();
    };
  }, [accounts, instance, locationSearchInputState, locationsStore]);

  return ReactDOM.createPortal(<Modal
    className={`${classes.modal} ${isMobile && classes.fullScreenModal}`}
    open={open}
    onClose={close}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={`${classes.paper} ${isMobile && classes.fullScreenPaper}`}>
              <ModalHeader headerText={t("userManagementView.addUser")} clear={close} id="addUserTitle" />

              <div className={classes.subsection}>
                  <TextField
                    id="firstNameInput"
                    required
                    label={t("userManagementView.firstName")}
                    InputLabelProps={{
                              shrink: true,
                    }}
                    error={validationState?.firstName?.error !== ""}
                    helperText={validationState?.firstName?.error}
                    onChange={(e) => onInputChange("firstName", e.target.value, dispatch)}
                    variant="outlined"
                    className={classes.firstName}
                  />
                  <div className={classes.divider} />
                  <TextField
                    className={classes.lastName}
                    id="lastNameInput"
                    required
                    error={validationState?.lastName?.error !== ""}
                    helperText={validationState?.lastName?.error}
                    onChange={(e) => onInputChange("lastName", e.target.value, dispatch)}
                    InputLabelProps={{
                              shrink: true,
                    }}
                    label={t("userManagementView.lastName")}
                    variant="outlined"
                  />
              </div>
              <div className={classes.subsection}>
                  <TextField
                    id="emailInput"
                    fullWidth
                    required
                    error={validationState?.email?.error !== ""}
                    helperText={validationState?.email?.error}
                    onChange={(e) => onInputChange("email", e.target.value, dispatch)}
                    InputLabelProps={{
                              shrink: true,
                    }}
                    label={t("userManagementView.email")}
                    variant="outlined"
                  />
              </div>
              <div className={classes.section}>
                  <div className={classes.subsection}>
                      <TextField
                        id="passwordInput"
                        fullWidth
                        required
                        error={validationState?.password?.error !== ""}
                        helperText={copied ? t("userManagementView.passwordCopied") : validationState?.password?.error}
                        onChange={(e) => onInputChange("password", e.target.value, dispatch)}
                        InputLabelProps={{
                                  shrink: true,
                        }}
                        label={t("userManagementView.password")}
                        variant="outlined"
                        inputRef={password}
                        InputProps={{
                                  endAdornment: <FileCopyOutlined className={classes.copyIcon} onClick={copyPasswordToClipboard} />,
                        }}
                      />
                      <Button
                        className={classes.generatePasswordButton}
                        variant="contained"
                        color="primary"
                        onClick={autoGeneratePassword}
                      >
                          {t("userManagementView.autoGeneratePassword")}
                      </Button>

                  </div>
                  <div className={classes.subsection}>
                      <TextField
                        id="phoneNumberInput"
                        fullWidth
                        required
                        error={validationState?.mobilePhone?.error !== ""}
                        helperText={validationState?.mobilePhone?.error}
                        onChange={(e) => onInputChange("mobilePhone", e.target.value, dispatch)}
                        InputLabelProps={{
                                  shrink: true,
                        }}
                        label={t("userManagementView.mobilePhone")}
                        variant="outlined"
                      />
                  </div>
                  {showOrganizations && organizations
                          && (
                              <div className={classes.subsection}>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel required id="organizationSelectLabel">{t("userManagementView.organization")}</InputLabel>
                                      <Select
                                        error={validationState?.organization?.error !== ""}
                                        labelId="orgLabel"
                                        id="orgLabelId"
                                        value={organizationId}
                                        onChange={handleOrganizationDropdownChange}
                                      >
                                          {organizations.map((org) => (
                                              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{t("userManagementView.organizationHelperText")}</FormHelperText>
                                  </FormControl>
                              </div>
                          )}
                  <div className={classes.subsection}>
                      <FormControl className={classes.formControl}>
                          <InputLabel required id="userRoleLabel">{t("userManagementView.role")}</InputLabel>
                          <Select
                            labelId="roleLabel"
                            id="roleLabelId"
                            onChange={handleRoleDropDownChange}
                            value={validationState?.role?.value}
                            displayEmpty={false}
                          >
                              {selectableUserRoles.map((role) => (
                                  <MenuItem value={role} key={role}>{t(`userRole.${role}`)}</MenuItem>
                                      ))}
                          </Select>
                          <FormHelperText>{t("userManagementView.roleHelperText")}</FormHelperText>
                      </FormControl>
                  </div>
                  {showLocationSelector && (
                      <div className={classes.subsection}>
                          <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="span">
                              {t("locationCreation.location")}
                          </Typography>
                          <FormControl className={classes.formControl}>
                              <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                loading={locationsStore.fetchingNewUserLocations}
                                id="newUserLocationLabelId"
                                onChange={(e, v, r) => { handleLocationDropdownChange(e, v as Location, r); }}
                                options={
                                      locationsStore.newUserSelectLocationsAvailable > 0
                                      ? [...locationsStore.newUserSelectLocations, `+ ${locationsStore.newUserSelectLocationsAvailable} tulosta`]
                                      : locationsStore.newUserSelectLocations
                                    }
                                renderInput={(params) => <TextField {...params} label={t("meterView.selectLocation")} />}
                                renderOption={(props, option) => {
                                      if (typeof option !== "string") {
                                        return (
                                            <li {...props} key={option.id}>
                                                {`${option.streetAddress}, ${option.postalCode} ${option.city}`}
                                            </li>
                                        );
                                      }
                                      return (
                                          <li {...props} key="user_edit_additional_locations">
                                              {option as string}
                                          </li>
                                      );
                                }}
                                value={locationState}
                                inputValue={locationSearchInputState}
                                getOptionLabel={(option) => (typeof option !== "string" ? `${option.streetAddress}, ${option.postalCode} ${option.city}` : option)}
                                isOptionEqualToValue={(option, value) => (typeof value !== "undefined" && typeof value !== "string" && typeof option !== "string" && option.id === value.id)}
                                onInputChange={onLocationSelectInputChange}
                                filterOptions={(x) => x}
                                noOptionsText={t("meterView.noLocationOptions")}
                                getOptionDisabled={(option) => typeof option === "string"}
                              />
                          </FormControl>
                      </div>
                          )}
                  {/* showLocationSelector && (
                              <div className={classes.subsection}>
                                  <FormControl className={classes.formControl}>
                                      <InputLabel id="meterTypeLabel">{t("userManagementView.location")}</InputLabel>
                                      <Select
                                        labelId="meterLocationLabel"
                                        id="meterLocationLabelId"
                                        value={locationIdState}
                                        onChange={handleLocationDropdownChange}
                                        displayEmpty
                                      >
                                          <MenuItem key="-1" value="-1"><span className={classes.locationName}>-----------</span></MenuItem>
                                          {locations.map((location:Location, index) => (
                                              <MenuItem value={location.id} key={location.id}>
                                                  <span className={classes.locationName}>{location.identifier}</span>
                                                  {" "}
                                                  (
                                                  {location.ownerOrganizationName}
                                                  )
                                                  {" "}
                                                  (
                                                  {location.streetAddress}
                                                  )
                                              </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{t("userManagementView.locationOfResident")}</FormHelperText>
                                  </FormControl>
                              </div>
                          ) */}
                  {locationState && (
                      <div
                        className={classes.subsection}
                        style={{
                                  flexDirection: "column",
                                  marginBottom: "15px",
                        }}
                      >
                          <FormControl>
                              <Typography style={{
                                  marginLeft: "8px",
                              }}
                              >
                                  {t("userManagementView.selectAccessStartedAt")}
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                                  <div id="userCreateAccessStart" className={classes.datePicker}>
                                      <DatePicker
                                        label={t(`userManagementView.accessStartedAt`)}
                                        value={accessStartedAt}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={(newValue : any) => {
                                              setAccessStartedAt(newValue);
                                        }}
                                        renderInput={(params : any) => <TextField2 {...params} />}
                                        showToolbar={false}
                                      />
                                  </div>
                              </LocalizationProvider>
                          </FormControl>
                      </div>
                          )}

              </div>
              <div className={classes.navigation}>

                  <div className={classes.navButtons}>
                      <Button
                        variant="contained"
                        id="closeCreateUserModal"
                        className={classes.backButton}
                        color="secondary"
                        onClick={close}
                      >
                          {t("userManagementView.cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        id="createUserButton"
                        className={classes.nextButton}
                        color="primary"
                        onClick={saveUser}
                      >
                          {t("userManagementView.saveUser")}
                      </Button>
                  </div>

              </div>
          </div>
      </Fade>
                               </Modal>, ModalPortalTarget);
});

import { observer } from "mobx-react-lite";
import React, { useState, useRef } from "react";
import { AppBar, Button, Container, Tab, Tabs, TextField, Typography, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileDownload } from "@mui/icons-material";

import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { useMsal } from "@azure/msal-react";
import MeterTable, { Column } from "../../components/table/MeterTable";
import "@fontsource/roboto";
import useStyles from "./MetersViewStyles";
import { WaterMeter, WaterMeterWithLocation } from "../../models/WaterMeter";
import Location from "../../models/Location";
import EditMeterModal from "../../components/editMeterModal/EditMeterModal";
import UploadExcelFileModal from "../../components/uploadExcelFileModal/UploadExcelFileModal";
import StatCard from "../../components/statCard/StatCard";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import { useRootStore } from "../../RootStateContext";
import ReportCreator from "../../components/reportCreator/ReportCreator";
import { OrganizationType, UserInfo } from "../../models/UserInfo";
import MapModal from "../../components/googleMaps/MeterMap/MeterMapModal";
import InfoModal from "../../components/infoModal/InfoModal";
import useResponsivity from "../../hooks/useResponsivity";
import { Organization } from "../../models/Organization";
import { CreateFullMeterReport } from "../../models/CreateReport";
import { TabPanel } from "../../components/tabPanel/TabPanel";
import { tokenAction } from "../../api/MsalUtil";
import { getMeter } from "../../api/WaterMeterApi";
import { getLocation } from "../../api/LocationsApi";

interface IMeterConfigViewProps {
  editMeter: (meter: WaterMeter) => Promise<void>;
  editMetersLocationsWithFile: (file: File, organizationId: string) => Promise<void>;
  getReport: (createReport: CreateFullMeterReport) => void;
  getMeterExcel: () => Promise<void>;
  locations: Location[];
  meters?: WaterMeter[];
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalMeters: number;
  totalMetersForCard: number;
  page: number;
  rowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  setArchived: (a: boolean) => void;
  search: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  updatingMetersInProgress: boolean;
  creatingReport: boolean;
  downloadingMeterExcel: boolean;
  activeSearchValue?: string;
  weeklyConsumption: number;
  currentUser?: UserInfo;
  customerOrganizations?: Organization[];
}

function a11yProps(index: any): any {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default observer(({ editMeter,
  editMetersLocationsWithFile,
  getReport,
  getMeterExcel,
  locations,
  meters,
  setPage,
  setPageSize,
  totalMeters,
  totalMetersForCard,
  page,
  rowsPerPage,
  sort,
  setSortDirection,
  setArchived,
  search,
  setSearchJustActivated,
  resetAllOptions,
  updatingMetersInProgress,
  creatingReport,
  downloadingMeterExcel,
  activeSearchValue,
  weeklyConsumption,
  customerOrganizations,
  currentUser }: IMeterConfigViewProps): JSX.Element => {
  const { isMobile } = useResponsivity();
  const classes = useStyles();
  const isLocationCreationModel = false;
  const [excelSelectorModalOpen, setExcelSelectorModalOpen] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletableMeter, setDeletableMeter] = useState({} as WaterMeter);
  const [editMeterModalOpen, setEditMeterModalOpen] = useState(false);

  const [editableMeter, setEditableMeter] = useState({} as WaterMeterWithLocation);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [archivedState, setArchivedState] = React.useState(0); // Tab control
  const searchInput = useRef<HTMLInputElement>();
  const [searchErrorState, setSearchErrorState] = useState<boolean>(false);
  const [searchErrorText, setSearchErrorText] = useState<string>("");
  const [mapModalOpen, setMapModalOpen] = useState<boolean>(false);
  const [creatingReportInfoModalOpen, setCreatingReportInfoModalOpen] = useState<boolean>(false);

  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const [mapOpenToTarget, setMapOpenToTarget] = useState<WaterMeter | boolean>(false);

  const metersStore = useRootStore().meterStore;
  const { fetchingMeters, fetchingWeeklyConsumption, fetchingMeterCount } = metersStore;

  const handleChangeArchived = (event: React.ChangeEvent<any>, newValue: number): void => {
    setArchivedState(newValue);
    setArchived(newValue === 1);
  };

  const getUsersTableItems = (): Column[] => {
    // todo: show columns modelName and devEUI to adminuser (vesilaitos or digita) only
    // todo: show ownerOrganizationName only to DigitaUser
    let columns: Column[] = [
      /* {
        id: "meterCheck",
        label: "",
        booleanType: true,
      }, */
      {
        id: "meterNumber",
        label: t("waterMeter.meterNumber"),
        minWidth: 175,
      },
      {
        id: "ownerOrganizationName",
        label: t("waterMeter.platformOwner"),
      },
      {
        id: "customerOrganizationName",
        label: t("waterMeter.customer"),
      },
      /* {
        id: "devEUI",
        label: t("waterMeter.devEUI"),
      }, */
      {
        id: "locationName",
        label: t("waterMeter.location"),
      },
      {
        id: "address",
        label: t("waterMeter.address"),
      },
      {
        id: "type", label: t("waterMeter.type"),
      },
      {
        id: "consumptionWeek",
        label: t("waterMeter.consumptionWeek"),
        maxWidth: 80,
      },
      {
        id: "consumptionMonth",
        label: t("waterMeter.thisMonth"),
        maxWidth: 80,
      },
      {
        id: "consumptionYear",
        label: t("waterMeter.thisYear"),
        maxWidth: 80,
      },
      {
        id: "latestReadingCubicMeters",
        label: t("waterMeter.reading"),
        maxWidth: 80,
      },
      {
        id: "latestReadingTimeStamp",
        label: t("waterMeter.time"),
      },
      {
        id: "connectionStatusCombined",
        label: t("waterMeter.status"),
      },
    ];
    if (currentUser && currentUser.usersOrganizationType !== OrganizationType.SUPERADMIN) {
      columns = columns.filter((item) => item.id !== "ownerOrganizationName");
    }
    if (currentUser && (currentUser.usersOrganizationType === OrganizationType.OMAVESI_HOUSING || currentUser.usersOrganizationType === OrganizationType.OTHER)) {
      columns = columns.filter((item) => item.id !== "customerOrganizationName");
    }
    return columns;
  };

  const addMeterForDeletion = (meter: WaterMeter): void => {
    const meterToArchive: WaterMeter = JSON.parse(JSON.stringify(meter));
    meterToArchive.archived = true;
    setDeletableMeter(meterToArchive);
    setDeleteModalOpen(true);
  };

  const setEditMeter = async (meter: WaterMeter): Promise<void> => {
    const meterToEdit: WaterMeterWithLocation = JSON.parse(JSON.stringify(meter));

    // Listen meters do not have location data
    // So we fetch the full meter data for the selected meter to get a location id
    // And then we fetch the full location data with that id
    await tokenAction(async (idToken) => {
      setCurrentUserToken(idToken);
      const fullMeterData = await getMeter(idToken, meterToEdit.id);

      if (fullMeterData.locationId) {
        const locationData = await getLocation(idToken, fullMeterData.locationId);

        setEditableMeter({
          ...meter,
          meterLocation: locationData,
        });
      } else {
        setEditableMeter(meter);
      }

      setEditMeterModalOpen(true);
    }, currentUserToken, accounts, instance);
  };

  const cellClickedCallback = (meter: WaterMeter): void => {
    navigate(`/meter/${meter.id}`);
  };

  // For opening map modal when the user clicks a highlighted address-cell
  const addressCellClickedCallback = (meter: WaterMeter) : void => {
    setMapOpenToTarget(meter);
    setMapModalOpen(true);
  };

  const doSearch = (): void => {
    const searchVal = searchInput && searchInput.current && searchInput.current.value;

    if (searchVal !== undefined) {
      if (searchVal.length <= 2) {
        setSearchErrorState(true);
        setSearchErrorText(t("common.searchErrorText"));
        return;
      }
      setSearchErrorState(false);
      setSearchErrorText("");
      search(searchVal);
      setSearchJustActivated(true);
    }
  };

  const resetParams = (): void => {
    resetAllOptions();
    setSearchErrorState(false);
    setSearchErrorText("");
    if (searchInput && searchInput.current) searchInput.current.value = "";
  };

  const handleMapClose = () : void => {
    setMapOpenToTarget(false);
    setMapModalOpen(false);
  };

  return (
      <>
          <Container className={`${classes.container} ${isMobile ? classes.topContainerMobile : classes.topContainer}`}>
              <Grid container className={classes.metersHeadGrid}>
                  <Grid item container xs={isMobile ? 12 : 3} justifyContent={isMobile ? "center" : "flex-start"}>
                      <Typography variant="h4" className={classes.headerTop}>Mittarit</Typography>
                  </Grid>
                  <Grid item xs={9}>
                      {!isMobile && (
                          <div className={classes.searchInputContainer}>
                              <TextField
                                inputRef={searchInput}
                                onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                                className={classes.searchInput}
                                id="metersview-search-input"
                                placeholder={t("meterView.searchHelpText2")}
                                size="small"
                                variant="outlined"
                                error={searchErrorState}
                                helperText={searchErrorText}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <Button id="metersview-search-button" onClick={() => doSearch()} className={classes.searchButton} variant="contained" color="primary">{t("meterView.search")}</Button>
                              <Button
                                color="secondary"
                                variant="contained"
                                id="metersview-search-clear"
                                onClick={resetParams}
                                className={classes.clearSearchButton}
                              >
                                  {t("meterView.defaultSettings")}
                              </Button>
                          </div>
                      )}
                      {activeSearchValue && !isMobile && (
                          <Typography className={classes.activeSearchWord} variant="h6">
                              {t("meterView.searchedWithWord", {
                                searchWord: activeSearchValue,
                              })}
                          </Typography>
                      )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.metersHeadCardsContainer}
                    justifyContent={isMobile ? "center" : "flex-start"}
                    alignItems="center"
                  >
                      <Grid item>
                          <StatCard
                            icon="Devices"
                            header={totalMetersForCard.toString()}
                            content={t("meterView.totalmeters")}
                            id="metersview-totalmeters-card"
                            loadingState={fetchingMeterCount}
                          />
                      </Grid>
                      <Grid item>
                          <StatCard
                            icon="Opacity"
                            header={`${weeklyConsumption.toFixed(3).replace(".", ",")} m${String.fromCharCode(179)}`}
                            content={t("meterView.consumptionWeekly")}
                            id="metersview-consumption-card"
                            loadingState={fetchingWeeklyConsumption}
                          />
                      </Grid>
                  </Grid>
              </Grid>

          </Container>

          <UploadExcelFileModal
            locationCreationForm={isLocationCreationModel}
            open={excelSelectorModalOpen}
            handleClose={() => setExcelSelectorModalOpen(false)}
            submit={editMetersLocationsWithFile}
            requestSent={updatingMetersInProgress}
            showOrganizations
            organizations={customerOrganizations}
          />
          <EditMeterModal open={editMeterModalOpen} handleClose={() => { setEditMeterModalOpen(false); setEditableMeter({} as WaterMeter); }} submit={editMeter} meterToBeEdited={editableMeter} />
          <MapModal openToTarget={mapOpenToTarget} openToTargetSetter={setMapOpenToTarget} open={mapModalOpen} handleClose={handleMapClose} />
          <InfoModal
            open={creatingReportInfoModalOpen}
            handleClose={() => setCreatingReportInfoModalOpen(false)}
            okFunc={() => navigate("/reports")}
            headerText="Luodaan"
            explainText={t("reports.reportCreatingInProgress")}
            okBtnText={t("reports.toReports")}
            dismissBtnText={t("reports.continue")}
          />

          <Grid
            container
            item
            style={{
              padding: "10px 0px 10px 10px",
            }}
          >
              {!isMobile && (
                  <Button
                    onClick={() => setExcelSelectorModalOpen(true)}
                    color="primary"
                    variant="outlined"
                    id="EditMetersWithFileButton"
                  >
                      <FileUploadIcon />
                      {t("meterView.updateLocationsOfMetersWithFile")}
                  </Button>
              )}

              {!isMobile && (
                  <Button
                    onClick={getMeterExcel}
                    color="primary"
                    variant="outlined"
                    id="DownloadMetersButton"
                    className={classes.downloadMetersButton}
                    disabled={downloadingMeterExcel}
                  >
                      <FileDownload />
                      {t("meterView.downloadMeters")}
                  </Button>
              )}

              {!isMobile && (
                  <Button
                    id="metersview-open-map-button"
                    onClick={() => setMapModalOpen(true)}
                    className={classes.mapButton}
                    variant="contained"
                    color="secondary"
                    style={{
                      marginLeft: "auto",
                      marginRight: "10px",
                    }}
                  >
                      {t("meterView.openMap")}
                  </Button>
              )}

              {downloadingMeterExcel && (
                  <Typography className={classes.downloadingExcel}>
                      <HourglassTopIcon />
                  </Typography>
              )}
          </Grid>

          <Container className={`${classes.container} ${isMobile ? classes.containerMobile : ""}`}>
              <AppBar className={`${classes.tabBar} ${isMobile && classes.tabBarMobile}`} position="static">
                  <Tabs value={archivedState} onChange={handleChangeArchived}>
                      <Tab label={t("meterView.meters")} {...a11yProps(0)} />
                  </Tabs>
              </AppBar>
              <TabPanel
                value={archivedState}
                index={0}
              >
                  {fetchingMeters ? (<PendingCircle />) : (
                      <Paper style={{
                        overflowX: "hidden",
                      }}
                      >
                          {!isMobile && (
                              <ReportCreator
                                submit={(r) => { setCreatingReportInfoModalOpen(true); getReport(r as CreateFullMeterReport); }}
                                creatingReport={creatingReport}
                                customerOrganizations={customerOrganizations}
                                singleReportCreator={false}
                              />
                          )}
                          {isMobile && (
                              <>
                                  {activeSearchValue && isMobile && (
                                      <Typography className={classes.activeSearchWordMobile} variant="h6">
                                          {t("meterView.searchedWithWord", {
                                            searchWord: activeSearchValue,
                                          })}
                                      </Typography>
                                  )}
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    id="metersview-search-clear"
                                    onClick={resetParams}
                                    className={classes.clearSearchButtonMobile}
                                  >
                                      {t("meterView.defaultSettings")}
                                  </Button>
                                  <TextField
                                    inputRef={searchInput}
                                    onKeyDown={(e) => { if (e.key === "Enter") doSearch(); }}
                                    className={classes.searchInputMobile}
                                    id="metersview-search-input"
                                    placeholder={t("meterView.searchHelpText2")}
                                    size="small"
                                    variant="outlined"
                                    error={searchErrorState}
                                    helperText={searchErrorText}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />

                                  <Button id="metersview-open-map-button" onClick={() => setMapModalOpen(true)} className={classes.mapButtonMobile} variant="contained" color="secondary">{t("meterView.openMap")}</Button>

                              </>
                          )}
                          <MeterTable<WaterMeter>
                            editRow={(meter: WaterMeter) => setEditMeter(meter)}
                            columns={getUsersTableItems()}
                            rows={meters || []}
                            cellClickedCallback={(meter: WaterMeter) => cellClickedCallback(meter)}
                            addressCellClickedCallback={(meter: WaterMeter) => addressCellClickedCallback(meter)}
                            handlePageChange={setPage}
                            handlePageSizeChange={setPageSize}
                            count={totalMeters}
                            currentPage={page}
                            currentRowsPerPage={rowsPerPage}
                            sort={sort}
                            setSortDirection={setSortDirection}
                          />
                      </Paper>
                  )}
                  { !fetchingMeters && meters && meters.length === 0 && (
                      <div className={classes.noMeters}>
                          <Typography className={classes.headerContainer} variant="h6" component="h6">
                              {!activeSearchValue ? t("meterView.noMeters") : t("meterView.noMetersWithSearch", {
                                searchWord: activeSearchValue,
                              })}
                          </Typography>
                      </div>
                  ) }

              </TabPanel>
          </Container>

      </>
  );
});

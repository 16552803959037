import { UserRole } from "./UserRole";
import AlertSetting from "./AlertSetting";
import { GeoJSONLocationWithZoom } from "./Map";
import { WaterPrice } from "./Pricing";

export type UserInfo = {
    id?: string;
    identityUserId: string;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    active: boolean;
    locationAccesses: LocationAccess[];
    organizationId?: string;
    organizationName?: string;
    usersOrganizationType: OrganizationType;
    userRole: UserRole;
    alertSettings: AlertSetting;
    mobilePhone: number | undefined;
    defaultMapView?: GeoJSONLocationWithZoom;
    waterPrice?: WaterPrice;
}

export type LocationAccess = {
    accessStartedAt: string;
    address: {
        city: string;
        postalCode: string;
        streetAddress: string;
    },
    locationId: string;
    role: UserRole
}

export type UserResponseWrapper = {
    content: UserInfo[];
    first: boolean;
    last: boolean;
    totalElements: number;
}

export type UserInfoForCreation = {
    firstName: string;
    lastName: string;
    email: string;
    active: boolean;
    organizationId: string;
    locationId?: string,
    role: UserRole;
    mobilePhone: number | undefined;
    password: string;
    locationAccessStartAt?: string;
}

export type UserForResidentCreation = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    locationId: string;
}

export type UserForLocationAccessEditing = {
    id?: string;
    identityUserId: string;
    locationId: string;
    roleInLocation: UserRole;
    removeAccessFromUser: boolean;
    locationAccessStartedAt?: string;
}

export type UserInfoForUpdating = {
    id?: string;
    identityUserId: string;
    firstName: string;
    lastName: string;
    email: string;
    active: boolean;
    organizationId: string;
    locationId?: string,
    role: UserRole;
    mobilePhone: number | undefined;
}

export enum OrganizationType {
    WATER_COMPANY = "WATER_COMPANY", SUPERADMIN = "SUPERADMIN", ADMIN="ADMIN", RETAILER="RETAILER", OMAVESI_HOUSING="OMAVESI_HOUSING", OTHER="OTHER",
    OMAVESI_HOUSE_MAINTENANCE="OMAVESI_HOUSE_MAINTENANCE",
}

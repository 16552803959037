import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import AlarmIcon from "@mui/icons-material/Alarm";
import ArrowLeft from "@mui/icons-material/ArrowBackIosNew";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import PeopleIcon from "@mui/icons-material/People";
import WaterDamageIcon from "@mui/icons-material/WaterDamage";
import SpeedIcon from "@mui/icons-material/Speed";
import { FileCopyOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import EuroIcon from "@mui/icons-material/Euro";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import omavesi_logo from "../../assets/images/ov_logo_compressed_white.png";
import { useRootStore } from "../../RootStateContext";
import i18n from "../../i18n";
import useStyles from "./NavigationBarStyles";
import { UserRole } from "../../models/UserRole";
import { OrganizationType } from "../../models/UserInfo";

export const drawerWidth = 240;
interface INavProps {
  userRole: UserRole;
  organizationType: OrganizationType;
  activeAlerts?: number;
}

export default observer(({ userRole, organizationType, activeAlerts }:INavProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useRootStore().uiStateStore;
  const { t } = useTranslation();

  const navigationComponents = [
    {
      name: t("navigation.home"),
      key: "home",
      icon: <HomeIcon />,
      id: "home",
    },
    {
      name: t("navigation.alerts"),
      key: "alerts",
      icon: <NotificationsActiveIcon />,
      id: "alerts",
    },
  ];

  const adminNavigationComponents = [
    {
      name: i18n.t("navigation.meters"),
      key: "meters",
      icon: <SpeedIcon />,
      id: "meters",
    },
    {
      name: i18n.t("navigation.locations"),
      key: "locations",
      icon: <WaterDamageIcon />,
      id: "locations",
    },
    {
      name: t("navigation.reports"),
      key: "reports",
      icon: <FileCopyOutlined />,
      id: "reports",
    }, {
      name: i18n.t("navigation.users"),
      key: "usermanagement",
      icon: <PeopleIcon />,
      id: "usermanagement",
    },
  ];

  const housingAdminNavigationComponents = [
    {
      name: i18n.t("navigation.prices"),
      key: "prices",
      icon: <EuroIcon />,
      id: "prices",
    },
  ];

  const handleDrawerToggle = () : void => {
      if (!store.drawerOpen) {
        store.setDrawerOpen();
      } else {
        store.setDrawerClosed();
      }
  };

  const drawer = (
      <div>
          {/* <div className={`${classes.toolbar} ${classes.toolbarLogoClass}`}>
              <img
                className={classes.omavesiLogo}
                src={omavesi_logo}
                alt="omavesi logo"
                style={{
                  visibility: showLogo ? "initial" : "hidden",
                  overflow: "hidden",
                }}
              />
              </div> */}

          <div className={classes.userNavItems}>
              <List>
                  {navigationComponents.map((val, idx) => (
                      <Link key={val.key} to={`/${val.key}`} className={classes.adminNavItem}>
                          <ListItem button key={val.name} id={val.id} className={useLocation().pathname === (`/${val.key}`) ? "selected" : "sss"}>
                              {val.key !== "alerts" && (
                                  <ListItemIcon>{val.icon}</ListItemIcon>
                              )}
                              {val.key === "alerts" && (
                                  <div className={classes.activeAlertsContainer}>
                                      <Badge badgeContent={activeAlerts} color={activeAlerts ? "warning" : "default"}>
                                          <NotificationsActiveIcon />
                                      </Badge>
                                  </div>
                              )}
                              <ListItemText primary={val.name} />

                          </ListItem>
                      </Link>
                  ))}
              </List>
          </div>
          {(userRole === UserRole.OV_ADMIN || userRole === UserRole.OV_CUSTOMER_ADMIN) && (
              <div className={classes.adminNavItems}>
                  <List>
                      {adminNavigationComponents.map((val, idx) => (
                          <Link key={val.key} to={`/${val.key}`} className={classes.adminNavItem}>
                              <ListItem button key={val.name} id={val.id} className={useLocation().pathname === (`/${val.key}`) ? "selected" : "sss"}>
                                  <ListItemIcon>{val.icon}</ListItemIcon>
                                  <ListItemText primary={val.name} />
                              </ListItem>
                          </Link>
                      ))}
                  </List>
              </div>
          )}
          {((userRole === UserRole.OV_ADMIN || userRole === UserRole.OV_CUSTOMER_ADMIN) && (organizationType === OrganizationType.OMAVESI_HOUSING || organizationType === OrganizationType.OMAVESI_HOUSE_MAINTENANCE)) && (
              <div className={classes.adminNavItems}>
                  <List>
                      {housingAdminNavigationComponents.map((val, idx) => (
                          <Link key={val.key} to={`/${val.key}`} className={classes.adminNavItem}>
                              <ListItem button key={val.name} id={val.id} className={useLocation().pathname === (`/${val.key}`) ? "selected" : "sss"}>
                                  <ListItemIcon>{val.icon}</ListItemIcon>
                                  <ListItemText primary={val.name} />
                              </ListItem>
                          </Link>
                      ))}
                  </List>
              </div>
          )}

      </div>
  );

  const container = undefined;

  return (
      <>
          <Fab size="small" color="primary" aria-label="add" className={`${classes.navToggleButton} ${store.drawerOpen ? "drawerIsOpen" : ""}`} onClick={handleDrawerToggle}>
              {store.drawerOpen ? <ArrowLeft /> : <ArrowRight />}
          </Fab>
          <nav className={`${classes.drawer} ${store.drawerOpen ? "drawerIsOpen" : ""}`} aria-label="mailbox folders">
              <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: `${classes.drawerPaper} ${classes.drawer} ${!store.drawerOpen ? classes.drawerIsClosed : ""}`,
                    }}
                    variant="permanent"
                    open
                  >
                      {drawer}
                  </Drawer>
              </Hidden>
          </nav>
      </>
  );
});

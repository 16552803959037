import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  addButton: {
    margin: "10px 0px 15px 0px",
    color: "white",
  },
  tableContainer: {
    padding: `0`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cardsContainer:
  {
    gap: "15px",
  },
  secondCard: {},
  tabPanelPaper:
    {
      padding: "16px",
    },
  tabPanel:
    {
      "& .MuiBox-root":
        {
          padding: "0px",
        },
    },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: "64px",
  },
  searchInputContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "stretch",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
  },
  clearSearchButton: {
    height: "45px",
    marginLeft: 15,
    borderRadius: 5,
  },
  activeSearchWord: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "45px",
    color: theme.palette.primary.dark,
  },
  headerContainer: {
    marginBottom: 50,
  },
  topContainer: {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  headerTop: {
    color: "#04444B",
  },
  spinner: {
    marginTop: "20%",
  },
  "& .MuiCardContent-root": {
    width: "100%",
  },
  myAppBar: {
    backgroundColor: "unset",
    boxShadow: "none",
    borderBottom: "1px #dfdfdf solid",
    borderColor: theme.palette.secondary.light,
  },
  tabContainer: {
    marginTop: "15px",
  },

  searchButton: {
    height: "56px",
    marginLeft: 15,
    borderRadius: 5,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),

  searchInput: {
    textAlign: "right",
    whiteSpace: "nowrap",
    justifySelf: "flex-end",
  },
  noUsers: {
    marginTop: 50,
  },

  tabBarMobile: {
    marginTop: 0,

    "& .MuiTabs-root": {
      overflow: "visible",
      overflowX: "visible",

      "& .MuiTabs-scroller": {
        overflow: "visible !important",
        overflowX: "visible !important",
      },
    },
  },

  tableContainerMobile: {
    padding: "0px 0px 20px 0px",
  },

  topContainerMobile: {
    padding: "0px 10px 15px 10px !important",
  },

  tabContentWrapper: {
    overflowX: "hidden",
    overflowY: "hidden",
    padding: 10,
  },

  tabContentWrapperMobile: {
    padding: "15px 0 0 0 !important",
  },

}));

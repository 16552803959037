import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({

  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headerContainer: {
    marginBottom: 50,
  },
  routeDisplay: {
    marginTop: 30,
    marginLeft: 30,
    display: "inline-flex",
  },
  locationNameDisplay: {
    marginLeft: 10,
    marginTop: 10,
  },
  routeDisplayElementWrapper: {
    marginRight: 10,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",

  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  h5title: {
    fontSize: "1rem",
    lineHeight: "1.1",
    padding: "1rem !important",
    margin: "0rem !important",
    float: "left",
    color: theme.palette.primary.main,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  gridContainer: {
    marginBottom: 50,
  },
  paper: {
    marginBottom: 5,
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.text.secondary,
    height: "100%",
    "& .MuiTypography-h4": {
      color: theme.palette.text.primary,
    },

    "& .MuiCardContent-root": {
      width: "100%",
    },
  },
  tabPanelPaper:
  {
    padding: "16px",
  },
  tabPanel:
  {
    "& .MuiBox-root":
    {
      padding: "0px",
    },
  },
  locationNameTop: {
    color: "#04444B",
  },
  topCard: {
    padding: "20px",
    marginTop: "10px",
    clear: "both",
  },
  topCardDataTitle: {
    fontWeight: "bold",
  },
  locEditButton: {
    backgroundColor: "#00707D",
    color: "white",
    fontWeight: "bold",
  },
  downloadRaportButton: {
    color: "#014961",
    fontWeight: "bold",
  },

  containerMobile: {
    padding: "0px 0px 20px 0px",
  },

  tabPanelPaperMobile: {
    padding: "0 0 10px 0",
  },

  tabBarMobile: {
    "& .MuiTabs-root": {
      overflow: "visible",
      overflowX: "visible",

      "& .MuiTabs-scroller": {
        overflow: "visible !important",
        overflowX: "visible !important",
      },
    },
  },
}));

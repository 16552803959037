import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  tabPanel: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  secondButton: {
    marginLeft: 15,
  },
  topCard: {
    padding: "20px",
    clear: "both",
  },
  topCardDataTitle: {
    fontWeight: "bold",
  },
  topContainer:
  {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  container: {
    padding: `${CSS_GLOBALS.mainContainerPadding !== null ? `${CSS_GLOBALS.mainContainerPadding} !important` : "unset"}`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  addNewMeterButton: {
    marginBottom: 10,
  },
  headerContainer: {
    marginBottom: 50,
  },
  metersTitle: {
    marginTop: 50,
  },
  metersHeadCardsContainer:
  {
    paddingTop: "15px",
  },

  headerTop: {
    color: "#04444B",
  },
}));

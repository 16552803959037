import React, { ReactNode, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { Button, IconButton, TableSortLabel } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import useStyles from "./TableStyles";
import { OrganizationType } from "../../models/UserInfo";
import { UserRole } from "../../models/UserRole";
import useResponsivity from "../../hooks/useResponsivity";

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (t: any) => string;
  booleanType?: boolean;
  nestedInside?: Record<string, unknown>;
  disableSort?: boolean;
}

const StyledTableRowWithSubRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(4n+1)": {
      backgroundColor: "white",
    },
  },
}))(TableRow);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
  },
}))(TableRow);

type Generic = {
  [key: string]: unknown
}

interface ITableProps<T extends Generic> {
  columns: Column[];
  rows: T[];
  editRow?: (s: T) => void;
  deleteRow?: (s: T) => void;
  cellClickedCallback?: (d: T) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (size: number) => void;
  count: number;
  currentPage: number;
  currentRowsPerPage: number;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  toggleRowSelection?: (rows: T) => void;
  onClickIcon?: JSX.Element;
}

interface IRowProps<T extends Generic> {
  row: T;
  columns: Column[];
  editRow?: (s: T) => void;
  deleteRow?: (s: T) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  rowIndex: string;
}

const generateId = (prefix : string, id: string) : string => `${prefix}-${id}`;

const responsivityFilter = (f: Column, isMobile: boolean) : boolean => {
  const responsiveShow = [
    // ALERTS
    "meterNumber",
    "alertStartedAt",
    // "alertEndedAt",
    "address",
    "alertType",
    // "meterType",
    // "meterModel",

    // LOCATIONS
    // "identifier",
    // "ownerOrganizationName",
    "streetAddress",
    "postalCode",
    "city",
    // "country",

    // ORGANIZATIONS
    "name",
    "organizationType",
    "accountId",

    // USERS
    "firstName",
    "lastName",
    // "email",
    // "mobilePhone",
    // "organizationName",
    "userRole",
  ];

  return !isMobile || (isMobile && responsiveShow.includes(f.id));
};

const shortenForMobile = (column: string, label : string, t: any) : string => {
  const shortened = {
    meterNumber: "#",
    alertType: t("alertView.alertTypeShort"),
    ownerOrganizationName: t("locationView.ownerOrganizationNameShort"),
    streetAddress: t("locationView.addressShort"),
    postalCode: t("locationView.zipShort"),

  };

  // as keyof typeof --> https://stackoverflow.com/a/69198602/2284136
  if (column in shortened) return shortened[column as keyof typeof shortened];
    return label;
};

const renderCellData = (column: Column, row: any, value: any, t: any, isMobile: boolean, classes: any) : ReactNode => {
  if (value === null) { return <span>{value as string}</span>; }

  if (column.id === "locationCheck" && column.booleanType) { return <span><Checkbox onClick={(e) => e.stopPropagation()} /></span>; }
  if (column.id === "latestReadingTimeStamp" || column.id === "alertStartedAt" || column.id === "alertCheckedAt" || column.id === "alertEndedAt") { return <span>{isMobile ? format(parseISO(value as string), "dd.MM.yy' 'HH:mm") : format(parseISO(value as string), "dd.MM.yyyy' 'HH:mm:ss")}</span>; }
  if (column.id === "connectionStatus") { return <span className={value === "OFFLINE" ? classes.offline : value === "ONLINE" ? classes.online : ""}>{t(`connectionStatus.${value}`)}</span>; }
  if (column.id === "organizationType") { return <span>{t(`organizationType.${value}`)}</span>; }
  if (column.id === "alertType") { return <span>{isMobile ? t(`alertTypeShort.${value}`) : t(`alertType.${value}`)}</span>; }
  if (column.id === "userRole") { return <span>{t(`userRole.${value}`)}</span>; }
  if (column.id === "meterType" || column.id === "type") { return <span>{t(`meterType.${value}`)}</span>; }
  if (column.id === "streetAddress") {
    if (row.latitude !== null && row.longitude !== null) {
      return (
          <span className={classes.locationAddressSpan}>
              {value as string}
          </span>
      );
    }
    return <span>{value as string}</span>;
}

  return <span>{value as string}</span>;
};

function RowElement<T extends Generic>({ row, columns, editRow, deleteRow, onClick, rowIndex }: IRowProps<T>): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();
  const isUserTable = columns.filter((x) => x.id === "userRole").length > 0;
  const isOrgTable = columns.filter((x) => x.id === "accountId").length > 0;

  return (
      <StyledTableRow
        className={row.alertCheckedAt === null ? classes.boldRoot : classes.root}
        hover
        role="checkbox"
        tabIndex={-1}
        key={Object.keys(row)[0]}
        style={
              onClick !== undefined
                ? {
                  cursor: "pointer",
                } : {}
            }
      >
          {columns.filter((f) => responsivityFilter(f, isMobile)).map((column) => {
                const value = row[column.id];
                return (
                    <TableCell
                      onClick={onClick}
                      component="th"
                      scope="row"
                      key={column.id}
                      align={column.align}
                      className={`${classes.borderBottom} ${column.id === "usageToday" || column.id === "latestReadingCubicMeters" || column.id === "connectionStatus" ? classes.borderLeft : ""}`}
                    >
                        {renderCellData(column, row, value, t, isMobile, classes)}
                    </TableCell>
                );
              })}

          {(!isMobile || (!isUserTable && !isOrgTable && isMobile)) && (
              <TableCell
                className={`
                        ${classes.borderBottom} 
                        ${classes.editButton}`}
                style={{
                  padding: "0",
                }}
              >
                  {(((row.userRole !== UserRole.OV_ADMIN && row.userRole !== UserRole.OV_CUSTOMER_ADMIN) || (row.usersOrganizationType !== OrganizationType.RETAILER && row.usersOrganizationType !== OrganizationType.WATER_COMPANY && row.usersOrganizationType !== OrganizationType.SUPERADMIN))
                      && (row.organizationType !== OrganizationType.RETAILER && row.organizationType !== OrganizationType.WATER_COMPANY && row.organizationType !== OrganizationType.SUPERADMIN))
                      && editRow
                      && (
                          <Button
                            className={classes.downloadButton}
                            onClick={() => editRow(row)}
                            role="presentation"
                            id={generateId("edit-button", rowIndex)}
                          >
                              <CreateIcon style={{
                                verticalAlign: "middle",
                              }}
                              />
                              {!isMobile && t("common.edit")}
                          </Button>
                      )}
                  {(((row.userRole === UserRole.OV_ADMIN || row.userRole === UserRole.OV_CUSTOMER_ADMIN) && (row.usersOrganizationType === OrganizationType.RETAILER || row.usersOrganizationType === OrganizationType.WATER_COMPANY || row.usersOrganizationType === OrganizationType.SUPERADMIN))
                      || (row.organizationType === OrganizationType.RETAILER || row.organizationType === OrganizationType.WATER_COMPANY || row.organizationType === OrganizationType.SUPERADMIN)) && editRow
                      && (
                          <div className={classes.colorfulText}>{t("userManagementView.manageInConsole")}</div>
                      )}
              </TableCell>
)}
          {(!isMobile || (!isUserTable && !isOrgTable && isMobile)) && (
              <TableCell className={`
                        ${classes.borderBottom} 
                        ${classes.editButton}`}
              >
                  {(row.organizationType !== OrganizationType.RETAILER && row.organizationType !== OrganizationType.WATER_COMPANY && row.organizationType !== OrganizationType.SUPERADMIN) && deleteRow
                        && (
                            <span onClick={() => deleteRow(row)} role="presentation" id={generateId("location-delete-button", rowIndex)}>
                                <DeleteForever style={{
                                  verticalAlign: "middle",
                                }}
                                />
                                {" "}
                                {t("common.delete")}
                            </span>

                        )}
              </TableCell>
)}
      </StyledTableRow>
  );
}

export default function<T extends Generic> ({ columns, rows, editRow, deleteRow, cellClickedCallback, handlePageChange, handlePageSizeChange, count, currentPage, currentRowsPerPage, sort, setSortDirection, toggleRowSelection, onClickIcon }: ITableProps<T>): JSX.Element {
  const classes = useStyles();
  const [currentSortedBy, setCurrentSortedBy] = useState("");
  const [asc, setAsc] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();

  const setSort = (field: string): void => {
    if (currentSortedBy === field) {
      const newDirection = asc ? "desc" : "asc";
      setSortDirection(newDirection);
      setAsc(!asc);
    } else {
      setAsc(false);
      setSortDirection("desc");
      setCurrentSortedBy(field);
      sort(field);
    }
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handlePageSizeChange(+event.target.value);
  };

  return (
      <Paper className={`${classes.root} ${isMobile ? classes.rootMobile : ""}`}>
          <TableContainer className={`${classes.tableContainer} ${isMobile ? classes.tableContainerMobile : ""}`}>
              <Table aria-label="collapsible table" size="small">
                  <TableHead>
                      <TableRow className={classes.root}>
                          {columns.filter((f) => responsivityFilter(f, isMobile)).map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: "bold",
                                }}
                                className={column.id === "usageToday"
                                || column.id === "latestReadingCubicMeters"
                                || column.id === "connectionStatus"
                                ? `${classes.borderLeft} ${isMobile ? classes.tableHead : ""}`
                                : `${isMobile ? classes.tableHead : ""}`}
                              >
                                  {column.disableSort
                                    ? <>{column.label}</>
                                    : (
                                        <TableSortLabel
                                          className={classes.sortLabel}
                                          active={currentSortedBy === column.id}
                                          onClick={() => setSort(column.id)}
                                          direction={asc ? "asc" : "desc"}
                                        >
                                            {isMobile ? shortenForMobile(column.id, column.label, t) : column.label}
                                        </TableSortLabel>
                                    ) }
                              </TableCell>
                          ))}
                          {!isMobile && <TableCell />}
                          {!isMobile && <TableCell />}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      { rows.length > 0 && rows.map((row, idx) => (
                          <RowElement onClick={() => cellClickedCallback && cellClickedCallback(row)} editRow={editRow} deleteRow={deleteRow} columns={columns} row={row} key={JSON.stringify(row)} rowIndex={idx.toString()} />
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
          {/* rows.length === 0 && <PendingCircle customStyles={classes.spinner} /> */}
          <TablePagination
            labelRowsPerPage={t("table.rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} (${count})`}
            rowsPerPageOptions={[25, 100]}
            component="div"
            count={count !== undefined ? count : rows.length}
            rowsPerPage={currentRowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
  );
};

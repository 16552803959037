const redirectUri = process.env.REACT_APP_API_URL || "http://localhost:8080";

export function redirectToSigninPage(): void {
  window.location.href = `${redirectUri}/signin`;
}

export function handleLogin(): void {
  console.error("login error: ");
}

function createHttpTimeout(timeout?: number): [NodeJS.Timeout, AbortSignal] {
  const t = timeout || 15000;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), t);
  return [id, controller.signal];
}

export async function getWithAccessToken(url: string, accessToken: string, timeout?: number, abortSignal?: AbortSignal): Promise<unknown> {
  const [t, signal] = createHttpTimeout(timeout);

  try {
    const bearer = `Bearer ${accessToken}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      redirect: "error",
      credentials: "include",
      signal: abortSignal || null,
    });
    clearTimeout(t);

    // TODO: Fix when 409 is changed
    if (response.status === 409) {
      console.error("user not setup correctly");
      return Promise.reject(new Error("User not found"));
    }
    if (response.status > 299) {
      console.error(`HTTP request failed: ${response}`);
      return Promise.reject(new Error(response.status.toString()));
    }

    let body: unknown = null;
    const contentType = response.headers.get("content-type");
    try {
      if (contentType && contentType.indexOf("application/json") !== -1) {
        body = await response.json();
      } else {
        body = await response.text();
      }
    } catch (err) {
      console.error("Failed to parse http response", err);
      return Promise.reject(new Error(response.status.toString()));
    }

    return body;
  } catch (err) {
    // TODO: this error should only occur when 302-redirect is received from the gateway (session expired)
    // signin-page will automatically redirect the browser to UI frontpage if the user session is still valid in azure
    // redirectToSigninPage();
    // todo redirect to login
    return Promise.reject(new Error("Session expired"));
  }
}

interface PutConfig {
  method: string;
  body?: string;
  headers: {
    "Content-Type": string;
    Authorization: string;
  }
  redirect: RequestRedirect;
  credentials: RequestCredentials;
  signal: AbortSignal;
}

export async function putWithAccessToken(url: string, accessToken: string, body?: unknown, timeout?: number): Promise<unknown> {
  const [t, signal] = createHttpTimeout(timeout);
  const bearer = `Bearer ${accessToken}`;
  const config: PutConfig = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    redirect: "error",
    credentials: "include",
    signal,
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(url, config);
  clearTimeout(t);

  if (response.status === 204) {
    return null;
  }

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    throw Error(`HTTP request failed: ${response}`);
  }

  const contentType = response.headers.get("content-type");
  try {
    let responseBody: unknown = null;

    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseBody = await response.json();
    } else {
      responseBody = await response.text();
    }
    return responseBody;
  } catch (err) {
    console.error("Failed to parse http response", err);
    return Promise.reject(new Error(response.status.toString()));
  }
}

export async function get(url: string, timeout?: number): Promise<unknown> {
  const [t, signal] = createHttpTimeout(timeout);

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "error",
      credentials: "include",
      signal,
    });
    clearTimeout(t);

    // TODO: Fix when 409 is changed
    if (response.status === 409) {
      console.error("user not setup correctly");
      return Promise.reject(new Error("User not found"));
    }
    if (response.status > 299) {
      console.error(`HTTP request failed: ${response}`);
      return Promise.reject(new Error(response.status.toString()));
    }

    let body: unknown = null;
    const contentType = response.headers.get("content-type");
    try {
      if (contentType && contentType.indexOf("application/json") !== -1) {
        body = await response.json();
      } else {
        body = await response.text();
      }
    } catch (err) {
      console.error("Failed to parse http response", err);
      return Promise.reject(new Error(response.status.toString()));
    }

    return body;
  } catch (err) {
    // TODO: this error should only occur when 302-redirect is received from the gateway (session expired)
    // signin-page will automatically redirect the browser to UI frontpage if the user session is still valid in azure
    // redirectToSigninPage();
    return Promise.reject(new Error("Session expired"));
  }
}

export async function postFileWithAccessToken(url: string, body: File, accessToken: string, timeout?: number): Promise<unknown> {
  const bearer = `Bearer ${accessToken}`;
  const [t, signal] = createHttpTimeout(timeout);
  const formData = new FormData();
  formData.append("file", body);
  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: bearer,
    },
    redirect: "error",
    credentials: "include",
    signal,
  });
  clearTimeout(t);

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    throw Error(`HTTP request failed: ${response}`);
  }

  let json: unknown = null;
  try {
    json = await response.json();
  } catch (err) {
    console.error(`HTTP request failed: ${response}`);
    throw err;
  }

  return json;
}
export async function getForFileWithAccessToken(url: string, accessToken: string, body?: unknown, timeout?: number): Promise<Blob> {
  const bearer = `Bearer ${accessToken}`;
  const [t, signal] = createHttpTimeout(timeout);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
  })
    .then((response) => {
      if (response.status > 299) {
        console.error(`HTTP request failed`);
        throw Error(`HTTP request failed: ${response}`);
      } else {
        return response.blob();
      }
    });

  clearTimeout(t);
  return response;
}

export async function postWithAccessToken(url: string, body: unknown, accessToken: string, timeout?: number): Promise<unknown> {
  const bearer = `Bearer ${accessToken}`;
  const [t, signal] = createHttpTimeout(timeout);
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    redirect: "error",
    credentials: "include",
    signal,
  });
  clearTimeout(t);

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    throw Error(`HTTP request failed: ${response}`);
  }

  let json: unknown = null;
  try {
    json = await response.json();
  } catch (err) {
    if (err instanceof SyntaxError) {
      console.warn("HTTP request expected return JSON");
    } else {
      console.error(`HTTP request failed: ${response}`);
      throw err;
    }
  }

  return json;
}

export async function post(url: string, body: unknown, timeout?: number): Promise<unknown> {
  const [t, signal] = createHttpTimeout(timeout);
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "error",
    credentials: "include",
    signal,
  });
  clearTimeout(t);

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    throw Error(`HTTP request failed: ${response}`);
  }

  let json: unknown = null;
  try {
    json = await response.json();
  } catch (err) {
    console.error(`HTTP request failed: ${response}`);
    throw err;
  }

  return json;
}

export async function del(url: string): Promise<void> {
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    return Promise.reject(new Error("fail"));
  }
  return Promise.resolve();
}

export async function delWithToken(url: string, accessToken: string): Promise<void> {
  const bearer = `Bearer ${accessToken}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "include",
  });

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    return Promise.reject(new Error("fail"));
  }
  return Promise.resolve();
}

export async function put(url: string, body: unknown, timeout?: number): Promise<unknown> {
  const [t, signal] = createHttpTimeout(timeout);
  const response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "error",
    credentials: "include",
    signal,
  });
  clearTimeout(t);

  if (response.status > 299) {
    console.error(`HTTP request failed: ${response}`);
    throw Error(`HTTP request failed: ${response}`);
  }

  let json: unknown = null;
  try {
    json = await response.json();
  } catch (err) {
    console.error(`HTTP request failed: ${response}`);
    throw err;
  }

  return json;
}

import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { CSS_GLOBALS } from "../../css_globals";

export default makeStyles((theme: Theme) => createStyles({
  pageContainer: {
    backgroundColor: theme.palette.secondary.light,
  },
  tabBar: CSS_GLOBALS.tabBar(theme),
  tabPanel: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  secondCard: {},
  topContainer: {
    padding: `${CSS_GLOBALS.topContainerPadding !== null ? `${CSS_GLOBALS.topContainerPadding} !important` : "unset"}`,
  },
  container: {
    padding: `10px 20px 20px 20px`,
    minWidth: "100%",
    "& .MuiBox-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headerContainer: {
    marginBottom: 50,
  },
  headerTop: {
    color: "#04444B",
  },
  consumptionTitle: {
    color: "#04444B",
    paddingBottom: 10,
  },
  coldText: {
    fontWeight: "bold",
    color: theme.palette.hotColdGraph.main,
    display: "inline-block",
    minWidth: "76px",
  },
  hotText: {
    fontWeight: "bold",
    color: theme.palette.hotColdGraph.dark,
    display: "inline-block",
    minWidth: "76px",
  },
  infoText: {
    display: "inline-block",
    minWidth: "120px",
  },
  priceInfoText: {
    display: "inline-block",
    fontWeight: "bold",
  },
  priceInfoTextCold: {
    display: "inline-block",
    fontWeight: "bold",
    color: theme.palette.hotColdGraph.main,
  },
  priceInfoTextHot: {
    display: "inline-block",
    fontWeight: "bold",
    color: theme.palette.hotColdGraph.dark,
  },
  centerText: {
    textAlign: "center",
  },
  coldPriceText: {
    /* marginLeft: 20, */
    color: "black",
  },
  hotPriceText: {
    /* marginLeft: 20, */
    color: "black",
  },
  pricesCard: {
    padding: 10,
    marginTop: "15px",
  },
  homePageMain:
  {
    flex: "unset !important",
    width: "60%",
    height: "auto",
    minHeight: "150px",
    padding: "15px !important",
    boxSizing: "border-box",
    margin: "15px 0px 15px 10px !important",
  },
  headCardsContainer:
  {
    gap: "15px",
    marginTop: "10px",
  },
  warmTotalContainer: {
    marginLeft: 30,
  },
  /* GRAPH */
  consumptionTabLeftWrapper:
  {
    height: "100%",
  },
  consumptionTabDateSelectGroup:
  {
    "& .MuiButton-root":
    {
      color: "#00A0B4",
      fontWeight: "600",
      border: 0,
    },
    "& .MuiButton-root:hover":
    {
      border: 0,
    },

    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

  },
  scaleSelected:
  {
    textDecoration: "underline !important",
    textUnderlineOffset: "5px",
  },
  datePickPopover:
  {
    padding: "15px",

    "& .MuiTextField-root":
    {
      marginTop: "10px",
    },

    "& .MuiButton-root":
    {
      backgroundColor: "#00A0B4",
      marginTop: "15px",
    },
    "& .MuiButton-root:hover":
    {
      backgroundColor: "#00A0B4",
    },
  },
  datePickText:
  {
    marginTop: "10px !important",
  },

  singleMeterNextConsumptionCard:
  {
    marginLeft: "15px",
  },

  homePageGraphContainer:
  {
    marginTop: "15px",
    padding: "15px",
  },

  homePageGraphResponsiveContainer: {
    minHeight: "600px",
  },

  MobileScaleButtons: {
    marginTop: "15px",
  },

  chartConsumptionInfoWrapper: {
    padding: "0px 15px 0px 15px",
  },
}));

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => createStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "15px",
    whiteSpace: "pre-wrap",
    zIndex: 1,
  },
  section: {
    marginBottom: 25,
    backgroundColor: "white",
  },
  modalText: {
    color: "#04444B",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
  },
  button: {
    marginRight: 15,
  },
  deleteButton: {
    marginRight: 15,
  },
}));

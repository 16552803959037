import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../RootStateContext";
import i18n from "../../i18n";
import useStyles from "./HeaderStyles";
import { UserRole } from "../../models/UserRole";
import useResponsivity from "../../hooks/useResponsivity";
import { WhitelabelConfiguration } from "../../assets/clientconfiguration/whitelabelConfig";
import { useLogo } from "../../hooks/useLogo";

interface IHeaderProps {
  userName?: string;
  userRole?: UserRole;
  organizationName?: string;
}

export default observer(({ userName, userRole, organizationName }:IHeaderProps): JSX.Element => {
  const classes = useStyles();
  const store = useRootStore().uiStateStore;
  const { t } = useTranslation();
  const authStore = useRootStore().authenticationStore;
  const changeLanguage = (lng: string):Promise<TFunction> => i18n.changeLanguage(lng);
  const { instance } = useMsal();
  const { isMobile } = useResponsivity();

  const logo = useLogo();

  const navigateToLogout = (): void => {
    instance.logout(
      {
        idTokenHint: authStore.idToken,
      },
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
      <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.contents}>
              <div className={`${classes.omavesiLogoContainer} ${isMobile ? classes.logoWhenMobile : store.drawerOpen ? classes.logoWhenDrawerOpen : classes.logoWhenDrawerClosed}`}>
                  <img
                    className={classes.omavesiLogo}
                    src={logo}
                    alt="omavesi logo"
                  />
              </div>
              {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={store.handleDrawerToggle}
              className={classes.menuButton}
              id="OpenMobileNavigation"
            >
                <MenuIcon />
</IconButton> */}
              <div className={classes.languageSelector}>
                  {/*           <Button
                  className={(i18n.language === "fi") ? classes.selectedLanguage : classes.languageButton}
                  onClick={() => changeLanguage("fi")}
                  id="ChangeLanguageToFinnish"
                >
                    Suomi
                </Button>
                <Button
                  className={(i18n.language === "se") ? classes.selectedLanguage : classes.languageButton}
                  onClick={() => changeLanguage("se")}
                  id="ChangeLanguageToFinnish"
                >
                    Svenska
                </Button>
                <Button
                  className={(i18n.language === "en") ? classes.selectedLanguage : classes.languageButton}
                  onClick={() => changeLanguage("en")}
                  id="ChangeLanguageToEnglish"
                >
                    English
                </Button> */}
              </div>
              {/* {authStore.authenticated && ( */}
              <div className={classes.orgAndUserNameContainer}>
                  <Typography component="span">{ organizationName }</Typography>
                  <Typography component="span" className={classes.userNameUnderOrganization}>{ userName }</Typography>
              </div>

              <div className={classes.nameAndUserMenuContainer}>
                  <div>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        size="large"
                      >
                          <AccountCircle />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                      >
                          <MenuItem onClick={handleClose} key="headermenuitem-username">
                              { userName }
                              {" "}
                              (
                              {t(`userRole.${userRole}`)}
                              )
                          </MenuItem>
                          <MenuItem onClick={navigateToLogout} key="headermenuitem-logout" id="logoutButton">{t("header.logout")}</MenuItem>
                      </Menu>
                  </div>
              </div>
              {/* )} */}
          </Toolbar>
      </AppBar>
  );
});

import { AppBar, Box, Card, Container, Fade, Tab, Tabs, Typography, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import useStyles from "./SingleOrganizationViewStyles";
import { WaterMeter, WaterMeterJustId } from "../../models/WaterMeter";
import { OrganizationType, UserForResidentCreation, UserInfo } from "../../models/UserInfo";
import { Organization } from "../../models/Organization";
import UsersOfOrganization from "./managementComponents/usersOfOrganization/UsersOfOrganization";
import MetersOfOrganization from "./managementComponents/metersOfOrganization/MetersOfOrganization";
import RouteDisplay from "../../components/routeDisplay/RouteDisplay";
import OrganizationsOfHousingManagementOrganization
  from "./managementComponents/organizationsOfHousingManagementOrganization/OrganizationsOfHousingManagementOrganization";
import useResponsivity from "../../hooks/useResponsivity";
import { TabPanel } from "../../components/tabPanel/TabPanel";

interface ISingleOrganizationViewProps {
  organization: Organization;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  totalMeters: number;
  page: number;
  rowsPerPage: number;
  fetchingMeters: boolean;
  sort: (s: string) => void;
  setSortDirection: (s: string) => void;
  setArchived: (a: boolean) => void;
  search: (s: string) => void;
  setSearchJustActivated: (s: boolean) => void;
  resetAllOptions: () => void;
  activeSearchValue?: string;
  allUsers?: UserInfo[];
  usersOfOrganization?: UserInfo[];
  saveUser: (s: UserForResidentCreation) => void;
  addMeterToOrganization: (m: WaterMeterJustId, orgId: string) => void;
  takeMeterFromOrganization: (m: WaterMeter) => void;
  organizationsOfOrganization?: Organization[];
  addOrganizationAccessToOrganization: (o: Organization) => void;
}

export default function ({ organization, setPage, setPageSize, totalMeters,
  page, rowsPerPage, fetchingMeters,
  sort, setSortDirection, setArchived, search,
  setSearchJustActivated, resetAllOptions,
  activeSearchValue, allUsers, usersOfOrganization, saveUser, addMeterToOrganization, organizationsOfOrganization, addOrganizationAccessToOrganization, takeMeterFromOrganization }: ISingleOrganizationViewProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useResponsivity();

  const [archivedState, setArchivedState] = React.useState(0); // Tab control

  const handleChangeArchived = (event: React.ChangeEvent<any>, newValue: number): void => {
    setArchivedState(newValue);
  };

  return (
      <Fade in>
          <Box>
              {/* <RouteDisplay parentLink="/usermanagement" parentName={t("navigation.users")} currentLink={`/organization/${organization.id}`} currentName={organization.name} /> */}
              <Container className={`${classes.container} ${isMobile && classes.containerMobile}`}>
                  <Grid item container xs={12} justifyContent={isMobile ? "center" : "flex-start"} flexDirection="column">
                      <Typography
                        style={isMobile ? {
                            textAlign: "center",
                        } : {}}
                        variant="h4"
                        className={classes.locationNameTop}
                        id="singleorganization-identifierhead"
                      >
                          {`${organization.name}`}
                      </Typography>
                      <Typography
                        style={isMobile ? {
                            textAlign: "center",

                        } : {
                            fontStyle: "italic",
                        }}
                        variant="h6"
                        className={classes.locationNameTop}
                      >
                          {t(`organizationType.${organization.organizationType}`)}
                      </Typography>
                  </Grid>
                  <AppBar className={`${classes.tabBar} ${isMobile && classes.tabBarMobile}`} position="static">

                      <Tabs value={archivedState} onChange={handleChangeArchived}>
                          <Tab label={t("singleLocation.users")} id="organizations-users-tab" aria-controls="organizations-users-tab" />
                          {organization.organizationType !== OrganizationType.OMAVESI_HOUSE_MAINTENANCE ? (
                              <Tab label={t("singleLocation.meters")} id="organizations-meters-tab" aria-controls="organizations-meters-tab" />
                          ) : (<Tab label={t("organizationView.organizations")} id="organizations-organizations-tab" aria-controls="organizations-organizations-tab" />)}
                      </Tabs>

                  </AppBar>

                  <TabPanel value={archivedState} index={0}>
                      <Card className={classes.paper} variant="elevation" elevation={2}>
                          <UsersOfOrganization
                            organization={organization}
                            usersOfOrganization={usersOfOrganization}
                            saveUser={saveUser}
                            setPage={setPage}
                            setPageSize={setPageSize}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            sort={sort}
                            setSortDirection={setSortDirection}
                            search={search}
                            setSearchJustActivated={setSearchJustActivated}
                            resetAllOptions={resetAllOptions}
                            activeSearchValue={activeSearchValue}
                          />
                      </Card>
                  </TabPanel>

                  {organization.organizationType !== OrganizationType.OMAVESI_HOUSE_MAINTENANCE ? (
                      <TabPanel value={archivedState} index={1}>
                          <Card className={classes.paper} variant="elevation" elevation={2}>
                              <MetersOfOrganization organization={organization} addMeter={addMeterToOrganization} removeMeter={takeMeterFromOrganization} />
                          </Card>
                      </TabPanel>
                  ) : (
                      <TabPanel value={archivedState} index={1}>
                          <Card className={classes.paper} variant="elevation" elevation={2}>
                              <OrganizationsOfHousingManagementOrganization organization={organization} organizations={organizationsOfOrganization} addOrganizationAccessToOrganization={addOrganizationAccessToOrganization} />
                          </Card>
                      </TabPanel>
                  )}

              </Container>
          </Box>
      </Fade>

  );
};

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import UnauthorizedComponent from "../../../components/unauthorizedComponent/UnauthorizedComponent";
import { useRootStore } from "../../../RootStateContext";
import PriceView from "../PriceView";
import { loginRequest } from "../../../authConfig";
import acquireToken, { tokenAction } from "../../../api/MsalUtil";
import Alert from "../../../models/Alert";
import { WaterPrice } from "../../../models/Pricing";

export default observer((): JSX.Element => {
  const [forbidden, setForbidden] = useState(false);
  const authStore = useRootStore().authenticationStore;

  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const savePrices = async (prices: WaterPrice): Promise<void> => {
    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        if (authStore.currentUser && authStore.currentUser.organizationId) {
          await authStore.savePrices(idToken, prices, authStore.currentUser.organizationId);
          if (authStore.error === undefined) {
            toast.info(t("pricing.priceSaved"), {
              toastId: "PriceSavedToast",
              autoClose: 5000,
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
            });
          }
        }
      }, currentUserToken, accounts, instance);
    })();
  };

  if (forbidden) {
    return <UnauthorizedComponent />;
  }

  return (
      <PriceView savePrices={savePrices} />
  );
});

import React from "react";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./modalHeaderStyles";

interface IModalHeaderProps {
    headerText: string;
    id?: string;
    clear: () => void;
}

export default function ({ headerText, id, clear } : IModalHeaderProps): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.sectionHeader}>
            <Typography variant="h6" component="span" id={id || ""}>{headerText}</Typography>
            <CloseIcon onClick={() => clear()} className={classes.closeIcon} />
        </div>
    );
  };

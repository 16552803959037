import { getWithAccessToken, putWithAccessToken } from "./HttpUtils";
import Alert, { AlertResponseWrapper } from "../models/Alert";

const api = process.env.REACT_APP_API_URL || "";

export const getAlertsOfLocationPaged = async (locationId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, archived?: boolean, search?: string): Promise<AlertResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  return await getWithAccessToken(`${api}/service/alert/alertsForLocation/${locationId}/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}`, userToken) as AlertResponseWrapper;
};

export const getAlertsOfMeterPaged = async (meterId: string, userToken: string, page: number, pageSize: number, sortBy?: string, sortDirection?: string, search?: string): Promise<AlertResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  return await getWithAccessToken(`${api}/service/alert/alertsForMeter/${meterId}/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useSearch}`, userToken) as AlertResponseWrapper;
};

export const getUnreadAlertCount = async (userToken: string): Promise<number> => await getWithAccessToken(`${api}/service/alert/unreadCount`, userToken) as number;

export const getUnreadAlertCountOfMeter = async (userToken: string, meterId: string): Promise<number> => await getWithAccessToken(`${api}/service/alert/unreadCount/${meterId}`, userToken) as number;

export const getAllAlertsPaged = async (
userToken: string,
  page: number,
  pageSize: number,
  sortBy?: string,
  sortDirection?: string,
  archived?: boolean,
  search?: string,
  alertType?: string,
  meterType?: string,
): Promise<AlertResponseWrapper> => {
  const dir = (sortDirection !== "desc" && sortDirection !== "asc") ? "desc" : sortDirection;
  const useSortBy = sortBy !== undefined ? `&sort=${sortBy}` : "";
  const useSortDirection = sortDirection !== undefined && sortBy !== undefined ? `,${dir}` : "";
  const useArchived = archived !== undefined ? `&archived=${archived}` : "";
  const useSearch = search !== undefined && search.length > 2 ? `&search=${search}` : "";
  const useAlertType = alertType !== undefined && alertType !== "" ? `&alertType=${alertType}` : "";
  const useMeterType = meterType !== undefined && meterType !== "" ? `&meterType=${meterType}` : "";
  return await getWithAccessToken(`${api}/service/alert/?page=${page}&size=${pageSize}${useSortBy}${useSortDirection}${useArchived}${useSearch}${useAlertType}${useMeterType}`, userToken) as AlertResponseWrapper;
};

export const updateAlert = async (currentUserToken: string, alertId: string, alert: Alert): Promise<Alert> => await putWithAccessToken(`${api}/service/alert/${alertId}`, currentUserToken, alert) as Alert;

export const markAllAlertsRead = async (currentUserToken: string): Promise<void> => putWithAccessToken(`${api}/service/alert/read`, currentUserToken) as Promise<void>;

import i18n from "i18next";

export type State = {
    [key: string]: any;
 }

export type Action = {
    type: ActionType;
    data: State
}

export enum ActionType {
    UPDATE
}

export const validateInput = (name: string, value: string): {hasError: boolean, error: string} => {
  let hasError = false;
  let error = "";
  switch (name) {
    case "userName":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyUserName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "email":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyEmail");
      } else if (
        !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
          value,
        )
      ) {
        hasError = true;
        error = i18n.t("validation.invalidEmail");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "password":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyPassword");
      } else if (value.trim().length < 8) {
        hasError = true;
        error = i18n.t("validation.invalidPassword");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "name":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyLocationName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "type":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyFirstName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "streetAddress":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyFirstName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "postalCode":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyFirstName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "city":
      if (value.trim() === "") {
        hasError = true;
        error = i18n.t("validation.emptyCity");
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "country":
      if (!value) {
        hasError = true;
        error = i18n.t("validation.emptyLastName");
      } else {
        hasError = false;
        error = "";
      }
      break;
    default:
      break;
  }
  return {
    hasError, error,
  };
};

export const onInputChange = (name: string, value: string, dispatch: any, formState: State): void => {
  const { hasError, error } = validateInput(name, value);
  let isFormValid = true;
  Object.keys(formState).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(formState, key)) {
      const item = formState[key];
      // Check if the current field has error
      if (key === name && hasError) {
        isFormValid = false;
      } else if (item !== undefined && key !== name && item.hasError) {
      // Check if any other field has error
        isFormValid = false;
      }
    }
  });
  dispatch({
    type: ActionType.UPDATE,
    data: {
      name, value, hasError, error, touched: false,
    },
  });
};

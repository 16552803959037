import React, { useEffect, useReducer, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import { Button,
  Fade,
  TextField,
  Typography,
  FormControl, InputLabel, Grid, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { observer } from "mobx-react-lite";
import useStyles from "./EditMeterModalStyles";
import { Action, ActionType, onInputChange, State } from "./Util";
import { WaterMeter, WaterMeterWithLocation } from "../../models/WaterMeter";
import Location, { SearchLocation } from "../../models/Location";
import ModalHeader from "../modalComponents/modalHeader";
import { useRootStore } from "../../RootStateContext";
import { loginRequest } from "../../authConfig";
import acquireToken, { tokenAction } from "../../api/MsalUtil";
import ModalPortalTarget from "../../stores/ModalPortalTarget";

interface IModalProps {
  meterToBeEdited: WaterMeterWithLocation;
  open: boolean;
  handleClose: () => void;
  submit: (d: WaterMeter) => void;
}
const initialValidationState = {
  name: {
    value: "", hasError: false, error: "",
  },
  type: {
    value: "", hasError: false, error: "",
  },
  streetAddress: {
    value: "", hasError: false, error: "",
  },
  postalCode: {
    value: "", hasError: false, error: "",
  },
  city: {
    value: "", hasError: false, error: "",
  },
  country: {
    value: "", hasError: false, error: "",
  },
};
const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.UPDATE) {
    const { name, value, hasError, error } = action.data;
    return {
      ...state,
      [name]: {
        ...state[name], value, hasError, error,
      },
    };
  }
  return state;
};
export default observer(({ meterToBeEdited, open, handleClose, submit }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locationsStore = useRootStore().locationStore;
  const [validationState, dispatch] = useReducer(reducer, initialValidationState);

  const [typeState, setTypeState] = useState(meterToBeEdited.type || "");
  const [addressState, setAddressState] = useState(meterToBeEdited.address);
  const [locationState, setLocationState] = useState<Location | null>(meterToBeEdited.meterLocation ?? null);
  const [locationSearchInputState, setLocationSearchInputState] = useState(meterToBeEdited.address || "");

  const { instance, accounts } = useMsal();
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const authStore = useRootStore().authenticationStore;

  useEffect(() => {
    if (!open) { return; }
    setLocationSearchInputState(meterToBeEdited.address || "");
    setTypeState(meterToBeEdited.type || "");
    setAddressState(meterToBeEdited?.address);
  }, [meterToBeEdited, open]);

  const clear = (): void => {
    setTypeState("");
    setAddressState("");
    setLocationSearchInputState("");
    setLocationState(null);
    handleClose();
  };

  const handleTypeChange = (event: SelectChangeEvent<string>): void => {
    if (event.target.value) {
      setTypeState(event.target.value as string);
    }
  };

  const saveMeter = (): void => {
    let finalType = null;

    if (!typeState || typeState === "" || typeState.length === 0 || typeState === null || typeState === undefined) { finalType = ""; } else { finalType = typeState; }

    const meter: WaterMeterWithLocation = {
      id: meterToBeEdited.id,
      meterNumber: meterToBeEdited.meterNumber,
      devEUI: meterToBeEdited.devEUI,
      modelName: meterToBeEdited.modelName,
      archived: meterToBeEdited.archived,
      type: finalType,
      address: "",
      connectionStatusCombined: meterToBeEdited.connectionStatusCombined,
      ownerOrganizationName: meterToBeEdited.ownerOrganizationName,
      latestReadingCubicMeters: meterToBeEdited.latestReadingCubicMeters,
      latestReadingTimeStamp: meterToBeEdited.latestReadingTimeStamp,
      consumptionYear: meterToBeEdited.consumptionYear,
      consumptionMonth: meterToBeEdited.consumptionMonth,
      meterLocation: locationState ?? undefined,
    };

    if (finalType === "") { delete meter.type; }

    submit(meter);
    clear();
  };

  const handleDefaultAddressChange = (locationId: string) : void => {
    if (locationId !== "") {
      const selectedLocation : Location | undefined = locationsStore.meterEditSelectLocations.find((l) => l.id === locationId);
      setAddressState(`${selectedLocation?.streetAddress}, ${selectedLocation?.postalCode} ${selectedLocation?.city}`);
    } else {
      setAddressState("");
    }
  };

  const handleLocationDropdownChange = (event: React.SyntheticEvent, value: Location | null, reason: AutocompleteChangeReason): void => {
    switch (reason) {
      case "clear":
        setLocationState(null);
        handleDefaultAddressChange("");
        setLocationSearchInputState("");
        break;
      case "selectOption":
        if (value) {
          setLocationState(value);
          handleDefaultAddressChange(value.id);
          setLocationSearchInputState(`${value?.streetAddress}, ${value?.postalCode} ${value?.city}`);
        }
        break;
      default:
          break;
    }
  };

  const onInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason): void => {
    if (reason !== "reset") {
      if (value === "") {
        setLocationState(null);
        handleDefaultAddressChange("");
        setLocationSearchInputState("");
      } else {
        setLocationSearchInputState(value);
      }
    }
  };

  useEffect(() => {
    if (locationSearchInputState.length <= 2) {
      locationsStore.clearMeterEditLocations();
      return undefined;
    }

    if (!open) { return undefined; }

    const controller = new AbortController();
    const { signal } = controller;
    const search = locationSearchInputState.includes(",")
    ? locationSearchInputState.substring(0, locationSearchInputState.indexOf(","))
    : locationSearchInputState;

    (async () => {
      await tokenAction(async (idToken) => {
        setCurrentUserToken(idToken);
        await locationsStore.loadMeterEditLocations(idToken, search, signal);

        if (locationState && locationState.id !== meterToBeEdited.locationId) {
          setLocationState(locationsStore.meterEditSelectLocations.find((l) => l.id === locationState.id) ?? null);
        } else {
          setLocationState(locationsStore.meterEditSelectLocations.find((l) => l.id === meterToBeEdited.locationId) ?? null);
        }
      }, currentUserToken, accounts, instance);
    })();

    return () => {
      controller.abort();
    };
  }, [accounts, instance, locationSearchInputState, locationsStore]);

  return ReactDOM.createPortal(<Modal
    className={classes.modal}
    open={open}
    onClose={clear}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
      <Fade in={open}>
          <div className={classes.paper}>
              <ModalHeader headerText={t("meterView.editMeter")} clear={clear} id="editMeterTitle" />
              <Grid container className={classes.nonEditableInfo}>
                  <Grid item xs={4} md={4}>
                      {t("waterMeter.meterNumber")}
                  </Grid>
                  <Grid item xs={8} md={8} className={classes.valueColumn}>
                      {meterToBeEdited.meterNumber}
                  </Grid>
                  <Grid item xs={4} md={4}>
                      {t("waterMeter.devEUI")}
                  </Grid>
                  <Grid item xs={8} md={8} className={classes.valueColumn}>
                      {meterToBeEdited.devEUI}
                  </Grid>
                  <Grid item xs={4} md={4}>
                      {t("waterMeter.meterModel")}
                  </Grid>
                  <Grid item xs={8} md={8} className={classes.valueColumn}>
                      {meterToBeEdited.modelName ? meterToBeEdited.modelName : "-"}
                  </Grid>
                  <Grid item xs={4} md={4}>
                      {t("waterMeter.archived")}
                  </Grid>
                  <Grid item xs={8} md={8} className={classes.valueColumn}>
                      {meterToBeEdited.archived ? t("common.yes") : t("common.no")}
                  </Grid>
              </Grid>
              <div className={classes.section}>

                  <div className={classes.subsection}>
                      <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="span">{t("waterMeter.type")}</Typography>
                      <FormControl className={classes.formControl}>
                          <InputLabel id="meterTypeLabel">{t("waterMeter.type")}</InputLabel>
                          <Select
                            labelId="meterTypeLabel"
                            id="meterType"
                            value={typeState}
                            onChange={handleTypeChange}
                            displayEmpty={false}
                          >
                              <MenuItem key="WARM" value="WARM">{t("meterType.WARM")}</MenuItem>
                              <MenuItem key="COLD" value="COLD">{t("meterType.COLD")}</MenuItem>
                          </Select>
                      </FormControl>
                  </div>

                  <div className={classes.subsection}>
                      <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="span">{t("waterMeter.location2")}</Typography>
                      <FormControl className={classes.formControl}>
                          <Autocomplete
                            disablePortal
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            loading={locationsStore.fetchingMeterEditLocations}
                            id="meterLocationLabelId"
                            onChange={(e, v, r) => { handleLocationDropdownChange(e, v as Location, r); }}
                            options={
                                      locationsStore.meterEditSelectLocationsAvailable > 0
                                      ? [...locationsStore.meterEditSelectLocations, `+ ${locationsStore.meterEditSelectLocationsAvailable} tulosta`]
                                      : locationsStore.meterEditSelectLocations
                                    }
                            renderInput={(params) => <TextField {...params} label={t("meterView.selectLocation")} />}
                            renderOption={(props, option) => {
                                      if (typeof option !== "string") {
                                        return (
                                            <li {...props} key={option.id}>
                                                {`${option.streetAddress}, ${option.postalCode} ${option.city}`}
                                            </li>
                                        );
                                      }
                                      return (
                                          <li {...props} key="meter_edit_additional_locations">
                                              {option as string}
                                          </li>
                                      );
                            }}
                            value={locationState}
                            inputValue={locationSearchInputState}
                            getOptionLabel={(option) => (typeof option !== "string" ? `${option.streetAddress}, ${option.postalCode} ${option.city}` : option)}
                            isOptionEqualToValue={(option, value) => (typeof value !== "undefined" && typeof value !== "string" && typeof option !== "string" && option.id === value.id)}
                            onInputChange={onInputChange}
                            filterOptions={(x) => x}
                            noOptionsText={t("meterView.noLocationOptions")}
                            getOptionDisabled={(option) => typeof option === "string"}
                          />
                          {/* <Select
                                    labelId="meterLocationLabel"
                                    id="meterLocationLabelId"
                                    value={locationIdState}
                                    defaultValue={meterToBeEdited.locationId}
                                    onChange={handleLocationDropdownChange}
                                  >
                                      {locations.sort((a, b) => a.meterCount - b.meterCount).map((location:Location, index) => (
                                          <MenuItem value={location.id} className={location.meterCount > 0 ? classes.takenLocation : classes.availableLocation} key={location.id}>
                                              {location.identifier}
                                              {" "}
                                              (
                                              {location.ownerOrganizationName}
                                              )
                                              {" "}
                                              (
                                              {location.meterCount}
                                              )
                                          </MenuItem>
                                      ))}
                                              </Select> */}
                      </FormControl>
                  </div>

                  {/* <div className={classes.subsection}>
                              <Typography variant="subtitle1" className={classes.routingPlanSelectionTitle} component="span">{t("waterMeter.address")}</Typography>
                              {addressState ? (
                                  <Typography variant="subtitle1" className={classes.addressText}>
                                      {addressState}
                                  </Typography>
                              ) : (
                                  <Typography variant="subtitle1" className={classes.addressText}>
                                      {meterToBeEdited.address}
                                  </Typography>
                              )}
                              </div> */}
              </div>
              <div className={classes.navigation}>

                  <div className={classes.modalButtons}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={clear}
                        className={classes.cancelButton}
                      >
                          {t("common.cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveMeter}
                        disabled={locationsStore.fetchingMeterEditLocations}
                      >
                          {t("common.save")}
                      </Button>
                  </div>
              </div>
          </div>
      </Fade>
  </Modal>, ModalPortalTarget);
});

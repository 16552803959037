import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Container, Paper, Typography, Checkbox, FormControl, FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import "@fontsource/roboto";
import { useMsal } from "@azure/msal-react";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AlertType } from "../../../models/Alert";
import PendingCircle from "../../../components/PendingCircle/PendingCircle";
import acquireToken from "../../../api/MsalUtil";
import { loginRequest } from "../../../authConfig";
import { useRootStore } from "../../../RootStateContext";
import useStyles from "./AlertSettingsViewStyles";
import AlertSetting, { SingleAlertSetting } from "../../../models/AlertSetting";
import { UserRole } from "../../../models/UserRole";
import useResponsivity from "../../../hooks/useResponsivity";

type Generic = {
    [key: string]: unknown
}

interface IRowProps<T extends Generic> {
    alertState: SingleAlertSetting;
    alertType: AlertType;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    smsDisabled: boolean;
}

const ALERT_TRESHOLD_MIN = 10;
const ALERT_TRESHOLD_MAX = 10000;
const ALERT_TRESHOLD_STEP = 5;

export default observer((): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const authStore = useRootStore().authenticationStore;
  const { usersAlertSettings, currentUser, fetchingUserDetails, updatingAlertSettingInProgress } = authStore;
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);

  const { instance, accounts } = useMsal();

  const [yearlyAlertSettingState, setYearlyAlertSettingState] = React.useState<number|"">(usersAlertSettings?.yearAlertSettingPercentage || "");
  const [weeklyAlertSettingState, setWeeklyAlertSettingState] = React.useState<number|"">(usersAlertSettings?.weekAlertSettingPercentage || "");
  const [monthlyAlertSettingState, setMonthlyAlertSettingState] = React.useState<number|"">(usersAlertSettings?.monthAlertSettingPercentage || "");

  const [smsOnState, setSmsOnState] = React.useState<boolean>(usersAlertSettings?.smsOn || false);
  const [emailOnState, setEmailOnState] = React.useState<boolean>(usersAlertSettings?.emailOn || false);

  const { isMobile } = useResponsivity();

  const resetInputFields = (): void => {
    setWeeklyAlertSettingState(usersAlertSettings?.weekAlertSettingPercentage || "");
    setMonthlyAlertSettingState(usersAlertSettings?.monthAlertSettingPercentage || "");
    setYearlyAlertSettingState(usersAlertSettings?.yearAlertSettingPercentage || "");
  };

  function IconWithArrow(): JSX.Element {
  return (
      <span
        style={{
          display: "flex",
          justifyContent: "Center",
          alignItems: "Center",
          marginRight: "5px",
        }}
      >
          <InfoIcon />
          <ArrowForwardIcon />
      </span>
  )
}

  const [leakState, setLeakState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.WATER_LEAK,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.WATER_LEAK)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.WATER_LEAK)?.sendEmail || false : false,
  });

  const [consumptionState, setConsumptionState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.CONSUMPTION,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.CONSUMPTION)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.CONSUMPTION)?.sendEmail || false : false,
  });

  const [overFlowState, setOverFlowState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.OVERFLOW,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.OVERFLOW)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.OVERFLOW)?.sendEmail || false : false,
  });

  const [tamperState, setTamperState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.TAMPER,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.TAMPER)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.TAMPER)?.sendEmail || false : false,
  });

  const [burstState, setBurstState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.BURST,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.BURST)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.BURST)?.sendEmail || false : false,
  });

  const [lowBatteryState, setLowBatteryState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.LOW_BATTERY,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.LOW_BATTERY)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.LOW_BATTERY)?.sendEmail || false : false,
  });

  const [reverseFlowState, setReverseFlowState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.REVERSE_FLOW,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.REVERSE_FLOW)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.REVERSE_FLOW)?.sendEmail || false : false,
  });

  const [wrongInstallationState, setWrongInstallationState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.WRONG_INSTALLATION,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.WRONG_INSTALLATION)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.WRONG_INSTALLATION)?.sendEmail || false : false,
  });

  const [permanentState, setPermanentState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.PERMANENT,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.PERMANENT)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.PERMANENT)?.sendEmail || false : false,
  });

  const [connectionErrorState, setConnectionErrorState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.CONNECTION_ERROR,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.CONNECTION_ERROR)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.CONNECTION_ERROR)?.sendEmail || false : false,
  });

  const [noWaterState, setNoWaterState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.NO_WATER,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.NO_WATER)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.NO_WATER)?.sendEmail || false : false,
  });

  const [temperatureErrorState, setTemperatureErrorState] = React.useState<SingleAlertSetting>({
    alertType: AlertType.LOW_TEMPERATURE,
    sendSMS: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.LOW_TEMPERATURE)?.sendSMS || false : false,
    sendEmail: (usersAlertSettings && usersAlertSettings.settings) ? usersAlertSettings.settings.find((a) => a.alertType === AlertType.LOW_TEMPERATURE)?.sendEmail || false : false,
  });

  const handleLeakAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLeakState({
      ...leakState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleConsumptionAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConsumptionState({
      ...consumptionState,
      [event.target.name]: event.target.checked,
    });
  };
  const handleOverFlowAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setOverFlowState({
      ...overFlowState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleTamperAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTamperState({
      ...tamperState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleBurstAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBurstState({
      ...burstState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleLowBatteryAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLowBatteryState({
      ...lowBatteryState,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePermanentAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPermanentState({
      ...permanentState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleWrongInstallationAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setWrongInstallationState({
      ...wrongInstallationState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleReverseFlowAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setReverseFlowState({
      ...reverseFlowState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleConnectionErrorAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConnectionErrorState({
      ...connectionErrorState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleTemperatureAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTemperatureErrorState({
      ...temperatureErrorState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleNoWaterAlertChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNoWaterState({
      ...noWaterState,
      [event.target.name]: event.target.checked,
    });
  };

  const updateUsersAlertSettings = async (): Promise<void> => {
    const settings:AlertSetting = {
      settings: [
        leakState,
        consumptionState,
        overFlowState,
        tamperState,
        burstState,
        lowBatteryState,
        reverseFlowState,
        wrongInstallationState,
        permanentState,
        connectionErrorState,
        noWaterState,
        temperatureErrorState],
      yearAlertSettingPercentage: yearlyAlertSettingState !== "" ? yearlyAlertSettingState : undefined,
      monthAlertSettingPercentage: monthlyAlertSettingState !== "" ? monthlyAlertSettingState : undefined,
      weekAlertSettingPercentage: weeklyAlertSettingState !== "" ? weeklyAlertSettingState : undefined,
      smsOn: smsOnState,
      emailOn: emailOnState,
    };
    if (!currentUserToken) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const response = await acquireToken(instance, request);

      if (response) {
        await authStore.updateCurrentUsersAlertSettings(response.idToken, settings);
        if (authStore.error === undefined) {
          toast.info(t("alertView.alertSettingsUpdated"), {
            toastId: "AlertSettingsUpdatedToast",
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
          });
        }
      }
    } else {
      await authStore.updateCurrentUsersAlertSettings(currentUserToken, settings);
      if (authStore.error === undefined) {
        toast.info(t("alertView.alertSettingsUpdated"), {
          toastId: "AlertSettingsUpdatedToast",
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  function RowElement<T extends Generic>({ alertType, alertState, handleChange, smsDisabled }: IRowProps<T>): JSX.Element {
  return (
      <TableRow
        className={`${classes.root} ${isMobile && classes.rootMobile}`}
        tabIndex={-1}
        key={`alertrow_${alertType}`}
      >
          <TableCell
            component="th"
            scope="row"
            className={classes.alertType}
          >
              {isMobile ? t(`alertTypeShort.${alertType}`) : t(`alertType.${alertType}`)}
              {alertType === AlertType.CONNECTION_ERROR && (
                  <Tooltip title="Järjestelmähälytys (> 48h)">
                      <InfoIcon className={classes.alertInfoIcon} fontSize="small" />
                  </Tooltip>
              )}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
              <FormControlLabel
                control={
                    <Switch checked={alertState.sendEmail} onChange={handleChange} name="sendEmail" color="primary" disabled={!emailOnState} />
                    }
                label={alertState.sendEmail ? `${t("common.yes")}` : `${t("common.no")}`}
              />
              {" "}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
              <FormControlLabel
                control={
                    <Switch checked={alertState.sendSMS} onChange={handleChange} name="sendSMS" disabled={smsDisabled || !smsOnState} />
                  }
                label={alertState.sendSMS ? `${t("common.yes")}` : `${t("common.no")}`}
              />
              {" "}
          </TableCell>
          {!isMobile && (
              <TableCell
                component="th"
                scope="row"
              />
)}
      </TableRow>
  )
}

  const tresholdInputKeydown = (e: React.KeyboardEvent<HTMLDivElement>) : void => {
    const disallowedKeycodes = [",", "."];

    if (disallowedKeycodes.includes(e.key)) { e.preventDefault(); e.stopPropagation(); }
  };

  const tresholdInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, scale: "week" | "month" | "year") : void => {
    function setTreshold(scale: "week" | "month" | "year", value: number | "") : void {
      switch (scale) {
        case "week":
            setWeeklyAlertSettingState(value);
            break;
        case "month":
            setMonthlyAlertSettingState(value);
            break;
        case "year":
            setYearlyAlertSettingState(value);
            break;
        default:
          break;
      }
    }

    if (!e.target.value) {
      setTreshold(scale, "");
      return;
    }

    let val = parseInt(e.target.value, 10);
    if (val > ALERT_TRESHOLD_MAX) { val = ALERT_TRESHOLD_MAX; }
    if (val < ALERT_TRESHOLD_MIN) { val = ALERT_TRESHOLD_MIN; }

    setTreshold(scale, val);
  };

  if (fetchingUserDetails) {
    return <PendingCircle />;
  }

  return (
      <Container className={`${classes.container}`}>
          <Grid container className={`${classes.metersHeadGrid} ${isMobile && classes.metersHeadGridMobile}`}>
              <Grid
                container
                item
                xs={isMobile ? 12 : 3}
                justifyContent="flex-start"
                className={classes.alertsOnContainer}
              >
                  <Paper elevation={0}>
                      <Typography variant="h6" className={classes.h6Title}>
                          {t("alertView.receiveAlerts")}
                      </Typography>
                      <FormGroup>
                          <FormControlLabel
                            control={(
                                <Checkbox
                                  disabled={!currentUser?.mobilePhone}
                                  checked={smsOnState}
                                  onChange={(e) => setSmsOnState(e.target.checked)}
                                  name="smsOn"
                                />
                            )}
                            label={`${t("alertView.bySms")}: ${currentUser?.mobilePhone ? currentUser?.mobilePhone : "-"}`}
                            className={classes.smsSetting}
                          />
                          <FormControlLabel
                            control={(
                                <Checkbox
                                  disabled={!currentUser?.email}
                                  checked={emailOnState}
                                  onChange={(e) => setEmailOnState(e.target.checked)}
                                  name="emailOn"
                                />
                            )}
                            label={`${t("alertView.byEmail")}: ${currentUser?.email}`}
                            className={classes.smsSetting}
                          />
                      </FormGroup>
                  </Paper>
              </Grid>
              <Grid
                container
                item
                xs={isMobile ? 12 : 9}
                className={classes.consumptionSettingsContainer}
                style={{
                      marginTop: isMobile ? "15px" : "0",
                }}
                rowGap={isMobile ? "20px" : 0}
              >
                  <Typography variant="h6" className={classes.h6Title}>
                      {t("alertView.selectConsumptionLimitSettings")}
                  </Typography>
                  <Grid
                    container
                    item
                    alignItems="center"
                    xs={12}
                    display="grid"
                    gridTemplateColumns={isMobile ? "1fr" : "min-content auto"}
                    columnGap={isMobile ? "0" : "3%"}
                    rowGap={isMobile ? "15px" : "0"}
                  >
                      <Grid item>
                          <span className={`${classes.consumptionSettingsLabel} ${isMobile && classes.consumptionSettingsLabelMobile}`}>{t("alertView.notificationWhenWeeklyUp")}</span>
                      </Grid>
                      <Grid item>
                          <FormControl sx={{
                                width: isMobile ? "100%" : "120px",
                          }}
                          >
                              <TextField
                                id="weeklyAlertSettingInput"
                                inputProps={{
                                      max: ALERT_TRESHOLD_MAX,
                                      min: ALERT_TRESHOLD_MIN,
                                      step: ALERT_TRESHOLD_STEP,
                                      inputMode: "numeric",
                                      pattern: "[0-9]*",
                                      className: classes.tresholdInput,
                                }}
                                label={t("alertView.tresholdInputWeek")}
                                type="number"
                                variant="standard"
                                color="primary"
                                value={weeklyAlertSettingState}
                                onChange={(e) => tresholdInputChange(e, "week")}
                                onKeyDown={tresholdInputKeydown}
                              />
                          </FormControl>
                      </Grid>
                      <Grid item>
                          <span className={`${classes.consumptionSettingsLabel} ${isMobile && classes.consumptionSettingsLabelMobile}`}>{t("alertView.notificationWhenMonthlyUp")}</span>
                      </Grid>
                      <Grid item>
                          <FormControl sx={{
                                width: isMobile ? "100%" : "120px",
                          }}
                          >
                              <TextField
                                id="monthlyAlertSettingInput"
                                inputProps={{
                                      max: ALERT_TRESHOLD_MAX,
                                      min: ALERT_TRESHOLD_MIN,
                                      step: ALERT_TRESHOLD_STEP,
                                      inputMode: "numeric",
                                      pattern: "[0-9]*",
                                      className: classes.tresholdInput,
                                }}
                                label={t("alertView.tresholdInputMonth")}
                                type="number"
                                variant="standard"
                                color="primary"
                                value={monthlyAlertSettingState}
                                onChange={(e) => tresholdInputChange(e, "month")}
                                onKeyDown={tresholdInputKeydown}
                              />
                          </FormControl>
                      </Grid>
                      <Grid item>
                          <span className={`${classes.consumptionSettingsLabel} ${isMobile && classes.consumptionSettingsLabelMobile}`}>{t("alertView.notificationWhenYearlyUp")}</span>
                      </Grid>
                      <Grid item>
                          <FormControl sx={{
                                width: isMobile ? "100%" : "120px",
                          }}
                          >
                              <TextField
                                id="yearlyAlertSettingInput"
                                inputProps={{
                                      max: ALERT_TRESHOLD_MAX,
                                      min: ALERT_TRESHOLD_MIN,
                                      step: ALERT_TRESHOLD_STEP,
                                      inputMode: "numeric",
                                      pattern: "[0-9]*",
                                      className: classes.tresholdInput,
                                }}
                                label={t("alertView.tresholdInputYear")}
                                type="number"
                                variant="standard"
                                color="primary"
                                value={yearlyAlertSettingState}
                                onChange={(e) => tresholdInputChange(e, "year")}
                                onKeyDown={tresholdInputKeydown}
                              />
                          </FormControl>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          <Container className={classes.container}>
              <Paper className={classes.tabBar}>
                  <div>{t("alertView.manageAlertSettings")}</div>
              </Paper>
              <Paper>
                  <Paper className={classes.tableContainer}>
                      <Table aria-label="collapsible table" size="small">
                          <TableHead>
                              <TableRow className={`${classes.root} ${isMobile && classes.rootMobile}`}>
                                  <TableCell
                                    style={{
                                          minWidth: isMobile ? 0 : 175, fontWeight: "bold",
                                    }}
                                  >
                                      <>{t("alertView.alertType")}</>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                          minWidth: isMobile ? 0 : 175, fontWeight: "bold",
                                    }}
                                  >
                                      <>{t("alertView.sendEmail")}</>
                                  </TableCell>

                                  <TableCell
                                    style={{
                                          minWidth: isMobile ? 0 : 175, fontWeight: "bold",
                                    }}
                                  >
                                      <>{t("alertView.sendSMS")}</>
                                  </TableCell>
                                  {!isMobile && (
                                      <TableCell
                                        style={{
                                          minWidth: 235, fontWeight: "bold",
                                        }}
                                      >
                                          <Button className={classes.infoButton} variant="outlined" color="secondary" target="_blank" href="https://digitaiot.zendesk.com/hc/fi">
                                              <IconWithArrow />
                                              {" "}
                                              {t("alertView.moreInfo")}
                                          </Button>
                                      </TableCell>
)}
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <RowElement alertType={AlertType.WATER_LEAK} alertState={leakState} handleChange={handleLeakAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                              <RowElement alertType={AlertType.CONSUMPTION} alertState={consumptionState} handleChange={handleConsumptionAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                              <RowElement alertType={AlertType.BURST} alertState={burstState} handleChange={handleBurstAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                              <RowElement alertType={AlertType.LOW_TEMPERATURE} alertState={temperatureErrorState} handleChange={handleTemperatureAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                              {(currentUser?.userRole === UserRole.OV_ADMIN || currentUser?.userRole === UserRole.OV_CUSTOMER_ADMIN) && (
                                  <>
                                      <RowElement alertType={AlertType.REVERSE_FLOW} alertState={reverseFlowState} handleChange={handleReverseFlowAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.WRONG_INSTALLATION} alertState={wrongInstallationState} handleChange={handleWrongInstallationAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.PERMANENT} alertState={permanentState} handleChange={handlePermanentAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.OVERFLOW} alertState={overFlowState} handleChange={handleOverFlowAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.TAMPER} alertState={tamperState} handleChange={handleTamperAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.LOW_BATTERY} alertState={lowBatteryState} handleChange={handleLowBatteryAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.NO_WATER} alertState={noWaterState} handleChange={handleNoWaterAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                      <RowElement alertType={AlertType.CONNECTION_ERROR} alertState={connectionErrorState} handleChange={handleConnectionErrorAlertChange} smsDisabled={!currentUser?.mobilePhone} />
                                  </>
                                  )}
                          </TableBody>
                      </Table>
                  </Paper>
              </Paper>
          </Container>

          <Button
            id="save-alert-settings-button"
            onClick={updateUsersAlertSettings}
            disabled={updatingAlertSettingInProgress}
            className={isMobile ? classes.mapButtonMobile : classes.mapButton}
            variant="contained"
            color="secondary"
          >
              {t("alertView.saveChanges")}

          </Button>

      </Container>
  );
});

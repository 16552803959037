import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

export default makeStyles(() => createStyles({
  container: {
    paddingTop: 70,
    minWidth: "100%",
    minHeight: "100vh",
  },
  headerContainer: {
    textAlign: "center",
  },
  text: {
    fontSize: "16pt",
    marginTop: "15px",
    textAlign: "center",
  },
}));

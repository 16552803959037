import { action, makeObservable, observable, runInAction } from "mobx";
import BaseStore from "./BaseStore";
import { login } from "../api/AuthorizationApi";
import { getUserDetails, updatePersonalAlertSetting } from "../api/UserApi";
import { UserInfo } from "../models/UserInfo";
import AlertSetting from "../models/AlertSetting";
import i18n from "../i18n";
import { LatLngPoint } from "../models/Map";
import { getOrganization, setDefaultMapLocation } from "../api/OrganizationApi";
import { savePrices } from "../api/PriceApi";
import { WaterPrice } from "../models/Pricing";
import { covertOrganizationToWaterPriceOrganization } from "../utils/BasicUtils";

export default class AuthenticationStore extends BaseStore {
  currentUser?: UserInfo = undefined;

  idToken? : string = undefined;

  authenticated = false;

  usersAlertSettings?: AlertSetting = undefined;

  fetchingUserDetails = true;

  updatingAlertSettingInProgress = false;

  login = async (): Promise<void> => {
    await login();
  }

  setIdToken = (idToken: string) : void => {
    runInAction(() => {
      this.idToken = idToken;
    });
  }

  fetchUserDetails = async (userToken: string): Promise<UserInfo> => {
    try {
      this.fetchingUserDetails = true;
      const u = await getUserDetails(userToken);
      runInAction(() => {
        this.currentUser = u;
        this.usersAlertSettings = u.alertSettings;
        this.fetchingUserDetails = false;
      });
      return u;
    } catch (e) {
      this.fetchingUserDetails = false;
      this.setError("Virhe käyttäjän tietojen haussa");
      return Promise.reject();
    }
  }

  updateCurrentUsersAlertSettings = async (currentUserToken: string, settings: AlertSetting): Promise<void> => {
    try {
      this.updatingAlertSettingInProgress = true;
      const updated = await updatePersonalAlertSetting(currentUserToken, settings);
      runInAction(() => {
        this.usersAlertSettings = updated;
        this.updatingAlertSettingInProgress = false;
      });
    } catch (e) {
      this.setError(i18n.t("alertStore.alertSettingUpdateError"));
      this.updatingAlertSettingInProgress = false;
    }
  }

  // This method is here because we need to update the state of currentUser.defaultMapView
  // Easier to do it from within the same store
  setDefaultMapLocation = async (userToken: string, orgId: string, location: LatLngPoint, zoomLevel: number) : Promise<void> => {
    if (!location || !location.lat || !location.lng || !zoomLevel) { return Promise.reject(); }

    try {
      await setDefaultMapLocation(userToken, orgId, {
        latitude: location.lat,
        longitude: location.lng,
        zoomLevel,
      });

      if (this.currentUser && this.currentUser.defaultMapView) {
        runInAction(() => {
          this.currentUser!.defaultMapView = {
            location: {
              coordinates: [location.lng!, location.lat!], // We check location lat and lng, so safely tell ts they're not undef
              type: "Point",
            },
            zoomLevel,
          };
        });
      }

      return Promise.resolve();
    } catch (e) {
      this.setError(i18n.t("organizationStore.orgUpdatingError"));
      console.error(e.stack);
      return Promise.reject(e.stack);
    }
  }

  // This method is here because we need to update the state of currentUser.waterPrice
  // Easier to do it from within the same store
  savePrices = async (userToken: string, prices: WaterPrice, organizationId: string): Promise<void> => {
    const organization = await getOrganization(userToken, organizationId);
    const waterPriceOrganization = covertOrganizationToWaterPriceOrganization(organization);

    waterPriceOrganization.waterPrice = prices;

    const resp = await savePrices(userToken, waterPriceOrganization, organizationId);

    runInAction(() => {
      if (this.currentUser) { this.currentUser.waterPrice = prices; }
    });
  }

  clearState = (): void => {
    runInAction(() => {
      this.currentUser = undefined;
      this.usersAlertSettings = undefined;
      this.error = undefined;
      this.fetchingUserDetails = true;
      this.updatingAlertSettingInProgress = false;
    });
  }

  constructor() {
    super();
    makeObservable(this, {
      currentUser: observable,
      authenticated: observable,
      usersAlertSettings: observable,
      updatingAlertSettingInProgress: observable,
      login: action,
      fetchUserDetails: action,
      fetchingUserDetails: observable,
      idToken: observable,
      error: observable,
      updateCurrentUsersAlertSettings: action,
      clearState: action,
      setDefaultMapLocation: action,
      savePrices: action,
    });
  }
}

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Container, Typography, Grid, TextField, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import "@fontsource/roboto";

import { Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import useStyles from "./PriceViewStyles";
import { WaterPrice } from "../../models/Pricing";
import PendingCircle from "../../components/PendingCircle/PendingCircle";
import { useRootStore } from "../../RootStateContext";

interface IPricesConfigViewProps {
  savePrices: (s: WaterPrice) => void;
}

export default observer(({ savePrices }: IPricesConfigViewProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { currentUser, fetchingUserDetails } = useRootStore().authenticationStore;

  const [editMode, setEditMode] = useState<boolean>(false);

  const [basePriceEdited, setBasePriceEdited] = useState<number | undefined>(currentUser?.waterPrice?.price);
  const [warmWaterEdited, setWarmWaterEdited] = useState<number | undefined>(currentUser?.waterPrice?.hotWaterPrice);
  const [coldWaterEdited, setColdWaterEdited] = useState<number | undefined>(currentUser?.waterPrice?.coldWaterPrice);

  useEffect(() => {
    setBasePriceEdited(currentUser?.waterPrice?.price);
    setWarmWaterEdited(currentUser?.waterPrice?.hotWaterPrice);
    setColdWaterEdited(currentUser?.waterPrice?.coldWaterPrice);
  }, [currentUser?.waterPrice])

  const saveNewPricing = (): void => {
    if (!basePriceEdited || !warmWaterEdited || !coldWaterEdited) {
        toast.info(t("pricing.priceSaveError"), {
            toastId: "priceSaveErrorToast",
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
        });
        return;
    }

    setEditMode(false);

    const pricing : WaterPrice = {
        price: basePriceEdited,
        hotWaterPrice: warmWaterEdited,
        coldWaterPrice: coldWaterEdited,
    };

    savePrices(pricing);
  };

  const priceChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, whichPrice: "BASE" | "WARM" | "COLD") : void => {
    const rawValue = e.target.value;
    const numberValue = parseFloat(rawValue);

    switch (whichPrice) {
        case "BASE":
            setBasePriceEdited(numberValue);
            break;
        case "WARM":
            setWarmWaterEdited(numberValue);
            break;
        case "COLD":
            setColdWaterEdited(numberValue);
            break;
        default:
            break;
    }
  }

  return (
      <>
          <Container className={`${classes.container} ${classes.topContainer}`}>
              <Grid container>
                  <Grid item xs={3}>
                      <Typography variant="h4" className={classes.headerTop}>{t("navigation.prices")}</Typography>
                  </Grid>
              </Grid>
          </Container>
          {fetchingUserDetails ? <PendingCircle /> : (
              <Container className={`${classes.container}`}>
                  <Card className={classes.topCard} variant="elevation" elevation={2}>
                      <Grid container item xs={12} gap="15px">
                          <Grid item xs={12}>
                              <Typography variant="h5">{t("pricing.priceInfo")}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                              <Grid container item>
                                  <Grid container item xs={2} alignContent="center" className={classes.topCardDataTitle}>
                                      {editMode ? `${t("pricing.basePrice")} € / kk` : t("pricing.basePrice")}
                                  </Grid>
                                  {editMode ? (
                                      <Grid item xs={10}>
                                          <TextField
                                            id="priceBasePriceEdit"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: 9999,
                                                    step: "any",
                                                },
                                            }}
                                            variant="outlined"
                                            value={basePriceEdited}
                                            onChange={(e) => priceChanged(e, "BASE")}
                                          />
                                      </Grid>
                                          )
                                            : (
                                                <Grid item xs={10} id="base-price">
                                                    {currentUser?.waterPrice && (
                                                        <span>
                                                            {currentUser?.waterPrice?.price}
                                                            {" "}
                                                            € / kk
                                                        </span>
                                                    )}
                                                </Grid>
                                            )}
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              <Grid container>
                                  <Grid container item xs={2} alignContent="center" className={classes.topCardDataTitle}>
                                      {editMode ? `${t("pricing.warm")} € / m³` : t("pricing.warm")}
                                  </Grid>
                                  {editMode ? (
                                      <Grid item xs={10}>
                                          <TextField
                                            id="priceaWarmPriceEdit"
                                            InputLabelProps={{
                                                  shrink: true,
                                            }}
                                            type="number"
                                            variant="outlined"
                                            value={warmWaterEdited}
                                            onChange={(e) => priceChanged(e, "WARM")}
                                          />
                                      </Grid>
                                          )
                                            : (
                                                <Grid item xs={10} id="warm-water-price">
                                                    {currentUser?.waterPrice && (
                                                        <span>
                                                            {currentUser?.waterPrice?.hotWaterPrice}
                                                            {" "}
                                                            € / m³
                                                        </span>
                                                    )}
                                                </Grid>
                                            )}
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              <Grid container>
                                  <Grid container item xs={2} alignContent="center" className={classes.topCardDataTitle}>
                                      {editMode ? `${t("pricing.cold")} € / m³` : t("pricing.cold")}
                                  </Grid>
                                  {editMode ? (
                                      <Grid item xs={10}>
                                          <TextField
                                            id="priceColdPriceEdit"
                                            InputLabelProps={{
                                                  shrink: true,
                                            }}
                                            type="number"
                                            variant="outlined"
                                            value={coldWaterEdited}
                                            onChange={(e) => priceChanged(e, "COLD")}
                                          />
                                      </Grid>
                                          )
                                            : (
                                                <Grid item xs={10} id="cold-water-price">
                                                    {currentUser?.waterPrice && (
                                                        <span>
                                                            {currentUser?.waterPrice?.coldWaterPrice}
                                                            {" "}
                                                            € / m³
                                                        </span>
                                                    )}
                                                </Grid>
                                            )}
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              {!editMode && (
                                  <Grid container>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        id="editPricesButton"
                                        onClick={() => setEditMode(true)}
                                      >
                                          <Edit />
                                          {t("common.edit")}
                                      </Button>
                                  </Grid>
                                      )}
                              {editMode && (
                                  <Grid container>
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        id="editPricesButton"
                                        onClick={() => setEditMode(false)}
                                      >
                                          <CancelIcon />
                                          {t("common.cancel")}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        id="editPricesButton"
                                        className={classes.secondButton}
                                        onClick={() => saveNewPricing()}
                                      >
                                          <SaveIcon />
                                          {t("common.save")}
                                      </Button>
                                  </Grid>
                                      )}
                          </Grid>
                      </Grid>
                  </Card>
              </Container>
          )}

      </>
  );
});

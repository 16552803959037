import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../RootStateContext";
import BaseStore from "./BaseStore";

export default observer(() => {
  const store = useRootStore();
  const storeErrors = Object.values(store)
    .filter((s) => s instanceof BaseStore)
    .filter((s) => s.error !== null && s.error !== undefined)
    .map((s) => s.error);

  useEffect(() => {
    storeErrors.forEach((error) => {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    });
  }, [storeErrors]);

  return (
      <ToastContainer autoClose={3000} />
  );
});

import React, { FC, ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { useErrorBoundary, withErrorBoundary } from "react-use-error-boundary";
import MainView from "./views/main/MainView";

const env = process.env.APP_ENV || undefined;

let App: FC;

if (env === "local") {
  App = withErrorBoundary(() => {
    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
      console.error(error, errorInfo);
    });

    if (error) {
      return (
          <div>
              <p>{error instanceof Error ? error.message : "Unknown"}</p>
              <button type="button" onClick={resetError}>Try again</button>
          </div>
      );
    }

    return <MainView />;
  });
} else {
  App = () => <MainView />;
}

export default observer(App);
